import React from "react";
import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import RoleDeletePopup from "./Settings/RoleDeletePopup";
import { useContext } from "react";
import TadobaContext from "./Context/TadobaContext";
import UserEdit from "./Settings/UserEdit";
import GeoLocationEdit from "./Settings/GeoLocationEdit";
import Nodata from "./Nodata";
import GeolocationDeletePopup from "./Settings/GeolocationDeletePopup";
import SpeciesEdit from "./Settings/SpeciesEdit";
import TigerEdit from "./Settings/TigerEdit";
import LostTigersEdit from "./Settings/LostTigersEdit";
import TigerConflictView from "./Settings/TigerConflictView";
import SpeciesDelete from "./Settings/SpeciesDelete";
import TigerDelete from "./Settings/TigerDelete";
import SmallLoader from "./SmallLoader";
import LazyImage from "./LazyImage";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Select, Space } from "antd";
import { toast } from "react-toastify";
import { makeRequest } from "../api";
import { successToaster, warningToaster } from "../Data/Data";

const Setting_User_Table = (props) => {
  const viewHandler = props.viewHandler;
  const editHandler = props.editHandler;
  const [columnName, setColumnName] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [render, setRender] = useState(1);
  const [deleteId, setDeleteId] = useState([]);
  const api = useContext(TadobaContext);
  const [Checked, setChecked] = useState(
    props.data.map((row, key) => {
      const obj = { ...row };
      obj.checked = false;
      return obj;
    })
  );
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [defaultYear, setDefaultYear] = useState(props.defaultYear);
  const [showCheckBox, setShowCheckBox] = useState(true);

  useEffect(() => {
    setPartialCheck(false);
    createYearOptions();
    Checked.map((value, key) => {
      if (value.checked === true) {
        setPartialCheck(true);
      }
      if (value.checked === false) {
        setAllCheck(false);
      }
    });
  }, [render]);

  useEffect(() => {
    const path = window.location.pathname;
    if (
      path === "/find_tiger/lost_tigers" ||
      path === "/find_tiger/tiger_risk" ||
      path === "/find_tiger/tiger_conflicts"
    ) {
      setShowCheckBox(false);
    }
  }, []);

  const createYearOptions = () => {
    const getCurrentYear = () => new Date().getFullYear();
    const startYear = 2021;
    const currentYear = getCurrentYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push({ value: year.toString(), label: year.toString() });
    }
    setYearOptions(years);
  };

  useEffect(() => {
    setChecked(
      props.data.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      })
    );
    setPartialCheck(false);
    setAllCheck(false);
  }, [props.data]);

  const checkboxChangeHandler = (row) => {
    if (allCheck === true) {
      setDeleteId([]);
      return true;
    }
    if (props.type === "Users") {
      const newData = [...Checked];
      setChecked(
        newData.map((value, key) => {
          if (row["Email"] === value["Email"]) {
            value.checked = !value.checked;
            if (value.checked) {
              setDeleteId([...deleteId, row["Email"]]);
            }
            if (value.checked === false) {
              const index = deleteId.indexOf(row["Email"]);
              deleteId.splice(index, 1);
              setDeleteId(deleteId);
            }
            return value;
          } else return value;
        })
      );
      setRender(render + 1);
    } else if (props.type === "Species") {
      const newData = [...Checked];
      setChecked(
        newData.map((value, key) => {
          if (row["Species name"] === value["Species name"]) {
            value.checked = !value.checked;
            const species = api.allSpecies.filter(function (item) {
              return item.name === row["Species name"];
            });
            if (value.checked) {
              setDeleteId([...deleteId, species[0].id]);
            }
            if (value.checked === false) {
              const index = deleteId.indexOf(species[0].id);
              deleteId.splice(index, 1);
              setDeleteId(deleteId);
            }
            return value;
          } else return value;
        })
      );
      setRender(render + 1);
    } else if (props.type === "Tiger") {
      const newData = [...Checked];
      setChecked(
        newData.map((value, key) => {
          if (row["Tiger Name"] === value["Tiger Name"]) {
            value.checked = !value.checked;
            if (value.checked) {
              setDeleteId([...deleteId, row["Tiger Name"]]);
            }
            if (value.checked === false) {
              const index = deleteId.indexOf(row["Tiger Name"]);
              deleteId.splice(index, 1);
              setDeleteId(deleteId);
            }
            return value;
          } else return value;
        })
      );
      setRender(render + 1);
    } else {
      const newData = [...Checked];
      setChecked(
        newData.map((value, key) => {
          if (row["Camera ID"] === value["Camera ID"]) {
            value.checked = !value.checked;
            if (value.checked) {
              setDeleteId([...deleteId, row["Camera ID"]]);
            }
            if (value.checked === false) {
              const index = deleteId.indexOf(row["Camera ID"]);
              deleteId.splice(index, 1);
              setDeleteId(deleteId);
            }
            return value;
          } else return value;
        })
      );
      setRender(render + 1);
    }
  };

  const AllCheckBoxChageHandler = () => {
    setDeleteId([]);
    const newData = [...Checked];
    const list = [...deleteId];
    if (allCheck === true) {
      setAllCheck(false);
      setChecked(
        newData.map((value, key) => {
          value.checked = false;
          return value;
        })
      );
    } else {
      if (props.type === "Users") {
        setChecked(
          newData.map((value, key) => {
            setAllCheck(true);
            value.checked = true;
            list.push(value["Email"]);
            return value;
          })
        );
      } else if (props.type === "Species") {
        setChecked(
          newData.map((value, key) => {
            setAllCheck(true);
            value.checked = true;
            list.push(value["Species name"]);
            return value;
          })
        );
      } else if (props.type === "Tigers") {
        setChecked(
          newData.map((value, key) => {
            setAllCheck(true);
            value.checked = true;
            list.push(value["Tiger name"]);
            return value;
          })
        );
      } else if (props.type === "losttigers") {
        setChecked(
          newData.map((value, key) => {
            setAllCheck(true);
            value.checked = true;
            list.push(value["Name"]);
            return value;
          })
        );
      } else {
        setChecked(
          newData.map((value, key) => {
            setAllCheck(true);
            value.checked = true;
            list.push(value["Camera ID"]);
            return value;
          })
        );
      }
    }
    setRender(render + 1);
  };

  useEffect(() => {
    let array = new Array();
    for (let key in props.data[0]) {
      array.push(key);
      setColumnName(array);
    }
  }, []);

  console.log(showCheckBox);

  useEffect(() => {
    if (props.data.length > 0) {
      const keys = Object.keys(props.data[0]);
      setColumnName(keys);
    }
  }, [props.data]);

  const scrollHandler = (e) => {
    if (props.type === "Geolocation") {
      if (props.data.length >= props.count) {
        return;
      }
      if (!props.scroll) {
        props.setPage(api.page + 1);
      }
    }
    if (
      props.type === "Tigers" ||
      props.type === "losttigers" ||
      props.type === "Tiger" ||
      props.type === "Tiger Conflicts" ||
      props.type === "Tiger Risk"
    ) {
      if (props.data.length >= props.count) {
        return;
      }
      if (!props.scroll) {
        props.setPage(props.currentPage + 1);
      }
    } else {
    }
  };

  const handleViewIconClick = (row) => {
    viewHandler(row);
  };

  const yearChangeHandler = (event) => {
    setSelectedYear(event);
    props.setDefaultYear(event);
  };

  const setDefault = async () => {
    console.log("default");
    setDefaultYear(selectedYear);

    let token = "";
    let body = [];
    body = { year: selectedYear };
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        `/api/v1/map/set_year_default/`,
        body
      );
      api.setDefaultYear(selectedYear);
      props.setDefaultYear(selectedYear);
      api.setGeolocationFilters(null);
      api.setAllGeolocations([]);
      api.setGeolocations([]);
      api.setPage(0);
      toast.success("Default Year set Successfully", successToaster);
    } catch (error) {
      toast.success("Error While setting Default Year", warningToaster);
    }
  };

  return (
    <div className="Setting_Table">
      {props.loading ? (
        <SmallLoader />
      ) : (
        <>
          <div className="Setting_Table_Header">
            <span>
              Showing {props.data.length} results{" "}
              {props?.total ? `of ${props.count}` : ""}
            </span>
            {partialCheck ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  className="deselect"
                  onClick={() => {
                    setAllCheck(false);
                    setPartialCheck(false);
                    setChecked(
                      props.data.map((row, key) => {
                        const obj = { ...row };
                        obj.checked = false;
                        return obj;
                      })
                    );
                  }}
                >
                  Deselect All
                </span>
                <div
                  onClick={() => {
                    props.deleteHandler(deleteId);
                    setAllCheck(false);
                    setDeleteId([]);
                    setPartialCheck(false);
                    setChecked(
                      props.data.map((row, key) => {
                        const obj = { ...row };
                        obj.checked = false;
                        return obj;
                      })
                    );
                  }}
                >
                  <img
                    src={require("../Assets/Icons/trash.png")}
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {props.type === "Geolocation" && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <div
                  className="DirectionDropdown"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select an year"
                    optionFilterProp="children"
                    size={"large"}
                    value={selectedYear}
                    onChange={yearChangeHandler}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={yearOptions}
                  />
                </div>
                <div
                  className="Setting_Add_Button"
                  style={{ width: "100%" }}
                  onClick={setDefault}
                >
                  <span>Make Default</span>
                </div>
              </div>
            )}
          </div>
          {props.data.length === 0 ? (
            <Nodata />
          ) : (
            <>
              {/* {props.loading === true ? <SmallLoader /> : */}
              <div className="Setting_Table_Layout" onScroll={scrollHandler}>
                <table>
                  <tr className="heading_row">
                    {showCheckBox && api.user.role.id === 2 ? (
                      <td className="row_check">
                        <div
                          className="checkbox"
                          onClick={() => {
                            AllCheckBoxChageHandler();
                          }}
                        >
                          {partialCheck ? (
                            <>
                              {allCheck ? (
                                <img
                                  src={require("../Assets/Icons/Checked_Box.png")}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require("../Assets/Icons/partialcheck.png")}
                                  alt=""
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={require("../Assets/Icons/checkbox.png")}
                              alt=""
                            />
                          )}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                    {columnName.map((value, index) => {
                      return (
                        <td key={index}>
                          <div>{value !== "Image" && <span>{value}</span>}</div>
                        </td>
                      );
                    })}
                    <td></td>
                  </tr>

                  {props.data.map((row, key) => {
                    if (key > props.count && props.type === "Geolocation") {
                      return "";
                    }
                    return (
                      <tr className="main_row" key={key}>
                        {showCheckBox && api.user.role.id === 2 ? (
                          <td className="row_check">
                            <div
                              className="checkbox"
                              onClick={() => {
                                checkboxChangeHandler(row);
                              }}
                            >
                              {Checked[key]?.checked ? (
                                <img
                                  src={require("../Assets/Icons/Checked_Box.png")}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require("../Assets/Icons/checkbox.png")}
                                  alt=""
                                />
                              )}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {columnName.map((value, index) => {
                          if (value === "Name") {
                            const u = api.users?.filter(
                              (ele) => row["Email"] === ele["email"]
                            );
                            return (
                              <td key={index}>
                                <div className="rowImgCombine">
                                  <div className="row_Img">
                                    {props.type === "losttigers" ? (
                                      <LazyImage src={row.Image} />
                                    ) : (
                                      <>
                                        {u[0]?.profile_picture === null ||
                                        u[0]?.profile_picture === "" ? (
                                          <img
                                            src={require("../Assets/Icons/person.png")}
                                            alt=""
                                          />
                                        ) : (
                                          <LazyImage
                                            src={u[0]?.profile_picture}
                                            placeholderSrc={require("../Assets/Icons/temp_img.png")}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <span>{row[value]}</span>
                                  </div>
                                </div>
                              </td>
                            );
                          } else
                            return (
                              <td key={index}>
                                <div>
                                  {value !== "Image" && (
                                    <span>{row[value]}</span>
                                  )}
                                </div>
                              </td>
                            );
                        })}
                        {api.user.role.id !== 3 ? (
                          <td className="row_icon">
                            <Popup
                              trigger={
                                <div className="row_edit">
                                  {props.type !== "Tiger Conflicts" &&
                                    props.type !== "Tiger Risk" && (
                                      <img
                                        src={require("../Assets/Icons/Edit.png")}
                                        className="row_edit_edit"
                                        alt=""
                                        onClick={() => {
                                          console.log("edit clicked");
                                        }}
                                      />
                                    )}

                                  <ReactTooltip
                                    anchorSelect=".row_edit_edit"
                                    place="top"
                                    style={{
                                      borderRadius: "2px",
                                      zIndex: "9999",
                                    }}
                                  >
                                    Edit
                                  </ReactTooltip>
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => {
                                if (props.type === "Users")
                                  // console.log("backend admin");
                                  return (
                                    <>
                                      {row["Role"] === "Backend admin" &&
                                      api.user.role.id !== 2 ? (
                                        <UserEdit
                                          access={true}
                                          close={close}
                                          accessHandler={props.accessHandler}
                                          row={row}
                                          editHandler={props.editHandler}
                                        />
                                      ) : (
                                        <UserEdit
                                          close={close}
                                          accessHandler={props.accessHandler}
                                          row={row}
                                          editHandler={props.editHandler}
                                        />
                                      )}
                                    </>
                                  );
                                if (props.type === "Species")
                                  return (
                                    <SpeciesEdit
                                      close={close}
                                      row={row}
                                      editHandler={props.editHandler}
                                    />
                                  );
                                if (props.type === "Tiger")
                                  return (
                                    <TigerEdit
                                      close={close}
                                      row={row}
                                      editHandler={props.editHandler}
                                    />
                                  );
                                if (props.type === "losttigers")
                                  return (
                                    <LostTigersEdit
                                      close={close}
                                      row={row}
                                      editHandler={props.editHandler}
                                    />
                                  );
                                else
                                  return (
                                    <GeoLocationEdit
                                      close={close}
                                      editHandler={props.editHandler}
                                      row={row}
                                    />
                                  );
                              }}
                            </Popup>
                            {props.type !== "losttigers" && (
                              <Popup
                                trigger={
                                  <div className="row_edit">
                                    {props.type === "Tiger Conflicts" && (
                                      <img
                                        src={require("../Assets/Icons/eye.png")}
                                        className="row_edit_edit"
                                        alt=""
                                      />
                                    )}
                                    <ReactTooltip
                                      anchorSelect=".row_edit_edit"
                                      place="top"
                                      style={{
                                        borderRadius: "2px",
                                        zIndex: "9999",
                                      }}
                                    >
                                      View
                                    </ReactTooltip>
                                  </div>
                                }
                                modal
                                nested
                              >
                                {(close) => {
                                  // if (props.type === "Users")
                                  //   return (
                                  //     <>
                                  //       {row["Role"] === "Backend admin" &&
                                  //       api.user.role.id !== 2 ? (
                                  //         <UserEdit
                                  //           access={true}
                                  //           close={close}
                                  //           accessHandler={props.accessHandler}
                                  //           row={row}
                                  //           editHandler={props.editHandler}
                                  //         />
                                  //       ) : (
                                  //         <UserEdit
                                  //           close={close}
                                  //           accessHandler={props.accessHandler}
                                  //           row={row}
                                  //           editHandler={props.editHandler}
                                  //         />
                                  //       )}
                                  //     </>
                                  //   );
                                  if (props.type === "Tiger Conflicts")
                                    return (
                                      <TigerConflictView
                                        close={close}
                                        row={row}
                                        editHandler={props.editHandler}
                                      />
                                    );

                                  return "";
                                }}
                              </Popup>
                            )}
                            <Popup
                              trigger={
                                props.delete ? (
                                  <div className="row_delete">
                                    {props.type !== "Tiger Conflicts" &&
                                      props.type !== "Tiger Risk" && (
                                        <img
                                          src={require("../Assets/Icons/trash-2.png")}
                                          className="row_delete_delete"
                                          alt=""
                                        />
                                      )}

                                    <ReactTooltip
                                      anchorSelect=".row_delete_delete"
                                      place="top"
                                      style={{
                                        borderRadius: "2px",
                                        zIndex: "9999",
                                      }}
                                    >
                                      Delete
                                    </ReactTooltip>
                                  </div>
                                ) : (
                                  ""
                                )
                              }
                              modal
                              nested
                            >
                              {(close) => {
                                if (props.type === "Users")
                                  return (
                                    <>
                                      {row["Role"] === "Backend admin" &&
                                      api.user.role.id !== 2 ? (
                                        <RoleDeletePopup
                                          access={true}
                                          close={close}
                                        />
                                      ) : (
                                        <RoleDeletePopup
                                          row={row}
                                          deleteHandler={props.deleteHandler}
                                          close={close}
                                          table={props.data}
                                        />
                                      )}
                                    </>
                                  );
                                if (props.type === "Species")
                                  return (
                                    <SpeciesDelete
                                      close={close}
                                      deleteHandler={props.deleteHandler}
                                      row={row}
                                    />
                                  );
                                if (props.type === "Tiger")
                                  return (
                                    <TigerDelete
                                      close={close}
                                      deleteHandler={props.deleteHandler}
                                      row={row}
                                    />
                                  );
                                else
                                  return (
                                    <GeolocationDeletePopup
                                      deleteHandler={props.deleteHandler}
                                      close={close}
                                      row={row}
                                    />
                                  );
                              }}
                            </Popup>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Setting_User_Table;
