import React, { useEffect, useState } from "react";
import { MapKey } from "../Data/Data";
import {
  GoogleMap,
  Marker,
  LoadScript,
  Polygon,
  Polyline,
  LoadScriptNext,
} from "@react-google-maps/api";
import { format } from "date-fns";
import TadobaContext from "../Components/Context/TadobaContext";
import { useContext } from "react";
import { Select, Space } from "antd";
import axios from "axios";
import { Radio, ConfigProvider } from "antd";
import { DatePicker } from "antd";
import MapTable from "../Components/Map/MapTable";
import { url } from "../Data/Data";
import { useRef } from "react";
import { makeRequest } from "../api";
import moment from "moment";
import YearIndicators from "../Components/Map/YearIndicators";
import html2canvas from "html2canvas";

const { RangePicker } = DatePicker;

const Mapview = ({
  tigerslist,
  camera,
  setCamera,
  CustomDate,
  setCustomDate,
  markers,
  setMarkers,
  duration,
  setDuration,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isYearlyTigerChecked,
  setIsYearlyTigerChecked,
  selectedSpecies,
  setSelectedSpecies,
  selectedTiger,
  setSelectedTigers,
  selectedCamera,
  setSelectedCamera,
  customDateChangeHandler,
  durationChangeHandler,
  downloadMap,
  id,
  setId,
  mapRef,
  setMapRef,
  selectedYear,
  isTigerNetworkChecked,
  setIsTigerNetworkChecked,
  buttonClicked,
  setButtonClicked,
}) => {
  const api = useContext(TadobaContext);
  const [species, setSpecies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [isTiger, setIsTiger] = useState(false);

  const [list, setList] = useState([
    "Barking Deer",
    "Bird",
    "Buffalo",
    "Spotted Deer",
    "Four horned antelope",
    "Common Palm Civet",
    "Cow",
    "Dog",
    "Gaur",
    "Goat",
    "Golden Jackal",
    "Hare",
    "Hyena",
    "Indian Fox",
    "Indian pangolin",
    "Indian Porcupine",
    "Jungle cat",
    "Jungle Fowls",
    "Langur",
    "Leopard",
    "Macaque",
    "Nilgai",
    "Palm squirrel",
    "Indian peafowl",
    "Honey badger",
    "Rodents",
    "Mongooses",
    "Rusty spotted cat",
    "Sambar",
    "Sheep",
    "Sloth bear",
    "Small indian civet",
    "Tiger",
    "Wild boar",
    "Wild dog",
    "Indian wolf",
    "Person",
    "Crocodile",
    "Indian Elephant",
    "Vehicle",
  ]);

  const [mapLoading, setMapLoading] = useState(false);
  const onLoad = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    if (selectedSpecies !== null && species && selectedTiger === null) {
      localStorage.setItem("selectedSpecies", JSON.stringify(selectedSpecies));
      setLoading(true);
      const item = species.filter((value) => {
        return value.value === selectedSpecies;
      });
      getZone(item[0]?.key);
      if (item[0]?.label === "Tiger") {
        setIsTiger(true);
      } else {
        setIsTiger(false);
      }
    } else if (selectedTiger !== null && species) {
      setIsTiger(true);
      localStorage.setItem("selectedTiger", JSON.stringify(selectedTiger));
      setLoading(true);
      const item = species.filter((value) => {
        return value.value === 0;
      });
      getZone(item[0]?.key, selectedTiger);
    }
  }, [species, selectedYear]);

  const getColorByYear = (year) => {
    const colorMap = {
      2024: "blue",
      2023: "green",
      2022: "red",
      2021: "yellow",
    };
    return colorMap[year] || "gray";
  };

  function getColor(count, maxCount, dates) {
    if (isYearlyTigerChecked) {
      const year = new Date(dates[0]).getFullYear();
      return getColorByYear(year);
    } else {
      const normalized = count / maxCount;
      const r = 255;
      const g = Math.round(255 * (1 - normalized));
      const b = 0;
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  function createColoredMarker(color, countOrRank) {
    return `data:image/svg+xml;charset=UTF-8,
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <circle cx="20" cy="20" r="10" fill="${color}" />
          <text x="20" y="20" text-anchor="middle" dy=".3em" font-family="Arial" font-size="12" fill="black">
            ${isTigerNetworkChecked ? countOrRank : countOrRank}
          </text>
      </svg>`;
  }

  const cameraChangeHandler = (value) => {
    if (value.length === 0) {
      setMarkers(
        camera.map((val, key) => {
          return {
            label: val.label,
            lat: val.lat,
            lng: val.lng,
            count: val.count,
            dates: val.dates,
          };
        })
      );
    } else {
      setMarkers(
        camera
          .filter((item) => value.includes(item.value))
          .map((val, key) => {
            return {
              label: val.label,
              lat: val.lat,
              lng: val.lng,
              count: val.count,
              dates: val.dates,
            };
          })
      );
    }
    localStorage.setItem("selectedCamera", JSON.stringify(value));
    setSelectedCamera(value);
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem("id", JSON.stringify(id));
    }
  }, [id]);

  const getZone = async (item = null, tigerId = null) => {
    setId(item);
    let data = {
      species_id: item,
      start_date: startDate,
      end_date: endDate,
      fixed_duration: duration,
      tigers_id: tigerId,
      ...(selectedYear ? { years: [+selectedYear] } : {}),
    };

    if (duration === "custom" && CustomDate !== null) {
      data = {
        ...data,
        start_date: format(new Date(CustomDate[0]), "yyyy-MM-dd"),
        end_date: format(new Date(CustomDate[1]), "yyyy-MM-dd"),
        duration: "",
      };
      setStartDate(format(new Date(CustomDate[0]), "yyyy-MM-dd"));
      setEndDate(format(new Date(CustomDate[1]), "yyyy-MM-dd"));
    }

    let token = "";
    let csrf = "";
    try {
      if (item !== null) {
        if (localStorage.token) {
          token = localStorage.token;
        }
        if (localStorage.csrf) {
          csrf = localStorage.csrf;
        }
        const result = await makeRequest(
          "post",
          `api/v1/map/camera_by_species/`,
          data
        );

        setCamera(
          result.data.data.map((value, key) => {
            return {
              label: value.station_name,
              value: key,
              id: value.station_id,
              lat: value.latitude,
              lng: value.longitude,
              count: value.count,
              dates: value.dates,
            };
          })
        );

        setMarkers(
          result.data.data.map((value, key) => {
            return {
              lat: value.latitude,
              label: value.station_name,
              lng: value.longitude,
              count: value.count,
              dates: value.dates,
            };
          })
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCamera.length > 0 && camera) {
      cameraChangeHandler(selectedCamera);
    }
  }, [camera]);

  useEffect(() => {
    if (selectedSpecies) {
      const item = species.filter((value) => {
        return value.value === selectedSpecies;
      });
      if (item.length > 0) {
        getZone(item[0].key);
      }
    }
  }, [duration]);

  useEffect(() => {
    if (selectedSpecies !== null || selectedSpecies !== undefined) {
      const item = species.filter((value) => {
        return value.value === selectedSpecies;
      });
      if (item.length > 0) {
        getZone(item[0].key, selectedTiger);
      }
    }
    // getZone();
  }, [startDate, endDate]);

  const speciesChangeHandler = (event) => {
    setSelectedSpecies(event);
    localStorage.setItem("selectedSpecies", JSON.stringify(event));
    setLoading(true);
    setSelectedCamera([]);
    setSelectedTigers(null);
    setCamera([]);
    const item = species.filter((value) => {
      return value.value === event;
    });
    getZone(item[0].key);
    if (item[0].label === "Tiger") {
      setIsTiger(true);
    } else {
      setIsTiger(false);
    }
  };

  useEffect(() => {
    if (api.allSpecies !== null) {
      setSpecies(
        api.allSpecies
          .filter((value) => list.includes(value.name))
          .sort((a, b) => list.indexOf(a.name) - list.indexOf(b.name))
          .map((value, key) => {
            return {
              label: value.name,
              value: key,
              key: value.id,
            };
          })
      );
    } else {
      setSpecies(null);
    }
  }, [api.allSpecies]);

  const handleMarkerDragEnd = (e) => {};

  const handleMapLoad = (map) => {
    setPosition({
      lat: map.latLng.lat(),
      lng: map.latLng.lng(),
    });
  };

  const handleDragEnd = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setPosition({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  const tigerSelectionHandler = (event) => {
    setSelectedTigers(event);
    localStorage.setItem("selectedTiger", JSON.stringify(event));
    setLoading(true);
    const item = species.filter((value) => {
      return value.value === 0;
    });
    getZone(item[0].key, event);
    if (!event) {
      setIsTigerNetworkChecked(false);
      setIsYearlyTigerChecked(false);
    }
  };

  const detailTigerView = () => {
    setButtonClicked(true);
  };

  const handleYearlyTigerRadioChange = () => {
    setIsYearlyTigerChecked(!isYearlyTigerChecked);
    setStartDate("");
    setEndDate("");
    setDuration("");
    setLoading(true);
    const item = species.filter((value) => {
      return value.value === 0;
    });
    getZone(item[0].key, selectedTiger);
    setIsTigerNetworkChecked(false);
  };

  const handleTigerNetworkRadioChange = () => {
    setIsTigerNetworkChecked(!isTigerNetworkChecked);
    setIsYearlyTigerChecked(false);
  };

  const latestYear = Math.max(
    ...markers.flatMap((marker) =>
      marker.dates?.map((date) => new Date(date).getFullYear())
    )
  );

  const latestYearMarkers = markers
    .flatMap((marker) =>
      marker.dates
        .filter((date) => new Date(date).getFullYear() === latestYear)
        .map((date) => ({
          date,
          lat: marker.lat,
          lng: marker.lng,
        }))
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((item, index) => ({
      rank: index + 1,
      ...item,
    }));

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonSolidCheckedBg: "#E8DFD5",
            buttonSolidCheckedActiveBg: "#E8DFD5",
            buttonSolidCheckedHoverBg: "#E8DFD5",
            colorPrimary: "#FFF3E8",
            colorPrimaryHover: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          DatePicker: {
            activeBorderColor: "#FFF3E8",
            colorLinkActive: "#FFF3E8",
            colorPrimary: "#FFF3E8",
            hoverBorderColor: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          Select: {
            optionActiveBg: "#FFF3E8",
            optionSelectedBg: "#FFF3E8",
            optionFontSize: 14,
            colorPrimaryHover: "#E67817",
            controlOutline: "#FFF3E8",
            multipleItemBg: "#FFF3E8",
          },
        },
      }}
    >
      <div className="">
        <div style={{ paddingBottom: "0px" }} className="Card MapCard">
          <div className="Map-View">
            <LoadScriptNext googleMapsApiKey={MapKey}>
                <GoogleMap
                  mapContainerStyle={{ width: "95%", height: "100%" }}
                  center={
                    position === null
                      ? { lat: 11.962482, lng: 77.15502 }
                      : position
                  }
                  zoom={10}
                  onClick={handleMapLoad}
                  onLoad={onLoad}
                  onDragEnd={handleDragEnd}
                >
                  <Polygon
                    paths={api.polygonCoordinates}
                    options={{
                      fillColor: "skyblue",
                      fillOpacity: 0.4,
                      strokeColor: "blue",
                      strokeOpacity: 0.6,
                      strokeWeight: 2,
                    }}
                    onClick={handleMapLoad}
                  />

                  {isTigerNetworkChecked ? (
                    <>
                      {latestYearMarkers.map((marker, index) => {
                        const offset = 0.001 * index;
                        const iconUrl = createColoredMarker(
                          "violet",
                          marker.rank
                        );
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: marker.lat + offset,
                              lng: marker.lng + offset,
                            }}
                            title={`Rank: ${marker.rank} Date: ${new Date(
                              marker.date
                            ).toLocaleDateString("en-GB")}`}
                            icon={{
                              url: iconUrl,
                            }}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        );
                      })}

                      {/* <Polyline
                            path={latestYearMarkers.map((marker) => ({
                              lat: marker.lat,
                              lng: marker.lng,
                            }))}
                            options={{
                              strokeColor: "red",
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                              geodesic: true,
                            }}
                          /> */}
                    </>
                  ) : (
                    markers.flatMap((marker, index) => {
                      const yearGroups = marker.dates?.reduce((acc, date) => {
                        const year = new Date(date).getFullYear();
                        if (!acc[year]) {
                          acc[year] = 0;
                        }
                        acc[year] += 1;
                        return acc;
                      }, {});
                      const years = Object.keys(yearGroups);
                      const hasDifferentYears = years.length > 1;

                      if (
                        marker.count > 1 &&
                        isYearlyTigerChecked &&
                        hasDifferentYears
                      ) {
                        const offset = 0.001;
                        return years.map((year, yearIndex) => {
                          const color = getColorByYear(year);
                          const yearCount = yearGroups[year];
                          const iconUrl = createColoredMarker(color, yearCount);
                          return (
                            <Marker
                              key={`${index}-${year}`}
                              position={{
                                lat: marker.lat + offset * yearIndex,
                                lng: marker.lng + offset * yearIndex,
                              }}
                              title={`Station Id: ${marker.label} Year: ${year} Total images: ${yearCount}`}
                              icon={{
                                url: iconUrl,
                              }}
                              draggable={true}
                              onDragEnd={handleMarkerDragEnd}
                            />
                          );
                        });
                      } else {
                        const duplicateMarkers = markers.filter(
                          (m) => m.lat === marker.lat && m.lng === marker.lng
                        );
                        const offset =
                          duplicateMarkers.length > 1 ? 0.0001 * index : 0;
                        const maxCount = markers.reduce(
                          (max, marker) => Math.max(max, marker.count),
                          0
                        );
                        const color = getColor(
                          marker.count,
                          maxCount,
                          marker.dates
                        );
                        const iconUrl = createColoredMarker(
                          color,
                          marker.count
                        );
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: marker.lat + offset,
                              lng: marker.lng + offset,
                            }}
                            title={`Station Id: ${marker.label} Total images: ${marker.count}`}
                            icon={{
                              url: iconUrl,
                            }}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        );
                      }
                    })
                  )}
                </GoogleMap>
            </LoadScriptNext>
          </div>
          <div className="Map-Right">
            <div className="Map-View-Description" onClick={handleMarkerDragEnd}>
              <span>View the area covered by various species</span>
            </div>
            <div className="filter-options">
              <div className="DirectionDropdown" style={{ width: "100%" }}>
                <label htmlFor="">Species</label>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select"
                  optionFilterProp="children"
                  loading={
                    (species !== null && species.length === 0) || loading
                  }
                  size={"large"}
                  value={selectedSpecies}
                  onChange={speciesChangeHandler}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={species}
                />
              </div>
              <div className="DirectionDropdown" style={{ width: "100%" }}>
                <label htmlFor="">Station ID</label>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    mode="multiple"
                    size="large"
                    style={{ width: "100%" }}
                    options={camera}
                    loading={
                      (species !== null && species.length === 0) || loading
                    }
                    value={selectedCamera}
                    onChange={cameraChangeHandler}
                    placeholder="Select Item..."
                    maxTagCount="responsive"
                  />
                </Space>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
              }}
            >
              {isTiger && (
                <div className="filter-options">
                  <div className="DirectionDropdown" style={{ width: "100%" }}>
                    <label htmlFor="">Tiger Name</label>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select"
                      optionFilterProp="children"
                      size={"large"}
                      value={selectedTiger}
                      onChange={tigerSelectionHandler}
                      allowClear={true}
                      loading={
                        (species !== null && species.length === 0) || loading
                      }
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      options={tigerslist}
                    />
                  </div>
                </div>
              )}
              {selectedTiger && (
                <div
                  className="Login_Button upload_button"
                  style={{ width: "48.5%", marginTop: "-10px" }}
                >
                  <button onClick={detailTigerView}>Detailed View</button>
                </div>
              )}
            </div>
            <div>
              {selectedTiger && (
                <div
                  style={{
                    paddingLeft: "0.1vw",
                    marginTop: "1vh",
                    display: "flex",
                    gap: "0.5vw",
                  }}
                >
                  <input
                    type="checkbox"
                    id="tigerOption"
                    name="animalOption"
                    checked={isYearlyTigerChecked}
                    onChange={() => handleYearlyTigerRadioChange()}
                  />
                  <label htmlFor="tigerOption">Yearly Tiger View</label>
                </div>
              )}
            </div>
            {selectedTiger &&
              isYearlyTigerChecked &&
              !isTigerNetworkChecked && <YearIndicators />}
            <div>
              {selectedTiger && (
                <div
                  style={{
                    paddingLeft: "0.1vw",
                    marginTop: "3vh",
                    display: "flex",
                    gap: "0.5vw",
                  }}
                >
                  <input
                    type="checkbox"
                    id="tigerOption"
                    name="animalOption"
                    checked={isTigerNetworkChecked}
                    onChange={() => handleTigerNetworkRadioChange()}
                  />
                  <label htmlFor="tigerOption">View Tiger Movement</label>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="SpeciesTable">
          <MapTable
            camera={camera}
            selectedCamera={selectedCamera}
            speciesID={id}
            geolocationBeat={[]}
            tiger_types={selectedTiger}
            start_date={startDate}
            end_date={endDate}
            selectedYear={selectedYear}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Mapview;
