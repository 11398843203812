import "./App.css";
import NavigationBar from "./Components/NavigationBar";
import DownloadBox from "./Components/DownloadBox";
import Login from "./Containers/Login";
import Forest from "./Containers/Forest";
import { useEffect, useState } from "react";
import { url } from "./Data/Data";
import User from "./Containers/User";
import TadobaContext from "./Components/Context/TadobaContext";
import { useIdleTimer } from "react-idle-timer";
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Privacy from "./Components/Privacy";
import { ToastContainer } from "react-toastify";
import { makeRequest } from "./api";
function App() {
  const [login, setLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [forest, setForest] = useState(false);
  const api = useContext(TadobaContext);
  const [role_name, setRoleName] = useState("");
  const navigate = useNavigate();
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const Logout = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", "/api/v1/account/logout/");
      localStorage.clear();
      navigate("/");
      window.location.reload();
      localStorage.removeItem('selectedSpecies');
      localStorage.removeItem('selectedTiger');
      localStorage.removeItem('tigerList');
    } catch (error) { }
  };

  const onIdle = () => {
    Logout();
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 7200_000,
    throttle: 500,
  });

  const clearLocalStorageItems = () => {
    localStorage.removeItem("recent_images");
    localStorage.removeItem("unclassified");
    localStorage.removeItem("top_tigers");
    localStorage.removeItem("top_species");
    localStorage.removeItem("statistic_classification");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearLocalStorageItems();
    }, 3600_000); // 1 hour in milliseconds

    setTimeoutId(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (!localStorage.csrf) {
      Logout();
    }
    const login = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", "api/v1/account/profile/");
        setLogin(true);
        api.setUser(result.data.data);
        api.setDefaultYear(result.data.data.default_year);
      } catch (error) {
        setForest(false);
      }
      setCurrentUser(true);
    };
    login();
  }, [forest]);

  if (!currentUser) {
    return null;
  } else {
  }
  return (
    <div className="App">
      {window.location.pathname !== "/privacy" ? <NavigationBar /> : ""}
      {api.startDownload || api.startDelete ? <DownloadBox /> : ""}
      {/* {<DownloadBox />} */}
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/*"
          element={login ? <User role_name={role_name} /> : <Login />}
        />
        <Route path="/forest" element={<Forest setForest={setForest} />} />
      </Routes>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
}

export default App;
