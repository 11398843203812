import React, { useContext } from "react";
import { Route, useNavigate } from "react-router-dom";
import FindTiger from "../Containers/FindTiger";
import FindMyTiger from "../Containers/FindMyTiger";
import Tiger_routes from "../Components/Upload/Tiger_routes";
import TadobaContext from "../Components/Context/TadobaContext";

const BackButton = (props) => {
  const navigate = useNavigate();
  const api = useContext(TadobaContext);

  const handleBackClick = () => {
    props.setButtonClicked(false)
    api.setTigerImage([])
  };

  return (
      <div className="EditHeadBar" onClick={handleBackClick}>
        <span>Home</span>
        <img src={require("../Assets/Icons/right_icon.png")} alt="" />
        {/* <span>{data?.village}.png</span> */}
      </div>
  );
};

export default BackButton;
