import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { makeRequest } from "../../api";

const Reset_Password = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [resetPassword, setResetPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const { token } = useParams();
  const { uid } = useParams();

  const showPasswordHandler = (event) => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const encryptAES = plaintext => {
    const secretKey = 'RUFqlY38xUWND5Yt';
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
    const combined = CryptoJS.lib.WordArray.create();
    combined.concat(iv).concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
  }
  const resetPasswordRequest = async (data) => {
    try {
      const result= await makeRequest("put",`api/v1/account/reset_password/`,data)
      alert("Password Changed Successfully");
      navigate("/");
    } catch (error) {
      setError(true);
      setMessage(error.response.data?.data);
    }
  };

  const fieldChangeHandler = (event) => {
    setDisabled(false);
    setMessage("");
    setError(false);
    if (event.target.name === "reset_password") {
      setResetPassword(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  const buttonHandler = () => {
    if (resetPassword === "") {
      setMessage("This field is required");
      setError(true);
      return;
    }
    if (password === "") {
      setMessage("This field is required");
      setDisabled(true);
      return;
    }
    if (password !== resetPassword) {
      setMessage("Password must same");
      setDisabled(true);
    } else {
      const data = {
        uid: uid,
        new_password: encryptAES(resetPassword),
        re_enter_new_password: encryptAES(password),
        TADOBAAUTH: token,
      };
      resetPasswordRequest(data);
    }
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div className="Login_Title">
          <span>Reset Password</span>
        </div>
        <div className="Description">
          <p>
            For the account <span>{searchParams.get("email")}</span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <div className="Login_Input">
            <label className="password_icon" htmlFor="reset_password">
              New password
            </label>
            <div className="password">
              <input
                type={type}
                name="reset_password"
                className={error ? "input_warning" : ""}
                onChange={fieldChangeHandler}
                placeholder="Enter your password"
                id="reset_password"
              />
              {type === "text" ? (
                <img
                  src={require("../../Assets/Icons/eye.png")}
                  onClick={showPasswordHandler}
                  alt=""
                />
              ) : (
                <img
                  onClick={showPasswordHandler}
                  src={require("../../Assets/Icons/eye-off.png")}
                  alt=""
                />
              )}
            </div>
            {error ? <label id="warning">{message}</label> : ""}
          </div>
          <div className="Login_Input">
            <label className="password_icon" htmlFor="password">
              Re-enter new password
            </label>
            <div className="password">
              <input
                type={type}
                name="password"
                className={disabled ? "input_warning" : ""}
                onChange={fieldChangeHandler}
                placeholder="Enter your password"
                id="password"
              />
              {type === "text" ? (
                <img
                  src={require("../../Assets/Icons/eye.png")}
                  onClick={showPasswordHandler}
                  alt=""
                />
              ) : (
                <img
                  onClick={showPasswordHandler}
                  src={require("../../Assets/Icons/eye-off.png")}
                  alt=""
                />
              )}
            </div>
            {disabled ? <label id="warning">{message}</label> : ""}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <div className="Password_conditions">
            <p>Password must contain</p>
            <ul>
              <li>
                <span>Minimum 10 character</span>
              </li>
              <li>
                <span>At least one uppercase letter</span>
              </li>
              <li>
                <span>At least one special character</span>
              </li>
            </ul>
          </div>
          <div className="Login_Button">
            <button onClick={buttonHandler}>Reset and Proceed</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset_Password;
