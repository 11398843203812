import React, { useContext, useEffect, useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import ImagesView from "./ImagesView";
import axios from "axios";
import LazyImage from "../LazyImage";
import Nodata from "../Nodata";
import { url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import SmallLoader from "../SmallLoader";
import { makeRequest } from "../../api";

const ClassifiedContainer = (props) => {
  const api = useContext(TadobaContext);
  const [uploadView, setUploadview] = useState(false);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(false);
  const [classified, setClassified] = useState([]);
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState("");
  const [classificationData, setClassificationData] = useState(
    api.classification
  );
  const navigate = useNavigate();
  // console.log(api.classification);
  const viewHandler = (key) => {
    setImages(api.classification?.grouped_data[classified_items[key]]);
    setTitle(classified_items[key]);
    setUploadview(true);
  };

  const AllSpeciesSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        "Species name": value.name,
        "No. Of Images": value.no_of_images,
      };
      result.push(obj);
    });
    api.setSpecies(result);
  };

  function updateClassification(response) {
    try {
      const { classified } = response;
      const classification = classificationData;

      // Filter out classified images from 'unclassified'
      const updatedUnclassified = classification.unclassified.filter(
        (image) => !classified.some((c) => c.image_id === image.id)
      );
      console.log(classified);
      console.log(updatedUnclassified);

      const newClassifiedEntries = classified.map((cls) => {
        return {
          id: cls.image_id,
          image_key: cls.image_url,
          species: cls.species.name,
          fileName: cls.image_url,
        };
      });

      console.log(classification);

      const updatedClassified = [
        ...classification.classifed,
        ...newClassifiedEntries,
      ];

      const updatedGroupedData = { ...classification.grouped_data };
      newClassifiedEntries.forEach((entry) => {
        if (updatedGroupedData[entry.species]) {
          updatedGroupedData[entry.species].push(entry);
        } else {
          updatedGroupedData[entry.species] = [entry];
        }
      });
      console.log(updatedGroupedData);
      // Update the context with new values
      const updatedApiClassification = {
        ...classification,
        unclassified: updatedUnclassified,
        classifed: updatedClassified,
        grouped_data: updatedGroupedData,
      };

      console.log(updatedApiClassification);
      api.setClassification(updatedApiClassification);
      setClassificationData(updatedApiClassification);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchUpdatedData = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/recenlty_classified_species/`
      );
      api.setClassification(result.data.data);
      api.setClassification({
        ...api.classification,
        classifed: result.data.data.classifed,
        grouped_data: result.data.data.grouped_data,
        tiger_images: result.data.data.tiger_images,
        time_taken: api.classification.time_taken + result.data.data.time_taken,
        unclassified: result.data.data.unclassified,
        species_found: Object.keys(api.classification.grouped_data).length,
      });
      setLoading(false);
      navigate("/upload/classified_images");
      setActive(false);
      setClassified([]);
    } catch (error) {
      setLoading(false);
      setClassified([]);
      alert(error);
    }
  };

  const speciesChangeHandler = async () => {
    setLoading(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/classify_species_manually/`,
        { data: classified }
      );
      console.log(api.classification);
      console.log(result.data.data);
      updateClassification(result.data.data);
      // fetchUpdatedData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setClassified([]);
    }
    navigate("/upload/classified_images");
  };

  useEffect(() => {
    const fetchSpecies = async () => {
      try {
        const result = await makeRequest("get", `api/v1/species/species/`);
        api.setAllSpecied(result.data.data);
        AllSpeciesSetup(result.data.data);
      } catch (error) {}
    };
    if (!localStorage.species) {
      fetchSpecies();
    } else {
      const storedData = JSON.parse(localStorage.species);
      api.setAllSpecied(storedData);
      AllSpeciesSetup(storedData);
    }
  }, []);

  let classified_items = [];
  for (const key in api.classification?.grouped_data) {
    classified_items.push(key);
  }

  function modifySpeciesHandler(id, value) {
    if (value === undefined) {
      value = "";
    }
    let myList = [...classified];
    var index = -1;
    for (var i = 0; i < myList.length; i++) {
      if (myList[i].image_id === id) {
        index = i;
        break;
      }
    }
    if (index !== -1 && value !== "") {
      myList[index].species_type_id = value;
    } else if (index !== -1 && value === "") {
      myList.splice(index, 1);
    } else {
      myList.push({ image_id: id, species_type_id: value });
    }
    setClassified(myList);
  }

  //   console.log(api.classification);

  return (
    <>
      {loading ? (
        <SmallLoader />
      ) : (
        <div className="ClassificationContainer" style={{ height: "100%" }}>
          {uploadView ? (
            <ImagesView
              images={images}
              title={title}
              setUploadview={setUploadview}
              setLabel={props.setLabel}
            />
          ) : (
            <>
              <div className="ClassificationContainerHeader">
                <div
                  className={
                    active
                      ? "classification_type"
                      : "classification_type classification_type_active"
                  }
                  onClick={() => {
                    setActive(false);
                    // props.setLabel(true);
                  }}
                >
                  <img
                    src={require("../../Assets/Icons/ImagesGroup.png")}
                    alt=""
                  />
                  <span>Classified species </span>
                  <span>({api.classification?.classifed.length})</span>
                </div>
                <div
                  className={
                    !active
                      ? "classification_type"
                      : "classification_type classification_type_active"
                  }
                  onClick={() => {
                    setActive(true);
                    // props.setLabel(true);
                  }}
                >
                  <img
                    src={require("../../Assets/Icons/ImageAlert.png")}
                    alt=""
                  />
                  <span>Unclassified species </span>
                  <span>({api.classification?.unclassified.length})</span>
                </div>
              </div>
              {active ? (
                <>
                  {api?.classification?.unclassified?.length === 0 ||
                  !api?.classification?.unclassified?.length ? (
                    <Nodata />
                  ) : (
                    <div
                      className="ClassificationImages"
                      style={{ position: "relative" }}
                    >
                      <div className="Images_View" style={{ height: "60vh" }}>
                        {api.classification.unclassified.map((val, key) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="ImageBox">
                                <div className="Individual_Image">
                                  <LazyImage
                                    src={val.fileName}
                                    placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                                  />
                                </div>
                              </div>
                              <select
                                style={{ width: "192px" }}
                                className="role_filter"
                                onChange={(event) => {
                                  setSpecies(event.target.value);
                                  const result = api.allSpecies.filter(
                                    function (item) {
                                      return item.name === event.target.value;
                                    }
                                  );
                                  modifySpeciesHandler(val.id, result[0]?.id);
                                }}
                              >
                                <option className="role_filter_span" value="">
                                  Select Species
                                </option>
                                {api.species.map((value, key) => {
                                  return (
                                    <option
                                      className="role_filter_span"
                                      key={key}
                                      value={value["Species name"]}
                                    >
                                      {value["Species name"]}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          );
                        })}
                      </div>
                      {classified.length !== 0 ? (
                        <div
                          className="popupDeleteButtonFilter"
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "28px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* <button style={{width:"152px", height:"40px"}} onClick={()=>{setSpecies(""); setClassified([])}} className='CancelButton '>Clear All</button> */}
                          <button
                            style={{ width: "152px", height: "40px" }}
                            className="DeleteButton"
                            onClick={speciesChangeHandler}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {classified_items.length === 0 ? (
                    <Nodata />
                  ) : (
                    <div className="ClassificationImages">
                      <div className="Images_View" style={{ height: "60vh" }}>
                        {classified_items.map((val, key) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // props.setLabel(true);
                                viewHandler(key);
                              }}
                            >
                              <div className="ImageBox">
                                <div className="Individual_Image">
                                  <LazyImage
                                    src={
                                      api.classification?.grouped_data[val][0]
                                        .fileName
                                    }
                                    placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                                  />
                                  {/* <img src={api.classification?.grouped_data[val][0].fileName} alt="" /> */}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "4px",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{val}</span>
                                <span className="classifyCount">
                                  (
                                  {api.classification?.grouped_data[val].length}
                                  )
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ClassifiedContainer;
