import React, { useContext, useState } from "react";
import TadobaContext from "../Context/TadobaContext";

const TigerDelete = (props) => {
  const [deleteId, setDeleteId] = useState([props.row["Tiger Name"]]);
  return (
    <div className="popupDeleteRole">
      <div className="popupDeleteContent">
        <div className="popupDeleteHeader">
          <div>
            <span>Delete Tiger</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainContent">
          <span>Are you sure you want to delete the Tiger data</span>
        </div>
        <div className="popupDeleteButton">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              props.deleteHandler(deleteId);
              props.close();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default TigerDelete;
