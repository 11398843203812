import React, { useContext, useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { ToastContainer } from "react-toastify";
const TigerEdit = (props) => {
  console.log({ props: props });
  const api = useContext(TadobaContext);
  const [name, setName] = useState(props.row["Tiger Name"]);
  const [isAlive, setIsAlive] = useState(props.row["Status"] === "Alive");
  const [isDead, setIsDead] = useState(props.row["Status"] === "Dead");
  const [isMale, setIsMale] = useState(props.row["Gender"] === "Male");
  const [isFemale, setIsFemale] = useState(props.row["Gender"] === "Female");
  const [isAdult, setIsAdult] = useState(props.row["Age"] == "Adult");
  const [isCub, setIsCub] = useState(props.row["Age"] === "Cubs ");
  const [isSubcub, setIsSubcub] = useState(props.row["Age"] === "Subcub");
  const [photo, setPhoto] = useState([]);
  const [warning, setWarning] = useState("This field is required");
  const [nameDisabled, setNameDisabled] = useState(false);
  const [editID, seteditId] = useState([props.row["Tiger Name"]]);

  console.log(isMale);

  const editHandler = (event) => {
    const tigerstatus = isAlive ? "Alive" : isDead ? "Dead" : "";
    const age = isAdult ? "Adult" : isCub ? "Cubs " : isSubcub ? "Subcub" : "";
    const gender = isMale ? "Male" : isFemale ? "Female" : "";

    const updatedTigerDetails = {
      name: name,
      age: age,
      status: tigerstatus,
      gender: gender,
      files: photo,
      id: editID,
    };
    console.log({ 34: updatedTigerDetails });

    if (name === "" || age === "" || gender === "" || tigerstatus === "") {
      setNameDisabled(true);
    }
    props.editHandler(
      updatedTigerDetails,
      props.close,
      setWarning,
      setNameDisabled
    );
  };

  const handleAdultCheckboxChange = () => {
    setIsAdult(true);
    setIsCub(false);
    setIsSubcub(false);
  };

  const handleCubCheckboxChange = () => {
    setIsAdult(false);
    setIsCub(true);
    setIsSubcub(false);
  };

  const handleSubcubCheckboxChange = () => {
    setIsAdult(false);
    setIsCub(false);
    setIsSubcub(true);
  };
  const handleMaleCheckboxChange = () => {
    setIsMale(!isMale);
    if (isFemale) {
      setIsFemale(false);
    }
  };
  const handleFemaleCheckboxChange = () => {
    setIsFemale(!isFemale);
    if (isMale) {
      setIsMale(false);
    }
  };

  const handleAliveCheckboxChange = () => {
    setIsAlive(!isAlive);
    if (isDead) {
      setIsDead(false);
    }
  };

  const handleDeadCheckboxChange = () => {
    setIsDead(!isDead);
    if (isAlive) {
      setIsAlive(false);
    }
  };

  const photoChangeHandler = (event) => {
    const selectedFiles = event.target.files;
    setPhoto(selectedFiles);
  };

  const nameChangeHandler = (event) => {
    setNameDisabled(false);
    setName(event.target.value);
  };
  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Edit Tiger</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>

        <div className="popupMainEditContent">
          <div className="EditField">
            <label htmlFor="name">Tiger Name</label>
            <input
              type="text"
              value={name}
              name="name"
              onChange={nameChangeHandler}
            />
            {nameDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
            <div className="EditField">
              <label htmlFor="age">Age</label>
              <div className="PopupRadioButton">
                <input
                  type="radio"
                  name="ageGroup"
                  value="Adult"
                  //value={isAdult}
                  checked={isAdult}
                  onChange={handleAdultCheckboxChange}
                  style={{ width: "15px" }}
                />
                <span style={{ marginTop: "10px" }}>Adult</span>
                <input
                  type="radio"
                  name="ageGroup"
                  value="Sub-Adult"
                  //value={isSubcub}
                  checked={isSubcub}
                  onChange={handleSubcubCheckboxChange}
                  style={{ width: "15px", marginLeft: "20px" }}
                />
                <span style={{ marginTop: "10px" }}>Sub-Adult</span>

                <input
                  type="radio"
                  name="ageGroup"
                  value="Cubs"
                  //value={isCub}
                  checked={isCub}
                  onChange={handleCubCheckboxChange}
                  style={{ width: "15px", marginLeft: "20px" }}
                />
                <span style={{ marginTop: "10px" }}>Cubs</span>
              </div>
            </div>
            <div className="EditField">
              <label htmlFor="gender">Gender</label>
              <div className="PopupRadioButton">
                <input
                  type="radio"
                  value={isMale}
                  checked={isMale}
                  onChange={handleMaleCheckboxChange}
                  style={{ width: "15px" }}
                />
                <span style={{ marginTop: "10px" }}>Male</span>

                <input
                  type="radio"
                  value={isFemale}
                  checked={isFemale}
                  onChange={handleFemaleCheckboxChange}
                  style={{ width: "15px", marginLeft: "20px" }}
                />
                <span style={{ marginTop: "10px" }}>Female</span>
              </div>
            </div>
            <div className="EditField">
              <label htmlFor="tigerstatus">Status</label>
              <div className="PopupRadioButton">
                <input
                  type="radio"
                  value={isAlive}
                  checked={isAlive}
                  onChange={handleAliveCheckboxChange}
                  style={{ width: "15px" }}
                />
                <span style={{ marginTop: "10px" }}>Alive</span>

                <input
                  type="radio"
                  value={isDead}
                  checked={isDead}
                  onChange={handleDeadCheckboxChange}
                  style={{ width: "15px", marginLeft: "20px" }}
                />
                <span style={{ marginTop: "10px" }}>Dead</span>
              </div>
            </div>
            {/* <div className="EditField">
              <label htmlFor="photo">Photos</label>
              <input
                multiple
                type="file"
                name="photos"
                onChange={photoChangeHandler}
              />
            </div> */}
          </div>
        </div>
        <div className="popupDeleteButtonUsers">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button className="DeleteButton" onClick={editHandler}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default TigerEdit;
