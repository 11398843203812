import React from 'react'

const AccessDenied = () => {
  return (
        <div className='Nodata' style={{width:"100%", height:"100%"}}>
            <img src={require('../Assets/Icons/No_Result.png')} alt="" />
            <span>Access Denied</span>
        </div>
  )
}

export default AccessDenied