import React, { useRef } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import { url } from "../../Data/Data";

const TigerAdd = (props) => {
  const [name, setName] = useState("");
  const [isAlive, setIsAlive] = useState(false);
  const [isDead, setIsDead] = useState(false);
  const [isMale, setIsMale] = useState(false);
  const [isFemale, setIsFemale] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [isCub, setIsCub] = useState(false);
  const [isSubcub, setIsSubcub] = useState(false);
  const [photo, setPhoto] = useState([]);
  const [warning, setWarning] = useState("This field is required");
  const [nameDisabled, setNameDisabled] = useState(false);

  // const nameChangeHandler = (event) => {
  //   setNameDisabled(false);
  //   setName(event.target.value);
  // };

  const setTigerHandler = (event) => {
    const tigerstatus = isAlive ? "Alive" : isDead ? "Dead" : "";
    const age = isAdult ? "Adult" : isCub ? "Cubs" : isSubcub ? "Subcub" : "";
    const gender = isMale ? "Male" : isFemale ? "Female" : "";
    const tigerDetails = {
      name: name,
      age: age,
      status: tigerstatus,
      gender: gender,
      files: photo,
    };
    if ((name === "") | (age === "") | (gender === "") | (tigerstatus === "")) {
      setNameDisabled(true);
    }

    props.tigerAddHandler(
      tigerDetails,
      props.close,
      setWarning,
      setNameDisabled
    );
  };
  const nameChangeHandler = (event) => {
    setNameDisabled(false);
    setName(event.target.value);
  };

  const handleAdultCheckboxChange = () => {
    setIsAdult(true);
    setIsCub(false);
    setIsSubcub(false);
  };

  const handleCubCheckboxChange = () => {
    setIsAdult(false);
    setIsCub(true);
    setIsSubcub(false);
  };

  const handleSubcubCheckboxChange = () => {
    setIsAdult(false);
    setIsCub(false);
    setIsSubcub(true);
  };
  const handleMaleCheckboxChange = () => {
    setIsMale(!isMale);
    if (isFemale) {
      setIsFemale(false);
    }
  };
  const handleFemaleCheckboxChange = () => {
    setIsFemale(!isFemale);
    if (isMale) {
      setIsMale(false);
    }
  };

  const handleAliveCheckboxChange = () => {
    setIsAlive(!isAlive);
    if (isDead) {
      setIsDead(false);
    }
  };

  const handleDeadCheckboxChange = () => {
    setIsDead(!isDead);
    if (isAlive) {
      setIsAlive(false);
    }
  };

  const photoChangeHandler = (event) => {
    const selectedFiles = event.target.files;
    setPhoto(selectedFiles);
  };

  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Add Tiger</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainEditContent">
          <div className="EditField">
            <label htmlFor="name">Tiger Name</label>
            <input
              type="text"
              value={name}
              name="name"
              placeholder="Enter tiger name"
              className={nameDisabled ? "input_warning" : ""}
              onChange={nameChangeHandler}
            />
            {nameDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="EditField">
            <label htmlFor="age">Age</label>
            <div className="PopupRadioButton">
              <input
                type="radio"
                value={isAdult}
                checked={isAdult}
                onChange={handleAdultCheckboxChange}
                style={{ width: "15px" }}
              />
              <span style={{ marginTop: "10px" }}>Adult</span>

              <input
                type="radio"
                value={isSubcub}
                checked={isSubcub}
                onChange={handleSubcubCheckboxChange}
                style={{ width: "15px", marginLeft: "20px" }}
              />
              <span style={{ marginTop: "10px" }}>Sub-cub</span>

              <input
                type="radio"
                value={isCub}
                checked={isCub}
                onChange={handleCubCheckboxChange}
                style={{ width: "15px", marginLeft: "20px" }}
              />
              <span style={{ marginTop: "10px" }}>Cubs</span>
            </div>
          </div>

          <div className="EditField">
            <label htmlFor="gender">Gender</label>
            <div className="PopupRadioButton">
              <input
                type="radio"
                value={isMale}
                checked={isMale}
                onChange={handleMaleCheckboxChange}
                style={{ width: "15px" }}
              />
              <span style={{ marginTop: "10px" }}>Male</span>

              <input
                type="radio"
                value={isFemale}
                checked={isFemale}
                onChange={handleFemaleCheckboxChange}
                style={{ width: "15px", marginLeft: "20px" }}
              />
              <span style={{ marginTop: "10px" }}>Female</span>
            </div>
          </div>
          <div className="EditField">
            <label htmlFor="tigerstatus">Status</label>
            <div className="PopupRadioButton">
              <input
                type="radio"
                value={isAlive}
                checked={isAlive}
                onChange={handleAliveCheckboxChange}
                style={{ width: "15px" }}
              />
              <span style={{ marginTop: "10px" }}>Alive</span>

              <input
                type="radio"
                value={isDead}
                checked={isDead}
                onChange={handleDeadCheckboxChange}
                style={{ width: "15px", marginLeft: "20px" }}
              />
              <span style={{ marginTop: "10px" }}>Dead</span>
            </div>
          </div>
          {/* <div className="EditField">
            <label htmlFor="photo">Photos</label>
            <input
              multiple
              type="file"
              name="photos"
              onChange={photoChangeHandler}
            />
          </div> */}
        </div>
        <div className="popupDeleteButtonUsers">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button className="DeleteButton" onClick={setTigerHandler}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default TigerAdd;
