// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-title:#3F3325;
  --map-filter-description: #9A938A;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
  color: #594F42;
}


*::-webkit-scrollbar {
  width: 6px;
  height: 72px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iCAAiC;AACnC;AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;AAChB;;;AAGA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[":root {\n  --main-title:#3F3325;\n  --map-filter-description: #9A938A;\n}\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Inter';\n  font-style: normal;\n  color: #594F42;\n}\n\n\n*::-webkit-scrollbar {\n  width: 6px;\n  height: 72px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #D9D9D9;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
