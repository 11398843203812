import React, { useContext, useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { ToastContainer } from "react-toastify";
const SpeciesEdit = (props) => {
  const api = useContext(TadobaContext);
  const [name, setName] = useState(props.row["Species name"]);
  const [warning, setWarning] = useState("This field is required");
  const [nameDisabled, setNameDisabled] = useState(false);
  const species = api.allSpecies.filter(function (item) {
    return item.name == props.row["Species name"];
  });

  const [id, setId] = useState(species[0].id);

  const nameChangeHandler = (event) => {
    setNameDisabled(false);
    setName(event.target.value);
  };
  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Edit Species</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainEditContent">
          <div className="EditField">
            <label htmlFor="name">Species Name</label>
            <input
              type="text"
              value={name}
              name="name"
              placeholder="Enter Species name"
              className={nameDisabled ? "input_warning" : ""}
              onChange={nameChangeHandler}
            />
            {nameDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="popupDeleteButtonUsers">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              const obj = {
                name: name,
                id: id,
              };
              if (name === "") {
                setNameDisabled(true);
                return;
              }
              props.editHandler(obj, props.close, setWarning, setNameDisabled);
            }}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default SpeciesEdit;
