import React from "react";
import { useState, useContext } from "react";
import TadobaContext from "../Context/TadobaContext";

const GeolocationAdd = (props) => {
  const api = useContext(TadobaContext);

  const [compartment_no, setCompartment_no] = useState("");
  const [beat_no, setBeat_no] = useState("");
  const [round_no, setRound_no] = useState("");
  const [range_no, setRange_no] = useState("");
  const [station_id, setStation_id] = useState("");
  const [camera_id, setCamera_id] = useState("");
  const [latitude, setLatitude] = useState("");
  const [logitude, setLogitude] = useState("");
  const [warning, setWarning] = useState("This field is required");
  const [disabled, setDisabled] = useState(false);
  const eventChangeHandler = (event) => {
    if (event.target.name == "compartment_no") {
      setCompartment_no(event.target.value);
    }
    if (event.target.name == "beat_no") {
      setBeat_no(event.target.value);
    }
    if (event.target.name == "round_no") {
      setRound_no(event.target.value);
    }
    if (event.target.name == "range_no") {
      setRange_no(event.target.value);
    }
    if (event.target.name == "station_id") {
      setStation_id(event.target.value);
    }
    if (event.target.name == "camera_id") {
      setCamera_id(event.target.value);
    }
    if (event.target.name == "latitude") {
      setLatitude(event.target.value);
    }
    if (event.target.name == "logitude") {
      setLogitude(event.target.value);
    }
  };

  const body = {
    compartment: compartment_no,
    beat_no: beat_no,
    range_no: range_no,
    round_no: round_no,
    station: station_id,
    camera: camera_id,
    latitude: latitude,
    longitude: logitude,
    year: Number(api.defaultYear),
  };

  const AddHandler = () => {
    if (
      compartment_no == "" ||
      beat_no == "" ||
      range_no == "" ||
      station_id == "" ||
      camera_id == "" ||
      latitude == "" ||
      logitude == ""
    ) {
      setDisabled(true);
    }
    props.addHandler(body, props.close);
  };

  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Add Geolocation</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="GeolocationEditContent">
          <div className="EditField">
            <label htmlFor="Range no.">Range no.</label>
            <input
              type="text"
              id="range_no"
              name="range_no"
              value={range_no}
              className={disabled && range_no == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && range_no == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Round no.">Round no.</label>
            <input
              type="text"
              id="round_no"
              name="round_no"
              value={round_no}
              className={disabled && round_no == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && round_no == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Beat no.">Beat no.</label>
            <input
              type="email"
              id="beat_no"
              name="beat_no"
              value={beat_no}
              className={disabled && beat_no == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && beat_no == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Compartment no.">Compartment no.</label>
            <input
              type="text"
              id="compartment_no"
              name="compartment_no"
              value={compartment_no}
              className={
                disabled && compartment_no == "" ? "input_warning" : ""
              }
              onChange={eventChangeHandler}
            />
            {disabled && compartment_no == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Station ID">Station ID</label>
            <input
              type="text"
              id="station_id"
              name="station_id"
              value={station_id}
              className={disabled && station_id == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && station_id == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Camera ID">Camera ID</label>
            <input
              type="text"
              id="camera_id"
              name="camera_id"
              value={camera_id}
              className={disabled && camera_id == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && camera_id == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Lattitude">Latitude</label>
            <input
              type="text"
              id="Latitude"
              name="latitude"
              value={latitude}
              className={disabled && latitude == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && latitude == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Logitude">Longitude</label>
            <input
              type="text"
              id="Logitude"
              name="logitude"
              value={logitude}
              className={disabled && logitude == "" ? "input_warning" : ""}
              onChange={eventChangeHandler}
            />
            {disabled && logitude == "" ? (
              <span className="warning" style={{ color: "red" }}>
                {warning}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="popupDeleteButton">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button className="DeleteButton" onClick={AddHandler}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeolocationAdd;
