import React from 'react'
import ComingSoon from '../Components/CommingSoon'
const Profile = () => {
  return (
    <div className="Dashboard">
        <ComingSoon/>
    </div>
  )
}

export default Profile