import React, { useContext, useEffect } from "react";
import TadobaContext from "../Context/TadobaContext";
import CircularProgressLoader from "../CircularProgressLoader";
import { useNavigate } from "react-router-dom";

const UploadLoader = ({ setUpload, data, total, complete, percent, totalImages, completedImages, type }) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();

  const cancelHandler = () => {
    console.log("clicked");
    api.setClassification(null);
    api.setFile([]);
    api.setUpload(false);
    api.setIdentifiedTigers([]);
    api.setUnIdentifiedTigers([]);
    api.setImages([]);
    // navigate("/upload");
    window.location.reload();
  };

  function bytesToSize(bytes, seperator = "") {
    if (bytes === 0) {
      return 0;
    }
    if (data === "Classifying Images...") {
      return bytes;
    }
    if (data === "Identifying Tigers...") {
      return bytes;
    }
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  }
  return (
    <div className="UploadLoader">
      <div className="UploadLoaderHeading">
        <span>{data}</span>
      </div>
      <div className="LoaderParent">
        <div className="loader">
          <CircularProgressLoader progress={percent} size={280} />
        </div>
        <div className="LoadContent">
          <div className="LoadMainContent">
            {!total ? (
              ""
            ) : (
              type === "general" ? (
                <span>
                {completedImages}/{totalImages}
              </span>
              ) : (
                <span>
                {bytesToSize(complete)}/{bytesToSize(total)}
              </span>
              )
            )}
          </div>
          <div className="LoadNormalContent">
            {percent === 0 || percent === 100 ? (
              <span>Processing...</span>
            ) : (
              <>
                <span>{Math.floor(percent)}%</span>
                <span>Completed</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div onClick={cancelHandler} className="Upload_Cancel_button">
        <span>Cancel</span>
      </div>
    </div>
  );
};

export default UploadLoader;
