import React from 'react'

const ShareImage = (props) => {
  return (
    <div className='popupDeleteRole'>
            <div className='popupDeleteContent'>
                <div className="popupDeleteHeader">
                    <div>
                        <span>Share Images</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                <div style={{display:"flex", gap:'30px', width:"400px", alignItems:"center", justifyContent:"center"}}>
                    <img style={{cursor:"pointer"}} src={require("../../Assets/Icons/Whatsapp.png")} alt="" />
                    <img style={{cursor:"pointer"}} src={require("../../Assets/Icons/Email.png")} alt="" />
                </div>
            </div>
        </div>
  )
}

export default ShareImage