import React, { useContext, useEffect, useState, useRef } from "react";
import ImageContainer from "../ImageContainer";
import SpeciesImagesFilters from "./SpeciesImagesFilter";
import axios, { all } from "axios";
import { url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import TadobaContext from "../Context/TadobaContext";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ImagesTable from "../ImagesTable";
import { Images_Template } from "../../Data/Data";
import { decryptImageUrl } from "../../Data/Data";
import { makeRequest, makeRequest2 } from "../../api";
import Popup from "reactjs-popup";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import FilterDetails from "../FilterDetails";
import ImageEdit from "./ImageEdit";
import EditMultipleImages from "./EditMultipleImages";

const SpeciesImages = (props) => {
  const api = useContext(TadobaContext);
  const { species_id } = useParams();
  const [species, setSpecies] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isInitialRender = useRef(true);
  const [scroll, setScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [listData, setListData] = useState([]);
  const [grid, stGrid] = useState(true);
  const [exportedImages, setExportedImages] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filter_years, setYears] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [exportedIds, setExportedIds] = useState([]);
  const [startDownload, setStartDownload] = useState(api.startDownload);
  const [abortController, setAbortController] = useState(null);
  const [downloadXl, setDownloadXl] = useState("0");
  const [selectedOption, setSelectedOption] = useState("");
  const [deletedImageList, setDeletedImageList] = useState([]);
  const prevStartDeleteRef = useRef(api.startDelete);
  const [filterSpecies, setFilterSpecies] = useState([]);
  const [filtersArray, setFiltersArray] = useState([]);
  const [isEditImgOpen, setIsEditImgOpen] = useState(false)
  const [imageEditId, setImageEditId] = useState('')
  // const [disable, setDisable] = useState(localStorage.disable === "true");

  const imgContainerRef = useRef();

  const resetRefs = () => {
    if (imgContainerRef.current) {
      imgContainerRef.current.resetStates(); // Call a method in Child2
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setLoading(true);
      setData([]);
      setListData([]);
      setCurrentPage(0);
      setExportedImages([]);
      setPage(1);
      setRender(render + 1);
      api.setFilterQuery([]);
      setYears([]);
      api.setYearsData([]);
    }
  }, [species_id]);

  useEffect(() => {
    if (render === 1) {
      api.setFilterQuery([]);
      setYears([]);
      api.setYearsData([]);
      setFilterSpecies([]);
      api.setImagesFilters([]);
    }
    getImages();
  }, [render, page]);

  const getImages = async () => {
    if (filter === true) {
      filterApply();
      return;
    }
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/image_list_classfied_species/?q=${species_id}&page=${page}&size=20`
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);

          let checked = false;
          if (allCheck) {
            checked = true;
          }
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            Range: cameraData.range.name,
            image: cameraData.image,
            id: cameraData.id,
            checked: checked,
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const cancelDownload = () => {
    if (abortController) {
      console.log("abort");
      abortController.abort(); // Abort the ongoing request
      setAbortController(null); // Reset the controller in the state
    }
    api.setDownloadingText("");
    api.setStartDownload(false);
    setStartDownload(false);
    localStorage.setItem("disable", false);
    api.setDisable(false);
  };

  const downloadAllImages = async () => {
    api.setStartDownload(true);

    api.setDisable(true);
    localStorage.setItem("disable", true);
    setStartDownload(true);
    const data = api.filterQuery;
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const size = 20;

    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: [species_id],
      years: filter_years,
    };
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      let data = {};
      if (allCheck) {
        data = {
          image_ids: [],
          specie_id: species_id,
          camera_id: "",
          download_xl: downloadXl,
          filters: result_data,
        };
      }
      if (exportedIds.length > 0) {
        data = {
          image_ids: exportedIds,
          specie_id: "",
          camera_id: "",
          download_xl: downloadXl,
          filters: result_data,
        };
      } else {
      }
      if ((allCheck || exportedIds.length > 0) && downloadXl === "0") {
        const displayCount = allCheck ? count : exportedIds.length;
        const finalCount = Math.min(displayCount, 5000);

        if (finalCount >= 5000) {
          toast.success("Download Limit - 5000 Images", warningToaster);
          console.log("Download Limit - 5000 Images");
          api.setStartDownload(false);
          setStartDownload(false);
          localStorage.setItem("disable", false);
          api.setDisable(false);
          return;
        }

        api.setDownloadingText(`Downloading ${finalCount} Images....`);
        localStorage.setItem("text", `Downloading ${finalCount} Images....`);
      }
      if (downloadXl === "1") {
        api.setDownloadingText(`Downloading Excel File....`);
        localStorage.setItem("text", `Downloading Excel File....`);
      }
      api.setDownloadQuery(data);
      setDownloadXl("0");
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.error("Failed to create download link:", error);
      }
      api.setDownloadingText("");
      api.setStartDownload(false);
      setStartDownload(false);
      localStorage.setItem("disable", false);
      api.setDisable(false);
    }
  };
  useEffect(() => {
    if (exportedImages.length !== 0 || allCheck) {
      api.setDisable(false);
    } else {
      api.setDisable(true);
    }
  }, [exportedImages, allCheck]);

  function handleExportOption(event) {
    const option = event.target.value;

    switch (option) {
      case "excel":
        console.log("1");
        setDownloadXl("1");

        break;
      case "images":
        downloadAllImages();
        break;
      default:
        console.log("No action for this option");
    }
    setSelectedOption("");
  }
  useEffect(() => {
    if (downloadXl === "1") {
      downloadAllImages();
    }
  }, [downloadXl]);
  const filterApply = async (close) => {
    setFilter(true);
    const data = api.filterQuery;
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const size = 20;
    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: [species_id],
      years: filter_years,
      page: page,
      size: size,
    };

    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/image_filter/`,
        result_data
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      const speciesIds = result_data.species;
      setFilterSpecies(speciesIds)

      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          let checked = false;
          if (allCheck) {
            checked = true;
          }
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            Range: cameraData.range.name,
            "Date and Time": formattedDate,
            image: cameraData.image,
            checked: checked,
            id: cameraData.id,
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const downloadImages = async () => {
    const imageUrls = [...exportedImages];
    const zip = new JSZip();

    const headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    const downloadPromises = imageUrls.map(async (imageUrl, index) => {
      const decryptUrl = await decryptImageUrl(
        imageUrl,
        localStorage.getItem("image_key")
      );

      const response = await fetch(decryptUrl, { headers });
      const imageData = await response.blob();

      zip.file(`image${index + 1}.jpg`, imageData);

      // Resolve the promise
      return Promise.resolve();
    });
    await Promise.all(downloadPromises);
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(zipBlob, "images.zip");
  };

  const clearFilter = () => {
    setFilterSpecies([]);
  };

  const deleteImage = async (array, close) => {
    let array_data = array;
    setDeletedImageList(array_data)
    const data = api.filterQuery;
    const controller = new AbortController();
    setAbortController(controller);
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const size = 20;

    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: [species_id],
      years: filter_years,
    };
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      let data = {};
      if (allCheck) {
        data = {
          image_ids: ["all"],
          filters: result_data,
        };
      }
      if (exportedIds.length > 0) {
        data = {
          image_ids: exportedIds,
          filters: result_data,
        };
      } else {
        data = {
          image_ids: array_data,
          filters: result_data,
        };
      }
      if (allCheck || exportedIds.length > 0 || array_data.length > 0) {
        let displayCount;

        if (allCheck) {
          displayCount = count;
        } else if (exportedIds.length > 0) {
          displayCount = exportedIds.length;
        } else if (array_data.length > 0) {
          displayCount = array_data.length;
        }
        const finalCount = Math.min(displayCount, 5000);
        if (finalCount >= 5000) {
          toast.success("Delete Limit - 5000 Images", warningToaster);
          console.log("Delete Limit - 5000 Images");
          api.setStartDelete(false);
          return;
        }
        api.setDownloadingText(`Deleting ${finalCount} Images....`);
        localStorage.setItem("text", `Deleting ${finalCount} Images....`);
      }
      api.setStartDelete(true);
      api.setDeleteQuery(data);
      api.setDisable(true);

      close();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filter_years.length > 0) {
      filterApply();
    }
  }, [filter_years]);

  useEffect(() => {
    if (prevStartDeleteRef.current === true && api.startDelete === false) {
      // setCurrentPage(0);
      setListData((prevListData) => prevListData.filter(item => !deletedImageList.includes(item.id)));
      setData((prevData) => prevData.filter(item => !deletedImageList.includes(item.id)));
      // setLoading(true);
      // setPage(1);
      // setRender(render + 1);
      api.setDeleteQuery([]);
      setAllCheck(false);
      setExportedIds([]);
    }
    prevStartDeleteRef.current = api.startDelete;
  }, [api.startDelete]);

  const editImage = (value) => {
    // props.setEdit(true);
    // navigate(`/images/${value.id}`);
    setImageEditId(value.id)
    setIsEditImgOpen(true)
  };
  // const cancelDownload = () => {};

  const closeEditImgPopup = () => setIsEditImgOpen(false)

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Images</span>
          <span className="Filter_Species_Display">
            <FilterDetails filtersArray={filtersArray} />
          </span>
          <span className="Filter_Species_Display">
            {filter_years.length > 0 && <span>(</span>}
            {filter_years.slice(0, 3).map((year, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span>, </span>}
                <span>{year}</span>
              </React.Fragment>
            ))}
            {filter_years.length > 3 && <span>, ...</span>}
            {filter_years.length > 0 && <span>)</span>}
          </span>
        </div>

        <div className="Setting_User_Attribute">
          {/* <div className='role_search'>
            <input type="text" placeholder='Search by name' />
            <img src={require('../../Assets/Icons/search_icon.png')} alt="" />
          </div> */}
          {/* <div className='role_filter'>
            <img src={require('../../Assets/Icons/filter.png')} alt="" />
          </div> */}
          {/* <div className="role_Calander">
            <img src={require("../../Assets/Icons/Calander.png")} alt="" />
          </div> */}
          {/* {exportedImages.length != 0 ? (
            <button className="role_filter" onClick={downloadImages}>
              Export
            </button>
          ) : (
            ""
          )} */}
        </div>
        <div className="Setting_User_Attribute">
        {exportedImages.length > 1 && (
              <EditMultipleImages
                getImages={getImages}
                exportedIds={exportedIds}
                onReset={resetRefs}
                setExportedImages={setExportedImages}
                setExportedIds={setExportedIds}
                listData={listData}
                setListData={setListData}
                allImages={false}
                groupTiger={false}
                species_id={species_id}
              />
          )}
          <Popup
            trigger={
              <div className="role_filter">
                <img src={require("../../Assets/Icons/filter.png")} alt="" />
                {/* {filterSpecies.length > 0 && (
                  <span className="filter_count"></span>
                )} */}
              </div>
            }
            modal
            nested
          >
            {(close) => (
              <SpeciesImagesFilters
                species={species}
                setFilter={setFilter}
                setRender={setRender}
                render={render}
                close={close}
                setLoading={setLoading}
                setCurrentPage={setCurrentPage}
                setData={setData}
                setListData={setListData}
                setPage={setPage}
                filterApply={filterApply}
                setSpecies={setSpecies}
                clearFilter={clearFilter}
                setYears={setYears}
                setFiltersArray={setFiltersArray}
              />
            )}
          </Popup>
          {/* <div className='role_Calander' onClick={handleCalendarClick}>
                        <img src={require('../../Assets/Icons/Calander.png')} style={{ display: showDatePicker ? 'none' : 'block' }} alt="" />
                        {dateRangeText && <span style={{display: showDatePicker ? 'none' : 'block', fontSize: "0.85rem", color:"#594F42", marginLeft: "12px"}}>{dateRangeText}</span>}
                        {showDatePicker && (
                            <RangePicker
                                value={selectedDateRange}
                                format="YYYY-MM-DD"
                                onChange={customDateChangeHandler}
                                bordered={false}
                                size="small"
                                onOpenChange={(open) => {
                                        setCurrentPage(0);
                                        setData([]);
                                        setListData([]);
                                        setFilter(true);
                                        setLoading(true);
                                        setPage(1);
                                        setRender(render + 1);
                                        setShowDatePicker(open);
                                        clearFilter()
                                }}
                            />
                        )}
                    </div> */}
          {/* {exportedImages.length !== 0 || allCheck ? (
            <button
              className={`role_filter ${api.disable ? "disabled" : ""}`}
              onClick={downloadAllImages}
              disabled={api.disable}
            >
              Export
            </button>
          ) : (
            ""
          )} */}
          {/* <button
            className={`role_filter ${api.disable ? "disabled" : ""}`}
            onClick={downloadAllImages}
            disabled={api.disable}
          >
            Export
          </button>
          <button
            className={`role_filter ${api.disable ? "disabled" : ""}`}
            onClick={() => {
              setDownloadXl("1");
              downloadAllImages();
            }}
            disabled={api.disable}
          >
            Export Excel
          </button> */}
          <div className={`role_filter ${api.disable ? "disabled" : ""}`}>
            <select
              onChange={handleExportOption}
              disabled={api.disable}
              // defaultValue=""
              value={selectedOption}
            >
              <option value="" disabled hidden>
                Export
              </option>
              <option value="excel">As Excel</option>
              <option value="images">As Images</option>
            </select>
          </div>
        </div>
      </div>{" "}
      {grid ? (
        <div style={{ position: "relative" }}>
          {/* {api.startDownload && (
            <div
              className="upload"
              style={{
                position: "absolute",
                bottom: "80px", // Adjust as needed
                right: "50px", // Adjust as needed
                width: "20%",
                height: "20%",
                border: "2px solid orange", // Add border style
                zIndex: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => cancelDownload()} // Add a function to handle the close action
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                <img src={require("../../Assets/Icons/Close.png")} alt="" />
              </button>
              <ClipLoader
                color={"orange"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <div style={{ marginTop: "10px" }}>
                {(allCheck || exportedIds.length > 0) && (
                  <p>{localStorage.text}</p>
                )}
              </div>
            </div>
          )} */}
          <ImageContainer
            exportedImages={exportedImages}
            scroll={scroll}
            setExportedImages={setExportedImages}
            currentPage={currentPage}
            grid={grid}
            setGrid={stGrid}
            setCurrentPage={setCurrentPage}
            page={page}
            setPage={setPage}
            count={count}
            setData={setListData}
            data={listData}
            getImages={getImages}
            deleteImage={deleteImage}
            editImage={editImage}
            type={"Classified"}
            loading={loading}
            setAllCheck={setAllCheck}
            setExportedIds={setExportedIds}
            ref={imgContainerRef}
          />
        </div>
      ) : (
        <ImagesTable
          exportedImages={exportedImages}
          scroll={scroll}
          setExportedImages={setExportedImages}
          currentPage={currentPage}
          grid={grid}
          setGrid={stGrid}
          delete={true}
          setCurrentPage={setCurrentPage}
          page={page}
          setPage={setPage}
          setData={setListData}
          count={count}
          data={listData}
          getImages={getImages}
          deleteImage={deleteImage}
          editImage={editImage}
          type={"Classified"}
          template={Images_Template}
          loading={loading}
          setAllCheck={setAllCheck}
          setExportedIds={setExportedIds}
        />
      )}
      <Popup open={isEditImgOpen} onClose={closeEditImgPopup} modal closeOnDocumentClick={false}>
        <ImageEdit closeEditImgPopup={closeEditImgPopup} id={imageEditId} setListData={setListData} listData={listData} allImages={false}/>
      </Popup>
    </div>
  );
};

export default SpeciesImages;
