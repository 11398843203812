import React, { useContext, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import Upload from "./Upload";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import Images from "./Images";
import Statistics from "./Statistics";
import TadobaContext from "../Components/Context/TadobaContext";
import AccessDenied from "../Components/AccessDenied";
import Mapview from "./Mapview";
import PolygonView from "./PolygonView";
import Profile from "./Profile";
import Alerts from "./Alerts";
import Upload_image from "./Upload_image";
import FindMyTiger from "./FindMyTiger";
import MapContainer from "./MapContainer";
const User = ({ role_name }) => {
  const api = useContext(TadobaContext);
  useEffect(()=>{
    localStorage.removeItem('selectedSpecies');
    localStorage.removeItem('selectedTiger');
    localStorage.removeItem('selectedCamera')
    localStorage.removeItem('tigerList');
  },[api])
  let Data = {
    dashboard:
      api.user.access.dashboard !== 1 ? <Dashboard /> : <AccessDenied />,
    upload: api.user.access.upload !== 1 ? <Upload /> : <AccessDenied />,
    setttings: api.user.access.settings !== 1 ? <Settings /> : <AccessDenied />,
    images: api.user.access.images !== 1 ? <Images /> : <AccessDenied />,
    maps: api.user.access.maps !== 1 ? <MapContainer /> : <AccessDenied />,
    statistics:
      api.user.access.statistics !== 1 ? <Statistics /> : <AccessDenied />,
    findTiger:
      api.user.access["Find Tiger"] !== 1 ? <FindMyTiger /> : <AccessDenied />,
    alerts: api.user.access.alerts !== 1 ? <Alerts /> : <AccessDenied />,
  };
  return (
    <div className="container">
      <Sidebar role_name={role_name} />
      <Routes>
        <Route path="/*" element={Data.dashboard} />
        <Route path="/upload/*" element={Data.upload} />
        <Route path="/settings/*" element={Data.setttings} />
        <Route path="/images/*" element={Data.images} />
        <Route path="/map_view/*" element={Data.maps} />
        <Route path="/statistics/*" element={Data.statistics} />
        <Route path="/alerts/*" element={Data.alerts} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/add_image/*" element={<Upload_image />} />
        <Route path="/find_tiger/*" element={Data.findTiger} />
      </Routes>
    </div>
  );
};

export default User;
