import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { makeRequest } from "../../api";
import { Select } from "antd";
const Add_User = (props) => {
  const api = useContext(TadobaContext);
  const [warning, setWarning] = useState({
    name: "This field is required",
    email: "This field is required",
    phone: "This field is required",
    password: "This field is required",
    rolw: "This field is required",
  });
  const [nameDisabled, setNameDisabled] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [passwordDisabled, setPasswordDisabled] = useState(false);
  const [mobileDisabled, setMobileDisabled] = useState(false);
  const [roledisabled, setRolwDisabled] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [img, setImg] = useState(null);
  const [drag, setDrag] = useState(false);
  const [role, setRoles] = useState([]);
  const [role_id, setRole_id] = useState();
  const [villageData, setVillageData] = useState([]);
  const [selectedVillage, setSelectedVillages] = useState([]);
  const [rangesData, setRangesData] = useState([]);
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [areaOptions, setAreaOption] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredRoleOptions, setFilteredRoleOptions] = useState([]);

  useEffect(() => {
    const getRangesAndVillageAndRole = async () => {
      let token = "";
      try {
        const resultVillage = await makeRequest(
          "get",
          "/api/v1/virtualwall/villages/"
        );
        const resultRanges = await makeRequest(
          "get",
          "/api/v1/map/range_list/"
        );
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/account/role_list/`);

        const tempVillageData = resultVillage.data.data;
        const tempRangeData = resultRanges.data.data;

        setVillageData(tempVillageData);
        setRangesData(tempRangeData);

        let namesToRemove = ["PRT", "Range officer", "Forester", "Field director", "Deputy director", "Beat guard"];

        let updatedArray = result.data.data.filter(item => !namesToRemove.includes(item.name));

        setRoles(updatedArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getRangesAndVillageAndRole();
  }, []);

  useEffect(() => {
    if (selectedVillage.length > 0 || selectedRanges.length > 0) {
      setCurrentPage(0);
      setData([]);
      setListData([]);
      setLoading(true);
      setPage(1);
    }
  }, [selectedVillage, selectedRanges]);

  const villageOptions = villageData.map((village) => ({
    label: village.name,
    value: village.beat.id,
  }));

  const rangesOption = rangesData.map((species) => ({
    label: species.name,
    value: species.id,
  }));

  const roleOptions = role
    .map((role) => {
      if (props.curr_role === 1 && role.id === 2) {
        return null;
      }
      return {
        label: role.name,
        value: role.id,
      };
    })
    .filter((roleOption) => roleOption !== null)
    .sort((a, b) => a.value - b.value);

  useEffect(() => {
    // Filter out the Admin role if the user's role ID is 1
    if (api.user.role.id === 1) {
      setFilteredRoleOptions(
        roleOptions.filter((role) => role.label !== "Backend admin")
      );
    } else {
      setFilteredRoleOptions(roleOptions);
    }
  }, [api.user.role.id, roleOptions]);

  const villageChangeHandler = (event) => {
    setSelectedVillages(event);
    console.log(selectedVillage);
  };

  const rangeChangeHandler = (event) => {
    setSelectedRanges(event);
    console.log(selectedRanges);
  };

  let areaChangeHandler = (event) => {
    setSelectedArea(event);
    console.log(selectedArea);
  };

  const encryptAES = (plaintext) => {
    const secretKey = "RUFqlY38xUWND5Yt";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    const combined = CryptoJS.lib.WordArray.create();
    combined.concat(iv).concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
  };

  const nameChangeHandler = (event) => {
    setNameDisabled(false);
    setName(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEmailDisabled(false);
    setEmail(event.target.value);
  };

  const mobileChangeHandler = (event) => {
    if (event.target.value === "") {
      setMobileDisabled(true);
      setWarning({
        ...warning,
        phone: "This field is required",
      });
    }
    if (event.target.value > 9999999999) {
      setMobileDisabled(true);
      setWarning({
        ...warning,
        phone: "Enter a valid phone number",
      });
      setMobile(event.target.value);
    } else {
      setMobileDisabled(false);
      setMobile(event.target.value);
    }
  };

  const passwordChangeHandler = (event) => {
    setPasswordDisabled(false);
    setPassword(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    directory: true,
    onDragOver: () => {
      setDrag(true);
    },
    onDragLeave: () => {
      setDrag(false);
    },
    onDrop: (acceptedFiles) => {
      setImg(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImg(acceptedFiles);
      setDrag(false);
    },
  });

  // const fetchRole = async () => {
  //     let token = ""
  //     try {
  //         if (localStorage.token) {
  //             token = localStorage.token;
  //         }
  //         const result= await makeRequest("get",`api/v1/account/role_list/`)
  //         setRoles(result.data.data)

  //     }
  //     catch (error) {
  //     }
  // }

  // useEffect(() => {
  //     fetchRole()
  // }, [])

  const checkValidation = (data) => {
    if (data.includes("phone")) {
      setWarning({
        ...warning,
        phone: data,
      });
      setMobileDisabled(true);
    } else if (data.includes("name")) {
      setWarning({
        ...warning,
        name: data,
      });
      setNameDisabled(true);
    } else if (data.includes("email")) {
      setWarning({
        ...warning,
        email: data,
      });
      setEmailDisabled(true);
    } else if (data.includes("Password")) {
      setWarning({
        ...warning,
        password: data,
      });
      setPasswordDisabled(true);
    }
  };

  const userAddHandler = async () => {
    if (name === "") {
      setNameDisabled(true);
      if (email === "") {
        setEmailDisabled(true);
        if (mobile === "") {
          setMobileDisabled(true);
          if (password === "") {
            setPasswordDisabled(true);
            if (role_id === 0) {
              setRolwDisabled(true);
            }
          }
        }
      }
    } else if (email === "") {
      setEmailDisabled(true);
      if (mobile === "") {
        setMobileDisabled(true);
        if (password === "") {
          setPasswordDisabled(true);
          if (role_id === 0) {
            setRolwDisabled(true);
          }
        }
      }
    } else if (mobile === "") {
      setMobileDisabled(true);
      if (password === "") {
        setPasswordDisabled(true);
        if (role_id === 0) {
          setRolwDisabled(true);
        }
      }
    } else if (password === "") {
      setPasswordDisabled(true);
      if (role_id === 0) {
        setRolwDisabled(true);
      }
    } else if (role_id === 0) {
      setRolwDisabled(true);
    } else {
      props.setLoading(true);
      props.setMessage("");
      let token = "";
      let csrftoken = "";
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", encryptAES(email));
      formData.append("password", encryptAES(password));
      formData.append("mobile", encryptAES(mobile));
      formData.append("role_id", role_id);
      if (role_id == 5 || role_id == 6)
        formData.append("ranges_id", selectedArea);
      else if (role_id == 7 || role_id == 8 || role_id == 9)
        formData.append("beats_id", selectedArea);
      if (img === null) {
      } else formData.append("profile_image", img[0]);
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        if (localStorage.csrf) {
          csrftoken = localStorage.csrf;
        }
        const result = await makeRequest(
          "post",
          `api/v1/account/register/`,
          formData
        );
        props.setMessage("User Added Successfully");
        props.setWarning("success");
        props.setUpdate(props.update + 1);
        props.setLoading(false);
        props.close();
      } catch (error) {
        props.setLoading(false);
        toast.success(error.response.data.data.toString(), warningToaster);
        checkValidation(error.response.data.data.toString());
      }
    }
  };

  const roleChangeHandler = (event) => {
    //, value
    setRolwDisabled(false);
    setRole_id(event); //value.id
    // console.log("========"+event);

    if (event === 7 || event === 8 || event === 9) {
      areaChangeHandler = villageChangeHandler;
      setSelectedArea(selectedVillage);
      setAreaOption(villageOptions);
      // console.log("======Village");
      // console.log(areaOptions);
    } else if (event === 5 || event === 6) {
      areaChangeHandler = rangeChangeHandler;
      setSelectedArea(selectedRanges);
      setAreaOption(rangesOption);
      // console.log("======range");
      // console.log(areaOptions);
    } else {
    }
  };

  return (
    <div className="PopupEditRole">
      <div className="popupEditContent" style={{ gap: "14px" }}>
        <div className="popupEditHeader">
          <div>
            <span>Add User</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainEditContent">
          <div className="EditImage">
            <div className="ImgCircle" {...getRootProps()}>
              <input {...getInputProps()} />
              {img === null ? (
                <img src={require("../../Assets/Icons/person.png")} alt="" />
              ) : (
                <img src={img[0].preview} alt="" />
              )}
            </div>
            <div className="ImgEdit">
              <img src={require("../../Assets/Icons/img_edit.png")} alt="" />
            </div>
          </div>
          <div className="EditField">
            <label htmlFor="name">Person name</label>
            <input
              type="text"
              value={name}
              name="name"
              placeholder="Enter user name"
              className={nameDisabled ? "input_warning" : ""}
              onChange={nameChangeHandler}
            />
            {nameDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning.name}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              value={email}
              name="email"
              placeholder="Enter email id"
              className={emailDisabled ? "input_warning" : ""}
              onChange={emailChangeHandler}
            />
            {emailDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning.email}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Mobile No">Mobile No</label>
            <input
              type="text"
              value={mobile}
              name="mobile"
              placeholder="Enter mobile no"
              className={mobileDisabled ? "input_warning" : ""}
              onChange={mobileChangeHandler}
            />
            {mobileDisabled ? (
              <span className="warning" style={{ color: "red" }}>
                {warning.phone}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="EditField">
            <label htmlFor="Mobile No">Password</label>
            <input
              type="text"
              value={password}
              name="password"
              placeholder="Enter password"
              className={passwordDisabled ? "input_warning" : ""}
              onChange={passwordChangeHandler}
            />
            {passwordDisabled ? (
              <span
                className="warning"
                style={{ color: "red", width: "320px" }}
              >
                {warning.password}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          {/* PopupRadioButton */}
          <div className="EditField">
            {/* {role.map((value, key) => { */}
            {/* if (api.user.role.id === 1 && value.id === 2) {
                            return ("")
                        } */}
            {/* return ( */}
            {/* // <>
                            //     <input type="radio" id="role" name="role" value={value.id} checked={role_id === value.id} onChange={(event) => { roleChangeHandler(event, value) }} />
                            //     <span>{value.name}</span>
                            // </> */}
            <label htmlFor="Mobile No">Role</label>
            <div
              className="DirectionDropdown"
              style={{ marginTop: 0.5 + "em", marginBottom: 0.7 + "em" }}
            >
              <Select
                className="alerts-select-placeholder"
                showSearch
                mode="single"
                style={{ width: 320, height: 40 }}
                placeholder="Roles"
                optionFilterProp="children"
                // loading={
                //     (role !== null && role.length === 0) || loading
                // }
                size="medium"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={roleChangeHandler} //roleChangeHandler
                options={filteredRoleOptions} //roleOptions
                value={role_id}
              />
            </div>
            {/* ) */}
            {/* })} */}
          </div>

          {/* <div className="EditField">
            <label htmlFor="Mobile No">Area</label>
            <div
              className="DirectionDropdown"
              style={{ marginTop: 0.5 + "em" }}
            >
              <Select
                className="alerts-select-placeholder"
                showSearch
                mode="multiple"
                style={{ width: 320, height: 40 }}
                placeholder="Area"
                optionFilterProp="children"
                // loading={
                //     (role !== null && role.length === 0) || loading
                // }
                size="medium"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={areaChangeHandler} //roleChangeHandler
                options={areaOptions} //roleOptions
                value={selectedArea}
              />
            </div>
          </div> */}

          {roledisabled ? (
            <div className="EditField">
              <span
                className="warning"
                style={{ color: "red", width: "320px" }}
              >
                {warning.rolw}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="popupDeleteButtonUsers">
          <button
            className="CancelButton"
            onClick={() => {
              props.close();
            }}
          >
            Cancel
          </button>
          <button
            className={props.loading ? "disableddelete" : "DeleteButton"}
            disabled={props.loading}
            onClick={userAddHandler}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default Add_User;
