import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../Data/Data";
import NoDetail from "../NoDetail";
import TadobaContext from "../Context/TadobaContext";
import { useNavigate } from "react-router-dom";
import LazyImage from "../LazyImage";
import { makeRequest } from "../../api";

const TopSpecies_Card = ({ selectedYear }) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [species, setSpecies] = useState([]);
  const [list, setList] = useState([
    "Tiger",
    "Leopard",
    "Sloth bear",
    "Spotted Deer",
    "Barking Deer",
    "Four horned antelope",
    "Sambar",
    "Gaur",
    "Mongooses",
    "Bird",
    "Buffalo",
    "Common Palm Civet",
    "Cow",
    "Dog",
    "Goat",
    "Golden Jackal",
    "Hare",
    "Nilgai",
    "Hyena",
    "Indian Fox",
    "Indian pangolin",
    "Indian Porcupine",
    "Jungle cat",
    "Jungle Fowls",
    "Langur",
    "Macaque",
    "Palm squirrel",
    "Indian peafowl",
    "Honey badger",
    "Rodents",
    "Rusty spotted cat",
    "Sheep",
    "Small indian civet",
    "Wild boar",
    "Wild dog",
    "Indian wolf",
    "Person",
    "Crocodile",
    "Indian Elephant",
    "Vehicle",
  ]);

  const getSpecies = async () => {
    let token = localStorage.token || "";

    try {
      const result = await makeRequest("get", `api/v1/species/species/`);
      setSpecies(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching species:", error);
    }
  };

  const getImages = async (year = "all") => {
    let token = localStorage.token || "";

    try {
      const url =
        year === "all" || (Array.isArray(year) && year.length === 0)
          ? `api/v1/statistic/top_species/`
          : `api/v1/statistic/top_species/?year=${year}`;

      const result = await makeRequest("get", url);
      const resultData = result.data.data;
      setData(resultData);
      console.log({ resultData: resultData });
      localStorage.setItem("top_species", JSON.stringify(resultData));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    const storedTopSpecies = localStorage.top_species;

    if (!storedTopSpecies) {
      getImages();
      getSpecies();
    } else {
      const storedData = JSON.parse(storedTopSpecies);
      setData(storedData);
    }
  }, []);

  useEffect(() => {
    const fetchByYear = async () => {
      if (api.selectedYearData) {
        if (api.selectedYearData === "all") {
          getImages();
        } else {
          getImages(api.selectedYearData);
        }
      }
    };

    fetchByYear();
  }, [api.selectedYearData]);

  return (
    <div>
      <div className="Card">
        <div className="Card_Heading">
          <div className="Card_title">
            <img src={require("../../Assets/Icons/top_species.png")} alt="" />
            <span>Top Species</span>
          </div>
          <div
            className="Card_Link"
            onClick={() => {
              api.setExpanded(false);
              navigate("/images/");
            }}
          >
            {selectedYear !== "all" && (
              <span style={{ marginRight: "0.5vw", fontWeight: "600" }}>
                ({selectedYear})
              </span>
            )}
            <span>View all</span>
          </div>
        </div>
        <>
          {data.length === 0 ? (
            <NoDetail loading={loading} />
          ) : (
            <div className="Top_Card_Content Top_Card">
              {data.list
                .filter((value) => list.includes(value.species))
                .sort(
                  (a, b) => list.indexOf(a.species) - list.indexOf(b.species)
                )
                .map((value, key) => {
                  if (value.count > 0 && list.includes(value.species)) {
                    return (
                      <div
                        className="top_element"
                        style={{ cursor: "pointer", backgroundColor: "black" }}
                        onClick={() => {
                          api.setExpanded(false);
                          navigate(`/images/key/${value.id}`);
                        }}
                      >
                        <div className="top_span">
                          <span className="top_name">{value.species}</span>
                          <span className="top_count">{value.count}</span>
                        </div>
                        {value.image === undefined ? (
                          <img
                            src={require("../../Assets/Icons/temp_img.png")}
                            alt=""
                          />
                        ) : (
                          <LazyImage
                            src={value.image}
                            image_key={value.image_key}
                            placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                          />
                        )}
                      </div>
                    );
                  }
                })}
              {/* <div className="top_element" style={{ backgroundColor: "#FFEFE3" }}>
                            <div className='top_span'>
                                <span className='top_name'>Tiger</span>
                                <span className='top_count'>{data.list[0].count}</span>
                            </div>
                            <img src={require("../../Assets/Images/top_tiger.png")} alt="" />
                        </div>
                        <div className="top_element" style={{ backgroundColor: "#E9EFF5" }}>
                            <div className='top_span'>
                                <span className='top_name'>Other</span>
                                <span className='top_count'>0</span>
                            </div>
                            <img src={require("../../Assets/Images/bufflow.png")} alt="" />
                        </div>
                        <div className="top_element" style={{ backgroundColor: "#FAF5F0" }}>
                            <div className='top_span'>
                                <span className='top_name'>Other</span>
                                <span className='top_count'>0</span>
                            </div>
                            <img src={require("../../Assets/Images/Deer.png")} alt="" />
                        </div>
                        <div className="top_element" style={{ backgroundColor: "#F1EEE1" }}>
                            <div className='top_span'>
                                <span className='top_name'>Other</span>
                                <span className='top_count'>0</span>
                            </div>
                            <img src={require("../../Assets/Images/hourse.png")} alt="" />
                        </div> */}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default TopSpecies_Card;
