import { React } from "react";
import { Route, Routes } from "react-router-dom";
import TigerView from "../../Containers/TigerView";

const Settings = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <TigerView
            tiger_id={props.tiger_id}
            latitude={props.latitude}
            longitude={props.longitude}
            image={props.image}
            radio={props.radio}
            uploaded_data={props.uploaded_data}
            origin={props.origin}
            setButtonClicked={props.setButtonClicked}
            buttonClicked={props.buttonClicked}
            types={props.types}
          />
        }
      />
    </Routes>
  );
};

export default Settings;
