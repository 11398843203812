import React, { useContext, useRef } from "react";
import ImageContainer from "../ImageContainer";
import axios from "axios";
import { decryptImageUrl, url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { makeRequest, makeRequest2 } from "../../api";
import ImagesFilters from "./ImagesFilters";
import Popup from "reactjs-popup";
import TadobaContext from "../Context/TadobaContext";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import FilterDetails from "../FilterDetails";
import ImageEdit from "./ImageEdit";

const UnClassified = (props) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filter_years, setYears] = useState([]);
  const [speciesData, setSpeciesData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [classified, setClassified] = useState([]);
  const [scroll, setScroll] = useState(true);
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [exportedImages, setExportedImages] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterSpecies, setFilterSpecies] = useState([]);
  const [species, setSpecies] = useState([]);
  const [popupLoading, setPopupLoading] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [exportedIds, setExportedIds] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [startDownload, setStartDownload] = useState(api.startDownload);
  const [filtersArray, setFiltersArray] = useState([]);
  const [downloadXl, setDownloadXl] = useState("0");
  const [selectedOption, setSelectedOption] = useState("");
  const [deletedImageList, setDeletedImageList] = useState([]);
  const prevStartDeleteRef = useRef(api.startDelete);
  const [isEditImgOpen, setIsEditImgOpen] = useState(false);
  const [imageEditId, setImageEditId] = useState("");

  useEffect(() => {
    if (render === 1) {
      api.setFilterQuery([]);
      setYears([]);
      api.setYearsData([]);
      setFilterSpecies([]);
      api.setImagesFilters([]);
    }
    getImages();
  }, [render, page]);

  const clearFilter = () => {
    setFilterSpecies([]);
    setYears([]);
  };
  const speciesChangeHandler = async () => {
    setPopupLoading(true);
    setLoading(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/classify_species_manually/`,
        { data: classified }
      );
      setCurrentPage(0);
      setPopupLoading(false);
      setData([]);
      setListData([]);
      setClassified([]);
      setPage(1);
      setRender(render + 1);
    } catch (error) {
      setLoading(false);
      setClassified([]);
      setPopupLoading(false);
    }
  };
  const filterApply = async (close) => {
    console.log("abc");
    setFilter(true);
    const data = api.filterQuery;
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const size = 20;
    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: ["Unclassified"],
      years: filter_years,
      page: page,
      size: size,
    };

    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/image_filter/`,
        result_data
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      const speciesIds = result_data.species;
      const matchedSpeciesNames = speciesIds
        .map((speciesId) => {
          const matchedSpecies = speciesData.find(
            (speciesEntry) => speciesEntry.id === speciesId
          );
          return matchedSpecies ? matchedSpecies.name : null;
        })
        .filter((name) => name !== null);
      setFilterSpecies(matchedSpeciesNames);

      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          let checked = false;
          if (allCheck) {
            checked = true;
          }
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            checked: checked,
            id: cameraData.id,
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const downloadAllImages = async () => {
    const controller = new AbortController();
    setAbortController(controller); // Store controller in state
    api.setStartDownload(true);
    setStartDownload(true);
    api.setDisable(true);
    localStorage.setItem("disable", true);
    const data = api.filterQuery;
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const size = 20;
    console.log(data);
    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: ["Unclassified"],
      years: filter_years,
    };
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      let data = {};
      if (allCheck) {
        data = {
          image_ids: ["all"],
          specie_id: "",
          camera_id: "",
          download_xl: downloadXl,
          filters: result_data,
        };
      }
      if (exportedIds.length > 0) {
        data = {
          image_ids: exportedIds,
          specie_id: "",
          camera_id: "",
          download_xl: downloadXl,
          filters: result_data,
        };
      } else {
      }
      if ((allCheck || exportedIds.length > 0) && downloadXl === "0") {
        const displayCount = allCheck ? count : exportedIds.length;
        const finalCount = Math.min(displayCount, 5000);
        if (finalCount >= 5000) {
          toast.success("Download Limit - 5000 Images", warningToaster);
          console.log("Download Limit - 5000 Images");
          api.setStartDownload(false);
          setStartDownload(false);
          localStorage.setItem("disable", false);
          api.setDisable(false);
          return;
        }
        api.setDownloadingText(`Downloading ${finalCount} Images....`);
        localStorage.setItem("text", `Downloading ${finalCount} Images....`);
      }
      if (downloadXl === "1") {
        api.setDownloadingText(`Downloading Excel File....`);
        localStorage.setItem("text", `Downloading Excel File....`);
      }
      api.setDownloadQuery(data);
      setDownloadXl("0");
    } catch (error) {
      console.error("Failed to create download link:", error);
      api.setDownloadingText("");
      api.setStartDownload(false);
      setStartDownload(false);
      localStorage.setItem("disable", false);
      api.setDisable(false);
    }
  };

  useEffect(() => {
    if (exportedImages.length !== 0 || allCheck) {
      api.setDisable(false);
    } else {
      api.setDisable(true);
    }
  }, [exportedImages, allCheck]);

  function handleExportOption(event) {
    const option = event.target.value;

    switch (option) {
      case "excel":
        console.log("1");
        setDownloadXl("1");

        break;
      case "images":
        downloadAllImages();
        break;
      default:
        console.log("No action for this option");
    }
    setSelectedOption("");
  }
  useEffect(() => {
    if (downloadXl === "1") {
      downloadAllImages();
    }
  }, [downloadXl]);

  const modifySpeciesHandler = (id, value) => {
    if (value === undefined) {
      value = "";
    }
    let myList = [...classified];
    var index = -1;
    for (var i = 0; i < myList.length; i++) {
      if (myList[i].image_id === id) {
        index = i;
        break;
      }
    }
    if (index !== -1 && value !== "") {
      myList[index].species_type_id = value;
    } else if (index !== -1 && value === "") {
      myList.splice(index, 1);
    } else {
      myList.push({ image_id: id, species_type_id: value });
    }
    setClassified(myList);
    setRender(render + 1);
  };

  const getImages = async () => {
    if (filter === true) {
      filterApply();
      return;
    }
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/unclassified/?page=${page}&size=20`
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };
          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          let checked = false;
          if (allCheck) {
            checked = true;
          }
          const formattedData = {
            Name: name || "not named",
            Species: species || "Unclassified",
            id: cameraData.id,
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            checked: checked,
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteImage = async (array, close) => {
    let array_data = array;
    setDeletedImageList(array_data);
    const data = api.filterQuery;
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationBeat = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    for (const entry of Object.values(data)) {
      const [key, value] = entry.split("=");
      if (key === "range_name") {
        geolocationRange.push(value);
      } else if (key === "round_name") {
        geolocationRound.push(value);
      } else if (key === "beat_name") {
        geolocationBeat.push(value);
      } else if (key === "compartment_name") {
        geolocationCompartment.push(value);
      } else if (key === "station_name") {
        geolocationStation.push(value);
      } else if (key === "camera_name") {
        geolocationCamera.push(parseInt(value));
      }
    }
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: ["Unclassified"],
      years: filter_years,
    };
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      let data = {};
      if (allCheck) {
        data = {
          image_ids: ["all"],
          filters: result_data,
        };
      }
      if (exportedIds.length > 0) {
        data = {
          image_ids: exportedIds,
          filters: result_data,
        };
      } else {
        data = {
          image_ids: array_data,
          filters: result_data,
        };
      }
      if (allCheck || exportedIds.length > 0 || array_data.length > 0) {
        let displayCount;

        if (allCheck) {
          displayCount = count;
        } else if (exportedIds.length > 0) {
          displayCount = exportedIds.length;
        } else if (array_data.length > 0) {
          displayCount = array_data.length;
        }
        const finalCount = Math.min(displayCount, 5000);
        if (finalCount >= 5000) {
          toast.success("Delete Limit - 5000 Images", warningToaster);
          console.log("Delete Limit - 5000 Images");
          api.setStartDelete(false);
          return;
        }
        api.setDownloadingText(`Deleting ${finalCount} Images....`);
        localStorage.setItem("text", `Deleting ${finalCount} Images....`);
      }
      api.setStartDelete(true);
      api.setDeleteQuery(data);
      api.setDisable(true);

      close();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (prevStartDeleteRef.current === true && api.startDelete === false) {
      // setCurrentPage(0);
      setListData((prevListData) =>
        prevListData.filter((item) => !deletedImageList.includes(item.id))
      );
      setData((prevData) =>
        prevData.filter((item) => !deletedImageList.includes(item.id))
      );
      // setLoading(true);
      // setPage(1);
      // setRender(render + 1);
      api.setDeleteQuery([]);
      setAllCheck(false);
      setExportedIds([]);
    }
    prevStartDeleteRef.current = api.startDelete;
  }, [api.startDelete]);

  const cancelDownload = () => {
    if (abortController) {
      console.log("abort");
      abortController.abort(); // Abort the ongoing request
      setAbortController(null); // Reset the controller in the state
    }
    api.setDownloadingText("");
    api.setStartDownload(false);
    setStartDownload(false);
    localStorage.setItem("disable", false);
    api.setDisable(false);
  };

  const downloadImages = async () => {
    const imageUrls = [...exportedImages];
    const zip = new JSZip();

    const headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    const downloadPromises = imageUrls.map(async (imageUrl, index) => {
      const decryptUrl = await decryptImageUrl(
        imageUrl,
        localStorage.getItem("image_key")
      );
      const response = await fetch(decryptUrl, { headers });
      const imageData = await response.blob();

      // Add the image to the zip file
      zip.file(`image${index + 1}.jpg`, imageData);

      // Resolve the promise
      return Promise.resolve();
    });
    await Promise.all(downloadPromises);
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    saveAs(zipBlob, "images.zip");
  };

  const editImage = (value) => {
    // props.setEdit(true);
    // navigate(`/images/${value.id}`);
    setImageEditId(value.id);
    setIsEditImgOpen(true);
  };

  const closeEditImgPopup = () => setIsEditImgOpen(false);

  return (
    <>
      {popupLoading ? (
        <div className="loading-mask">
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              color={"green"}
              loading={popupLoading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ marginTop: "10px", color: "green" }}>
              Classifying Manually...
            </p>
          </div>
        </div>
      ) : (
        <div className="Setting_User">
          <div className="Setting_User_Header">
            <div>
              <span>Images</span>
              <span className="Filter_Species_Display">
                <FilterDetails filtersArray={filtersArray} />
              </span>
            </div>
            <div className="Setting_User_Attribute">
              {/* <div className="role_search">
            <input type="text" placeholder="Search by name" />
            <img src={require("../../Assets/Icons/search_icon.png")} alt="" />
          </div> */}
              {/* <div className='role_filter'>
                        <img src={require('../../Assets/Icons/filter.png')} alt="" />
                    </div> */}
              {/* <div className='role_Calander'>
                        <img src={require('../../Assets/Icons/Calander.png')} alt="" />
                    </div> */}
              {/* {exportedImages.length != 0 ? (
                <button className="role_filter" onClick={downloadImages}>
                  Export
                </button>
              ) : (
                ""
              )} */}
              <div className="Setting_User_Attribute">
                <Popup
                  trigger={
                    <div className="role_filter">
                      <img
                        src={require("../../Assets/Icons/filter.png")}
                        alt=""
                      />
                      {filterSpecies.length > 0 && (
                        <span className="filter_count"></span>
                      )}
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <ImagesFilters
                      species={species}
                      setFilter={setFilter}
                      setRender={setRender}
                      render={render}
                      close={close}
                      setLoading={setLoading}
                      setCurrentPage={setCurrentPage}
                      setData={setData}
                      setListData={setListData}
                      setPage={setPage}
                      filterApply={filterApply}
                      setSpecies={setSpecies}
                      clearFilter={clearFilter}
                      setYears={setYears}
                      type={"tiger images"}
                      setFiltersArray={setFiltersArray}
                    />
                  )}
                </Popup>
                {/* <div className='role_Calander' onClick={handleCalendarClick}>
                        <img src={require('../../Assets/Icons/Calander.png')} style={{ display: showDatePicker ? 'none' : 'block' }} alt="" />
                        {dateRangeText && <span style={{display: showDatePicker ? 'none' : 'block', fontSize: "0.85rem", color:"#594F42", marginLeft: "12px"}}>{dateRangeText}</span>}
                        {showDatePicker && (
                            <RangePicker
                                value={selectedDateRange}
                                format="YYYY-MM-DD"
                                onChange={customDateChangeHandler}
                                bordered={false}
                                size="small"
                                onOpenChange={(open) => {
                                        setCurrentPage(0);
                                        setData([]);
                                        setListData([]);
                                        setFilter(true);
                                        setLoading(true);
                                        setPage(1);
                                        setRender(render + 1);
                                        setShowDatePicker(open);
                                        clearFilter()
                                }}
                            />
                        )}
                    </div> */}
                {/* {exportedImages.length !== 0 || allCheck ? (
                  <button
                    className={`role_filter ${api.disable ? "disabled" : ""}`}
                    onClick={downloadAllImages}
                    disabled={api.disable}
                  >
                    Export
                  </button>
                ) : (
                  ""
                )} */}
                <div className={`role_filter ${api.disable ? "disabled" : ""}`}>
                  <select
                    onChange={handleExportOption}
                    disabled={api.disable}
                    // defaultValue=""
                    value={selectedOption}
                  >
                    <option value="" disabled hidden>
                      Export
                    </option>
                    <option value="excel">As Excel</option>
                    <option value="images">As Images</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            {/* {startDownload && (
              <div
                className="upload"
                style={{
                  position: "absolute",
                  bottom: "80px", // Adjust as needed
                  right: "50px", // Adjust as needed
                  width: "20%",
                  height: "20%",
                  border: "2px solid orange", // Add border style
                  zIndex: 1,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => cancelDownload()} // Add a function to handle the close action
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <img src={require("../../Assets/Icons/Close.png")} alt="" />
                </button>
                <ClipLoader
                  color={"orange"}
                  loading={true}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <div style={{ marginTop: "10px" }}>
                  {(allCheck || exportedIds.length > 0) && (
                    <p>{localStorage.text}</p>
                  )}
                </div>
              </div>
            )} */}
            <ImageContainer
              exportedImages={exportedImages}
              scroll={scroll}
              setExportedImages={setExportedImages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setData={setListData}
              page={page}
              setPage={setPage}
              speciesChangeHandler={speciesChangeHandler}
              classified={classified}
              modifySpeciesHandler={modifySpeciesHandler}
              count={count}
              data={listData}
              getImages={getImages}
              deleteImage={deleteImage}
              editImage={editImage}
              type={"unclassified"}
              loading={loading}
              setAllCheck={setAllCheck}
              setExportedIds={setExportedIds}
            />
          </div>
        </div>
      )}
      <Popup open={isEditImgOpen} onClose={closeEditImgPopup} modal>
        <ImageEdit closeEditImgPopup={closeEditImgPopup} id={imageEditId} />
      </Popup>
    </>
  );
};

export default UnClassified;
