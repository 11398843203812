import React from "react";
import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import RoleDeletePopup from "../Settings/RoleDeletePopup";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import UserEdit from "../Settings/UserEdit";
import GeoLocationEdit from "../Settings/GeoLocationEdit";
import Nodata from "../Nodata";
import LazyImage from "../LazyImage";
import SmallLoader from "../SmallLoader";

const AlertsTable = (props) => {
  const [columnName, setColumnName] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [render, setRender] = useState(1);
  const [deleteId, setDeleteId] = useState(props.exportedImages);
  const api = useContext(TadobaContext);
  const [Checked, setChecked] = useState(props.data);

  useEffect(() => {
    setPartialCheck(false);
    Checked.map((value, key) => {
      if (value.checked == true) {
        setPartialCheck(true);
        setAllCheck(true);
      }
      if (value.checked == false) {
        setAllCheck(false);
      }
    });
  }, [render]);

  useEffect(() => {
    setChecked(props.data);
    props.data.map((value, key) => {
      if (value.checked == true) {
        setPartialCheck(true);
      }
      if (value.checked == false) {
        setAllCheck(false);
      }
    });
  }, [props.data]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (props.data.length >= props.count) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (!props.scroll) {
        props.setPage(props.page + 1);
      }
    }
  }, 0);

  const AllCheckBoxChageHandler = () => {
    const list = [];
    const newData = [...Checked];
    if (allCheck === true) {
      setAllCheck(false);
      const tempData = newData.map((value, key) => {
        value.checked = false;
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    } else {
      const tempData = newData.map((value, key) => {
        setAllCheck(true);
        value.checked = true;
        list.push(value["image"]);
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    }
    setDeleteId(list);
    props.setExportedImages(list);
    setRender(render + 1);
  };

  const checkboxChangeHandler = (row) => {
    const newData = [...Checked];
    const defaultData = newData.map((value, key) => {
      if (row["image"] === value["image"]) {
        value.checked = !value.checked;
        if (value.checked) {
          setDeleteId([...deleteId, row["image"]]);
          props.setExportedImages([...deleteId, row["image"]]);
        }
        if (value.checked === false) {
          const index = deleteId.indexOf(row["image"]);
          deleteId.splice(index, 1);
          props.setExportedImages(deleteId);
          setDeleteId(deleteId);
        }
        return value;
      } else return value;
    });
    setChecked(defaultData);
    props.setData(defaultData);
    setRender(render + 1);
  };

  useEffect(() => {
    let array = new Array();
    for (let key in props.template) {
      array.push(key);
      setColumnName(array);
    }
  }, []);

  return (
    <div className="Setting_Table">
      <div className="Setting_Table_Header Alert_Header">
        <span>
          Showing {props.data.length} results{" "}
          {props?.count ? `of ${props.count}` : ""}{" "}
          {props.exportedImages.length > 0
            ? `( ${props.exportedImages.length} Selected)`
            : ""}
        </span>
        <div style={{ display: "flex", gap: "10px" }}>
          {partialCheck ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="deselect"
                onClick={() => {
                  setAllCheck(false);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                Deselect All
              </span>
              <div
                onClick={() => {
                  props.deleteHandler(deleteId);
                  setAllCheck(false);
                  setDeleteId([]);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                <img
                  src="{require('../../Assets/Icons/trash.png')}"
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {props.data.length === 0 ? (
        <Nodata />
      ) : (
        <>
          {props.loading === true ? (
            <SmallLoader />
          ) : (
            <div className="Setting_Table_Layout" onScroll={handleScroll}>
              <table>
                <tr className="heading_row">
                  {columnName.map((value, index) => {
                    return (
                      <td key={index}>
                        <div>
                          <span>{value}</span>
                        </div>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                {props.data.map((row, key) => {
                  return (
                    <tr
                      className="main_row"
                      onClick={() => {
                        props.editImage(row);
                      }}
                      style={{ cursor: "pointer" }}
                      key={key}
                    >
                      {columnName.map((value, index) => {
                        if (value === "Name") {
                          return (
                            <td key={index}>
                              <div className="rowImgCombine">
                                <div className="row_Img">
                                  {row.image === null || row.image === "" ? (
                                    <img
                                      src={require("../../Assets/Icons/person.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <LazyImage
                                      src={row.image}
                                      placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                                    />
                                  )}
                                </div>
                                <div>
                                  <span>{row[value]}</span>
                                </div>
                              </div>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={
                                value === "Date and Time" ? "viewControls" : ""
                              }
                              key={index}
                            >
                              <div style={{ position: "relative" }}>
                                <span>
                                  {row[value] === "Tadoba"
                                    ? "Village"
                                    : row[value]}
                                </span>
                              </div>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AlertsTable;
