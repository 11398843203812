import React, { useContext, useState } from "react";
import Setting_User_Table from "../Components/Setting_User_Table";
import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import Popup from "reactjs-popup";
import TadobaContext from "../Components/Context/TadobaContext";
import { toast } from "react-toastify";
import { successToaster } from "../Data/Data";
import { warningToaster } from "../Data/Data";
import { makeRequest } from "../api";

const TigerRisk = () => {
  const api = useContext(TadobaContext);
  const [uploadfile, setUploadfile] = useState(null);
  const [render, setRender] = useState(1);
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(true);
  const [searchItem, setSearchItem] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [TigerData, setTigerData] = useState([]);
  const [AllTigerData, setAllTigerData] = useState([]);
  const [scroll, setScroll] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchTiger();
  }, [page, render]);

  const handleChange = (event) => {
    setUploadfile(event.target.files[0]);
    setUploadfile("");
    const data = new FormData();
    data.append("file", event.target.files[0]);
    uploadedfile(data);
  };

  const uploadedfile = async (data) => {
    try {
      let token = "";
      let csrftoken = "";
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result = await makeRequest(
        "post",
        `api/v1/identification/tiger_excel_upload/`,
        data
      );
      setRender(render + 1);
      toast.success("File Uploaded Successfully", successToaster);
    } catch (error) {
      toast.success(error.response.data.data.toString(), warningToaster);
      setRender(render + 1);
    }
  };

  function search(searchTerm) {
    if (searchTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    const filtered = TigerData.filter((product) => {
      return product["Tiger Name"]
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setFilteredProducts(filtered);
  }

  const searchHandler = (event) => {
    setSearchItem(event.target.value);
    search(event.target.value);
  };

  const AllTigerSetup = (
    tigers,
    existingTigerData = [],
    existingAllTigerData = []
  ) => {
    let result = [];
    let result2 = [];

    tigers.forEach((value) => {
      const obj = {
        "Tiger Name": value.name,
        Age: value.age,
        Gender: value.gender,
        "Risk Score": value.score + "%",
        "Territory Movement": value.territory_movement === 0 ? "No" : "Yes",
        "Loss of Teeth": value.loss_of_teeth === 0 ? "No" : "Yes",
        "Physical Injury": value.physical_injury === 0 ? "No" : "Yes",
      };
      const obj2 = {
        name: value.name,
        Age: value.age,
        Gender: value.gender,
        "Risk Score": value.score + "%",
        "Territory Movement": value.territory_movement === 0 ? "No" : "Yes",
        "Loss of Teeth": value.loss_of_teeth === 0 ? "No" : "Yes",
        "Physical Injury": value.physical_injury === 0 ? "No" : "Yes",
        id: value.id,
      };

      result.push(obj);
      result2.push(obj2);
    });

    setTigerData([...existingTigerData, ...result]);
    setAllTigerData([...existingAllTigerData, ...result2]);
  };

  const handleEditTiger = async (tigerDetails, close, url, method, body) => {
    let token = "";
    let csrftoken = "";
    const formData = new FormData();
    formData.append("name", tigerDetails.name);
    formData.append("status", tigerDetails.status);
    formData.append("age", tigerDetails.age);
    formData.append("gender", tigerDetails.gender);
    formData.append("files", tigerDetails.files);
    const tigerToEdit = AllTigerData.find(
      (tiger) => tiger.name === tigerDetails.id[0]
    );
    const id = tigerToEdit.id;
    formData.append("id", id);
    if (localStorage.token) {
      token = localStorage.token;
    }
    if (localStorage.csrf) {
      csrftoken = localStorage.csrf;
    }

    try {
      const result = await makeRequest(
        (method = "put"),
        (url = `api/v1/identification/tiger_update/`),
        (body = formData)
      );
      setPage(1);

      setTigerData([]);
      setAllTigerData([]);
      setCurrentPage(0);
      setRender((prevRender) => prevRender + 1);
      toast.success("Tiger Edited Successfully");
      close();
    } catch (error) {
      toast.success("Error While Editing Tiger data", warningToaster);
    }
  };

  const deleteTigerHandler = async (tigerId, closePopup) => {
    setLoading(true);
    let token = "";
    let csrftoken = "";
    try {
      token = localStorage.token;
      const formData = new FormData();
      formData.append("id", tigerId);
      const result = await makeRequest(
        "delete",
        `api/v1/identification/tiger_delete/`,
        formData
      );

      setSearchItem([]);
      setSearchValue(false);
      closePopup();
      setRender(render + 1);
      setLoading(false);
      toast.success("Tiger Deleted Successfully", successToaster);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTiger = async () => {
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/identification/tiger_risk/?page=${page}&size=10`
      );
      const currentTigerData = TigerData;
      const currentAllTigerData = AllTigerData;

      setCount(result.data.count);
      AllTigerSetup(result.data.data, currentTigerData, currentAllTigerData);
      setCurrentPage(currentPage + 1);
      setScroll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const tigerAddHandler = async (
    tigerDetails,
    close,
    method,
    url,
    body,
    headers
  ) => {
    let token = "";
    let csrftoken = "";
    const formData = new FormData();
    formData.append("name", tigerDetails.name);
    formData.append("status", tigerDetails.status);
    formData.append("age", tigerDetails.age);
    formData.append("gender", tigerDetails.gender);
    const files = Array.from(tigerDetails.files);
    files.forEach((file) => {
      formData.append("files", file);
    });
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result = await makeRequest(
        (method = "post"),
        (url = `/api/v1/identification/tiger_add/`),
        (body = formData),
        (headers = { "Content-Type": "multipart/form-data" })
      );

      setRender(render + 1);
      toast.success("Tiger Added Successfully", successToaster);
      close();
    } catch (error) {
      toast.success("Error While Adding Tiger data", warningToaster);
    }
  };

  const deleteHandler = async (value) => {
    const tigerToDeleteName = value[0];
    try {
      const tigerToDelete = AllTigerData.find(
        (tiger) => tiger.name === tigerToDeleteName
      );
      if (tigerToDelete) {
        deleteTigerHandler(tigerToDelete.id);
      } else {
        console.log("Tiger not found or no data provided for deletion");
      }
    } catch (error) {
      console.error("Error occurred while fetching tiger data:", error);
    }
  };

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Tiger Risk Index</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input
              type="text"
              placeholder="Search by name"
              value={searchItem}
              onChange={searchHandler}
            />
            <img src={require("../Assets/Icons/search_icon.png")} alt="" />
          </div>
        </div>
      </div>
      <Setting_User_Table
        scroll={scroll}
        total={true}
        count={count}
        loading={loading}
        setLoading={setLoading}
        setPage={setPage}
        delete={true}
        data={searchValue ? filteredProducts : TigerData}
        currentPage={currentPage}
        page={page}
        type="Tiger Risk"
      />
      {/* <ToastContainer rtl={false} style={{ height: "10px" }} /> */}
    </div>
  );
};

export default TigerRisk;
