import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import SmallLoader from './SmallLoader'
import Nodata from './Nodata'
import LazyImage from './LazyImage'
import TadobaContext from './Context/TadobaContext'
const Alert_Table = (props) => {


    const api = useContext(TadobaContext)
    const [columnName, setColumnName] = useState([])


    useEffect(() => {
        let array = new Array();
        for (let key in props.data[0]) {
            array.push(key)
            setColumnName(array)
        }
    }, [])




    return (
        <div className="Setting_Table">
            <div className="Setting_Table_Header">
                <span>Showing {props.data.length} results {props?.total?`of ${props.count}`:""}</span>
            </div>
            {props.data.length === 0 ?
                <Nodata />
                :
                <>
                    {props.loading === true ? <SmallLoader /> :
                        <div className="Setting_Table_Layout">
                            <table>
                                <tr className='heading_row'>
                                    {columnName.map((value, index) => {
                                        return (
                                            <td key={index}>
                                                <div>
                                                    <span>{value}</span>
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                                {props.data.map((row, key) => {
                                    return (
                                        <tr className='main_row' key={key}>
                                            {columnName.map((value, index) => {
                                                if (value === 'Name') {
                                                    const u = api.users?.filter((ele) => row['Email'] === ele['email'])
                                                    return (
                                                        <td key={index}>
                                                            <div className='rowImgCombine'>
                                                                <div className='row_Img'>
                                                                    {u[0]?.profile_picture === null || u[0]?.profile_picture === "" ?
                                                                        <img src={require('../Assets/Icons/person.png')} alt="" />
                                                                        :
                                                                        <LazyImage src={u[0]?.profile_picture}  placeholderSrc={require("../Assets/Icons/temp_img.png")} />
                                                                        // <img src={u[0]?.profile_picture} alt="" />
                                                                    }
                                                                </div>
                                                                <div><span>{row[value]}</span></div>
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                                
                                                if (value == "Send Alerts") {
                                                    return (
                                                        <td key={index}>
                                                            <div>
                                                                <label className='toggle_label'>
                                                                    <input className={api.user.role.id===3?'toggle_input':"toggle_input"} disabled={api.user.role.id===3}   type="checkbox" checked={row[value]} onChange={(event) => { props.updateStatus(event.target.value, row) }} />
                                                                    <span className={api.user.role.id===3?'toggle_span toggle_disabled':"toggle_span"} />
                                                                </label>
                                                                {/* <ToggleButton toggled={row[value]} changeState={(state) => { props.updateStatus(state, row) }} /> */}
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                                return (
                                                    <td key={index}>
                                                        <div>
                                                            <span>{row[value]}</span>
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    }</>
            }
        </div>
    )
}

export default Alert_Table