import React, { useEffect, useRef, useState } from "react";
import Setting_User_Table from "../Components/Setting_User_Table";
import Popup from "reactjs-popup";
import { useContext } from "react";
import TadobaContext from "../Components/Context/TadobaContext";
import axios from "axios";
import { role, successToaster, url, warningToaster } from "../Data/Data";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../api";
import Tiger_routes from "../Components/Upload/Tiger_routes";

const LostTigers = () => {
  const api = useContext(TadobaContext);
  const [update, setUpdata] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [allLostTigers, setAllLostTigers] = useState([]);
  const [lostTigers, setLostTigers] = useState([]);
  const [buttonClicked, setButtonCllicked] = useState(null);
  const [name, setName] = useState("");
  const [allData, setAllData] = useState(null);
  const [id, setId] = useState(null);
  const [declared, setDeclared] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page]);

  useEffect(() => {
    if (declared) {
      console.log(1);
      setPage(1);
      setCurrentPage(0);
      setAllLostTigers([]);
      setLostTigers([]);
      fetchUsers();
    }
  }, [declared]);

  function search(searchTerm) {
    setUpdata(update + 1);
    if (searchTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    const filtered = allLostTigers.filter((product) => {
      return (
        product.Age.toLowerCase().includes(searchItem.toLowerCase()) ||
        product.Name.toLowerCase().includes(searchItem.toLowerCase())
      );
    });

    setFilteredProducts(filtered);
  }

  function filter(searchTerm, nextTerm) {
    if (searchTerm === "" && nextTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    if (dropdownValue === "") {
      search(nextTerm);
    } else {
      const filtered = allLostTigers.filter((product) => {
        return (
          product.Role.toLowerCase() === dropdownValue.toLowerCase() &&
          (product.Age.toLowerCase().includes(searchItem.toLowerCase()) ||
            product.Name.toLowerCase().includes(searchItem.toLowerCase()))
        );
      });

      setFilteredProducts(filtered);
    }
  }

  useEffect(() => {
    filter(dropdownValue, searchItem);
  }, [dropdownValue, searchItem]);

  const serachHandler = (event) => {
    setSearchItem(event.target.value);
  };

  useEffect(() => {
    if (message !== "") toast.success(message, successToaster);
  }, [message]);

  const AllUsersSetup = (users) => {
    const formattedUsers = users.map((value) => {
      const dateObj = new Date(value.last_seen_date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "UTC",
      };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        dateObj
      );

      return {
        Name: value.name,
        Age: value.Age,
        Range: value.range_name,
        Camera: value.camera_name,
        "Last Seen Date": formattedDate,
        Status: "Lost",
        Image: value.image,
      };
    });

    setAllLostTigers((prevData) => [...prevData, ...formattedUsers]);
  };

  const getNameToId = (name) => {
    const tiger = allData.find((tiger) => tiger.name === name);
    return tiger ? tiger.tiger_id : null;
  };

  const fetchUsers = async () => {
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/identification/lost_tigers/?page=${page}&size=20`
      );
      setCount(result.data.count);
      setAllData((prevData) => {
        if (prevData) {
          return [...prevData, ...result.data.data];
        } else {
          return [...result.data.data];
        }
      })
      AllUsersSetup(result.data.data);
      setLostTigers((prevData) => [...prevData, ...allLostTigers]);
      setCurrentPage(currentPage + 1);
      setDeclared(false);
      setLoading(false);
      setScroll(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const editHandler = async (row, close) => {
    // console.log(row);
    const name = row.Name;
    const id = getNameToId(name);
    let token = "";
    const data = {
      tiger_id: id,
    };
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      setPage(1);
      setCurrentPage(0);
      const result = await makeRequest(
        "post",
        `api/v1/identification/edit_status/`,
        data
      );
      close();
      setDeclared(true);
      toast.success(result.data.data.message, warningToaster);
    } catch (error) {
      close();
      setLoading(false);
    }
  };

  const viewHandler = (data) => {
    setName(data.Name);
    const tiger_id = getNameToId(data.Name);
    setId(tiger_id);
    setButtonCllicked(true);
  };

  if (buttonClicked === true) {
    return <Tiger_routes tiger_id={id} origin={"losttigers"} />;
  }

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Lost Tigers</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input
              type="text"
              value={searchItem}
              onChange={serachHandler}
              placeholder="Search by Name or Age"
            />
            <img src={require("../Assets/Icons/search_icon.png")} alt="" />
          </div>
        </div>
      </div>
      <Setting_User_Table
        scroll={scroll}
        total={true}
        count={count}
        loading={loading}
        setLoading={setLoading}
        setPage={setPage}
        data={searchValue ? filteredProducts : allLostTigers}
        viewHandler={viewHandler}
        editHandler={editHandler}
        currentPage={currentPage}
        page={page}
        type="losttigers"
      />
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default LostTigers;
