import React, { useContext, useEffect, useState, useRef } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { ImagesData } from "../Data/Data";
import SpeciesImages from "../Components/Images/SpeciesImages";
import AlertChildbar from "../Components/AlertChildbar";
import TigerImages from "../Components/Images/TigerImages";
// import ImageEdit from "../Components/Images/ImageEdit";
import TigerGroupImages from "../Components/Images/TigerGroupImages";
const Images = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const { species_id } = useParams();
  const navigate = useNavigate();
  return (
    <div className="settings">
      {!edit ? <AlertChildbar Data={ImagesData} image={true} /> : ""}
      <div className="settings_Child">
        <Routes>
          {ImagesData.map((value, key) => {
            return (
              <Route
                key={key}
                path={"/" + value.link.substr(1)}
                element={value.element(setEdit)}
              />
            );
          })}
          {/* <Route path="/:image_id" element={<ImageEdit setEdit={setEdit} />} /> */}
          <Route
            path="/key/Tigerimages/:species_id"
            element={<TigerImages />}
          />
          <Route
            path="/key/Tigerimages/:species_id/:groupName"
            element={<TigerGroupImages />}
          />
          <Route
            path="/key/:species_id"
            element={
              <SpeciesImages
                setEdit={setEdit}
                loading={loading}
                setLoading={setLoading}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Images;
