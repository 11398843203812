import React, { useState } from 'react'
import axios from 'axios'
import { setMinDate, url } from '../../Data/Data'
import { useEffect } from 'react'
import NoDetail from '../NoDetail'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeRequest } from '../../api'

const AlertReport_Card = () => {


  const [data,setData]= useState([])
  const [loading, setLoading] = useState(true)



  const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(new Date());

    useEffect(()=>{
        getImages()
    },[selectedDay,selectedMonth,selectedDate])

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedMonth(date)
        setSelectedDay(date)
    };

    const handleMonthChange = (date) => {
        setSelectedMonth(date);
        setSelectedDay(date)
        setSelectedDate(date)
    };

    const handleDayChange = (date) => {
        setSelectedDay(date);
        setSelectedMonth(date)
        setSelectedDate(date)
    };


  const getImages = async () => {
    let token = ""
    try {
        if (localStorage.token) {
            token = localStorage.token;
        }
        const result= await makeRequest("get",`api/v1/statistic/statistic_alert_timeline/?date=${selectedDay.toISOString().split('T')[0]}`)
        setData(result.data.data)
        setLoading(false)
    } catch (error) {
        setLoading(false)
    }
}
// useEffect(() => {
//     getImages()
// }, [])


  return (
    <div className='Card'>
            <div className="Card_Heading">
                <div className='Card_title'><img src={require("../../Assets/Icons/alert.png")} alt="" /><span>Alert Report</span></div>
                {/* <div className='Card_Link'><span>View all</span></div> */}
            </div>
            {data.length === 0 ? <NoDetail loading={loading}/> :
                <div className="Stat_Card_Content">
                    <div className="stat_element" style={{width:"30%"}}>
                        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                            <span className='stat_title'>Year:</span>
                            <DatePicker dateFormat="yyyy"
                                className="datepicker-container"
                                showYearPicker 
                                selected={selectedDate} 
                                minDate={setMinDate()}
                                maxDate={new Date()}
                                onChange={handleDateChange}     
                                />
                            </div>
                        <span className='stat_count'>{data.year}</span>
                    </div>
                    <div className="stat_element" style={{width:"30%"}}>
                        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                            <span className='stat_title'>Month:</span>
                            <DatePicker
                                dateFormat="MM"
                                showMonthYearPicker
                                showMonthDropdown
                                className="datepicker-container"
                                selected={selectedMonth} 
                                maxDate={new Date()}
                                minDate={setMinDate()}
                                onChange={handleMonthChange}     
                                />
                        </div>
                        <span className='stat_count'>{data.month}</span>
                    </div>
                    <div className="stat_element" style={{width:'30%'}}>
                        <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
                            <span className='stat_title'>Day:</span>
                            <DatePicker 
                                className="datepicker-container"
                                selected={selectedDay} 
                                maxDate={new Date()}
                                minDate={setMinDate()}
                                dateFormat="yyyy-MM-dd"
                                onChange={handleDayChange}     
                                />
                        </div>
                        <span className='stat_count'>{data.day}</span>
                    </div>
                </div>
            }
        </div>
  )
}

export default AlertReport_Card