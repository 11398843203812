import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, Marker, LoadScript, LoadScriptNext } from "@react-google-maps/api";
import LazyImage from "../Components/LazyImage";
import { makeRequest } from "../api";
import { MapKey } from "../Data/Data";

const Forest = (props) => {
  const navigate = useNavigate();
  const [forest, setForest] = useState();
  const [forestList, setForestList] = useState([]);
  const handleMarkerDragEnd = (e) => {};
  const fetchForest = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/setting/forest/`);
      setForestList(result.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchForest();
  }, []);

  const forestChangeHandler = (event) => {
    setForest(event.target.value);
  };
  const ForestSelectionHandler = async (event) => {
    let token = "";
    let csrfToken = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrfToken = localStorage.csrf;
      }
      const result = await makeRequest(
        "post",
        `api/v1/account/user_forest_set/`,
        { forest_id: event.id }
      );
      setForestList(result.data.data);
      props.setForest(true);
      navigate("/");
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="ForestPage">
      <div className="ForestSelection">
        <div className="ForestMap">
          <LoadScriptNext googleMapsApiKey={MapKey}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={{
                lat: forestList[0]?.latitude,
                lng: forestList[0]?.longitude,
              }}
              zoom={12}
            >
              {forestList.map((value, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: value.latitude, lng: value.longitude }}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                  />
                );
              })}
            </GoogleMap>
          </LoadScriptNext>
        </div>
        <div className="ForestList">
          <div className="ForestListCard">
            <div className="Forest_Card_Heading">
              <span>Select Forest</span>
            </div>
            {forestList.map((row, key) => {
              return (
                <div className="ForestListRow" key={key}>
                  <div className="ForestCheck">
                    <input
                      type="radio"
                      value={key}
                      checked={key === 0}
                      disabled={key > 0}
                      onChange={forestChangeHandler}
                      name="forest"
                    />
                  </div>
                  <div className="Forest">
                    <div className="Forest_img" style={{ marginLeft: "-30px" }}>
                      <img
                        className="forest_img_3"
                        src={require("../Assets/Forest_Images/forestIcon.png")}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div className="Forest_name" style={{ marginTop: "-10px" }}>
                      <span>{row.name}</span>
                    </div>
                    <div
                      className={
                        "Login_Button Forest_Proceed " +
                        " " +
                        (key === forest || key === 0 ? "selected_forest" : "")
                      }
                      style={{ marginTop: "-20px" }}
                    >
                      <button
                        onClick={() => {
                          ForestSelectionHandler(row);
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forest;
