import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
const ChildSidebar = ({ Data }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`ChildSideBar ${
        window.location.pathname.includes("/find_tiger") ? "active" : ""
      }`}
      style={
        window.location.pathname.includes("/find_tiger")
          ? { width: "170px" }
          : {}
      }
    >
      <ul className="ChildSideBarList">
        {Data.map((value, key) => {
          if (value.link === "./") {
            return (
              <li
                key={key}
                className="ChildListSideBarItem"
                id={
                  window.location.pathname === "/settings/" ||
                  window.location.pathname === "/images/" ||
                  window.location.pathname === "/find_tiger/" ||
                  window.location.pathname === "/find_tiger"
                    ? "active"
                    : ""
                }
              >
                <div
                  onClick={() => {
                    navigate(value.link);
                  }}
                  className="ChildSideBarItem"
                >
                  <span>{value.title}</span>
                </div>
              </li>
            );
          }
          return (
            <li
              key={key}
              className="ChildListSideBarItem"
              id={
                window.location.pathname.includes(value.link.substr(1))
                  ? "active"
                  : ""
              }
            >
              <div
                onClick={() => {
                  navigate(value.link);
                }}
                className="ChildSideBarItem"
              >
                <span>{value.title}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChildSidebar;
