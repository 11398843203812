import React from 'react'
import ChildSidebar from '../Components/ChildSidebar'
import { SettingsData } from '../Data/Data'
import { Route, Routes } from 'react-router-dom'
import Map_edit from '../Components/Settings/Map_edit'
const Settings = () => {
    return (
        <div className='settings'>
            <ChildSidebar Data={SettingsData} />
            <div className="settings_Child">
                <Routes>
                    {SettingsData.map((value, key) => {
                        return (
                            <Route path={"/" + value.link.substr(1)} element={value.element} />
                        )
                    })}
                    <Route path='/map_setup/:map_id' element={<Map_edit/>}/>
                </Routes>
            </div>
        </div>
    )
}

export default Settings