import React from "react";
import ChildSidebar from "../Components/ChildSidebar";
import { FindTigerData } from "../Data/Data";
import { Route, Routes } from "react-router-dom";
import Map_edit from "../Components/Settings/Map_edit";

const FindMyTiger = () => {
  return (
    <div className="settings">
      <ChildSidebar Data={FindTigerData} />
      <div className="settings_Child">
        <Routes>
          {FindTigerData.map((value, key) => {
            return (
              <Route
                path={"/" + value.link.substr(1)}
                element={value.element}
              />
            );
          })}
          <Route path="/map_setup/:map_id" element={<Map_edit />} />
        </Routes>
      </div>
    </div>
  );
};

export default FindMyTiger;
