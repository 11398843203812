import React from "react";
import { useState } from "react";
import axios from "axios";
import { url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import NoDetail from "../NoDetail";
import LazyImage from "../LazyImage";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { makeRequest } from "../../api";
const UnClassified_Card = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const api = useContext(TadobaContext);
  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/unclassified/?page=1&size=20`
      );
      setData(result.data.data);
      localStorage.setItem("unclassified", JSON.stringify(result.data.data));
      setLoading(false);
    } catch (error) {
      setWarning(true);
      setWarningMessage(error.response.data.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!localStorage.unclassified) {
      getImages();
    } else {
      const storedData = JSON.parse(localStorage.unclassified);
      setData(storedData);
    }
  }, []);

  const viewHandler = () => {
    api.setExpanded(false);
    navigate("./images/unclassified");
  };

  return (
    <div className="Card">
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/Card_Stat.png")} alt="" />
          <span>Unclassified Species</span>
        </div>
        <div className="Card_Link" onClick={viewHandler}>
          <span>View all</span>
        </div>
      </div>
      {data.length === 0 ? (
        <NoDetail
          loading={loading}
          warning={warning}
          warningMessage={warningMessage}
        />
      ) : (
        <div className="unclassified_card_content">
          {data.map((value, index) => {
            if (index < 4) {
              return (
                <LazyImage
                  classProp={
                    index === 0 || index === 3 ? "big_un" : "classified_img"
                  }
                  src={value.image}
                  placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default UnClassified_Card;
