import React from 'react'
import { useState } from 'react';
import { useDropzone } from 'react-dropzone'

const Map_Upload = (props) => {


    const [drag, setDrag] = useState(false);
    const [file, setFile] = useState([]);
    const [map_name,setMapName]= useState("")

    const eventChangeHandler = (event) =>{
        setMapName(event.target.value)
    }


    const { getRootProps, getInputProps } = useDropzone({
        accept: "*",
        directory: true,
        onDragOver: () => {
            setDrag(true)
        },
        onDragLeave: () => {
            setDrag(false)
        },
        onDrop: (acceptedFiles) => {
            setFile(
                acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
            setDrag(false)
        }
    })



    return (
        <div className='setting_map_upload'>
            <div className='map_upload_img'>
                <img src={URL.createObjectURL(props.image[0])} alt="" />
            </div>
            <div className="map_input">
                <label className='map_name' htmlFor="map_name">Map Name</label>
                <input type="text" name="map_name" placeholder="Enter Map Name" value={map_name} onChange={eventChangeHandler} id="map_name" />
            </div>
            {file.length===0?
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='map_upload'>
                    <div>
                        <img src={require('../../Assets/Icons/Cloud.png')} alt="" />
                    </div>
                    <div className='map_upload_heading'>
                        <span className='map_upload_main_heading'>Upload excel</span>
                        <span className='map_upload_text'>Select the excel file which contains the geolocation data </span>
                    </div>
                    <div>
                        <button className='map_upload_excel_button'>Select Excel</button>
                    </div>
                </div>
            </div>:
            <div className='map_uploaded_file'>
                <div className='map_upload_file_heading'>
                    <span>File Added</span>
                </div>
                <div className='map_uploaded_area'>
                    <span>{file[0].name}</span>
                    <img src={require("../../Assets/Icons/x-circle.png")} style={{cursor:"pointer"}} onClick={()=>{
                        setFile([])
                    }} alt="" />
                </div>
            </div>
            }
            <div className="popupDeleteButton">
                <button className='CancelButton' onClick={()=>{props.close()}} style={{width:"50%"}}>Cancel</button>
                <button className={map_name===""?"upload_disabled":"DeleteButton"} style={{width:"50%", color:"#FFFFFF"}} onClick={()=>{props.mapUploadHandler(file[0],map_name,props.image[0])}} >Upload</button>
            </div>
        </div>
    )
}

export default Map_Upload