import React, { useEffect, useState } from 'react';
import { api } from '../api';
import { makeRequest } from '../api';

const FilterDetails = ({ filtersArray }) => {
  const [ranges, setRanges] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [beats, setBeats] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [stations, setStations] = useState([]);
  const [cameras, setCameras] = useState([]);

  const fetchFilters = async (key, setState) => {
    if (key === null) {
      api.setImagesFilters("");
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/geolocation_filter_core/?key=${key}`
      );
      setState(result.data.data);
    } catch (error) {
      console.error(`Failed to fetch ${key} filters`, error);
    }
  };

  useEffect(() => {
    fetchFilters("range", setRanges);
    fetchFilters("round", setRounds);
    fetchFilters("beat", setBeats);
    fetchFilters("compartment", setCompartments);
    fetchFilters("station", setStations);
    fetchFilters("camera", setCameras);
  }, []); 

  const transformFilters = (filters) => {
    const filterMap = {};

    filters.forEach(filter => {
      const [type, id] = filter.split('=');
      const idNum = parseInt(id, 10);

      const typeKey = type.replace('_name', '');
      if (!filterMap[typeKey]) {
        filterMap[typeKey] = [];
      }
      filterMap[typeKey].push({ id: idNum });
    });

    return Object.entries(filterMap).map(([key, value]) => ({ [key]: value }));
  };

  const newArray = transformFilters(filtersArray);

  const findNameById = (list, id) => {
    const item = list.find((el) => el.id === id);
    return item ? item.name : 'Unknown';
  };

  useEffect(() => {
    console.log(newArray);
  }, [filtersArray]);

  return (
    <span>
      {newArray.map((filterGroup, index) => {
        const [type, ids] = Object.entries(filterGroup)[0];
        let list = [];
        switch (type) {
          case 'range':
            list = ranges;
            break;
          case 'round':
            list = rounds;
            break;
          case 'beat':
            list = beats;
            break;
          case 'compartment':
            list = compartments;
            break;
          case 'station':
            list = stations;
            break;
          case 'camera':
            list = cameras;
            break;
          default:
            list = [];
        }

        return (
            <span key={index}>
            (
            {ids.slice(0, 3).map((item, idx) => (
              <span key={item.id}>
                {findNameById(list, item.id)}
                {idx < Math.min(ids.length - 1, 2) ? ', ' : ''}
              </span>
            ))}
            {ids.length > 3 ? ' ...' : ''}
            )
          </span>
        );
      })}
    </span>
  );
};

export default FilterDetails;
