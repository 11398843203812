import React, { useEffect, useState } from "react";
import TadobaContext from "./TadobaContext";
import { user_data } from "../../Data/Data";
import { setting_map } from "../../Data/Data";
import { Geolocation_Data } from "../../Data/Data";
import { species_data } from "../../Data/Data";
import { alert_data } from "../../Data/Data";
import { url } from "../../Data/Data";
import axios from "axios";
import { makeRequest } from "../../api";
const Tadoba = (props) => {
  const [allUsers, setAllUsers] = useState(user_data);
  const [token, setToken] = useState(null);
  const [allGeolocations, setAllGeolocations] = useState(Geolocation_Data);
  const [allMapGeolocations, setAllMapGeolocations] =
    useState(Geolocation_Data);
  const [totalGeolocations, setTotalGrolocations] = useState(0);
  const [geolocationFilters, setGeolocationFilters] = useState(null);
  const [ImagesFilters, setImagesFilters] = useState([]);
  const [settingMap, setSettingMap] = useState(setting_map);
  const [Geolocations, setGeolocations] = useState([]);
  const [mapGeolocations, setMapGeolocations] = useState([]);
  const [totalMapGeolocations, setTotalMapGeolocations] = useState(0);
  const [users, setUsers] = useState([]);
  const [lostTigers, setLostTigers] = useState([]);
  const [allLostTigers, setAllLostTigers] = useState([]);
  const [images, setImages] = useState([]);
  const [classification, setClassification] = useState(null);
  const [species, setSpecies] = useState(species_data);
  const [allSpecies, setAllSpecied] = useState([]);
  const [page, setPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState([]);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState({});
  const [alertData, setAlertData] = useState(alert_data);
  const [UploadKey, setUploadKey] = useState();
  const [file, setFile] = useState([]);
  const [upload, setUpload] = useState(false);
  const [tigerImage, setTigerImage] = useState([]);
  const [identifiedTigers, setIdentifiedTigers] = useState([]);
  const [progress, setProgress] = useState({
    uploadPercentage: 0,
    totalUpload: 0,
    completeUpload: 0,
  });
  const [expanded, setExpanded] = useState(true);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [unIdentifiedTigers, setUnIdentifiedTigers] = useState([]);
  const [startDownload, setStartDownload] = useState(false);
  const [totalTigersCount, setTotalTigersCount] = useState(null);
  const [totalIdentifiedCount, setTotalIdentifiedCount] = useState(null);
  const [yearsData, setYearsData] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [defaultYear, setDefaultYear] = useState([]);
  const [downloadingText, setDownloadingText] = useState("");
  const [downloadQuery, setDownloadQuery] = useState([]);
  const [deleteQuery, setDeleteQuery] = useState([]);
  const [disable, setDisable] = useState(true);
  const [startDelete, setStartDelete] = useState(false);
  const [selectedYearData, setSelectedYearData] = useState([])
  const [isIdentifying, setIsIdentifying] = useState(false);

  useEffect(() => {
    if (!localStorage.species) {
      getSpecies();
    } else {
      const storedData = JSON.parse(localStorage.species);

      setAllSpecied(storedData);
    }

    if (!localStorage.mapborders) {
      getTadobaArea();
    } else {
      const storedData = JSON.parse(localStorage.mapborders);

      setPolygonCoordinates(storedData);
    }

    yearsListGenerator();
  }, [token]);

  const getSpecies = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/species/species/`);
      setAllSpecied(result.data.data);
      localStorage.setItem("species", JSON.stringify(result.data.data));
    } catch (error) {
      setAllSpecied(null);
    }
  };

  const getTadobaArea = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/map/map_borders/`);
      const convertedData = result.data.data.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude,
      }));
      setPolygonCoordinates(convertedData);
      localStorage.setItem("mapborders", JSON.stringify(convertedData));
    } catch (error) {
      setPolygonCoordinates(null);
    }
  };

  const yearsListGenerator = () => {
    const getCurrentYear = () => new Date().getFullYear();
    const startYear = 2018;
    const currentYear = getCurrentYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year.toString());
    }
    setYearsList(years);
    setDefaultYear(currentYear.toString());
  };

  return (
    <TadobaContext.Provider
      value={{
        token,
        setToken,
        expanded,
        ImagesFilters,
        polygonCoordinates,
        setImagesFilters,
        setExpanded,
        progress,
        setProgress,
        upload,
        setUpload,
        file,
        setFile,
        UploadKey,
        setUploadKey,
        alertData,
        setAlertData,
        user,
        setUser,
        totalMapGeolocations,
        setTotalMapGeolocations,
        filterQuery,
        setFilterQuery,
        allMapGeolocations,
        setAllMapGeolocations,
        mapGeolocations,
        setMapGeolocations,
        totalGeolocations,
        setTotalGrolocations,
        page,
        setPage,
        allUsers,
        setAllUsers,
        allGeolocations,
        setAllGeolocations,
        Geolocations,
        setGeolocations,
        users,
        setUsers,
        lostTigers,
        setLostTigers,
        allLostTigers,
        setAllLostTigers,
        settingMap,
        setSettingMap,
        geolocationFilters,
        setGeolocationFilters,
        images,
        setImages,
        classification,
        setClassification,
        species,
        setSpecies,
        allSpecies,
        setAllSpecied,
        query,
        setQuery,
        tigerImage,
        setTigerImage,
        identifiedTigers,
        setIdentifiedTigers,
        unIdentifiedTigers,
        setUnIdentifiedTigers,
        startDownload,
        setStartDownload,
        totalTigersCount,
        setTotalTigersCount,
        totalIdentifiedCount,
        setTotalIdentifiedCount,
        yearsData,
        setYearsData,
        yearsList,
        setYearsList,
        defaultYear,
        setDefaultYear,
        downloadingText,
        setDownloadingText,
        downloadQuery,
        setDownloadQuery,
        disable,
        setDisable,
        startDelete,
        setStartDelete,
        deleteQuery,
        setDeleteQuery,
        setSelectedYearData,
        selectedYearData,
        setIsIdentifying,
        isIdentifying,
      }}
    >
      {props.children}
    </TadobaContext.Provider>
  );
};

export default Tadoba;
