import React from "react";
import TadobaContext from "./Context/TadobaContext";
import { useContext } from "react";
const NavigationBar = () => {
  const api = useContext(TadobaContext);
  const expandHandler = () => {
    api.setExpanded(true);
  };
  return (
    <div className="Nav">
      <ul className="Nav_item1">
        <li className="menu" onClick={expandHandler}>
          <img
            className="menu_img"
            src={require("../Assets/Icons/menu.png")}
            alt=""
          />
        </li>
        <li>
          <img
            className="forest_img_3"
            src={require("../Assets/Images/forestIcon.png")}
          />
        </li>
        <li>
          <div className="Nav_item1_text">
            <span>Karnataka Forest Department</span>
          </div>
        </li>
      </ul>
      <ul className="Nav_item2">
        <li>
          <div className="Nav_item2_text">
            <span>Powered By</span>
          </div>
        </li>
        <li>
          <img
            className="forest_img_2"
            src={require("../Assets/Images/logo.png")}
          />
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
