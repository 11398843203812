import React from "react";
import { useState, useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { ToastContainer } from "react-toastify";
import { AccesDetails } from "../../Data/Data";
const Permissions = ({ props, role, setPermission }) => {
  const api = useContext(TadobaContext);
  const user = api.users.filter(function (item) {
    return item.email === props.row.Email;
  });
  const [name, setName] = useState(user[0].name);
  const [email, setEmail] = useState(user[0].email);
  const [role_id, setRole_id] = useState(user[0].role.id);
  const [drag, setDrag] = useState(false);
  const [image_preview, setImagePreview] = useState(user[0].profile_picture);
  const [access, setAccess] = useState(() => {
    const filteredUsers = api.users.filter(function (item) {
      return item.email === props.row.Email;
    });
    if (filteredUsers.length > 0 && "access" in filteredUsers[0])
      return filteredUsers[0].access;
    else {
      return [];
      // setPermission(false);
    }
  });

  const roleChangeHandler = (event, value) => {
    setRole_id(value.id);
  };

  const accessChangeHandler = (event, value) => {
    setAccess((prevAccess) => ({
      ...prevAccess,
      [value]: Number(event.target.value),
    }));
  };

  console.log(AccesDetails);
  console.log(api.users);

  const editHandler = () => {
    let formData = new FormData();
    for (const key in access) {
      formData.append(key, access[key]);
    }
    props.accessHandler(formData, props.close);
  };

  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Access Settings</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="PermissionHeader">
          <div className="smallImgCircle">
            {image_preview === null || image_preview === "" ? (
              <img src={require("../../Assets/Icons/person.png")} alt="" />
            ) : (
              <img src={image_preview} alt="" />
            )}
          </div>
          <div
            className="Details"
            style={{ position: "relative", width: "100%" }}
          >
            <span className="permission_user_name">{name}</span>
            <img
              src={require("../../Assets/Icons/Edit.png")}
              onClick={() => {
                setPermission(false);
              }}
              style={{ position: "absolute", right: "0", cursor: "pointer" }}
              alt=""
            />
            <span className="permission_email_name">{email}</span>
          </div>
        </div>
        <div className="permissionContent" style={{ width: "110%" }}>
          {AccesDetails.map((value) => {
            const str = value;
            const str2 = str.charAt(0).toUpperCase() + str.slice(1);
            return (
              <div className="Permission">
                <div className="Permission_Title">
                  <span>{str2}</span>
                </div>
                <div className="PopupRadioButton">
                  <input
                    type="radio"
                    id={value}
                    value={1}
                    checked={access[value] === 1}
                    onChange={(event) => {
                      accessChangeHandler(event, value);
                    }}
                    name={value}
                  />
                  <span for={value}>Hide</span>
                  <input
                    type="radio"
                    id={value}
                    value={2}
                    checked={access[value] === 2}
                    onChange={(event) => {
                      accessChangeHandler(event, value);
                    }}
                    name={value}
                  />
                  <span for={value}>Read only</span>
                  {value === "maps" || value === "images" ? (
                    <>
                      <input
                        type="radio"
                        id={value}
                        value={3}
                        checked={access[value] === 3}
                        onChange={(event) => {
                          accessChangeHandler(event, value);
                        }}
                        name={value}
                      />
                      <span for={value}>Edit</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="popupDeleteButtonUsers"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="CancelButton"
            onClick={() => {
              props.close();
            }}
          >
            Cancel
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              editHandler();
            }}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default Permissions;
