import React, { useContext, useState } from "react";
// import ClassifiedContainer from "./ClassifiedContainer";
import IdentifiedContainer from "./IdentifiedContainer";
import TadobaContext from "../Context/TadobaContext";
import { Navigate, useNavigate } from "react-router-dom";
import { url } from "../../Data/Data";
import axios from "axios";
import { makeRequest } from "../../api";
const IdentifyLoader = (props) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [label, setLabel] = useState(false);
  const hideLabel = () => {
    setLabel(true);
  };

  const clearHandler = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/classification/upload_complete/`
      );
      props.setFile([]);
      api.setClassification(null);
      api.setFile([]);
      api.setUpload(false);
      navigate("/upload");
    } catch (error) {}
  };

  return (
    <div className="Classify_Section">
      <div className="Classify_Header">
        <div className="Classify_Header">
          <span>Identified Tigers</span>
        </div>
        <div
          className="Classify_Upload"
          style={{ cursor: "pointer" }}
          onClick={clearHandler}
        >
          <span>Finish Upload</span>
        </div>
      </div>
      {label ? "" : ""}
      <div className="Classify_Container">
        <IdentifiedContainer setLabel={setLabel} />
      </div>
    </div>
  );
};

export default IdentifyLoader;
