import React from "react";
import LazyImage from "../LazyImage";
const ImagesView = (props) => {
  const handleBack = () => {
    props.setUploadview(false)
    props.setLabel(false)
  }
  return (
    <>
      <div className="ClassificationContainerHeader">
        <div className="Setting_User_Header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={require("../../Assets/Icons/left.png")}
              onClick={handleBack}
              alt=""
            />
            <span>{props.title}</span>
          </div>
        </div>
      </div>
      <div className="Images_View">
        {props.images.map((value, key) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <div className="ImageBox">
                <div className="Individual_Image">
                  <LazyImage
                    src={value.fileName}
                    placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImagesView;
