import React, { useContext, useImperativeHandle, forwardRef } from "react";
import DeleteImage from "./Images/DeleteImage";
import Popup from "reactjs-popup";
import ShareImage from "./Images/ShareImage";
import TadobaContext from "./Context/TadobaContext";
import { url } from "../Data/Data";
import NoDetail from "./NoDetail";
import { useEffect, useState } from "react";
import { makeRequest } from "../api";
import LazyImage from "./LazyImage";
import { decryptImageUrl } from "../Data/Data";
import { useRef } from "react";

const ImageContainer = forwardRef((props, ref) => {
  const [allCheck, setAllCheck] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [render, setRender] = useState(1);
  const [Checked, setChecked] = useState(props.data);
  const scrollContainerRef = useRef(null);
  const [exportedIds, setExportedIds] = useState([]);
  const [deleteId, setDeleteId] = useState(props.exportedImages);
  useEffect(() => {
    setPartialCheck(false);
    Checked.map((value, key) => {
      if (value.checked === true) {
        setPartialCheck(true);
        setAllCheck(true);
      }
      if (value.checked === false) {
        setAllCheck(false);
      }
    });
  }, [render]);

  useEffect(() => {
    setPartialCheck(false);
    setChecked(props.data);
    props.data.map((value, key) => {
      if (value.checked === true) {
        setPartialCheck(true);
      }
      if (value.checked === false) {
        setAllCheck(false);
      }
    });
  }, [props.data]);

  useImperativeHandle(ref, () => ({
    resetStates: () => {
      setPartialCheck(false);
      setAllCheck(false);
      setExportedIds([])
      setChecked(props.data)
      const newData = [...Checked];
      newData.map((value, key) => {
        props.setAllCheck(false);
        value.checked = false;
        return value;
      }); 
    },
  }));

  const AllSpeciesSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        "Species name": value.name,
        "No. Of Images": value.no_of_images,
      };
      result.push(obj);
    });
    api.setSpecies(result);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollContainerRef.current;
    if (props.data.length >= props.count) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (!props.scroll) {
        props.setPage(props.currentPage + 1);
      }
    }
  }, 0);

  const api = useContext(TadobaContext);
  useEffect(() => {
    const fetchSpecies = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/species/species/`);
        if (props.type === "unclassified") {
          api.setAllSpecied(result.data.data);
          AllSpeciesSetup(result.data.data);
        }
      } catch (error) {}
    };
    if (!localStorage.species) {
      fetchSpecies();
    } else {
      const storedData = JSON.parse(localStorage.species);
      if (props.type === "unclassified") {
        api.setAllSpecied(storedData);
        AllSpeciesSetup(storedData);
      }
    }
  }, []);

  const AllCheckBoxChageHandler = () => {
    const list = [];
    const newData = [...Checked];
    if (allCheck === true) {
      setAllCheck(false);
      props.setAllCheck(false);
      const tempData = newData.map((value, key) => {
        value.checked = false;
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    } else {
      const tempData = newData.map((value, key) => {
        setAllCheck(true);
        props.setAllCheck(true);
        value.checked = true;
        list.push(value["image"]);
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    }
    setDeleteId(list);
    props.setExportedImages(list);
    setRender(render + 1);
  };

  useEffect(() => {
    if (api.startDelete === false) {
      setExportedIds([]);
    }
  }, [api.startDelete]);
  const checkboxChangeHandler = (row) => {
    const newData = Checked.map((value) => {
      if (row.image === value.image) {
        value.checked = !value.checked;
  
        if (value.checked) {
          setDeleteId((prev) => [...prev, row.image]);
          props.setExportedImages((prev) => [...prev, row.image]);
          setExportedIds((prev) => [...prev, row.id]);
          props.setExportedIds((prev) => [...prev, row.id]);
        } else {
          setDeleteId((prev) => prev.filter((img) => img !== row.image));
          props.setExportedImages((prev) => prev.filter((img) => img !== row.image));
          setExportedIds((prev) => prev.filter((id) => id !== row.id));
          props.setExportedIds((prev) => prev.filter((id) => id !== row.id));
        }
      }
      return value;
    });
    setChecked(newData); 
    props.setData(newData); 
    setRender((prev) => prev + 1); 
  };
  

  const downloadImage = (imageURL) => {
    const decryptUrl = decryptImageUrl(
      imageURL,
      localStorage.getItem("image_key")
    );
    fetch(decryptUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "image.jpg";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <div className="Images_Table" style={{ position: "relative" }}>
        <div
          className="checkbox"
          style={{ display: "flex", alignItems: "center" }}
        >
          {partialCheck ? (
            <>
              {allCheck ? (
                <img
                  onClick={() => {
                    AllCheckBoxChageHandler();
                  }}
                  src={require("../Assets/Icons/Checked_Box.png")}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => {
                    AllCheckBoxChageHandler();
                  }}
                  src={require("../Assets/Icons/partialcheck.png")}
                  alt=""
                />
              )}
            </>
          ) : (
            <img
              onClick={() => {
                AllCheckBoxChageHandler();
              }}
              src={require("../Assets/Icons/checkbox.png")}
              alt=""
            />
          )}
          {props.type === "unclassified" &&
          props.data.length > 0 &&
          api.user.access.images === 3 ? (
            <div
              className="popupDeleteButtonFilter"
              style={{ width: "100%", justifyContent: "flex-end" }}
            >
              <button
                style={{ marginTop: "0px" }}
                className="DeleteButton"
                onClick={props.speciesChangeHandler}
              >
                Save
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="Setting_Table_Header" style={{ alignItems: "center" }}>
          <span>
            Showing {props.data.length} results of {props.count}{" "}
            {props.exportedImages.length > 0 && !allCheck
              ? `(${props.exportedImages.length} Selected)`
              : allCheck
              ? `(All ${props.count} Selected)`
              : ""}
          </span>
          {partialCheck &&
          (api.user.role.id === 2 || api.user.role.id === 1) ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
                // alignItems: "center",
                width: "62%",
                justifyContent: "flex-end",
              }}
            >
              {/* <div
                className="icons-animation"
                onClick={() => {
                  props.deleteHandler(deleteId);
                  setAllCheck(false);
                  setDeleteId([]);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                <img
                  src={require("../Assets/Icons/edit_share.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
              <div
                className="icons-animation"
                onClick={() => {
                  props.deleteHandler(deleteId);
                  setAllCheck(false);
                  setDeleteId([]);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                <img
                  src={require("../Assets/Icons/edit_download.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div> */}
              <Popup
                trigger={
                  <div
                    className="icons-animation"
                    onClick={() => {
                      props.deleteHandler(deleteId);
                      setAllCheck(false);
                      setDeleteId([]);
                      setPartialCheck(false);
                      setChecked(
                        props.data.map((row, key) => {
                          const obj = { ...row };
                          obj.checked = false;
                          return obj;
                        })
                      );
                    }}
                  >
                    <img
                      src={require("../Assets/Icons/edit_trash.png")}
                      style={{ cursor: "pointer" }}
                      alt=""
                    />
                  </div>
                }
                modal
                nested
              >
                {(close) => {
                  return (
                    <DeleteImage
                      multiple={true}
                      close={close}
                      deleteImage={props.deleteImage}
                      count={ allCheck ? props.count : props.exportedImages.length}
                      data={Checked}
                      setCount={props.setExportedImages}
                      setDeleteId={setDeleteId}
                    />
                  );
                }}
              </Popup>
            </div>
          ) : (
            ""
          )}
          {props.type === "Classified" ? (
            <div className="ViewSwitcher">
              {props.grid ? (
                <>
                  <img
                    src={require("../Assets/Icons/highlighted_grid.png")}
                    onClick={() => {
                      props.setGrid(true);
                    }}
                    alt=""
                  />
                  <img
                    src={require("../Assets/Icons/list_view.png")}
                    onClick={() => {
                      props.setGrid(false);
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img
                    src={require("../Assets/Icons/grid_view.png")}
                    onClick={() => {
                      props.setGrid(true);
                    }}
                    alt=""
                  />
                  <img
                    src={require("../Assets/Icons/highlighted_list.png")}
                    onClick={() => {
                      props.setGrid(false);
                    }}
                    alt=""
                  />
                </>
              )}
            </div>
          ) : (
            ""
          )}
          {/* {props.type == "unclassified" ?
                        <div className="popupDeleteButtonFilter" style={{ width: "80%", justifyContent: "flex-end" }}>
                            <button className='DeleteButton' onClick={props.speciesChangeHandler}>Run Classification</button>
                        </div> : ""
                    } */}
        </div>
        <div
          className="Images_View"
          id="ScrollArea"
          ref={scrollContainerRef}
          style={{ height: "60vh" }}
          onScroll={handleScroll}
        >
          {props.data.length === 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NoDetail loading={props.loading} />
            </div>
          ) : (
            <>
              {props.data.map((value, key) => {
                const name = value.id;
                return (
                  <div key={key} className="ImageBox">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="Individual_Image"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="image_checkbox"
                          onClick={() => {
                            checkboxChangeHandler(value);
                          }}
                        >
                          {Checked[key]?.checked ? (
                            <img
                              src={require("../Assets/Icons/Checked_Box.png")}
                              style={{ visibility: "visible" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={require("../Assets/Icons/checkbox.png")}
                              alt=""
                            />
                          )}
                        </div>
                        <LazyImage
                          src={value.image}
                          placeholderSrc={require("../Assets/Icons/temp_img.png")}
                        />
                        <div className="Images_Controllers">
                          {props.type === "unclassified" ||
                          props.exportedImages.length > 1 ? (
                            ""
                          ) : (
                            <img
                              src={require("../Assets/Icons/edit_image.png")}
                              onClick={() => {
                                props.editImage(value);
                              }}
                              alt=""
                            />
                          )}
                          {/* <Popup
                            trigger={
                              <div className="row_edit">
                                <img
                                  src={require("../Assets/Icons/share.png")}
                                  loading="lazy"
                                  alt=""
                                />
                              </div>
                            }
                            modal
                            nested
                          >
                            {(close) => {
                              return <ShareImage close={close} />;
                            }}
                          </Popup> */}
                          <div className="row_edit">
                            <img
                              src={require("../Assets/Icons/download.png")}
                              onClick={() => {
                                downloadImage(value.image);
                              }}
                              loading="lazy"
                              alt=""
                            />
                          </div>
                          {api.user.access.images === 3 ? (
                            <Popup
                              trigger={
                                <div className="row_edit">
                                  <img
                                    src={require("../Assets/Icons/delete_img.png")}
                                    loading="lazy"
                                    alt=""
                                  />
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => {
                                return (
                                  <DeleteImage
                                    multiple={props.exportedImages.length > 1 ? true : false}
                                    count={props.exportedImages.length}
                                    deleteImage={props.deleteImage}
                                    value={value}
                                    data={Checked}
                                    name={name}
                                    setCount={props.setExportedImages}
                                    close={close}
                                    setDeleteId={setDeleteId}
                                  />
                                );
                              }}
                            </Popup>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {api.user.access.images === 3 ? (
                        <>
                          {props.type === "unclassified" ? (
                            <select
                              style={{ width: "192px" }}
                              className="role_filter"
                              onChange={(event) => {
                                const result = api.allSpecies.filter(function (
                                  item
                                ) {
                                  return item.name === event.target.value;
                                });
                                props.modifySpeciesHandler(
                                  value.id,
                                  result[0]?.id
                                );
                              }}
                            >
                              <option className="role_filter_span" value="">
                                Select Species
                              </option>
                              {api.species.map((value, key) => {
                                return (
                                  <option
                                    className="role_filter_span"
                                    key={key}
                                    value={value["Species name"]}
                                  >
                                    {value["Species name"]}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {props.type === "unclassified" ? (
                      ""
                    ) : (
                      <div className="ImageName">
                        <span>{name}</span>
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;
                          {value.tiger_name}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default ImageContainer;
