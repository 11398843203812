import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../Data/Data";
import NoDetail from "../NoDetail";
import Popup from "reactjs-popup";
import RecentImages from "./RecentImages";
import LazyImage from "../LazyImage";
import { makeRequest } from "../../api";
const RecentImages_Card = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/recent_images/?page=1&size=20`
      );
      setData(result.data.data);
      localStorage.setItem("recent_images", JSON.stringify(result.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!localStorage.recent_images) {
      getImages();
    } else {
      // Parse the JSON string from localStorage before using it

      const storedData = JSON.parse(localStorage.recent_images);

      setData(storedData);
    }
  }, []);

  return (
    <div className="Card">
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/recetn_images.png")} alt="" />
          <span>Recent Images</span>
        </div>
        <Popup
          trigger={
            <div className="Card_Link">
              <span>View all</span>
            </div>
          }
          modal
          nested
          className="recent_popup"
        >
          {(close) => {
            return <RecentImages data={data} close={close} />;
          }}
        </Popup>
      </div>
      {data.length === 0 ? (
        <NoDetail loading={loading} />
      ) : (
        <div className="recent_image">
          <LazyImage
            src={data[0].image}
            placeholderSrc={require("../../Assets/Icons/temp_img.png")}
          />
          <div className="recent_items">
            {data.map((value, key) => {
              if (key > 0 && key < 7) {
                return (
                  <LazyImage
                    src={value.image}
                    placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentImages_Card;
