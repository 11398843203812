import React, { useEffect, useContext, useState } from "react";
import { Geolocation_Filter_Data } from "../../Data/Data";
import TadobaContext from "../Context/TadobaContext";
import { url } from "../../Data/Data";
import axios from "axios";
import {
  image_filter_options,
  years_array,
  species_image_filter_options,
} from "../../Data/Data";
import Checkbox from "../Checkbox";
import SmallLoader from "../SmallLoader";
import { makeRequest } from "../../api";

const ImagesFilters = (props) => {
  const api = useContext(TadobaContext);
  const [filterData, setFilterData] = useState(Geolocation_Filter_Data);
  const [filters, setfilters] = useState(api.ImagesFilters);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [years, setYears] = useState([]);
  const [speciesSearch, setSpeciesSearch] = useState("");
  const [yearsData, setYearsData] = useState(api.yearsData);
  const [searchValues, setSearchValues] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [species_filter, setSpeciesFilter] = useState([]);

  const expandFilterHandler = (value) => {
    if (!api.ImagesFilters?.[value.id]) {
      setLoading(true);
      fetchFilters(value.id);
    }
    const newData = [...filterData];
    const updatedData = newData.map((data, key) => {
      if (data.name === value.name) {
        data.isOpen = !value.isOpen;
        return data;
      } else data.isOpen = false;
      return data;
    });
    setFilterData(updatedData);
  };

  useEffect(() => {
    const updatedItems = filterData.map((item) => ({
      ...item,
      isOpen: false,
    }));
    setFilterData(updatedItems);
  }, []);

  const fetchFilters = async (key) => {
    if (key === null) {
      api.setImagesFilters("");
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/geolocation_filter_core/?key=${key}`
      );
      const newData = { ...api.ImagesFilters, [key]: result.data.data };
      newData[key].map((item, val) => {
        item.checked = false;
      });
      api.setImagesFilters(newData);
      setfilters(newData);
      setLoading(false);
    } catch (error) {}
  };

  const resetFilter = () => {
    const newData = filters;
    if (newData === null) {
      return;
    }
    filterData.map((value, key) => {
      if (newData[value.id]) {
        newData[value.id].map((item, val) => {
          item.checked = false;
        });
      }
    });
    api.setImagesFilters(newData);
    setfilters(newData);
  };

  const image_filterChangeHandler = (event, id) => {
    const checked = event.checked;
    if (checked) {
      props.setSpecies((prevSpecies) =>
        prevSpecies.filter((speciesId) => speciesId !== id)
      );
    } else {
      props.setSpecies((prevSpecies) => [...prevSpecies, id]);
    }
  };

  const year_filterChangeHandler = (value) => {
    const updatedYearsData = yearsData.map((year) => {
      if (year.id === value.id) {
        return { ...year, checked: !year.checked };
      } else {
        return year;
      }
    });

    setYearsData(updatedYearsData);
    api.setYearsData(updatedYearsData);
    if (value.checked === false) {
      props.setYears((prevSpecies) => [...prevSpecies, value.value]);
      setYears((prevSpecies) => [...prevSpecies, value.value]);
    } else {
      props.setYears((prevSpecies) =>
        prevSpecies.filter((speciesValue) => speciesValue !== value.value)
      );
      setYears((prevSpecies) =>
        prevSpecies.filter((speciesValue) => speciesValue !== value.value)
      );
    }
  };

  // console.log(filterData);
  const filterChangeHandler = ({ id, value, name, checked }) => {
    const tempData = { ...filters };
    tempData[id] = tempData[id].map((item) =>
      item.id === value ? { ...item, checked: !checked } : item
    );
    api.setImagesFilters(tempData);
    setfilters(tempData);

    const newData = [...api.filterQuery];
    const item = `${name}=${value}`;

    if (!checked) {
      newData.push(item);
    } else {
      const index = newData.indexOf(item);
      newData.splice(index, 1);
    }

    const uniqueData = newData.filter(
      (item, index, self) => self.indexOf(item) === index
    );
    props.setFiltersArray(uniqueData);
    api.setFilterQuery(uniqueData);
  };
  const handleSearchChange = (event, id) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const filteredOptions = (options) => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    return options?.filter((option) =>
      option.name.toLowerCase().includes(lowerCaseSearchValue)
    );
  };

  useEffect(() => {
    if (species_filter.length !== 0) {
      return;
    } else {
      let newData = [];
      api.allSpecies.map((value, key) => {
        if (props.species.includes(value.id)) {
          newData.push({ ...value, checked: true });
        } else newData.push({ ...value, checked: false });
      });
      setSpeciesFilter(newData);
    }
  });

  const handleSpeciesSearchChange = (event) => {
    setSpeciesSearch(event.target.value);
  };

  const filteredSpeciesOptions = (species) => {
    return species?.filter((option) =>
      option.name.toLowerCase().includes(speciesSearch.toLowerCase())
    );
  };

  useEffect(() => {
    if (yearsData.length === 0) {
      const transformedYears = years_array.map((year) => ({
        id: year,
        name: year,
        checked: false,
      }));
      setYearsData(transformedYears);
      api.setYearsData(transformedYears);
    }
  }, [yearsData]);

  return (
    <div className="PopupEditRole">
      <div className="popupEditContentFilter">
        <div className="popupEditHeader">
          <div>
            <span>Filters</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="GeolocationFilterContent">
          <div className="FilterSidebar">
            {props.type === "tiger images"
              ? species_image_filter_options.map((value, key) => (
                  <ul
                    key={key}
                    className={active !== key ? "not_active" : ""}
                    onClick={() => {
                      setActive(key);
                    }}
                  >
                    <li>
                      <span>{value}</span>
                    </li>
                  </ul>
                ))
              : image_filter_options.map((value, key) => (
                  <ul
                    key={key}
                    className={active !== key ? "not_active" : ""}
                    onClick={() => {
                      setActive(key);
                    }}
                  >
                    <li>
                      <span>{value}</span>
                    </li>
                  </ul>
                ))}
          </div>
          {props.type === "all images" ? (
            <div className="FilterList">
              {active === 0 ? (
                <ul>
                  {filterData.map((value) => {
                    return (
                      <li key={value.id}>
                        <div
                          onClick={() => {
                            expandFilterHandler(value);
                          }}
                        >
                          <span>{value.name}</span>
                          <div>
                            {value?.isOpen ? (
                              <img
                                src={require("../../Assets/Icons/filter_dropicon.png")}
                                alt=""
                              />
                            ) : (
                              <img
                                src={require("../../Assets/Icons/filter_up_icon.png")}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                        {value?.isOpen && (
                          <>
                            {filters === undefined ||
                            loading ||
                            !filters[value.id] ? (
                              <SmallLoader />
                            ) : (
                              <div className="filterListData">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchValues[value.id]}
                                    onChange={(e) => {
                                      handleSearchChange(e, value.id);
                                    }}
                                  />
                                  <img
                                    src={require("../../Assets/Icons/search_icon.png")}
                                    style={{
                                      position: "absolute",
                                      left: "1px",
                                    }}
                                    alt=""
                                  />
                                </div>
                                {searchValues[value.id] !== ""
                                  ? filteredOptions(
                                      filters[value.id],
                                      searchValues[value.id]
                                    ).map((data) => {
                                      return (
                                        <Checkbox
                                          key={data.id}
                                          value={data.id}
                                          type={data.name}
                                          checked={data.checked}
                                          name={value.label}
                                          id={value.id}
                                          Modify={filterChangeHandler}
                                        />
                                      );
                                    })
                                  : filters[value.id].map((data) => {
                                      return (
                                        <Checkbox
                                          key={data.id}
                                          value={data.id}
                                          type={data.name}
                                          checked={data.checked}
                                          name={value.label}
                                          id={value.id}
                                          Modify={filterChangeHandler}
                                        />
                                      );
                                    })}
                              </div>
                            )}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : active === 2 ? (
                <ul
                  className="species_list"
                  style={{ maxHeight: "60vh", overflow: "auto" }}
                >
                  {yearsData.map((year, key) => (
                    <li key={key}>
                      <div className="filterListData">
                        <Checkbox
                          value={year.id}
                          type={year.name}
                          checked={year.checked}
                          name={year.name}
                          id={year.id}
                          Modify={year_filterChangeHandler}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul
                    className="species_list"
                    style={{ maxHeight: "60vh", overflow: "auto" }}
                  >
                    <div className="species_search_wrapper">
                      <img
                        src={require("../../Assets/Icons/search_icon.png")}
                        alt=""
                      />
                      <input
                        type="text"
                        placeholder="Search..."
                        value={speciesSearch}
                        onChange={handleSpeciesSearchChange}
                      />
                    </div>
                    {filteredSpeciesOptions(species_filter).map(
                      (value, key) => {
                        const data = value;
                        return (
                          <li key={key}>
                            <div className="filterListData">
                              <Checkbox
                                value={data.id}
                                type={data.name}
                                checked={data.checked}
                                name={data.label}
                                id={data.id}
                                Modify={(event) =>
                                  image_filterChangeHandler(event, data.id)
                                }
                              />
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
            </div>
          ) : (
            <div className="FilterList">
              {active === 0 ? (
                <ul>
                  {filterData.map((value, key) => {
                    return (
                      <li key={key}>
                        <div
                          onClick={() => {
                            expandFilterHandler(value);
                          }}
                        >
                          <span>{value.name}</span>
                          <div>
                            {value?.isOpen ? (
                              <img
                                src={require("../../Assets/Icons/filter_dropicon.png")}
                                alt=""
                              />
                            ) : (
                              <img
                                src={require("../../Assets/Icons/filter_up_icon.png")}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                        {value?.isOpen ? (
                          <>
                            {filters === undefined ||
                            loading ||
                            !filters[value.id] ? (
                              <SmallLoader />
                            ) : (
                              <div className="filterListData">
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchValue}
                                    onChange={(e) => {
                                      handleSearchChange(e);
                                    }}
                                  />
                                  <img
                                    src={require("../../Assets/Icons/search_icon.png")}
                                    style={{
                                      position: "absolute",
                                      left: "1px",
                                    }}
                                    alt=""
                                  />
                                </div>
                                {searchValue !== ""
                                  ? filteredOptions(filters[value.id]).map(
                                      (data, key) => {
                                        return (
                                          <Checkbox
                                            key={key}
                                            value={data.id}
                                            type={data.name}
                                            checked={data.checked}
                                            name={value.label}
                                            id={value.id}
                                            Modify={filterChangeHandler}
                                          />
                                        );
                                      }
                                    )
                                  : filters[value.id].map((data, key) => {
                                      return (
                                        <Checkbox
                                          key={key}
                                          value={data.id}
                                          type={data.name}
                                          checked={data.checked}
                                          name={value.label}
                                          id={value.id}
                                          Modify={filterChangeHandler}
                                        />
                                      );
                                    })}
                              </div>
                            )}{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul
                  className="species_list"
                  style={{ maxHeight: "60vh", overflow: "auto" }}
                >
                  {yearsData.map((year, key) => (
                    <li key={key}>
                      <div className="filterListData">
                        <Checkbox
                          value={year.id}
                          type={year.name}
                          checked={year.checked}
                          name={year.name}
                          id={year.id}
                          Modify={year_filterChangeHandler}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className="popupDeleteButtonFilter">
          <button
            className="CancelButton "
            onClick={() => {
              resetFilter();
              props.setCurrentPage(0);
              props.setData([]);
              props.setListData([]);
              props.setFilter(false);
              props.setSpecies([]);
              props.setLoading(false);
              props.setPage(1);
              props.setRender(props.render + 1);
              props.close();
              props.clearFilter();
              props.setYears([]);
              setYearsData([]);
              api.setYearsData([]);
              api.setFilterQuery([]);
              props.setFiltersArray([]);
            }}
          >
            Clear All
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              props.setCurrentPage(0);
              props.setData([]);
              props.setListData([]);
              props.setFilter(true);
              props.setLoading(true);
              props.setPage(1);
              props.setRender(props.render + 1);
              props.close();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImagesFilters;
