import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { url } from '../../Data/Data'
import { makeRequest } from '../../api'
const Forgot_Password = ({ email, setEmail }) => {
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const navigate = useNavigate()

    const inputChangeHandler = (event) => {
        setError("")
        setMessage("")
        setEmail(event.target.value)
    }

    const forgotRequest = async (data) => {
        try {
            const result = await makeRequest("post",`api/v1/account/forgot_password/`,data)
            alert(result.data.data)
            navigate('/login')
        } catch (error) {
            setError(true)
            setMessage(error.response.data.data)
        }
        setDisabled(false)
    }


    const buttonHandler = () => {
        setDisabled(true)
        const data = {
            email: email
        }
        forgotRequest(data)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (disabled) {
                return
            }
            if (event.target.value === "") {
                setError(true)
                setMessage("This field is required")
                return
            }

            setDisabled(true)
            const data = {
                email: email
            }
            forgotRequest(data)
        }
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <div className='Login_Title'>
                    <span>Forgot Password  ?</span>
                </div>
                <div className='Description'>
                    <p>We will send a link to the following email to reset your password</p>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                <div className='Login_Input'>
                    <label className='email_icon' htmlFor="login">Email</label>
                    <input type="login" name="login" placeholder="Enter your email" onKeyDown={handleKeyDown} className={error ? "input_warning" : ""} value={email} onChange={inputChangeHandler} id="login" />
                    {error ? <label id="warning" >{message}</label> : ""}
                </div>
                <div className="Login_Button">
                    <button disabled={disabled} className={disabled ? "login_button_disabled" : ""} onClick={buttonHandler}>Send Link</button>
                </div>
            </div>
        </>
    )
}

export default Forgot_Password