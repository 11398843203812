import React, { useContext, useState } from 'react'
import TadobaContext from '../Context/TadobaContext'

const SpeciesDelete = (props) => {

    const api = useContext(TadobaContext)
    const species=api.allSpecies.filter(function(item)  {
        return item.name==props.row["Species name"]
    })

    const [id, setId] = useState(species[0].id)
    return (
        <div className='popupDeleteRole'>
            <div className='popupDeleteContent'>
                <div className="popupDeleteHeader">
                    <div>
                        <span>Delete species</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                <div className="popupMainContent">
                    <div style={{ width: "344px" }}>
                        <span>Are you sure you want to delete {props.row["Species name"]} ? Images classified under this species will be moved to unclassified images folder.</span>
                    </div>
                </div>
                <div className="popupDeleteButton">
                    <button className='CancelButton' onClick={props.close}>Cancel</button>
                    <button className='DeleteButton' onClick={()=>{
                       
                        props.deleteHandler([id],props.close)
                    }}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default SpeciesDelete