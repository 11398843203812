import React from 'react'
import LazyImage from '../LazyImage'
const RecentImages = (props) => {
    return (
        <div className="Upload_Area">
            <div className='Setting_User'>
                <div className="Setting_User_Header">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                        <img style={{ cursor: "pointer" }} src={require('../../Assets/Icons/left.png')} onClick={() => {
                            props.close()
                        }} alt="" />
                        <span>Recent Images</span>
                    </div>
                </div>
            </div>
            <div className='Images_View' style={{ height: "62vh" }}>
                {props.data.map((value, key) => {
                    return (
                        <div className='Individual_Image'>
                            <LazyImage src={value.image}  placeholderSrc={require("../../Assets/Icons/temp_img.png")} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RecentImages




