// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox{
    display: flex;
}


.circular-progress {
    display: block;
  }
  
  .circular-progress__background {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 25px;
  }
  
  .circular-progress__progress {
    fill: none;
    stroke: #FF8214;
    background: repeating-linear-gradient(
        45deg,
        #606dbc,
        #606dbc 10px,
        #465298 10px,
        #465298 20px
      );
    stroke-width: 25px;
    /* stroke-linecap: round; */
    transition: stroke-dashoffset 0.3s;
  }
  .ImgFullScreen{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 9999 !important;
    background: #000 !important;
    border-radius: 0 !important;
  }
  .circular-progress__text {
    fill: #333;
    font-size: 16px;
    text-anchor: middle;
    dominant-baseline: middle;
  }


  `, "",{"version":3,"sources":["webpack://./src/CSS/External.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI,cAAc;EAChB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,eAAe;IACf;;;;;;OAMG;IACH,kBAAkB;IAClB,2BAA2B;IAC3B,kCAAkC;EACpC;EACA;IACE,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,2BAA2B;IAC3B,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,eAAe;IACf,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":[".Checkbox{\n    display: flex;\n}\n\n\n.circular-progress {\n    display: block;\n  }\n  \n  .circular-progress__background {\n    fill: none;\n    stroke: #e6e6e6;\n    stroke-width: 25px;\n  }\n  \n  .circular-progress__progress {\n    fill: none;\n    stroke: #FF8214;\n    background: repeating-linear-gradient(\n        45deg,\n        #606dbc,\n        #606dbc 10px,\n        #465298 10px,\n        #465298 20px\n      );\n    stroke-width: 25px;\n    /* stroke-linecap: round; */\n    transition: stroke-dashoffset 0.3s;\n  }\n  .ImgFullScreen{\n    width: 100% !important;\n    height: 100% !important;\n    object-fit: contain !important;\n    position: fixed !important;\n    top: 0 !important;\n    left: 0 !important;\n    z-index: 9999 !important;\n    background: #000 !important;\n    border-radius: 0 !important;\n  }\n  .circular-progress__text {\n    fill: #333;\n    font-size: 16px;\n    text-anchor: middle;\n    dominant-baseline: middle;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
