import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Upload_Routes from "../Components/Upload/Upload_Routes";
import { useNavigate } from "react-router-dom";
import TadobaContext from "../Components/Context/TadobaContext";

const Upload_image = () => {
  const navigate = useNavigate();
  const api = useContext(TadobaContext);
  const [drag, setDrag] = useState(false);
  useEffect(() => {
    var formData = new FormData();
    for (const key of Object.keys(api.file)) {
      formData.append("files", api.file[key]);
    }
  }, [api.file]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "add_image/*",
    directory: true,
    onDragOver: () => {
      setDrag(true);
    },
    onDragLeave: () => {
      setDrag(false);
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const validFiles = acceptedFiles.filter(
        (file) =>
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg" ||
          file.type === "image/jfif"
      );
      api.setFile(
        validFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      api.setImages([]);
      api.setFile(validFiles);
      setDrag(false);
      navigate("/add_image");
    },
  });
  const fileUploadHandler = (event) => {};

  if (api.file.length > 0) {
    return <Upload_Routes file={api.file} setFile={api.setFile} />;
  }
  return (
    <div className="Login">
      <div className="upload" {...getRootProps()}>
        <input {...getInputProps({...getInputProps()})} />
        {!drag ? (
          <div className="upload_container">
            <div className="upload_heading">
              <span>Upload Your Images</span>
            </div>
            <div className="upload_symbol">
              <img
                src={require("../Assets/Upload_Images/Upload_Symbol.png")}
                alt=""
              />
            </div>
            <div className="upload_description">
              <span>Drag and drop your images here or choose folder</span>
            </div>
            <div className="upload_button">
              <div className="Login_Button upload_button">
                <button onClick={fileUploadHandler}>Browse Files</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="drop_container">
            <span>Drop your images here</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload_image;
