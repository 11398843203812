import React, { useContext } from 'react'
import { useEffect, useState } from 'react';
import { makeRequest } from '../api';
import TadobaContext from "../Components/Context/TadobaContext";
import { Select } from 'antd';

const YearFilter = ({ loading, setLoading, setSelectedYear, selectedYear }) => {

  const [yearData, setYearData] = useState([]);

  const api = useContext(TadobaContext);

  useEffect(() => {
    getYearFilters();
  }, [])

  const getYearFilters = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/statistic/years_list/`);
      const formattedYearData = result.data.map(year => ({
        value: year,
        label: year
      }));
      const yearOptions = [{ value: "all", label: "All Year" }, ...formattedYearData];
      setYearData(yearOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleYearChange = (value) => {
    setSelectedYear(value)
    api.setSelectedYearData(value);
    localStorage.setItem("selectedYearData", value)
  };

  return (
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "0.5vw" }}>
      <label style={{fontSize: "2vh"}} htmlFor="">Select Year</label>
      <Select
        placeholder="Select"
        style={{ width: 150, height: 22 }}
        defaultValue={"All Year"}
        options={yearData}
        onChange={handleYearChange}
        value={selectedYear}
        loading={loading}
      />
    </div>
  )
}

export default YearFilter