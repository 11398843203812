import axios from "axios";
import { baseUrl } from "./Data/EndPonit";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.token;
    const csrf = localStorage.csrf;
    if (token) {
      config.headers["TADOBAAUTH"] = `${token}`;
    }

    if (csrf) {
      config.headers["X-CSRFToken"] = `${csrf}`;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to make dynamic requests
const makeRequest = async (method, url, data, config) => {
  try {
    const response = await api({
      method,
      url,
      data,
      ...config,
    });
    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

const makeRequest2 = async (method, url, data, config = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      responseType: "blob",
      ...config,
    });
    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export { api, makeRequest, makeRequest2 };
