import React, { useEffect, useRef, useState } from "react";
import Setting_User_Table from "../Setting_User_Table";
import Popup from "reactjs-popup";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import axios from "axios";
import { role, successToaster, url, warningToaster } from "../../Data/Data";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Add_User from "./Add_User";
import { makeRequest } from "../../api";
const Users = () => {
  const api = useContext(TadobaContext);
  const inputFile = useRef(null);
  const [update, setUpdate] = useState(1);
  const [uploadfile, setUploadfile] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  function search(searchTerm) {
    setUpdate(update + 1);
    if (searchTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    const filtered = api.allUsers.filter((product) => {
      return (
        product.Email.toLowerCase().includes(searchItem.toLowerCase()) ||
        product.Name.toLowerCase().includes(searchItem.toLowerCase())
      );
    });

    setFilteredProducts(filtered);
  }

  function filter(searchTerm, nextTerm) {
    if (searchTerm === "" && nextTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    if (dropdownValue === "") {
      search(nextTerm);
    } else {
      const filtered = api.allUsers.filter((product) => {
        return (
          product.Role.toLowerCase() === dropdownValue.toLowerCase() &&
          (product.Email.toLowerCase().includes(searchItem.toLowerCase()) ||
            product.Name.toLowerCase().includes(searchItem.toLowerCase()))
        );
      });

      setFilteredProducts(filtered);
    }
  }

  useEffect(() => {
    filter(dropdownValue, searchItem);
  }, [dropdownValue, searchItem]);

  const serachHandler = (event) => {
    setSearchItem(event.target.value);
  };

  const dropdownFilterHandler = (event) => {
    setDropdownValue(event.target.value);
  };

  const DeleteUsers = async (data, close) => {
    console.log(data);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "delete",
        `api/v1/account/users_delete/`,
        data
      );
      setDropdownValue("");
      setSearchItem("");
      setUpdate(update + 1);
      if (close !== undefined) {
        close();
      }
      toast.success("User Deleted Successfully", successToaster);
    } catch (error) {
      console.log(error);
    }
  };

  const EditUsers = async (data, close) => {
    setMessage("");
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        `api/v1/account/profile_edit/`,
        data
      );
      toast.success("User Updated Successfully", successToaster);
      setDropdownValue("");
      setSearchItem("");
      search(searchItem);
      setSearchValue(false);
      setUpdate(update + 1);
      close();
    } catch (error) {
      toast.success(error.response.data.data.toString(), warningToaster);
    }
  };

  useEffect(() => {
    if (message !== "") toast.success(message, successToaster);
  }, [message]);

  const UserDeleteHandler = (row, close) => {
    if (row.length === 0) {
      const body = {
        user_ids: [],
        all: true,
      };
      DeleteUsers(body, close);
      return;
    }

    let selected_users = [];
    row.map((value, index) => {
      selected_users = [
        ...selected_users,
        api.users.filter(function (item) {
          return item.email === value;
        })[0],
      ];
    });

    const selected_users_id = [];
    selected_users.map((value, key) => {
      selected_users_id.push(value.id);
    });
    const delete_Json_data = {
      user_ids: selected_users_id,
      all: false,
    };
    DeleteUsers(delete_Json_data, close);
  };

  const editHandler = (updatedData, close) => {
    EditUsers(updatedData, close);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (update > 2) {
      fetchUsers();
    }
  }, [update]);

  console.log(update);

  const AllUsersSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        Name: value.name,
        Email: value.email,
        Role: value.role.name,
      };
      result.push(obj);
    });
    api.setAllUsers(result);
  };

  const fetchUsers = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/account/users/`);
      AllUsersSetup(result.data.data);
      api.setUsers(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const uploadFile = async (data) => {
    let token = "";
    let csrftoken = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result = await makeRequest(
        "post",
        `api/v1/account/user_upload/`,
        data
      );
      setUpdate(update + 1);
      toast.success("File Uploaded Successfully", successToaster);
    } catch (error) {
      toast.success(error.response.data.data.toString(), warningToaster);
    }
  };

  const handleChange = (event) => {
    setUploadfile(event.target.files[0]);
    setUploadfile("");
    const data = new FormData();
    data.append("file", event.target.files[0]);
    uploadFile(data);
  };

  const accessHandler = async (data, close) => {
    setMessage("");
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/setting/user_access_set/`,
        data
      );
      toast.success("User Access Updated Successfully", successToaster);
      setDropdownValue("");
      setSearchItem("");
      search(searchItem);
      setSearchValue(false);
      setUpdate(update + 1);
      close();
    } catch (error) {
      close();
      toast.success(error.response.data.data.toString(), warningToaster);
    }
  };

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Users</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input
              type="text"
              value={searchItem}
              onChange={serachHandler}
              placeholder="Search by name, email id"
            />
            <img src={require("../../Assets/Icons/search_icon.png")} alt="" />
          </div>
          <select
            className="role_filter"
            value={dropdownValue}
            onChange={dropdownFilterHandler}
          >
            <option className="role_filter_span" value="">
              All
            </option>
            {role.map((value, key) => {
              return (
                <option className="role_filter_span" key={key} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          {api.user.role.id === 2 ? (
            <div className="Setting_Upload_Button">
              <button
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                Upload
              </button>
              <input
                type="file"
                onChange={handleChange}
                value={uploadfile}
                ref={inputFile}
              />
            </div>
          ) : (
            ""
          )}
          {api.user.role.id !== 3 ? (
            <Popup
              trigger={
                <div className="Setting_Add_Button">
                  <img src={require("../../Assets/Icons/plus.png")} alt="" />
                  <span>Add</span>
                </div>
              }
              modal
              nested
            >
              {(close) => (
                <Add_User
                  close={close}
                  warning={warning}
                  loading={loading}
                  setLoading={setLoading}
                  setMessage={setMessage}
                  setWarning={setWarning}
                  update={update}
                  setUpdate={setUpdate}
                />
              )}
            </Popup>
          ) : (
            ""
          )}
        </div>
      </div>
      <Setting_User_Table
        accessHandler={accessHandler}
        loading={loading}
        deleteHandler={UserDeleteHandler}
        editHandler={editHandler}
        data={searchValue ? filteredProducts : api.allUsers}
        delete={true}
        type="Users"
      />
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default Users;
