import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { url } from "../Data/Data";
import { useState, useEffect } from "react";
import TadobaContext from "./Context/TadobaContext";
import { useContext } from "react";
import { makeRequest } from "../api";

const AlertChildbar = ({ Data }) => {
  const api = useContext(TadobaContext);
  const [list, setList] = useState([
    "Tiger",
    "Leopard",
    "Sloth bear",
    "Spotted Deer",
    "Barking Deer",
    "Four horned antelope",
    "Sambar",
    "Gaur",
    "Mongooses",
    "Bird",
    "Buffalo",
    "Common Palm Civet",
    "Cow",
    "Dog",
    "Goat",
    "Golden Jackal",
    "Hare",
    "Nilgai",
    "Hyena",
    "Indian Fox",
    "Indian pangolin",
    "Indian Porcupine",
    "Jungle cat",
    "Jungle Fowls",
    "Langur",
    "Macaque",
    "Palm squirrel",
    "Indian peafowl",
    "Honey badger",
    "Rodents",
    "Rusty spotted cat",
    "Sheep",
    "Small indian civet",
    "Wild boar",
    "Wild dog",
    "Indian wolf",
    "Person",
    "Crocodile",
    "Indian Elephant",
    "Vehicle",
  ]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/species/species/`);
      setData(result.data.data);
      api.setAllSpecied(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!localStorage.species) {
      getImages();
    } else {
      const storedData = JSON.parse(localStorage.species);
      setData(storedData);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="ChildSideBar">
        <ul className="ChildSideBarList">
          {Data.map((value, key) => {
            if (value.link === "./") {
              return (
                <li
                  key={key}
                  className="ChildListSideBarItem"
                  id={
                    window.location.pathname === "/settings/" ||
                    window.location.pathname === "/images/"
                      ? "item_active"
                      : ""
                  }
                >
                  <div
                    onClick={() => {
                      navigate(value.link);
                    }}
                    className="ChildSideBarItem"
                  >
                    <span>{value.title}</span>
                  </div>
                </li>
              );
            }
            return (
              <li
                key={key}
                className="ChildListSideBarItem"
                id={
                  window.location.pathname.includes(value.link.substr(1))
                    ? "item_active"
                    : ""
                }
              >
                <div
                  onClick={() => {
                    navigate(value.link);
                  }}
                  className="ChildSideBarItem"
                >
                  <span>{value.title}</span>
                </div>
              </li>
            );
          })}

          <li style={{ marginTop: "40px" }}>
            <div className="classified_sidebar_heading">
              <span>Classified Species</span>
            </div>
            <div className="sub_classified_list">
              {data
                .filter((value) => list.includes(value.name))
                .sort((a, b) => list.indexOf(a.name) - list.indexOf(b.name))
                .map((value, key) => {
                  if (value.no_of_images > 0 && list.includes(value.name)) {
                    return (
                      <li
                        key={key}
                        className="ChildListSideBarItem"
                        id={
                          window.location.pathname === `/images/key/${value.id}`
                            ? "item_active"
                            : ""
                        }
                      >
                        {value.name === "Tiger" ? (
                          <div
                            id={
                              window.location.pathname ===
                              `/images/key/Tigerimages/${value.id}`
                                ? "item_active"
                                : ""
                            }
                            onClick={() => {
                              navigate(`./key/Tigerimages/${value.id}`);
                            }}
                            className="ChildSideBarItem"
                          >
                            <span>{value.name}</span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              navigate(`./key/${value.id}`);
                            }}
                            className="ChildSideBarItem"
                          >
                            <span>{value.name}</span>
                          </div>
                        )}
                      </li>
                    );
                  }
                })}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AlertChildbar;
