import React, { useState, useContext, useEffect } from "react";
import TadobaContext from "../Context/TadobaContext";
import { PieChart, Pie, Cell, LabelList, Legend } from "recharts";

const Unidentified = () => {
  const api = useContext(TadobaContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Calculate percentages when data or total counts change
    if (api.totalTigersCount > 0) {
      const identifiedPercentageValue =
        (api.totalIdentifiedCount / api.totalTigersCount) * 100;
      const unidentifiedPercentageValue = 100 - identifiedPercentageValue;
      const pie_data = [
        {
          name: "Identified Tigers",
          count: parseFloat(identifiedPercentageValue.toFixed(2)),
        },
        {
          name: "Unidentified Tigers",
          count: parseFloat(unidentifiedPercentageValue.toFixed(2)),
        },
      ];
      setData(pie_data);
    }
  }, [api.totalTigersCount, api.totalIdentifiedCount]);

  const colors = ["#48887B", "#A3C3BD"];

  const renderLabel = (props) => {
    return `${props}%`;
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
  };

  return (
    <div className="Card">
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/Unidentified.png")} alt="" />
          <span>Identified vs Unidentified tigers </span>
        </div>
        {/* <div className="Card_Link">
          <span>View all</span>
        </div> */}
      </div>
      <PieChart width={500} height={300}>
        <Pie
          data={data}
          dataKey="count"
          outerRadius={120}
          innerRadius={50}
          fill="#8884d8"
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          <LabelList
            dataKey="count"
            position="inside"
            fill="#ffffff"
            formatter={renderLabel}
          />
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          formatter={renderColorfulLegendText}
        />
      </PieChart>
    </div>
  );
};

export default Unidentified;
