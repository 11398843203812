import React, { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import TadobaContext from "./Context/TadobaContext";
import { makeRequest2, makeRequest } from "../api";
import { toast } from "react-toastify";
import { warningToaster } from "../Data/Data";

const DownloadBox = () => {
  const api = useContext(TadobaContext);
  const [startDownload, setStartDownload] = useState(true);
  const [startDelete, setStartDelete] = useState(true);
  const [abortController, setAbortController] = useState(null);

  const cancelDownload = () => {
    if (abortController) {
      console.log("abort");
      abortController.abort(); // Abort the ongoing request
      setAbortController(null);
    }
    api.setDownloadingText("");
    api.setDownloadQuery([]);
    api.setDeleteQuery([]);
    api.setStartDelete(false);
    api.setStartDownload(false);
    setStartDownload(false);
    localStorage.setItem("disable", true);
    api.setDisable(true);
  };

  const downloadAllImages = async () => {
    try {
      const controller = new AbortController();
      setAbortController(controller);
      let result = "";
      if (api.downloadQuery.download_xl === "1") {
        result = await makeRequest(
          "post",
          `api/v1/classification/images_download/`,
          api.downloadQuery
        );
      } else {
        result = await makeRequest2(
          "post",
          `api/v1/classification/images_download/`,
          api.downloadQuery,
          { signal: controller.signal }
        );
      }

      if (api.downloadQuery.download_xl === "1") {
        downloadCSV(result.data.data.url);
        api.setDownloadingText("");
        api.setStartDownload(false);
        setStartDownload(false);
        localStorage.setItem("disable", false);
        api.setDisable(false);
      } else {
        const blob = result.data;
        api.setStartDownload(false);
        localStorage.setItem("disable", false);
        api.setDisable(false);
        api.setDownloadQuery([]);

        if (blob.size > 0) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "images.zip";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Received empty blob.");
          api.setDownloadingText("");
          api.setStartDownload(false);
          setStartDownload(false);
          localStorage.setItem("disable", false);
          api.setDisable(false);
        }
      }
    } catch (error) {
      console.error("Failed to create download link:", error);
      api.setDownloadingText("");
      api.setStartDownload(false);
      setStartDownload(false);
      localStorage.setItem("disable", false);
      api.setDisable(false);
    }
  };

  const downloadCSV = async (signedUrl) => {
    const response = await fetch(signedUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "file.csv"; // You can set the filename as needed
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (api.startDownload === true) {
      downloadAllImages();
    }
  }, [api.startDownload]);

  const deleteImages = async () => {
    try {
      const controller = new AbortController();
      setAbortController(controller);
      const result = await makeRequest(
        "delete",
        `api/v1/classification/image_delete/`,
        api.deleteQuery,
        { signal: controller.signal }
      );
      api.setDeleteQuery([]);
      api.setStartDelete(false);
      api.setDisable(false);

      toast.success(`Successfully deleted Images`, warningToaster);
    } catch (error) {
      console.error("Failed to create download link:", error);
      api.setDownloadingText("");
      api.setStartDownload(false);
      setStartDownload(false);
      localStorage.setItem("disable", false);
      api.setDisable(false);
    }
  };

  useEffect(() => {
    if (api.startDelete === true) {
      deleteImages();
    }
  }, [api.startDelete]);

  return (
    <div>
      {startDownload && (
        <div
          className="upload"
          style={{
            position: "absolute",
            bottom: "30px",
            right: "50px",
            width: "18%",
            height: "18%",
            border: "2px solid orange",
            zIndex: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={cancelDownload}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              background: "none",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            <img src={require("../Assets/Icons/Close.png")} alt="Close" />
          </button>
          <ClipLoader
            color={"orange"}
            loading={true}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <div style={{ marginTop: "10px" }}>
            <p>{localStorage.text}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadBox;
