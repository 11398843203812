import React from "react";
import TigerGridImage from "./TigerGridImage";
const IndividualTigerContainer = ({
  organizedData,
  lastElementRef,
  handleGroupClick,
  type,
}) => {
  return (
    <div className="Images_Table" style={{ position: "relative" }}>
      <div className="Images_View Tiger_Views">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "2vw",
            alignItems: "center",
            justifyContent: "center",
            ...(type === "bulkupload" && {
              marginLeft: "-200px",
              marginTop: "-200px",
            }),
          }}
        >
          {Object.entries(organizedData).map(
            ([groupName, tigerData], index) => {
              if (Object.entries(organizedData).length === index + 1) {
                return (
                  <div
                    ref={lastElementRef}
                    key={groupName}
                    style={{ textAlign: "center" }}
                  >
                    <TigerGridImage tigerData={tigerData} />
                    <span
                      className="tiger-category-heading"
                      onClick={() => handleGroupClick(groupName)}
                      style={{ display: "block", cursor: "pointer" }}
                    >
                      {groupName === "Unclassified"
                        ? "Unidentified"
                        : groupName}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div key={groupName} style={{ textAlign: "center" }}>
                    <TigerGridImage tigerData={tigerData} />
                    <span
                      className="tiger-category-heading"
                      onClick={() => handleGroupClick(groupName)}
                      style={{ display: "block", cursor: "pointer" }}
                    >
                      {groupName === "Unclassified"
                        ? "Unidentified"
                        : groupName}
                    </span>
                  </div>
                );
              }
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualTigerContainer;
