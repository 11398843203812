import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { url } from "../../Data/Data";
import axios from "axios";
import moment from "moment/moment";
import { useEffect } from "react";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { makeRequest } from "../../api";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const AverageUploadLineGraph = () => {
  const [Data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("month");
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);

  const aggregateData = () => {
    const aggregatedData = {};

    const sortedData = Data.sort((a, b) => {
      const dateA = moment(a.interval);
      const dateB = moment(b.interval);
      return dateA - dateB;
    });

    sortedData.forEach((item) => {
      const interval = moment(item.interval).format(
        getIntervalFormat(selectedOption)
      );
      if (aggregatedData[interval]) {
        aggregatedData[interval] += item.count;
      } else {
        aggregatedData[interval] = item.count;
      }
    });

    const formattedLabels = Object.keys(aggregatedData).sort((a, b) => {
      return (
        moment(a, getIntervalFormat(selectedOption)) -
        moment(b, getIntervalFormat(selectedOption))
      );
    });
    const counts = formattedLabels.map((label) => aggregatedData[label]);

    return [formattedLabels, counts];
  };

  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/statistic_upload_graph/?interval=month`
      );
      setData(result.data.data);
      setLoading(false);
      aggregateData();
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getImages();
  }, []);

  const getIntervalFormat = (option) => {
    if (option === "month") {
      return "MMMM";
    } else if (option === "date") {
      return "MMMM DD";
    } else if (option === "year") {
      return "YYYY";
    }
    return "MMMM YYYY";
  };

  const data = {
    labels: aggregateData()[0],
    datasets: [
      {
        data: aggregateData()[1],
        backgroundColor: "transparent",
        borderColor: "#48887B",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 800000,
        ticks: {
          stepSize: 50000,
          callback: (value) => value,
        },
        grid: {
          borderDash: [10],
        },
      },
    },
  };
  return (
    <div className="Card" style={{ width: "80%" }}>
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/Average_Upload.png")} alt="" />
          <span>Average Upload</span>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "24px" }}>
        <div className="ChartXaxis">
          <span>No of Images</span>
        </div>
        <Line data={data} options={options}></Line>
      </div>
    </div>
  );
};

export default AverageUploadLineGraph;
