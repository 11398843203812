import React, { useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { useContext } from "react";
const LostTigersEdit = (props) => {
  const api = useContext(TadobaContext);
  const body = props.row;
  // console.log(body);

  return (
    <div className="PopupEditRole">
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Edit Tiger Status</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div>
          <div className="GeolocationEditContent">
            <label> Do you want to declare {body.Name} as dead</label>
          </div>
        </div>

        <div className="popupDeleteButton">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              props.editHandler(body, props.close);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default LostTigersEdit;
