import React from "react";
import Alert_Setup from "../Components/Settings/Alert_Setup";
import Geolocation_Setup from "../Components/Settings/Geolocation_Setup";
import Map_Setup from "../Components/Settings/Map_Setup";
import Species_Setup from "../Components/Settings/Species_Setup";
import Tigers_Setup from "../Components/Settings/Tigers_Setup";
import Users from "../Components/Settings/Users";
import AllImages from "../Components/Images/AllImages";
import UnClassified from "../Components/Images/UnClassified";
import ComingSoon from "../Components/CommingSoon";
import Dashboard from "../Containers/Dashboard";
import FindTiger from "../Containers/FindTiger";
import LostTigers from "../Containers/LostTigers";
import TigerConflicts from "../Containers/TigerConflicts";
import TigerRisk from "../Containers/TigerRisk";

import CryptoJS from "crypto-js";

const ForestData = [
  {
    name: "Tadoba-Andhari Tiger Reserve",
    image: require("../Assets/Forest_Images/Tadoba.png"),
  },
  {
    name: "Nawegaon Nagzira Tiger Reserve",
    image: require("../Assets/Forest_Images/Nawegaon.png"),
  },
  {
    name: "Pench National Park",
    image: require("../Assets/Forest_Images/Pench.png"),
  },
  {
    name: "Sanjay Gandhi National Park",
    image: require("../Assets/Forest_Images/Sanjay.png"),
  },
  {
    name: "Chandoli National Park",
    image: require("../Assets/Forest_Images/Chandoli.png"),
  },
];

const SidebarData = [
  {
    title: "Dashboard",
    icon: require("../Assets/Icons/Dashboard.png"),
    colored_icon: require("../Assets/Icons/Colored_Dashboard.png"),
    link: "./",
  },
  {
    title: "Alerts",
    icon: require("../Assets/Icons/alert-triangle (1).png"),
    colored_icon: require("../Assets/Icons/alert-triangle.png"),
    link: "/alerts/",
  },
  // {
  //   title: "Add_image",
  //   icon: require("../Assets/Icons/upload.png"),
  //   colored_icon: require("../Assets/Icons/colored_upload.png"),
  //   link: "/add_image",
  // },
  {
    title: "Upload",
    icon: require("../Assets/Icons/upload.png"),
    colored_icon: require("../Assets/Icons/colored_upload.png"),
    link: "/upload",
  },
  {
    title: "Images",
    icon: require("../Assets/Icons/Images.png"),
    colored_icon: require("../Assets/Icons/colored_images.png"),
    link: "/images/",
  },
  {
    title: "Map View",
    icon: require("../Assets/Icons/map_view.png"),
    colored_icon: require("../Assets/Icons/Colored_mapicon.png"),
    link: "/map_view",
  },
  {
    title: "Statistics",
    icon: require("../Assets/Icons/pie-chart.png"),
    colored_icon: require("../Assets/Icons/colored_Stat.png"),
    link: "/statistics",
  },
  {
    title: "Settings",
    icon: require("../Assets/Icons/settings.png"),
    colored_icon: require("../Assets/Icons/colored_settings.png"),
    link: "/settings/",
  },
  {
    title: "Find Tiger",
    icon: require("../Assets/Icons/Images.png"),
    colored_icon: require("../Assets/Icons/colored_images.png"),
    link: "/find_tiger",
  },
];

const AccesDetails = [
  "dashboard",
  "alerts",
  "images",
  "maps",
  "settings",
  "statistics",
  "upload",
  "Find Tiger",
];

const SettingsData = [
  {
    title: "Users",
    link: "./",
    element: <Users />,
  },
  {
    title: "Species Setup",
    link: "./species_setup",
    element: <Species_Setup />,
  },
  {
    title: "Geolocation Setup",
    link: "./geolocation_setup",
    element: <Geolocation_Setup />,
  },
  {
    title: "Tigers Setup",
    link: "./tigers_setup",
    element: <Tigers_Setup />,
  },
  // {
  //   title: "Alert Setup",
  //   link: "./alert_setup",
  //   element: <Alert_Setup />,
  // },
  {
    title: "Map Setup",
    link: "./map_setup",
    element: <Map_Setup />,
  },
];

const FindTigerData = [
  {
    title: "Find Tiger",
    link: "./",
    element: <FindTiger />,
  },
  {
    title: "Lost Tigers",
    link: "./lost_tigers",
    element: <LostTigers />,
  },
  {
    title: "Tiger Risk",
    link: "./tiger_risk",
    element: <TigerRisk />,
  },
  {
    title: "Tiger Conflicts",
    link: "./tiger_conflicts",
    element: <TigerConflicts />,
  },
];

const MapKey = "AIzaSyDTg790iZlfKpDScxDQ9FMxSG0oPH8Oe8M";

const ImagesData = [
  {
    title: "All Images",
    link: "./",
    element: (value) => <AllImages setEdit={value} />,
  },
  {
    title: "Unclassified Species",
    link: "./unclassified",
    element: (value) => <UnClassified setEdit={value} />,
  },
  {
    title: "Reference Images",
    link: "./reference",
    element: (value) => <ComingSoon setEdit={value} />,
  },
  {
    title: "Deleted Images",
    link: "./deleted",
    element: (value) => <ComingSoon setEdit={value} />,
  },
];

const user_data = [
  {
    Name: "",
    Email: "",
    Role: "",
  },
];

const role = ["Backend admin", "Admin", "User"];

const alert_data = [
  {
    Name: "",
    Email: "",
    "Mobile no.": "",
    "Send Alerts": "",
  },
];

const species_data = [
  {
    "Species name": "",
    "No. Of Images": "",
  },
];

const Geolocation_Data = [
  {
    "Range no.": "",
    "Round no.": "",
    "Beat no.": "",
    "Compartment no.": "",
    "Station ID": "",
    "Camera ID": "",
  },
];

const Geolocation_Filter_Data = [
  {
    name: "Range no.",
    isOpen: false,
    id: "range",
    label: "range_name",
  },
  {
    name: "Round no.",
    isOpen: false,
    id: "round",
    label: "round_name",
  },
  {
    name: "Beat no.",
    isOpen: false,
    id: "beat",
    label: "beat_name",
  },
  {
    name: "Compartments",
    isOpen: false,
    id: "compartment",
    label: "compartment_name",
  },
  {
    name: "Station ID",
    isOpen: false,
    id: "station",
    label: "station_name",
  },
  {
    name: "Camera ID",
    isOpen: false,
    id: "camera",
    label: "camera_name",
  },
];

const setting_map = [
  {
    id: 0,
    map_name: "",
    forest_id: "",
    map_image: "",
  },
];

const Images_Template = {
  Name: "",
  Species: "",
  Range: "",
  "Station ID": "",
  "Camera ID": "",
  "Date and Time": "",
};

const Map_Images_Template = {
  Name: "",
  Species: "",
  "Range no.": "",
  "Beat no.": "",
  "Compartment no.": "",
  "Station ID": "",
  "Camera ID": "",
  "Date and Time": "",
  longitude: "",
  latitude: "",
};

const Map_Images_Tiger_Template = {
  Name: "",
  "Tiger Id": "",
  Species: "",
  Range: "",
  Beat: "",
  "Station ID": "",
  "Camera ID": "",
  longitude: "",
  latitude: "",
  "Date and Time": "",
};

const Map_Images_Tiger_Template_2 = {
  Name: "",
  "Tiger Id": "",
  Species: "",
  Range: "",
  Beat: "",
  "Station ID": "",
  "Camera ID": "",
  latitude: "",
  longitude: "",
  "Distance from Current Loc.": "",
  "Date and Time": "",
};

const Map_Images_Tiger_Template_3 = {
  Name: "",
  "Image Id": "",
  Range: "",
  Beat: "",
  // "Compartment no.":"",
  "Station ID": "",
  "Camera ID": "",
  latitude: "",
  longitude: "",
  "Date and Time": "",
};

const Alerts_Template = {
  "Date and Time": "",
  "Village Name": "",
  Landmark: "",
  // "Users name": "",
  "Camera ID": "",
  "Species Name": "",
};

const conflicts_list = [
  { id: 1, name: "Human Death" },
  { id: 2, name: "Livestock Death" },
  { id: 3, name: "Human Injury" },
  { id: 4, name: "Crop Damage" },
  { id: 5, name: "Conflict with other tiger" },
  { id: 6, name: "Village Movement" },
  { id: 7, name: "Others" },
];

const warningToaster = {
  position: "top-center",
  autoClose: 10000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  icon: ({ theme, type }) => (
    <img src={require("../Assets/Icons/Warning.png")} />
  ),
  draggable: true,
  closeButton: false,
  pauseOnHover: true,
  theme: "colored",
  bodyClassName: "alertbody",
  className: "alertdangerwrapper",
};

const successToaster = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  icon: ({ theme, type }) => (
    <img src={require("../Assets/Icons/toast_success.png")} />
  ),
  draggable: true,
  closeButton: false,
  pauseOnHover: true,
  theme: "colored",
  bodyClassName: "alertbody",
  className: "alertwrapper",
};

function decryptImageUrl(encryptedUrl, key) {
  key = CryptoJS.enc.Utf8.parse(key.slice(0, 32)); // Truncate or expand key to 32 bytes
  const encodedData = CryptoJS.enc.Base64.parse(encryptedUrl);
  const iv = CryptoJS.lib.WordArray.create(encodedData.words.slice(0, 4));
  const data = CryptoJS.lib.WordArray.create(encodedData.words.slice(4));

  const decryptedData = CryptoJS.AES.decrypt({ ciphertext: data }, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}

const image_filter_options = ["Geo Location", "Species", "Year"];
const species_image_filter_options = ["Geo Location", "Year"];

const years_array = ["2021", "2022", "2023", "2024"];

// const url = "https://datarepositorytatr.in/";
// const hostname = "https://da tarepositorytatr.in/";

const url = "https://btr-wildlife-backend-370316724016.asia-south1.run.app/";
const hostname =
  "https://btr-wildlife-backend-370316724016.asia-south1.run.app/";

const minYear = 2021;

const setMinDate = () => {
  const date = new Date();
  date.setFullYear(minYear);
  return date;
};

export {
  decryptImageUrl,
  hostname,
  Map_Images_Template,
  Map_Images_Tiger_Template,
  Map_Images_Tiger_Template_2,
  Map_Images_Tiger_Template_3,
  MapKey,
  Alerts_Template,
  image_filter_options,
  setMinDate,
  AccesDetails,
  alert_data,
  ForestData,
  url,
  SidebarData,
  SettingsData,
  FindTigerData,
  user_data,
  species_data,
  Geolocation_Data,
  warningToaster,
  successToaster,
  Geolocation_Filter_Data,
  setting_map,
  role,
  ImagesData,
  Images_Template,
  years_array,
  species_image_filter_options,
  conflicts_list,
};

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#99b0c3",
      },
      {
        visibility: "on",
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    stylers: [
      {
        color: "#8aa0b2",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#c3d2dd",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text",
    stylers: [
      {
        color: "#8fa6b9",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#8aa0b2",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#99b0c3",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#869daf",
      },
    ],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#e8e8e8",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c5d7e5",
      },
      {
        saturation: -5,
      },
      {
        visibility: "on",
      },
    ],
  },
];
