import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import UploadProperties from "./UploadProperties";
import UploadedImages from "./UploadedImages";
import ClassifyLoader from "./ClassifyLoader";
import IdentifyLoader from "./IdentifyLoader";
import TadobaContext from "../Context/TadobaContext";

const Settings = (props) => {
  const api = useContext(TadobaContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          api.images.length === 0 ? (
            <UploadProperties file={props.file} setFile={props.setFile} />
          ) : api.classification === null ? (
            <UploadedImages />
          ) : (
            <ClassifyLoader file={props.file} setFile={props.setFile} />
          )
        }
      />
      <Route path="/upload_images" element={<UploadedImages />} />
      <Route
        path="/classified_images"
        element={<ClassifyLoader file={props.file} setFile={props.setFile} />}
      />
      <Route
        path="/identified_images"
        element={<IdentifyLoader file={props.file} setFile={props.setFile} />}
      />
    </Routes>
  );
};

export default Settings;
