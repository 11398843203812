import React from 'react'
import SmallLoader from './SmallLoader'
const ComingSoon = (props) => {
  return (
    <div className='Nodata'>
        {props.loading?<SmallLoader/>:
        <>
        <img src={require('../Assets/Icons/Nothing_found.png')} alt="" />
        <span>Coming Soon ...</span> 
        </>}
    </div>
  )
}

export default ComingSoon