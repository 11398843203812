import React from 'react'
import { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import Map_Upload from './Map_Upload';
import axios from 'axios';
import { url } from '../../Data/Data';
const MapAdd = (props) => {

    const [drag, setDrag] = useState(false);
    const [file, setFile] = useState([]);




    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        directory: true,
        onDragOver: () => {
            setDrag(true)
        },
        onDragLeave: () =>{
            setDrag(false)
        },
        onDrop: (acceptedFiles) => {
            setFile(
                acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
            setDrag(false)
        }
    })

    return (
        <div className='PopupEditRole'>
            <div className='popupEditContent'>
                <div className="popupEditHeader">
                    <div>
                        <span>Add Map</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                {file.length==0?
                <div className='map_upload_settings' {...getRootProps()}>
                <input {...getInputProps()} />
                {!drag ?
                    <div className="upload_container">
                        <div className='upload_heading'>
                            <span>
                                Upload maps
                            </span>
                        </div>
                        <div className="upload_symbol">
                            <img src={require("../../Assets/Upload_Images/Upload_Symbol.png")} alt="" />
                        </div>
                        <div className='upload_description'>
                            <span>
                                Drag and drop your images here or choose folder
                            </span>
                        </div>
                        <div className="upload_button">
                            <div className="Login_Button upload_button">
                                <button style={{width:"325px"}}>Browse Files</button>
                            </div>
                        </div>
                    </div> :
                    <div className="drop_container">
                        <span>Drop your images here</span>
                    </div>
                }
                </div>:
                <Map_Upload image={file} close={props.close} mapUploadHandler={props.mapUploadHandler}/>
                }
            </div>
        </div>
    )
}

export default MapAdd