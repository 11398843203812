import React, { useContext, useState, useEffect } from "react";
import ClassifiedContainer from "./ClassifiedContainer";
import TadobaContext from "../Context/TadobaContext";
import { Navigate, useNavigate } from "react-router-dom";
import { url } from "../../Data/Data";
import axios from "axios";
import UploadLoader from "./UploadLoader";
import Popup from "reactjs-popup";

import { makeRequest } from "../../api";
const ClassifyLoader = (props) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [label, setLabel] = useState(false);
  const [species, setSpecies] = useState([]);
  const [progress, setProgress] = useState({
    uploadPercentage: 0,
    totalUpload: 0,
    completeUpload: 0,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const hideLabel = () => {
    setLabel(true);
  };

  const clearHandler = async () => {
    setIsPopupOpen(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/classification/upload_complete/`
      );
      props.setFile([]);
      api.setClassification(null);
      api.setFile([]);
      api.setUpload(false);
      navigate("/upload");
    } catch (error) {}
  };

  useEffect(() => {
    if (confirm) {
      clearHandler();
    }
  }, [confirm]);

  const bulkIdentifyTigers = async (ids) => {
    api.setIsIdentifying(true);
    const batchSize = 20;
    let batches = [];
    for (let i = 0; i < ids.length; i += batchSize) {
      batches.push(ids.slice(i, i + batchSize));
    }

    let mergedResult = {
      matched: {},
      unmatched: [],
      unidentified: [],
    };

    let temp = null;
    setProgress({
      ...progress,
      totalUpload: ids.length,
      uploadPercentage: 0,
      completeUpload: 0,
    });
    api.setUpload(true);

    const processBatch = async (count) => {
      // console.log(mergedResult);
      if (batches.length === 0) {
        setTimeout(() => {
          api.setUpload(false);
          navigate("/upload/identified_images");
          setProgress({
            uploadPercentage: 0,
            totalUpload: 0,
            completeUpload: 0,
          });
          api.setIsIdentifying(false);
        }, 2000);
        return;
      }

      const batch = batches.shift();
      try {
        const result = await makeRequest(
          "post",
          "api/v1/identification/tiger_identify_multiple/",
          { ids: batch }
        );
        if (result.data) {
          console.log(result.data);
          Object.keys(result.data.data.matched).forEach((key) => {
            mergedResult.matched[key] = result.data.data.matched[key];
          });
          mergedResult.unmatched = mergedResult.unmatched.concat(
            result.data.data.unmatched
          );
          mergedResult.unidentified = mergedResult.unidentified.concat(
            result.data.data.unidentified
          );

          api.setIdentifiedTigers(mergedResult);
          api.setProgress({
            totalUpload: ids.length,
            uploadPercentage: ((count + batch.length) / ids.length) * 100,
            completeUpload: count + batch.length,
          });
        } else {
          console.error("Error: result.data is undefined");
        }
      } catch (error) {
        console.error("Error in batch processing:", error);
      }
      processBatch(count + batch.length);
    };

    processBatch(0);
  };

  const startIdentificationProcess = () => {
    const idsToIdentify = api.classification.grouped_data.Tiger.map(
      (tiger) => tiger.id
    );
    bulkIdentifyTigers(idsToIdentify);
  };

  useEffect(() => {
    setSpecies(Object.keys(api.classification.grouped_data));
    console.log(Object.keys(api.classification.grouped_data))
  }, []);

  return (
    <div className="Classify_Section">
      {api.isIdentifying ? (
        <div className="Upload">
          <div className="UploadProperties">
            <UploadLoader
              percent={api.progress.uploadPercentage}
              total={api.progress.totalUpload}
              complete={api.progress.completeUpload}
              data={"Identifying Tigers..."}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="Classify_Header">
            <div className="Classify_Header">
              <span>Upload</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {species.includes("Tiger") && (
                <div
                  className="Classify_Identify"
                  style={{ cursor: "pointer" }}
                  onClick={startIdentificationProcess}
                >
                  <span>Identify Tigers</span>
                </div>
              )}
              {species.includes("Tiger") ? (
                <Popup
                  open={isPopupOpen}
                  trigger={
                    <div
                      className="Classify_Upload"
                      style={{ cursor: "pointer", marginLeft: "20px" }}
                      onClick={clearHandler}
                    >
                      <span>Finish Upload</span>
                    </div>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="PopupEditRole" style={{ width: "400px" }}>
                      <div className="popupEditContent">
                        {/* <div className="popupEditHeader">
                        <div>
                          <span>Edit Tiger Status</span>
                        </div>
                      </div> */}
                        <div>
                          <div
                            className="GeolocationEditContent"
                            style={{ width: "700px" }}
                          >
                            <label>
                              Would you like to proceed without identification?
                              If you finish you have to identify Tigers
                              individually.
                            </label>
                          </div>
                        </div>

                        <div className="popupDeleteButton">
                          <button
                            className="CancelButton"
                            onClick={() => {
                              close(); // Close the popup
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            className="DeleteButton"
                            onClick={() => {
                              setConfirm(true);
                              // clearHandler();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : (
                <div
                  className="Classify_Upload"
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                  onClick={() => {
                    setConfirm(true);
                    // clearHandler();
                  }}
                >
                  <span>Finish Upload</span>
                </div>
              )}
            </div>
          </div>

          {label ? (
            ""
          ) : (
            <div className="Classify_Label">
              <div className="Classify_Label_Item">
                <img
                  src={require("../../Assets/Icons/ImagesGroup.png")}
                  alt=""
                />
                <span className="label">Images uploaded</span>
                <span className="value">
                  {api.classification?.images_uploaded}
                </span>
              </div>
              <div className="Classify_Label_Item">
                <img src={require("../../Assets/Icons/clock.png")} alt="" />
                <span className="label">Time taken</span>
                <span className="value">
                  {Math.floor(api.classification?.time_taken)} sec{" "}
                </span>
              </div>
              <div className="Classify_Label_Item">
                <img src={require("../../Assets/Icons/gitlab.png")} alt="" />
                <span className="label">Species found</span>
                <span className="value">
                  {api.classification?.species_found}
                </span>
              </div>
              <div className="Classify_Label_Item">
                <img src={require("../../Assets/Icons/Images.png")} alt="" />
                <span className="label">Tiger Images</span>
                <span className="value">
                  {api.classification?.tiger_images}
                </span>
              </div>
              <div className="Classify_Label_Item">
                <img src={require("../../Assets/Icons/SideClaw.png")} alt="" />
                <img src={require("../../Assets/Icons/SideClaw.png")} alt="" />
              </div>
              <div className="Classify_Label_Item">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={hideLabel}
                  src={require("../../Assets/Icons/Close.png")}
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="Classify_Container">
            <ClassifiedContainer setLabel={setLabel} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClassifyLoader;
