import React, { useEffect, useState } from "react";
import TadobaContext from "../Components/Context/TadobaContext";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Select, Space, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import UploadTiger from "../Components/Upload/UploadTiger";
import Tiger_routes from "../Components/Upload/Tiger_routes";

const FindTiger = () => {
  const navigate = useNavigate();
  const api = useContext(TadobaContext);
  const [drag, setDrag] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    var formData = new FormData();
    for (const key of Object.keys(api.file)) {
      formData.append("files", api.file[key]);
    }
  }, [api.file]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "find_tiger/*",
    multiple: false,
    onDragOver: () => {
      setDrag(true);
    },
    onDragLeave: () => {
      setDrag(false);
    },
    onDrop: (acceptedFiles, rejectedFiles, event) => {
      const isDirectory = acceptedFiles.some((file) => file.isDirectory);
      if (isDirectory) {
        event.preventDefault();
        return;
      }

      const validFiles = acceptedFiles.filter(
        (file) =>
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg" ||
          file.type === "image/jfif"
      );

      if (validFiles.length > 0) {
        api.setTigerImage(
          validFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        api.setImages([]);
        api.setTigerImage(validFiles);
        setDrag(false);
        navigate("/find_tiger");
      } else {
        console.log("Please drop a valid image file.");
      }
    },
  });

  const fileUploadHandler = (event) => {};

  const TigerSelectHandler = (event) => {
    setButtonClicked(true);
  };

  if (
    api.tigerImage.length > 0 &&
    !api.tigerImage[0].type.startsWith("application/x-moz-file")
  ) {
    return <UploadTiger file={api.tigerImage} setFile={api.setTigerImage} setButtonClicked={setButtonClicked} buttonClicked={buttonClicked} />;
  }

  if (buttonClicked === true) {
    // navigate(`/find_tiger`);
    return <Tiger_routes setButtonClicked={setButtonClicked} />;
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonSolidCheckedBg: "#E8DFD5",
            buttonSolidCheckedActiveBg: "#E8DFD5",
            buttonSolidCheckedHoverBg: "#E8DFD5",
            colorPrimary: "#FFF3E8",
            colorPrimaryHover: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          DatePicker: {
            activeBorderColor: "#FFF3E8",
            colorLinkActive: "#FFF3E8",
            colorPrimary: "#FFF3E8",
            hoverBorderColor: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          Select: {
            optionActiveBg: "#FFF3E8",
            optionSelectedBg: "#FFF3E8",
            optionFontSize: 14,
            colorPrimaryHover: "#E67817",
            controlOutline: "#FFF3E8",
            multipleItemBg: "#FFF3E8",
          },
        },
      }}
    >
      <div className="Setting_User">
        <div
          className="upload"
          {...getRootProps()}
          style={{ marginLeft: "190px" }}
        >
          <input {...getInputProps()} />

          <div className="upload_container">
            <div className="upload_heading">
              <span>Upload an Image / Select a Tiger </span>
            </div>
            <div className="upload_symbol">
              <img
                src={require("../Assets/Upload_Images/Upload_Symbol.png")}
                alt=""
              />
            </div>
            <div className="upload_description">
              <span>Drag and drop your Image here</span>
            </div>
            <div className="upload_button" style={{ marginTop: "-15px" }}>
              <div
                className="Login_Button upload_button"
                style={{ marginTop: "25px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button onClick={fileUploadHandler}>Upload an Image</button>
                  <div style={{ margin: "0 30px" }}>OR</div>
                  <button
                    onClick={(e) => {
                      TigerSelectHandler();
                      e.stopPropagation();
                    }}
                  >
                    Select a Tiger
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ) : (
            <div className="drop_container">
              <span>Drop your images here</span>
            </div>
          )} */}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default FindTiger;
