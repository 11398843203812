import React from 'react'

const YearIndicators = () => {
    const data = [{year:2024, color: 'blue'}, {year: 2023, color: 'green'}, {year: 2022, color: 'red'}, {year: 2021, color: 'yellow'}];
    
    return (
        <div className='year-indicator'>
            {data.map((info) => (
                <div className='indicator-container' key={info.year}>
                    <div className='indicator-circle' style={{backgroundColor: info.color}}></div>
                    <span>{info.year}</span>
                </div>
            ))}
        </div>
    );
}

export default YearIndicators;
