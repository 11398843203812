import React, { useContext, useEffect, useState } from 'react'
import Alert_Table from '../Alert_Table'
import { ToastContainer } from 'react-toastify'
import TadobaContext from '../Context/TadobaContext'
import { url } from '../../Data/Data'
import axios from 'axios'
import { makeRequest } from '../../api'
const Alert_Setup = () => {

  const api = useContext(TadobaContext)
  const [loading, setLoading] = useState(true)
  const [update, setUpdate] = useState(1)
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [searchItem, setSearchItem] = useState('')
  const [dropdownValue, setDropdownValue] = useState('')
  function search(searchTerm) {
    setUpdate(update + 1)
    if (searchTerm === '') {
      setSearchValue(false)
    }
    else {
      setSearchValue(true)
    }
    const filtered = api.alertData.filter(
      product => {
        return (product.Email.toLowerCase().includes(searchItem.toLowerCase()) || product.Name.toLowerCase().includes(searchItem.toLowerCase()))
      }
    );

    setFilteredProducts(filtered)
  }

  function filter(searchTerm, nextTerm) {
    if (searchTerm === '' && nextTerm === '') {
      setSearchValue(false)
    }
    else {
      setSearchValue(true)
    }
    if (dropdownValue === "") {
      search(nextTerm)
    }
    else {
      const filtered = api.alertData.filter(
        product => {
          return (product.Email.toLowerCase().includes(searchItem.toLowerCase() || product.Name.toLowerCase().includes(searchItem.toLowerCase())))
        }
      );

      setFilteredProducts(filtered)
    }
  }


  useEffect(() => {
    filter(dropdownValue, searchItem)
  }, [dropdownValue, searchItem])


  const serachHandler = (event) => {
    setSearchItem(event.target.value)
  }




  const changeStatus = async (data) => {
    let token = ""
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("post", `api/v1/account/user_alert_change/`, data)
      setUpdate(update + 1)
    } catch (error) {
      setUpdate(update + 1)
      // toast.success(error.response.data.data.toString(), warningToaster)
    }
  }


  const updateStatus = (state, row) => {
    const user = api.users.filter(function (item) {
      return item.email === row.Email;
    });

    const data = {
      "user_id": user[0].id,
      "alert": !row["Send Alerts"]
    }
    changeStatus(data)
  }


  useEffect(() => {

    const AllUsersSetup = (users) => {
      let result = []
      users.map((value, key) => {
        const obj = {
          Name: value.name,
          Email: value.email,
          "Mobile no.": value.mobile,
          "Send Alerts": value.send_alert,
        }
        result.push(obj)
      })
      api.setAlertData(result)
    }



    const fetchUsers = async () => {
      let token = ""
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/account/users/`)
        AllUsersSetup(result.data.data)
        api.setUsers(result.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)

      }
    }
    fetchUsers()
  }, [update])



  return (
    <div className='Setting_User'>
      <div className="Setting_User_Header">
        <div><span>Alert Setup</span></div>
        <div className='Setting_User_Attribute'>
          <div className='role_search'>
            <input value={searchItem} onChange={serachHandler} x type="text" placeholder='Search by name' />
            <img src={require('../../Assets/Icons/search_icon.png')} alt="" />
          </div>
        </div>
      </div>
      <Alert_Table updateStatus={updateStatus} loading={loading} data={searchValue ? filteredProducts : api.alertData} />
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  )
}

export default Alert_Setup