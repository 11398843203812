import React, { useContext, useState } from 'react'
import Setting_User_Table from '../Setting_User_Table'
import axios from 'axios'
import { url } from '../../Data/Data'
import { useEffect } from 'react'
import { useRef } from 'react'
import Popup from 'reactjs-popup'
import TadobaContext from '../Context/TadobaContext'
import SpeciesAdd from './SpeciesAdd'
import { toast } from 'react-toastify'
import { successToaster } from '../../Data/Data'
import { warningToaster } from '../../Data/Data'
import { makeRequest } from '../../api'



const Users = () => {


    const [uploadfile, setUploadfile] = useState(null)
    const [render, setRender] = useState(1)
    const inputFile = useRef(null)
    const [loading, setLoading] = useState(true)
    const [searchItem, setSearchItem] = useState([])
    const [searchValue, setSearchValue] = useState(false)
    const [filteredProducts, setFilteredProducts] = useState([])
    const api = useContext(TadobaContext)

    const handleChange = (event) => {
        setUploadfile(event.target.files[0])
        setUploadfile("")
        const data = new FormData()
        data.append('file', event.target.files[0])
        uploadedfile(data)
    }


    const uploadedfile = async (data) => {
        let token = ""
        let csrftoken = ""

        try {
            if (localStorage.token) {
                token = localStorage.token;
            }
            if (localStorage.csrf) {
                csrftoken = localStorage.csrf;
            }
            const result = await makeRequest("post", `api/v1/species/species_excel_upload/`, data)
            setRender(render + 1)
            toast.success("File Uploaded Successfully", successToaster)
        } catch (error) {
            toast.success(error.response.data.data.toString(), warningToaster)
            setRender(render + 1)
        }
    }

    function search(searchTerm) {
        if (searchTerm === '') {
            setSearchValue(false)
        }
        else {
            setSearchValue(true)
        }
        const filtered = api.species.filter(
            product => {
                return (product["Species name"].toLowerCase().includes(searchItem.toLowerCase()))
            }
        );

        setFilteredProducts(filtered)
    }


    const searchHandler = (event) => {
        setSearchItem(event.target.value)
        search(event.target.value)
    }



    const AllSpeciesSetup = (users) => {
        let result = []
        users.map((value, key) => {
            const obj = {
                "Species name": value.name,
                "No. Of Images": value.no_of_images
            }
            result.push(obj)
        })
        api.setSpecies(result)
    }


    const speciesEditHandler = async (name, close, setWarning, setNameDisabled) => {
        let token = ""
        try {
            if (localStorage.token) {
                token = localStorage.token;
            }
            const result = await makeRequest("put", `api/v1/species/species_update/`, name)
            close()
            toast.success("Species Edited Successfully", successToaster)
            setRender(render + 1)
        } catch (error) {
            setWarning(error.response.data.data.toString())
            setNameDisabled(true)
            // toast.success(error.response.data.data.toString(), warningToaster)
        }
    }


    const deleteHandler = async (obj, close) => {
        setLoading(true)
        let data = {}
        if (obj.length === 0) {
            data = {
                "camera_ids": [],
                "all": true
            }
        }
        else {
            data = {
                ids: obj,
                "all": false
            }
        }
        let token = ""
        try {
            if (localStorage.token) {
                token = localStorage.token;
            }
            const result = await makeRequest("delete", `api/v1/species/species_delete/`, data)
            setSearchItem([])
            setSearchValue(false)
            close()
            setRender(render + 1)
            setLoading(false)
            toast.success("Species Deleted Successfully", successToaster)
        } catch (error) {
            setLoading(false)
        }
    }



    useEffect(() => {
        const fetchSpecies = async () => {
            let token = ""
            try {
                if (localStorage.token) {
                    token = localStorage.token;
                }
                const result = await makeRequest("get", "api/v1/species/species/")
                api.setAllSpecied(result.data.data)
                AllSpeciesSetup(result.data.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchSpecies()
    }, [render])


    const speciesAddHandler = async (name, close, setWarning, setNameDisabled) => {
        let body = {
            name: name
        }
        let token = ""
        let csrftoken = ""
        try {
            if (localStorage.token) {
                token = localStorage.token;
            }
            if (localStorage.csrf) {
                csrftoken = localStorage.csrf;
            }
            const result = await makeRequest("post", `/api/v1/species/species_add/`, body)
            setRender(render + 1)
            toast.success("Species Added Successfully", successToaster)
            close()
        } catch (error) {
            setWarning(error.response.data.data.toString())
            setNameDisabled(true)
            // toast.success(error.response.data.data.toString(), warningToaster)
        }
    }

    return (
        <div className='Setting_User'>
            <div className="Setting_User_Header">
                <div><span>Species Setup</span></div>
                <div className='Setting_User_Attribute'>
                    <div className='role_search'>
                        <input type="text" placeholder='Search by name' value={searchItem} onChange={searchHandler} />
                        <img src={require('../../Assets/Icons/search_icon.png')} alt="" />
                    </div>
                    {api.user.role.id === 2 ?
                        <div className='Setting_Upload_Button'>
                            <button onClick={() => { inputFile.current.click() }}>Upload</button>
                            <input type="file" onChange={handleChange} value={uploadfile} ref={inputFile} />
                        </div> : ""}
                    {api.user.role.id !== 3 ?
                        <Popup trigger={
                            <div className='Setting_Add_Button'>
                                <img src={require('../../Assets/Icons/plus.png')} alt="" />
                                <span>Add</span>
                            </div>
                        } modal nested>
                            {
                                close => (
                                    <SpeciesAdd render={render} speciesAddHandler={speciesAddHandler} setRender={setRender} close={close} />
                                )
                            }
                        </Popup> : ""}
                </div>
            </div>
            <Setting_User_Table loading={loading} data={searchValue ? filteredProducts : api.species} editHandler={speciesEditHandler} deleteHandler={deleteHandler} delete={true} type="Species" />
            {/* <ToastContainer rtl={false} style={{ height: "10px" }} /> */}
        </div>
    )
}

export default Users