import React, { useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { useContext } from "react";
import axios from "axios";
import { url } from "../../Data/Data";
import Upload_Setup from "./Upload_Setup";
import { useNavigate } from "react-router-dom";
import LazyImage from "../LazyImage";
import UploadLoader from "./UploadLoader";
import { makeRequest } from "../../api";
const UploadedImages = () => {
  const api = useContext(TadobaContext);
  const [UploadView, setUploadview] = useState(false);
  const [totalUpload, setTotalUpload] = useState(0);
  const navigate = useNavigate();
  const viewHandler = () => {
    setUploadview(true);
  };

  const uploadAllImages = (bodyData) => {
    api.setProgress({
      ...api.progress,
      totalUpload: bodyData.length,
    });
    let groupedData = null;
    setTotalUpload(bodyData.length);
    const batchSize = 20;
    let totalUploaded = 0;
    const batches = [];

    for (let i = 0; i < bodyData.length; i += batchSize) {
      const batch = bodyData.slice(i, i + batchSize);
      batches.push(batch);
    }

    const processBatch = async (list, count, temp) => {
      if (batches.length === 0) {
        setTimeout(() => {
          api.setClassification(temp);
          api.setUpload(false);
          navigate("/upload/classified_images");
          api.setProgress({
            uploadPercentage: 0,
            totalUpload: 0,
            completeUpload: 0,
          });
        }, 2000);
        return true;
      }

      const batch = batches.shift();

      let token = "";
      api.setUpload(true);
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest(
          "post",
          `api/v1/classification/classify_species/`,
          {
            all_unclassified: false,
            ids: batch,
            upload_token: localStorage.getItem("upload_token"),
          }
        );

        api.setProgress({
          totalUpload: bodyData.length,
          uploadPercentage: ((count + batch.length) / bodyData.length) * 100,
          completeUpload: count + batch.length,
        });
        let groupedData1 = null;
        let groupedData2;
        if (temp === null) {
          temp = result.data.data;
        } else {
          groupedData1 = temp.grouped_data;
          groupedData2 = result.data.data.grouped_data;
          for (let species in groupedData2) {
            if (species in groupedData1) {
              groupedData1[species] = groupedData1[species].concat(
                groupedData2[species]
              );
            } else {
              groupedData1[species] = groupedData2[species];
            }
          }
          temp = {
            ...temp,
            classifed: [...temp.classifed, ...result.data.data.classifed],
            grouped_data: groupedData1,
            images_uploaded:
              temp.images_uploaded + result.data.data.images_uploaded,
            tiger_images: temp.tiger_images + result.data.data.tiger_images,
            time_taken: temp.time_taken + result.data.data.time_taken,
            unclassified: [
              ...temp.unclassified,
              ...result.data.data.unclassified,
            ],
            species_found: Object.keys(groupedData1).length,
          };
        }
      } catch (error) {}
      processBatch(list, count + batch.length, temp);
    };
    processBatch(bodyData, totalUploaded, groupedData);
  };

  const classifyApi = async (bodyData) => {
    api.setUpload(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/classify_species/`,
        {
          all_unclassified: false,
          ids: bodyData,
          upload_token: api.UploadKey,
        }
      );
      api.setUpload(false);
      api.setClassification(result.data.data);
      navigate("/upload/classified_images");
    } catch (error) {
      api.setUpload(false);
      navigate("/upload/classified_images");
      alert(error);
    }
  };

  const classifyHandler = () => {
    var list = [];
    for (const key in api.images) {
      list.push(api.images[key].image_key);
    }
    const bodyData = {
      ids: list,
    };
    uploadAllImages(list);
    // classifyApi(list)
  };

  return (
    <div className="Upload">
      {UploadView ? (
        <Upload_Setup setUploadview={setUploadview} />
      ) : (
        <div className="UploadProperties">
          {api.upload ? (
            <UploadLoader
              percent={api.progress.uploadPercentage}
              total={api.progress.totalUpload}
              complete={api.progress.completeUpload}
              data={"Classifying Images..."}
            />
          ) : (
            <>
              <div className="ViewUploadedImages">
                <div className="Images_Uploaded">
                  <span>Images Uploaded</span>
                </div>
                <div className="UploadImagesGroup">
                  <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <img
                      src={api.file[0].preview}
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={api.file[1]?.preview}
                      style={{ borderRadius: "0px 0px 0px 8px" }}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={api.file[2]?.preview}
                      style={{ borderRadius: "0px 0px 8px 0px" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="uploadimagdesc">
                  {/* <span>Images</span> */}
                  <span style={{ color: "#9A938A", marginTop: "-10px" }}>
                    ({api.file.length})
                  </span>
                </div>
                <div className="View_Uploaded_Images_Button">
                  {/* <div className="Upload_Cancel_button" onClick={viewHandler}>
                    <span>View uploaded images</span>
                  </div> */}
                  <div
                    onClick={classifyHandler}
                    className="Upload_Classify_button"
                  >
                    <span>Classify species</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadedImages;
