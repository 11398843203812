import React from "react";
import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import TadobaContext from "../Context/TadobaContext";
import {
  Geolocation_Data,
  successToaster,
  url,
  warningToaster,
} from "../../Data/Data";
import Popup from "reactjs-popup";
import Setting_User_Table from "../Setting_User_Table";
import GeolocationAdd from "./GeolocationAdd";
import { useRef } from "react";
import GeolocationFilters from "./GeolocationFilters";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { makeRequest } from "../../api";
const Geolocation_Setup = () => {
  const api = useContext(TadobaContext);
  const [file, setFile] = useState("");
  const [render, setRender] = useState(1);
  const [scroll, setScroll] = useState(true);
  const inputFile = useRef(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [page, setPage] = useState(api.page + 1);
  const [loading, setLoading] = useState(true);
  const [defaultYear, setDefaultYear] = useState(api.defaultYear);

  function search(searchTerm) {
    // setRender(render + 1)
    if (searchTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    const filtered = api.allGeolocations.filter((product) => {
      return (
        product["Compartment no."]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        product["Camera ID"].toLowerCase().includes(searchTerm.toLowerCase()) ||
        product["Round no."].toLowerCase().includes(searchTerm.toLowerCase()) ||
        product["Range no."].toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredProducts(filtered);
  }

  const serachHandler = (event) => {
    setSearchItem(event.target.value);
    search(event.target.value);
  };

  console.log(defaultYear);

  useEffect(() => {
    search(searchItem);
  }, [searchItem]);

  const upload = async (data) => {
    setLoading(true);
    try {
      let token = null;
      let csrftoken = "";
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result = await makeRequest(
        "post",
        `api/v1/map/geolocation_upload/`,
        data
      );
      setLoading(false);
      api.setPage(0);
      toast.success("File Uploaded Successfully", successToaster);
      setRender(render + 1);
    } catch (error) {
      setLoading(false);
      setRender(render + 1);
      toast.success("File is not able to upload", warningToaster);
    }
  };

  const handleChange = (e) => {
    api.setAllGeolocations(Geolocation_Data);
    api.setGeolocations([]);
    setFile(e.target.files[0]);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    upload(data);
    setFile(null);
    inputFile.current.value = "";
  };

  const editGeoLocation = async (body, close) => {
    let token = "";
    setLoading(true);
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        `api/v1/map/geolocation_update/`,
        body
      );
      toast.success("Geolocation renamed successfully", successToaster);
      api.setPage(0);
      api.setAllGeolocations(Geolocation_Data);
      api.setGeolocations([]);
      setPage(1);
      setRender(render + 1);
      close();
    } catch (error) {
      close();
      toast.success(error.response.data.data.toString(), warningToaster);
      setLoading(false);
    }
  };

  const addGeoLocation = async (body, close) => {
    let token = "";
    let csrftoken = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result = await makeRequest(
        "post",
        `api/v1/map/geolocation_add/`,
        body
      );
      toast.success("Geolocations Added SuccessFully", successToaster);
      api.setGeolocationFilters(null);
      api.setAllGeolocations(Geolocation_Data);
      api.setGeolocations([]);
      setSearchItem("");
      api.setPage(0);
      setPage(1);
      setRender(render + 1);
      close();
    } catch (error) {
      toast.success("Error While Adding Geolocations", warningToaster);
    }
  };

  const addHandler = (body, close) => {
    addGeoLocation(body, close);
  };

  const AllGeolocationSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        "Range no.": value.range.range_name,
        "Round no.": value.round.round_name,
        "Beat no.": value.beat.beat_name,
        "Compartment no.": value.compartment.compartment_name,
        "Station ID": value.station.station_name,
        "Camera ID": value.camera.camera_name,
      };
      result.push(obj);
    });
    if (api.Geolocations.length <= 1) {
      api.setAllGeolocations(result);
      setLoading(false);
    } else if (
      users.length + api.allGeolocations.length >
      api.totalGeolocations
    ) {
      const temp =
        users.length + api.allGeolocations.length - api.totalGeolocations;
      result.splice(result.length - temp, temp);
      api.setAllGeolocations([...api.allGeolocations, ...result]);
      setLoading(false);
    } else {
      api.setAllGeolocations([...api.allGeolocations, ...result]);
      setLoading(false);
    }
    setScroll(false);
  };

  const fetchUsers = async () => {
    setScroll(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (page <= api.page) {
        setLoading(false);
        return;
      }

      const result = await makeRequest(
        "get",
        `api/v1/map/geolocation_filter1/?page=${page}${api.query}&year=${defaultYear}`
      );
      api.setTotalGrolocations(result.data.count);
      api.setPage(api.page + 1);
      api.setGeolocations([...api.Geolocations, ...result.data.data]);
      AllGeolocationSetup(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setScroll(true);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [render, page, api.defaultYear]);

  const deleteGeoLocation = async (body) => {
    setLoading(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "delet",
        `api/v1/map/geolocation_delete/`,
        body
      );
      toast.success("Geolocations Deleted Successfully", successToaster);
      api.setGeolocationFilters(null);
      api.setAllGeolocations(Geolocation_Data);
      api.setGeolocations([]);
      setSearchItem("");
      api.setPage(0);
      setPage(1);
      setRender(render + 1);
    } catch (error) {
      toast.success("Error While Deleting Geolocations", warningToaster);
      setLoading(false);
    }
  };

  const editHandler = (data, close) => {
    editGeoLocation(data, close);
  };

  const deleteHandler = (data) => {
    if (data.length === 0) {
      const body = {
        camera_ids: [],
        all: true,
      };
      deleteGeoLocation(body);
      return;
    }
    const list = [];

    api.Geolocations.map((value, key) => {
      data.map((row, key) => {
        if (value.camera["camera_name"] === row) {
          list.push(value.camera["camera_id"]);
        }
      });
    });
    const body = {
      camera_ids: list,
      all: false,
    };
    deleteGeoLocation(body);
  };

  const filterApply = (filter, close) => {
    if (filter.length === 0) {
      api.setPage(0);
      api.setAllGeolocations(Geolocation_Data);
      api.setGeolocations([]);
      api.setQuery("");
      setLoading(true);
      setPage(1);
      close();
      setRender(render + 1);
      return;
    }
    let item = "&";
    filter.map((value, key) => {
      if (key === filter.length - 1) {
        item = item + value;
        return;
      }
      item = item + value + "&";
    });
    api.setPage(0);
    api.setAllGeolocations(Geolocation_Data);
    api.setGeolocations([]);
    api.setQuery(item);
    setLoading(true);
    close();
    setPage(1);
    setRender(render + 1);
  };

  const clearFilter = (filter, close) => {};
  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Geolocation Setup</span>
          <span style={{ fontSize: "10px" }}> ({api.defaultYear})</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input
              type="text"
              value={searchItem}
              placeholder="Search by Geolocations "
              onChange={serachHandler}
            />
            <img src={require("../../Assets/Icons/search_icon.png")} alt="" />
          </div>
          <Popup
            trigger={
              <div className="role_filter">
                <img src={require("../../Assets/Icons/filter.png")} alt="" />
              </div>
            }
            modal
            nested
          >
            {(close) => (
              <GeolocationFilters
                close={close}
                filterApply={filterApply}
                clearFilter={clearFilter}
              />
            )}
          </Popup>
          {api.user.role.id === 2 ? (
            <div className="Setting_Upload_Button">
              <button
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                Upload
              </button>
              <input type="file" onChange={handleChange} ref={inputFile} />
            </div>
          ) : (
            ""
          )}
          {api.user.role.id !== 3 ? (
            <Popup
              trigger={
                <div className="Setting_Add_Button">
                  <img src={require("../../Assets/Icons/plus.png")} alt="" />
                  <span>Add</span>
                </div>
              }
              modal
              nested
            >
              {(close) => (
                <GeolocationAdd close={close} addHandler={addHandler} />
              )}
            </Popup>
          ) : (
            ""
          )}
        </div>
      </div>
      <Setting_User_Table
        scroll={scroll}
        total={true}
        count={api.totalGeolocations}
        page={page}
        loading={loading}
        setLoading={setLoading}
        setPage={setPage}
        deleteHandler={deleteHandler}
        data={searchValue ? filteredProducts : api.allGeolocations}
        editHandler={editHandler}
        delete={true}
        type="Geolocation"
        defaultYear={defaultYear}
        setDefaultYear={setDefaultYear}
      />
      <ToastContainer />
    </div>
  );
};

export default Geolocation_Setup;
