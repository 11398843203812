import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Setting_User_Table from "../Setting_User_Table";
import { Geolocation_Data, url } from "../../Data/Data";
import TadobaContext from "../Context/TadobaContext";
import { makeRequest } from "../../api";
import { successToaster, warningToaster } from "../../Data/Data";
import { toast } from "react-toastify";

const Map_edit = () => {
  const { map_id } = useParams();

  const api = useContext(TadobaContext);
  const [query, setQuery] = useState(`&map_id=${map_id}`);
  const [map, setMap] = useState({});
  const navigate = useNavigate();
  const [page, setPage] = useState(api.page + 1);
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [render, setRender] = useState(1);
  const [total, setTotal] = useState(0);

  const backHandler = () => {
    navigate("/settings/map_setup");
  };

  const AllGeolocationSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        "Range no.": value.range.range_name,
        "Round no.": value.round.round_name,
        "Beat no.": value.beat.beat_name,
        "Compartment no.": value.compartment.compartment_name,
        "Station ID": value.station.station_name,
        "Camera ID": value.camera.camera_name,
      };
      result.push(obj);
    });

    if (api.mapGeolocations.length <= 1) {
      api.setAllMapGeolocations(result);
      setLoading(false);
    } else if (
      users.length + api.allMapGeolocations.length >
      api.totalMapGeolocations
    ) {
      const temp =
        users.length + api.allMapGeolocations.length - api.totalGeolocations;
      result.splice(result.length - temp, temp);
      api.setAllMapGeolocations([...api.allMapGeolocations, ...result]);
      setLoading(false);
    } else {
      api.setAllMapGeolocations([...api.allMapGeolocations, ...result]);
      setLoading(false);
    }
  };

  const fetchMapData = async () => {
    try {
      let token = null;
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = makeRequest("get", `/api/v1/map/map/?map_id=${map_id}`);
      setMap(result.data.data);
      fetchGeoLocations();
    } catch (error) {}
  };

  const deleteGeoLocation = async (body) => {
    setLoading(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "delete",
        `api/v1/map/geolocation_delete/`,
        body
      );
      alert("Geolocations Deleted Successfully");
      api.setAllMapGeolocations(Geolocation_Data);
      api.setMapGeolocations([]);
      setPage(1);
      setRender(render + 1);
    } catch (error) {
      alert("Error While Deleting Geolocations");
      setLoading(false);
    }
  };

  const deleteHandler = (data) => {
    if (data.length === 0) {
      const body = {
        camera_ids: [],
        all: true,
      };
      deleteGeoLocation(body);
      return;
    }
    const list = [];
    api.mapGeolocations.map((value, key) => {
      data.map((row, key) => {
        if (value.camera["camera_name"] === row) {
          list.push(value.camera["camera_id"]);
        }
      });
    });
    const body = {
      camera_ids: list,
      all: false,
    };
    deleteGeoLocation(body);
  };

  const fetchGeoLocations = async () => {
    setScroll(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/geolocation_filter1/?page=${page}${query}`
      );
      api.setTotalMapGeolocations(result.data.count);
      api.setMapGeolocations([...api.mapGeolocations, ...result.data.data]);
      AllGeolocationSetup(result.data.data);
      setScroll(false);
      setTimeout(() => {
        setLoading(false);
      }, [2000]);
    } catch (error) {
      setScroll(false);
      setLoading(false);
    }
    setScroll(false);
  };

  useEffect(() => {
    fetchMapData();
  }, [render, page]);

  const editGeoLocation = async (body, close) => {
    let token = "";
    setLoading(true);
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        `api/v1/map/geolocation_update/`,
        body
      );
      alert("Geolocations Edited SuccessFully");
      api.setAllMapGeolocations(Geolocation_Data);
      api.setMapGeolocations([]);
      setPage(1);
      setRender(render + 1);
      close();
    } catch (error) {
      close();
      alert("Error While Editing Geolocations");
      setLoading(false);
    }
  };

  const editHandler = (data, close) => {
    editGeoLocation(data, close);
  };

  const makeMapDefault = async () => {
    setLoading(true);
    let token = "";
    const body = { map_id: map_id };
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        `api/v1/map/set_map_default/`,
        body
      );
      setLoading(false);
      toast.success("Map set default successfully.", successToaster);
      backHandler();
    } catch (error) {
      toast.success("Error While setting default Map.", warningToaster);
      setLoading(false);
    }
  };

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Map Setup</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input type="text" placeholder="Search by name, email id" />
            <img src={require("../../Assets/Icons/search_icon.png")} alt="" />
          </div>
          <div className="role_filter">
            <img src={require("../../Assets/Icons/filter.png")} alt="" />
          </div>
        </div>
      </div>
      <div className="map_Setting_Table">
        <div className="map_edit_heading">
          <img
            onClick={backHandler}
            src={require("../../Assets/Icons/left.png")}
            alt=""
          />
          <span>{map?.name}</span>
          <div
            className="Setting_Add_Button"
            onClick={() => {
              makeMapDefault(map);
            }}
            style={{ marginLeft: "20px" }}
          >
            <span>Make Default</span>
          </div>
        </div>
        <div className="map_edit_image">
          <img src={map?.map_image} alt="" />
        </div>
        <Setting_User_Table
          scroll={scroll}
          total={true}
          count={api.totalMapGeolocations}
          page={page}
          loading={loading}
          setLoading={setLoading}
          setPage={setPage}
          deleteHandler={deleteHandler}
          editHandler={editHandler}
          data={api.allMapGeolocations}
          delete={true}
          type="Geolocation"
        />
      </div>
    </div>
  );
};

export default Map_edit;
