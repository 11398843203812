import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alerts_Template } from "../../Data/Data";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import TadobaContext from "../Context/TadobaContext";
import AlertsTable from "./AlertsTable";
import { Select } from "antd";
import { makeRequest } from "../../api";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const AllAlerts = (props) => {
  const navigate = useNavigate();
  const [species, setSpecies] = useState([]);
  const api = useContext(TadobaContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [grid, stGrid] = useState(true);
  const [listData, setListData] = useState([]);
  const [exportedImages, setExportedImages] = useState([]);
  const [villageData, setVillageData] = useState([]);
  const [speciesData, setSpeciesData] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState(
    JSON.parse(localStorage.getItem("selectedSpecies")) || []
  );
  const [selectedVillage, setSelectedVillages] = useState(
    JSON.parse(localStorage.getItem("selectedVillage")) || []
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [dateRangeText, setDateRangeText] = useState("");

  useEffect(() => {
    if (page > 1) {
      getImages();
    }
    if (page === 1) {
      getImages();
    }
  }, [render, page, selectedDateRange]);

  // sample comment for re-deployment

  useEffect(() => {
    const getSpeciesAndVillage = async () => {
      try {
        const resultVillage = await makeRequest(
          "get",
          "/api/v1/virtualwall/villages/"
        );
        let resultSpecies = [];
        if (!localStorage.species) {
          const species = await makeRequest("get", "/api/v1/species/species/");
          resultSpecies = species.data.data;
        } else {
          resultSpecies = JSON.parse(localStorage.species);
        }

        const tempVillageData = resultVillage.data.data;

        const filteredSpeciesData = resultSpecies.filter((species) => {
          return ["Tiger", "Leopard", "Bear"].includes(species.name);
        });

        setVillageData(tempVillageData);
        setSpeciesData(filteredSpeciesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getSpeciesAndVillage();
  }, []);

  useEffect(() => {
    if (selectedVillage.length > 0 || selectedSpecies.length > 0) {
      setCurrentPage(0);
      setData([]);
      setListData([]);
      setLoading(true);
      setPage(1);
      getImages();
    }
    localStorage.setItem("selectedVillage", JSON.stringify(selectedVillage));
    localStorage.setItem("selectedSpecies", JSON.stringify(selectedSpecies));
  }, [selectedVillage, selectedSpecies]);

  const handleCalendarClick = () => {
    setShowDatePicker(true);
  };

  const villageOptions = villageData.map((village) => ({
    label: village.name,
    value: village.beat.id,
  }));

  const speciesOption = speciesData.map((species) => ({
    label: species.name,
    value: species.id,
  }));

  const getImages = async () => {
    setScroll(true);

    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      let requestBody = {
        page: page,
        size: "20",
        villages: selectedVillage,
        species: selectedSpecies,
        start_date: selectedDateRange
          ? selectedDateRange[0].format("YYYY-MM-DD")
          : "",
        end_date: selectedDateRange
          ? selectedDateRange[1].format("YYYY-MM-DD")
          : "",
      };

      // if (clearFilters) {
      //   requestBody = {
      //     ...requestBody,
      //     page: 1,
      //     villages: [],
      //     species: [],
      //     start_date: "",
      //     end_date: "",
      //   };
      // }

      const result = await makeRequest(
        "post",
        "api/v1/virtualwall/alert_list_web/",
        requestBody
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      if (newData.length === 0) {
        // Handle case when newData is empty
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const dateString = cameraData.created_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };
          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            "Species Name": cameraData.name,
            "Camera ID": cameraData.camera.name,
            "Date and Time": formattedDate,
            "Village Name": cameraData.village.name,
            Landmark: cameraData.landmark.name,
            "Users name": "",
            checked: false,
            id: cameraData.id,
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle error
    }
  };

  const downloadImages = async () => {
    const imageUrls = [...exportedImages];
    const zip = new JSZip();

    const headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    const downloadPromises = imageUrls.map(async (imageUrl, index) => {
      const response = await fetch(imageUrl, { headers });
      const imageData = await response.blob();
      zip.file(`image${index + 1}.jpg`, imageData);
      return Promise.resolve();
    });
    await Promise.all(downloadPromises);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "images.zip");
  };

  const deleteImage = async (array, close) => {
    let data = {};
    if (array.length > 0) {
      data = {
        image_ids: array,
        all: false,
      };
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }

      const result = await makeRequest(
        "delete",
        `api/v1/classification/image_delete/`,
        data
      );
      setCurrentPage(0);
      setData([]);
      setListData([]);
      setLoading(true);
      setPage(1);
      setRender(render + 1);
      close();
    } catch (error) {}
  };

  const editImage = (value) => {
    navigate(`/alerts/${value.id}`);
  };

  const speciesChangeHandler = (event) => {
    setSelectedSpecies(event);
  };

  const villageChangeHandler = (event) => {
    setSelectedVillages(event);
  };

  const clearAllFilters = () => {
    setSelectedSpecies([]);
    setSelectedVillages([]);
    setSelectedDateRange(null);
    setShowDatePicker(false);
    setDateRangeText("");
    setCurrentPage(0);
    setData([]);
    setListData([]);
    setLoading(true);
    setPage(1);
    setRender(render + 1);
  };

  const customDateChangeHandler = (dates, dateStrings) => {
    setSelectedDateRange(dates);
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      // Reset the list to an empty array
      setListData([]);
      setData([]);
      setCurrentPage(0);
      // setData([]);
      // setListData([]);
      setLoading(true);
    }
    if (dateStrings[0] && dateStrings[1]) {
      setDateRangeText(
        `${moment(dateStrings[0]).format("D MMMM YYYY")} - ${moment(
          dateStrings[1]
        ).format("D MMMM YYYY")}`
      );
    } else {
      setDateRangeText("");
    }
  };

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Alerts</span>
        </div>
        <div className="Setting_User_Attribute">
          <div
            className="role_ClearAll"
            style={{
              display:
                selectedSpecies.length === 0 &&
                selectedVillage.length === 0 &&
                dateRangeText.length === 0
                  ? "none"
                  : "block",
            }}
          >
            <button onClick={clearAllFilters}>Clear All</button>
          </div>
          <div className="DirectionDropdown">
            {/* <p>{speciesData}</p>
                    <p>{villageData}</p> */}
            <Select
              className="alerts-select-placeholder"
              showSearch
              mode="multiple"
              style={{ width: 180, height: 40 }}
              placeholder="Villages"
              optionFilterProp="children"
              loading={
                (villageData !== null && villageData.length === 0) || loading
              }
              size="medium"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={villageChangeHandler}
              options={villageOptions}
              value={selectedVillage}
            />
          </div>
          <div className="DirectionDropdown">
            <Select
              className="alerts-select-placeholder"
              showSearch
              mode="multiple"
              style={{ width: 180, height: 40 }}
              placeholder="Species"
              optionFilterProp="children"
              loading={
                (speciesData !== null && speciesData.length === 0) || loading
              }
              size="medium"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={speciesChangeHandler}
              options={speciesOption}
              value={selectedSpecies}
            />
          </div>
          <div className="role_Calander" onClick={handleCalendarClick}>
            <img
              src={require("../../Assets/Icons/Calander.png")}
              alt=""
              style={{ display: showDatePicker ? "none" : "block" }}
            />
            {dateRangeText && (
              <span
                style={{
                  display: showDatePicker ? "none" : "block",
                  fontSize: "0.85rem",
                  color: "#594F42",
                  marginLeft: "12px",
                }}
              >
                {dateRangeText}
              </span>
            )}
            {showDatePicker && (
              <RangePicker
                value={selectedDateRange}
                format="YYYY-MM-DD"
                onChange={customDateChangeHandler}
                bordered={false}
                size="large"
                onOpenChange={(open) => {
                  setCurrentPage(0);
                  // setData([]);
                  // setListData([]);
                  // setLoading(true);
                  // setPage(1);
                  // setRender(render + 1);
                  // getImages();
                  setShowDatePicker(open);
                }}
              />
            )}
          </div>
          {exportedImages.length !== 0 ? (
            <button className="role_filter" onClick={downloadImages}>
              Export
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <AlertsTable
        exportedImages={exportedImages}
        scroll={scroll}
        setExportedImages={setExportedImages}
        currentPage={currentPage}
        grid={grid}
        setGrid={stGrid}
        delete={true}
        setCurrentPage={setCurrentPage}
        page={page}
        setPage={setPage}
        setData={setListData}
        count={count}
        data={listData}
        getImages={getImages}
        deleteImage={deleteImage}
        editImage={editImage}
        type={"Classified"}
        template={Alerts_Template}
        loading={loading}
      />
    </div>
  );
};

export default AllAlerts;
