import React, { useEffect, useContext, useState } from 'react'
import { Geolocation_Filter_Data } from '../../Data/Data'
import TadobaContext from '../Context/TadobaContext'
import { makeRequest } from '../../api'
import Checkbox from '../Checkbox'
import SmallLoader from '../SmallLoader'
const GeolocationFilters = (props) => {
    const api = useContext(TadobaContext)
    const [filterData, setFilterData] = useState(Geolocation_Filter_Data)
    const [filters, setfilters] = useState(api.geolocationFilters)
    const [render, setRender] = useState(1)
    const [loading, setLoading] = useState(false)
    const expandFilterHandler = (value) => {
        if (!api.geolocationFilters?.[value.id]) {
            setLoading(true)
            fetchFilters(value.id)
        }
        const newData = [...filterData]
        const updatedData = newData.map((data, key) => {
            if (data.name == value.name) {
                data.isOpen = !value.isOpen
                return data
            }
            else
                data.isOpen = false
            return data
        })
        setFilterData(updatedData)
    }


    const fetchFilters = async (key) => {

        if (key == null) {
            api.setGeolocationFilters("")
            return
        }
        let token = ""
        try {
            if (localStorage.token) {
                token = localStorage.token;
            }
        const result = await makeRequest("get", `api/v1/map/geolocation_filter_core/?key=${key}`)
            const newData = { ...api.geolocationFilters, [key]: result.data.data }
            newData[key].map((item, val) => {
                item.checked = false;
            })
            api.setGeolocationFilters(newData)
            setfilters(newData)
            setLoading(false)
        } catch (error) {
        }
    }


    const resetFilter = () => {
        const newData = filters
        filterData.map((value, key) => {
            if (newData[value.id]) {
                newData[value.id].map((item, val) => {
                    item.checked = false;
                })
            }
        })
        api.setGeolocationFilters(newData)
        setfilters(newData)
    }



    const filterChangeHandler = (value) => {
        const tempData = { ...filters }
        tempData[value.id].map((item) => {
            if (item.name == value.value) {
                item.checked = !item.checked
            }
        })

        setfilters(tempData)
        if (value.checked == false) {
            const newData = [...api.filterQuery]
            newData.push(`${value.name}=${value.value}`)
            const uniqueData = newData.filter((value, index, self) => self.indexOf(value) === index);
            api.setFilterQuery(uniqueData)
        }
        else {
            const newData = [...api.filterQuery]
            const item = `${value.name}=${value.value}`;
            const index = newData.indexOf(item)
            newData.splice(index, 1);
            api.setFilterQuery(newData)
        }
    }
    const [searchValue, setSearchValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);

        clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            setLoading(true);
            // Perform search or other asynchronous operations here
            setTimeout(() => {
                setLoading(false);
            }, 1000); // Simulating loading for demonstration purposes
        }, 500); // Delay before triggering loading state

        setTimeoutId(newTimeoutId);
    };

    const filteredOptions = (options) => {
        return options?.filter((option) =>
            option.name.includes(searchValue)
        )
    };

    // const displayedOptions = searchValue ? filteredOptions : options;



    return (
        <div className='PopupEditRole'>
            <div className='popupEditContentFilter'>
                <div className="popupEditHeader">
                    <div>
                        <span>Filters</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                <div className='GeolocationFilterContent'>
                    <div className="FilterSidebar">
                        <ul>
                            <li>
                                <span>Geo Location</span>
                            </li>
                        </ul>
                    </div>
                    <div className="FilterList">
                        <ul>
                            {filterData.map((value, key) => {
                                return (
                                    <li key={key}>
                                        <div onClick={() => { expandFilterHandler(value) }}>
                                            <span>{value.name}</span>
                                            <div>
                                                {value?.isOpen ?
                                                    <img src={require('../../Assets/Icons/filter_dropicon.png')} alt="" /> :
                                                    <img src={require('../../Assets/Icons/filter_up_icon.png')} alt="" />
                                                }
                                            </div>
                                        </div>
                                        {value?.isOpen ?
                                            <>
                                                {filters == null || undefined || loading || !filters[value.id] ? <SmallLoader /> :

                                                    <div className='filterListData'>
                                                        <div style={{position:"relative"}}>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            value={searchValue}
                                                            onChange={(e) => { handleSearchChange(e) }}
                                                        />
                                                        <img src={require("../../Assets/Icons/search_icon.png")} style={{position:"absolute", left:"1px"}} alt="" />
                                                        </div>
                                                        {
                                                            searchValue != "" ? filteredOptions(filters[value.id]).map((data, key) => {
                                                                return (
                                                                    <Checkbox value={data.name} checked={data.checked} name={value.label} id={value.id} Modify={filterChangeHandler} />
                                                                )
                                                            }) :
                                                                filters[value.id].map((data, key) => {
                                                                    return (
                                                                        <Checkbox value={data.name} checked={data.checked} name={value.label} id={value.id} Modify={filterChangeHandler} />
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                } </> : ""}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="popupDeleteButtonFilter">
                    <button className='CancelButton ' onClick={() => {
                        resetFilter()
                        props.filterApply([], props.close)
                    }}>Clear All</button>
                    <button className='DeleteButton' onClick={() => {
                        props.filterApply(api.filterQuery, props.close)
                    }}>Apply</button>
                </div>
            </div>
        </div>
    )
}

export default GeolocationFilters