import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LazyImage from "../LazyImage";
import { url } from "../../Data/Data";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { GoogleMap, Marker, Polygon, LoadScriptNext } from "@react-google-maps/api";
import { MapKey } from "../../Data/Data";
import { makeRequest } from "../../api";
import { useNavigate } from 'react-router-dom';

const AlertEdit = () => {
  const { alert_id } = useParams();
  const [render, setRender] = useState();
  const api = useContext(TadobaContext);
  const [data, setData] = useState(null);
  const [position, setPosition] = useState(null);
  const [date, setDate] = useState("")
  const navigate = useNavigate();
  useEffect(() => {
    const getImages = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/virtualwall/alert_details/?alert_id=${alert_id}`)
        setData(result.data.data);
        setPosition({
          lat: parseFloat(result.data.data.location.latitude, 10),
          lng: parseFloat(result.data.data.location.longitude, 10),
        });
        const dateObj = new Date(result.data.data?.created_at)
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "UTC"
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObj);
        setDate(formattedDate)
      } catch (error) { }
    };
    getImages();
  }, [render]);

  return (
    <div>
      <div className="EditHeadBar" onClick={() => navigate(-1)}>
        <span>Alerts</span>
        <img src={require("../../Assets/Icons/right_icon.png")} alt="" />
        {/* <span>{data?.village}.png</span> */}
      </div>
      <div className="Setting_User_Header">
        <div>
          <span>{data?.name}</span>
        </div>
      </div>
      <div className="Image_Edit_Mode">
        <div className="Image_Edit_View Alert_Image" style={{ width: "60%" }}>
          <div className="Alert_Edit_Image">
            <LazyImage
              src={data?.image}
              placeholderSrc={require("../../Assets/Icons/temp_img.png")}
            />
          </div>
          {/* <img style={{height:"30vh"}} src="" loading='lazy' alt="" /> */}
          <div className="ForestMap" style={{ height: "30vh", width: "100%" }}>
            <LoadScriptNext googleMapsApiKey={MapKey}>
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={position === null ? { lat: 20.49835556, lng: 79.28112222 } : position}
                zoom={10}
              >
                <Polygon
                  paths={api.polygonCoordinates}
                  options={{
                    fillColor: "skyblue",
                    fillOpacity: 0.4,
                    strokeColor: "blue",
                    strokeOpacity: 0.6,
                    strokeWeight: 2,
                  }}
                />
                <Marker animation={true} position={position} />
              </GoogleMap>
            </LoadScriptNext>
          </div>
        </div>
        <div className="Image_Edit_Data" style={{ width: "40%" }}>
          <div className="BasicDetails">
            <div className="BasicHeader">
              <span>Basic details</span>
            </div>
            <div
              className="BasicDetailsEditContent"
              style={{ flexWrap: "wrap" }}
            >
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Date & Time</label>
                <span>{date}</span>
              </div>
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Village Name</label>
                <span>{data?.village?.name}</span>
              </div>
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Species category</label>
                <span>{data?.name}</span>
              </div>
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Landmark</label>
                <span>{data?.landmark?.name}</span>
              </div>
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Users</label>
                <span>No user found</span>
              </div>
            </div>
          </div>
          <div className="GeolocationDetails">
            <div className="GeolocationHeader">
              <span>Geo location details</span>
            </div>
            <div className="GeolocationDetailsEditContent">
              {/* <div className="BasicDetailEditField">
                    <label htmlFor="Range no.">Range no.</label>
                    <span>{data?.range.name}</span>
                  </div>
                  <div className="BasicDetailEditField">
                    <label htmlFor="Range no.">Round no.</label>
                    <span>{data?.round.name}</span>
                  </div>
                  <div className="BasicDetailEditField">
                    <label htmlFor="Range no.">Beat no.</label>
                    <span>{data?.beat.name}</span>
                  </div>
                  <div className="BasicDetailEditField">
                    <label htmlFor="Range no.">Compartment no.</label>
                    <span>{data?.compartment.name}</span>
                  </div>
                  <div className="BasicDetailEditField">
                    <label htmlFor="Range no.">Station ID</label>
                    <span>{data?.station.name}</span>
                  </div> */}
              <div className="BasicDetailEditField">
                <label htmlFor="Range no.">Camera ID</label>
                <span>{data?.camera.name}</span>
              </div>
              <div className="BasicDetailEditField">
                <label htmlFor="Latitude and Longitude">Latitude and Longitude</label>
                <span>{data?.location.latitude}, {data?.location.longitude}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertEdit;
