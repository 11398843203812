import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { makeRequest } from "../api";
import { toast } from "react-toastify";
import { warningToaster } from "../Data/Data";
import TadobaContext from "../Components/Context/TadobaContext";
import { useDropzone } from "react-dropzone";
import { Select } from "antd";
import { conflicts_list } from "../Data/Data";

const RecordConflict = (props) => {
  const api = useContext(TadobaContext);
  const [file, setFile] = useState([]);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [location_type, setLocationType] = useState("");
  const [number, setNumber] = useState("");
  const [range, setRange] = useState([]);
  const [camera, setCamera] = useState([]);
  const [cameraId, setCameraId] = useState("");
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(true);
  const [drag, setDrag] = useState(false);
  const [comment, setComment] = useState("");
  const [tigerslist, setTigersList] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [disable, setDisable] = useState(true);

  const locationChangeHandler = (event) => {
    setNumber("");
    setLocationType(event.target.value);
    const array = range.filter((val) => {
      return val.name === event.target.value;
    });
    fetchCameras(array[0].id);
  };

  useEffect(() => {
    const fetchRanges = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/map/range_list/`);
        setRange(result.data.data);
      } catch (error) {}
    };
    fetchRanges();
  }, []);

  useEffect(() => {
    const fetchTiger = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;
        const names = result_data
          .map((tiger) => {
            if (tiger.images.length >= 0) {
              return {
                value: tiger.id,
                label: tiger.name,
              };
            }
            return null;
          })
          .filter(Boolean);

        setTigersList(names);
      } catch (error) {
        toast.success("Error While getting Tiger data", warningToaster);
      }
    };
    fetchTiger();
  }, []);

  const numberChangeHandler = (event) => {
    const item = JSON.parse(event.target.value);
    setCameraId(item.name);
    setNumber(event.target.value);
    setPosition({
      lat: item.latitude,
      lng: item.longitude,
    });
    setError(false);
  };
  const fetchCameras = async (id) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/village_list/?range_id=${id}`
      );
      setCamera(result.data.data);
    } catch (error) {}
  };

  const commentChangeHandler = (event) => {
    setComment(event.target.value);
  };

  const speciesChangeHandler = (event) => {
    setSelectedSpecies(event);
  };

  useEffect(() => {
    if (
      latitude &&
      longitude &&
      location_type &&
      cameraId &&
      selectedDate &&
      comment
    ) {
      setDisable(false);
    }
  }, [latitude, longitude, location_type, cameraId, selectedDate, comment]);

  const uploadAllImages = async () => {
    try {
      let formData = new FormData();
      formData.append("lat", latitude);
      formData.append("long", longitude);
      formData.append("upload_token", localStorage.getItem("upload_token"));
      formData.append("location_type", location_type);
      formData.append("village", cameraId);
      formData.append("conflict_date", selectedDate);
      formData.append("comments", comment);
      formData.append("species_data_id", selectedSpecies);
      formData.append(
        "files",
        new File([file, "extra data: " + file.path], file.name, {
          type: file.type,
        })
      );
      let token = "";
      let csrftoken = "";

      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }

      const result = await makeRequest(
        "post",
        `api/v1/classification/record_conflict/`,
        formData
      );
      props.recordAddHandler(props.close);
    } catch (error) {
      const message = error.response.data.data;
      toast.success(message, warningToaster);
      return false;
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleLatitudeChange = (e) => {
    const value = e.target.value.replace(/[^0-9.-]/g, "");
    setLatitude(value);
  };

  const handleLongitudeChange = (e) => {
    const value = e.target.value.replace(/[^0-9.-]/g, "");
    setLongitude(value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    directory: true,
    onDragOver: () => {
      setDrag(true);
    },
    onDragLeave: () => {
      setDrag(false);
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setDrag(false);
    },
  });

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/*",
  //   multiple: false,
  //   onDragOver: () => {
  //     setDrag(true);
  //   },
  //   onDragLeave: () => {
  //     setDrag(false);
  //   },
  //   onDrop: (acceptedFiles, rejectedFiles, event) => {
  //     const isDirectory = acceptedFiles.some((file) => file.isDirectory);
  //     if (isDirectory) {
  //       event.preventDefault();
  //       return;
  //     }

  //     const validFiles = acceptedFiles.filter(
  //       (file) =>
  //         file.type === "image/png" ||
  //         file.type === "image/jpg" ||
  //         file.type === "image/jpeg" ||
  //         file.type === "image/jfif"
  //     );

  //     setFile(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  // });

  return (
    <div className="PopupEditRole" style={{ width: "1200px" }}>
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Add Tiger Conflict</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainEditContent">
          <div className="UploadFields">
            {file.length == 0 ? (
              <div
                className="map_upload_settings"
                style={{ marginLeft: "-140px", width: "35%", height: "auto" }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {!drag ? (
                  <div className="upload_container">
                    <div className="upload_heading">
                      <span>Upload Image</span>
                    </div>
                    <div className="upload_symbol">
                      <img
                        src={require("../Assets/Upload_Images/Upload_Symbol.png")}
                        alt=""
                      />
                    </div>
                    <div className="upload_description">
                      <span>
                        Drag and drop your image here or choose folder
                      </span>
                    </div>
                    <div className="upload_button">
                      <div className="Login_Button upload_button">
                        <button style={{ width: "150px" }}>Browse Files</button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="drop_container">
                    <span>Drop your images here</span>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  width: "30%",
                  height: "90%",
                }}
              >
                <img
                  src={file[0].preview}
                  style={{
                    width: "100%",
                  }}
                  alt=""
                />
                <div className="DirectionDropdown">
                  <Select
                    showSearch
                    style={{ width: 360 }}
                    placeholder="Select Tiger Name"
                    optionFilterProp="children"
                    size={"large"}
                    value={selectedSpecies}
                    onChange={speciesChangeHandler}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={tigerslist}
                  />
                </div>
              </div>
            )}
            <div>
              <div style={{ textAlign: "right" }}>
                <span style={{ fontSize: "8px" }}>
                  *All location fields are Mandatory
                </span>
              </div>
              <div className="UploadParameters">
                <div className="UploadLocations">
                  <div className="DirectionDropdown">
                    <label htmlFor="">Location type*</label>
                    <select onChange={locationChangeHandler} required>
                      <option disabled selected hidden value="">
                        Select or type range
                      </option>
                      {range.map((value, key) => {
                        return (
                          <option
                            className="role_filter_span"
                            name={value.id}
                            key={value.id}
                            value={value.name}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="">Select Village*</label>
                    <select
                      onChange={numberChangeHandler}
                      value={number}
                      required
                    >
                      <option disabled selected hidden value="">
                        Select or type village
                      </option>
                      {camera.map((value, key) => {
                        return (
                          <option
                            className="role_filter_span"
                            name={value.name}
                            key={value.id}
                            value={JSON.stringify(value)}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="conflictDate">Conflict Date*</label>
                    <input
                      type="date"
                      name="conflictDate"
                      style={{ width: 330 }}
                      value={selectedDate}
                      onChange={handleDateChange}
                      disabled={false}
                      placeholder="Add the conflict Date"
                      id="conflictDate"
                    />
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="">Comments*</label>
                    <select onChange={commentChangeHandler} required>
                      <option disabled selected hidden value="">
                        Select or type comments
                      </option>
                      {conflicts_list.map((value, key) => {
                        return (
                          <option
                            className="role_filter_span"
                            name={value.id}
                            key={value.id}
                            value={value.name}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div
                  className="UploadLocations"
                  style={{ marginTop: "-170px" }}
                >
                  <div className="DirectionDropdown">
                    <label htmlFor="latitude">Latitude*</label>
                    <input
                      type="text"
                      name="latitude"
                      value={latitude}
                      onChange={handleLatitudeChange}
                      placeholder="Latitude"
                      id="latitude"
                    />
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="">Longitude*</label>
                    <input
                      type="text"
                      name="longitude"
                      value={longitude}
                      onChange={handleLongitudeChange}
                      placeholder="Longitude"
                      id="longitude"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popupDeleteButtonUsers" style={{ marginLeft: "900px" }}>
        <button className="CancelButton" onClick={props.close}>
          Cancel
        </button>
        <button
          className="DeleteButton"
          onClick={uploadAllImages}
          disabled={disable}
          style={{ opacity: disable ? 0.5 : 1 }}
        >
          Save
        </button>
      </div>

      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default RecordConflict;
