import React from "react";

const StatsBar = ({ name, score, isActive }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
  };

  const nameStyle = {
    width: "150px",
    marginRight: "10px",
    textAlign: "right",
  };

  const barContainerStyle = {
    flex: 1,
    marginLeft: "-70px",
    position: "relative",
  };

  const maxScore = 100;

  const barStyle = {
    width: `${(score / maxScore) * 100}%`,
    backgroundColor: "green",
    height: "8px",
    // borderRadius: "5px",
    position: "relative",
  };

  const scoreContainerStyle = {
    paddingLeft: "5px",
    marginLeft: "5px",
    minWidth: "50px",
    fontSize: "12px",
  };

  return (
    <div style={containerStyle}>
      <div style={nameStyle}>{name}</div>
      <div style={barContainerStyle}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#ddd",
          }}
        >
          <div style={barStyle}></div>
        </div>
      </div>
      <div
        style={
          isActive
            ? { ...scoreContainerStyle, fontWeight: "bold" }
            : scoreContainerStyle
        }
      >
        {score.toFixed(2)}%
      </div>
    </div>
  );
};

export default StatsBar;
