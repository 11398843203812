import React, { useEffect, useState, useRef } from "react";
import {
  Map_Images_Tiger_Template_2,
  Map_Images_Tiger_Template_3,
} from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import ImagesTable from "../ImagesTable";
import { makeRequest } from "../../api";
import { Radio } from "antd";

const TigerTable = (props) => {
  // console.log("tigertable");
  const species_id = props.speciesID;
  const tiger_types = props.tiger_types;
  const geolocationBeat = props.geolocationBeat;
  const latitude = props.latitude;
  const longitude = props.longitude;
  const onTigerTableData = props.onTigerTableData;
  const upload_flow = props.type;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isInitialRender = useRef(true);
  const [scroll, setScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [listData, setListData] = useState([]);
  const [grid, stGrid] = useState(true);
  const [exportedImages, setExportedImages] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showLastSightings, setShowLastSightings] = useState(true);
  const [nearestListData, setNearestListData] = useState([]);
  const listContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    // Save current scroll position before appending new data
    if (listContainerRef.current) {
      listContainerRef.current.addEventListener("scroll", handleScroll);
      return () =>
        listContainerRef.current.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleScroll = () => {
    setScrollPosition(listContainerRef.current.scrollTop);
  };

  useEffect(() => {
    // Restore scroll position after appending new data
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = scrollPosition;
    }
  }, [nearestListData]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setLoading(true);
      setData([]);
      setListData([]);
      setCurrentPage(0);
      setExportedImages([]);
      stGrid(true);
      setPage(1);
      setRender(render + 1);
    }
  }, [props.selectedCamera]);

  useEffect(() => {
    console.log("tigertable");
    filterApply();
  }, [tiger_types]);

  useEffect(() => {
    if (showLastSightings) {
      // Load last sightings data
      // filterApply();
    } else {
      // Load nearest tigers data
      nearestTigers();
    }
  }, [showLastSightings, page]);

  const getImages = async () => {
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/image_list_classfied_species/?q=${species_id}&page=${page}&size=20`
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });

      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.created_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            id: cameraData.id,
            image_key: cameraData.image_key,
            checked: false,
            longitude: cameraData.location.longitude,
            latitude: cameraData.location.latitude,
            "Tiger Id": `T_${name % 120}`,
            Beat: cameraData.beat.name.toString(),
            Range: cameraData.range.name.toString(),
            "Compartment no.": cameraData.compartment.name.toString(),
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterApply = async () => {
    setListData([]);
    const size = 20;
    const result_data = {
      geolocation_beat: geolocationBeat,
      species: species_id,
      tiger_types: [tiger_types],
      size: size,
      latitude: latitude,
      longitude: longitude,
    };

    setScroll(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/tiger_image_filter/`,
        result_data
      );
      const tempData = result.data.data;
      setFilterData(tempData);
      onTigerTableData(tempData);
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });

      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            id: cameraData.id,
            image_key: cameraData.image_key,
            checked: false,
            latitude: cameraData.location.latitude,
            longitude: cameraData.location.longitude,
            "Tiger Id": `T_${name % 120}`,
            Beat: cameraData.beat.name.toString(),
            Range: cameraData.range.name.toString(),
            Round: cameraData.round.name.toString(),
            "Compartment no.": cameraData.compartment.name.toString(),
            "Distance from Current Loc.":
              typeof cameraData.distance === "number"
                ? `${cameraData.distance.toFixed(2)} km`
                : cameraData.distance,
          };
          // console.log(formattedData);
          formattedList.push(formattedData);
        }
        // console.log("filterapply");

        setListData(formattedList);
        setData((prevData) => [...prevData, ...newData]);
        // setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const nearestTigers = async () => {
    setScroll(true);
    try {
      const size = 20;
      const result_data = {
        tiger_types: [tiger_types],
        page: page,
        size: size,
        latitude: latitude,
        longitude: longitude,
      };

      if (page <= currentPage) {
        setLoading(false);
        return;
      }

      let token = "";
      if (localStorage.token) {
        token = localStorage.token;
      }

      const result = await makeRequest(
        "post",
        `api/v1/classification/nearest_tiger_filter/`,
        result_data
      );

      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });

      setCount(result.data.count);
      if (newData.length === 0) {
        setScroll(false);
      } else {
        const formattedList = [];
        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.specie_sub_type?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            "Image Id": cameraData.id,
            Name: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            image_key: cameraData.image_key,
            checked: false,
            latitude: cameraData.location.latitude,
            longitude: cameraData.location.longitude,
            "Tiger Id": `T_${name % 120}`,
            Beat: cameraData.beat.name.toString(),
            Range: cameraData.range.name.toString(),
            "Round no": cameraData.round.name.toString(),
            "Compartment no.": cameraData.compartment.name.toString(),
          };
          formattedList.push(formattedData);
        }

        setNearestListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
        setScroll(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLastSightingsClick = () => {
    setListData([]);
    setNearestListData([]);
    setShowLastSightings(true);
    setLoading(true);
    setCurrentPage(0);
    setData([]);
    setPage(1);
    setRender(render + 1);
    filterApply();
  };

  const handleNearestTigersClick = () => {
    setShowLastSightings(false);
    setCount(0);
    setListData([]);
    setLoading(true);
    setCurrentPage(0);
    setListData([]);
    setData([]);
    setPage(1);
    setRender(render + 1);
    // nearestTigers();
  };

  return (
    <div className="Setting_User" style={{ height: "82vh" }}>
      <div style={{ position: "relative", top: "50px", marginLeft: "50px" }}>
        <Radio.Button
          onClick={handleLastSightingsClick}
          style={{ fontWeight: showLastSightings ? "bold" : "normal" }}
        >
          Last 5 Sightings
        </Radio.Button>

        <Radio.Button
          onClick={handleNearestTigersClick}
          style={{ fontWeight: !showLastSightings ? "bold" : "normal" }}
        >
          Nearby Tigers
        </Radio.Button>
      </div>

      {showLastSightings === true ? (
        <ImagesTable
          table={"map"}
          speciesID={species_id}
          exportedImages={exportedImages}
          scroll={scroll}
          setExportedImages={setExportedImages}
          currentPage={currentPage}
          grid={grid}
          setGrid={stGrid}
          setCurrentPage={setCurrentPage}
          page={page}
          setPage={setPage}
          setData={setListData}
          count={count}
          data={listData}
          getImages={getImages}
          type={"Classified"}
          template={Map_Images_Tiger_Template_2}
          loading={loading}
        />
      ) : (
        <ImagesTable
          table={"map"}
          speciesID={species_id}
          exportedImages={exportedImages}
          scroll={scroll}
          setExportedImages={setExportedImages}
          currentPage={currentPage}
          grid={grid}
          setGrid={stGrid}
          setCurrentPage={setCurrentPage}
          page={page}
          setPage={setPage}
          setData={setNearestListData}
          count={count}
          data={nearestListData}
          getImages={getImages}
          type={"nearby"}
          template={Map_Images_Tiger_Template_3}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TigerTable;
