import React, { useState } from 'react';
import { makeRequest } from '../api';
const DownloadTigerData = ({ tigerName, basicTigerDetails, geolocationData, herbCount, downloadMap, nearestTigerCount, geolocationBeat, speciesID, tiger_types, latitude, longitude }) => {

  const [loading, setLoading] = useState(false);

  const convertToCSV = (data) => {
    const headers = Object.keys(data).join(',');
    const values = Object.values(data).join(',');
    return `${headers}\n${values}`;
  };

  const downloadCSV = async () => {
    const basicData = {
      Gender: basicTigerDetails?.speciesGender || 'NA',
      Age: basicTigerDetails?.speciesAge || 'NA',
      Status: geolocationData?.status || 'NA',
    };
  
    const geoData = {
      Range: geolocationData?.last_seen_data?.range_name || 'NA',
      Round: geolocationData?.last_seen_data?.round_name || 'NA',
      Beat: geolocationData?.last_seen_data?.beat_name || 'NA',
      Compartment: geolocationData?.last_seen_data?.compartment_name || 'NA',
      'Station ID': geolocationData?.last_seen_data?.station_name || 'NA',
      'Camera ID': geolocationData?.last_seen_data?.number_type || 'NA',
      Latitude: geolocationData?.last_seen_data?.latitude || 'NA',
      Longitude: geolocationData?.last_seen_data?.longitude || 'NA',
    };
  
    const additionalData = {
      'Distance Travelled: 3 Months': geolocationData?.distance_traveled_3m || 'NA',
      'Distance Travelled: 6 Months': geolocationData?.distance_traveled_6m || 'NA',
      'Dist from Last Known Loc.': geolocationData?.last_seen_data?.distance || 'NA',
      'Nearby Tigers': nearestTigerCount || 'NA',
      'Herbivores Count': herbCount || 'NA',
    };
  
    // Convert individual sections to CSV format
    const basicCSV = convertToCSV(basicData);
    const geoCSV = convertToCSV(geoData);
    const additionalCSV = convertToCSV(additionalData);
    downloadMap();
  
    try {
      setLoading(true);
  
      // Fetch and format data from filterApply
      const size = 20;
      const filterApplyData = {
        geolocation_beat: geolocationBeat,
        species: speciesID,
        tiger_types: [tiger_types],
        size: size,
        latitude: latitude,
        longitude: longitude,
      };
  
      const filterApplyResult = await makeRequest(
        "post",
        `api/v1/classification/tiger_image_filter/`,
        filterApplyData
      );
      const filterApplyFormattedList = filterApplyResult.data.data.map((cameraData) => {
        const dateString = cameraData.captured_at;
        const dateObj = new Date(dateString);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "UTC",
        };

        const formattedDate = `"${new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(dateObj)}"`;

        return {
          ID: cameraData.id,
          // Species: cameraData.species.name,
          Range: cameraData.range.name,
          Beat: cameraData.beat.name,
          "Station ID": cameraData.station.name,
          "Camera ID": cameraData.camera.name,
          Latitude: cameraData.location.latitude.toString(),
          Longitude: cameraData.location.longitude.toString(),
          "Distance from Current Loc.":
          typeof cameraData.distance === "number"
            ? `${cameraData.distance.toFixed(2)} km`
            : cameraData.distance,
          "Date and Time": formattedDate,
        };
      });
  
      // Fetch and format data from nearestTigers
      const nearestTigerData = {
        tiger_types: [tiger_types],
        page: 1,
        size: size,
        latitude: latitude,
        longitude: longitude,
      };
  
      const nearestTigerResult = await makeRequest(
        "post",
        `api/v1/classification/nearest_tiger_filter/`,
        nearestTigerData
      );
  
      const nearestTigerFormattedList = nearestTigerResult.data.data.map((cameraData) => {
        const dateString = cameraData.captured_at;
        const dateObj = new Date(dateString);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: "UTC",
        };

        const formattedDate = `"${new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(dateObj)}"`;
        return {
          ID: cameraData.id,
          Name: cameraData.specie_sub_type.name,
          Range: cameraData.range.name,
          Beat: cameraData.beat.name,
          "Station ID": cameraData.station.name,
          "Camera ID": cameraData.camera.name,
          Latitude: cameraData.location.latitude.toString(),
          Longitude: cameraData.location.longitude.toString(),
          "Distance from Current Loc.":
          typeof cameraData.distance === "number"
            ? `${cameraData.distance.toFixed(2)} km`
            : cameraData.distance,
          "Date and Time": formattedDate,
        };
      });
  
      // Combine CSV Data
      const lastTigersCSV = filterApplyFormattedList.map(convertToCSV).join('\n');
      const nearbyTigersCSV = nearestTigerFormattedList.map(convertToCSV).join('\n');
  
      // Generate the CSV file
      const combinedCSV = 
      `Tiger Name,${tigerName}\n\n` + 
      `Basic Details\n${basicCSV}\n\n` +
      `Geo location Details - (Last Known Location)\n${geoCSV}\n\n` +
      `Additional Details\n${additionalCSV}\n\n` +
      `Last 5 Sightings\n${lastTigersCSV}\n\n` +
      `Nearby Tigers\n${nearbyTigersCSV}`;
      
      // Generate the CSV file
      const blob = new Blob([combinedCSV], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', 'tiger_data.csv');
      a.click();
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching tiger data:', error);
    }
  };
  
  return (
    <div className="dowload_button">
      <button onClick={downloadCSV} disabled={loading}>
        {loading ? 'Downloading...' : 'Download Map & Tiger Data'}
      </button>
    </div>
  );
};

export default DownloadTigerData;
