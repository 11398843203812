import { upload } from "@testing-library/user-event/dist/upload";
import React, { useContext, useState, useEffect } from "react";
import TadobaContext from "../Components/Context/TadobaContext";
import { useNavigate } from "react-router-dom";
import Statistics_card from "../Components/Cards/Statistics_card";
import TopSpecies_Card from "../Components/Cards/TopSpecies_Card";
import Toptigers from "../Components/Cards/Toptigers";
import Unidentified from "../Components/Cards/Unidentified";
import UnClassified_Card from "../Components/Cards/UnClassified_Card";
import RecentImages_Card from "../Components/Cards/RecentImages_Card";
import { makeRequest } from "../api";
import { Select } from "antd";
const Dashboard = () => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const clickUpdate = () => {
    getImages();
  };
  
  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/statistic/update_stats/`);
      setData(result.data.data);
      localStorage.removeItem("recent_images");
      localStorage.removeItem("unclassified");
      localStorage.removeItem("top_tigers");
      localStorage.removeItem("top_species");
      localStorage.removeItem("statistic_classification");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const [selectedYear, setSelectedYear] = useState(localStorage.getItem("selectedYearData") || 'all');

  return (
    <div className="Dashboard">
      <div className="Dashnoard_Header">
        <div style={{ display: "flex", gap: "4px", flexDirection: "column" }}>
          <span className="welcome">Welcome {api.user.name},</span>
          <span className="Forest_Name">
            {api.user.forest?.name
              ? api.user.forest?.name
              : "Data Repository Management"}
          </span>
        </div>
        {/* <div>
          <button onClick={clickUpdate}>update</button>
        </div> */}
        <div>
          <img
            src={require("../Assets/Forest_Images/Dashboard_Header.png")}
            alt=""
          />
        </div>
      </div>

      <Statistics_card pageLoading={loading} setPageLoading={setLoading} selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <TopSpecies_Card selectedYear={selectedYear}/>
        <Toptigers selectedYear={selectedYear}/>
      </div>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <UnClassified_Card />
        <Unidentified />
      </div>
      <div style={{ marginTop: "24px" }}>
        <RecentImages_Card />
      </div>
    </div>
  );
};

export default Dashboard;
