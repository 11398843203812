// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Forgot_Desc {
    display: inline-block;
}

.Forgot_Desc>p {
    width: 300px;
    font-weight: 400;
    color: #9A938A;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/CSS/Forgot.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".Forgot_Desc {\n    display: inline-block;\n}\n\n.Forgot_Desc>p {\n    width: 300px;\n    font-weight: 400;\n    color: #9A938A;\n    display: inline-block;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
