import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import TadobaContext from '../Context/TadobaContext'
import { url } from '../../Data/Data'
import Popup from 'reactjs-popup'
import MapAdd from './MapAdd'
import { useNavigate } from 'react-router-dom'
import Nodata from '../Nodata'
import LazyImage from '../LazyImage'
import { makeRequest } from '../../api'
const Map_Setup = () => {

  const [maps, setMaps] = useState([])
  const api = useContext(TadobaContext)
  const [render, setRender] = useState(1)
  const navigate = useNavigate()
  const getMaps = async () => {
    try {
      let token = null
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result= await makeRequest("get",`api/v1/map/map_list/`)
      api.setSettingMap(result.data.data)
    }
    catch (error) {
    }
  }

  useEffect(() => {
    getMaps()
  }, [render])


  const exceluploader = async (excel, name) => {
    let token = ""
    let csrftoken = ""
    let formdata = new FormData()
    formdata.append('file', excel)
    formdata.append('map_name', name)
    formdata.append('forest_id', 1)
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result= await makeRequest("post",`api/v1/map/map_upload_excel/`,formdata)
      alert("excel uploaded suceessfully")
      setRender(render + 1)
    } catch (error) {
      alert("Error While Uploading Excel")
    }
  }
  const mapUploadHandler = async (excel, name, image) => {

    let token = ""
    const formdata = new FormData()
    formdata.append('forest_id', 1)
    formdata.append('map_name', name)
    formdata.append('map_image', image)
    let csrftoken = ""
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      if (localStorage.csrf) {
        csrftoken = localStorage.csrf;
      }
      const result=await makeRequest("post",`api/v1/map/map_add_image/`,formdata)
      alert("image uploaded suceessfully")
      exceluploader(excel, name)
    } catch (error) {
      alert("Error While Uploading An Image")
    }
  }

  const editMapHandler = (map_id) => {
    navigate(`./${map_id}`)
  }

  const deleteMapHandler = async (map_id) => {
    let token = ""

    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result=await makeRequest("delete",`api/v1/map/map_delete/?map_id=${map_id}`)
      alert("map deleted suceessfully")
      setRender(render + 1)
    } catch (error) {
      alert("Error While deleting map")
    }
  }

  return (
    <div className='Setting_User'>
      <div className="Setting_User_Header">
        <div><span>Map Setup</span></div>
        <div className='Setting_User_Attribute'>
          {/* <div className='role_search'>
            <input type="text" placeholder='Search by name, email id' />
            <img src={require('../../Assets/Icons/search_icon.png')} alt="" />
          </div> */}
          {/* <div className='role_filter'>
            <img src={require('../../Assets/Icons/filter.png')} alt="" />
          </div> */}
        {api.user.role.id===2?
          <Popup trigger={
            <div className='Setting_Add_Button'>
              <img src={require('../../Assets/Icons/plus.png')} alt="" />
              <span>Add</span>
            </div>
          } modal nested>
            {
              close => (
                <MapAdd mapUploadHandler={mapUploadHandler} close={close} />
              )
            }
          </Popup>:""
}
        </div>
      </div>
      <div className="Setting_Table">
        <div className="Setting_Table_Header">
          <span>Showing {api.settingMap.length} results</span>
        </div>
        {api.settingMap.length === 0 ?
          <Nodata /> :
          <div className="setting_map_content">
            {api.settingMap.map((value, key) => {
              return (
                <div className='Setting_Map_Block'>
                  <div className="setting_map">
                    {api.user.role.id === 2 ?
                      <div className='setting_map_button'>
                        <img src={require('../../Assets/Icons/Edit.png')} alt="" onClick={() => { editMapHandler(value.id) }} />
                        <img src={require('../../Assets/Icons/trash-2.png')} alt="" onClick={() => { deleteMapHandler(value.id) }} />
                      </div> : ""
                    }
                    <LazyImage src={value.map_image}  placeholderSrc={require("../../Assets/Icons/temp_img.png")} />
                    {/* <img src={value.map_image} alt="" /> */}
                  </div>
                  <div>
                    <span>{value.map_name}</span>
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}

export default Map_Setup