import React from 'react'
import AccessDenied from '../AccessDenied'


const RoleDeletePopup = (props) => {

    return (
        <div className='popupDeleteRole'>
            <div className='popupDeleteContent'>
                <div className="popupDeleteHeader">
                    <div>
                        <span>Delete User</span>
                        </div>
                        <div onClick={props.close}>
                            <img src={require('../../Assets/Icons/Close.png')} alt="" />
                        </div>
                </div>
                {props?.access==true?<AccessDenied/>:
                <>
                <div className="popupMainContent">
                    <span>Are you sure you want to delete {props.row.Name}?</span>
                </div>
                <div className="popupDeleteButton">
                    <button className='CancelButton'>Cancel</button>
                    <button className='DeleteButton' onClick={()=>{
                        props.deleteHandler([props.row.Email], props.close)}}>Delete</button>
                </div>
                </>}
            </div>
        </div>
    )
}

export default RoleDeletePopup