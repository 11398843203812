import React, { useContext, useState } from "react";
import Setting_User_Table from "../Components/Setting_User_Table";
import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import Popup from "reactjs-popup";
import TadobaContext from "../Components/Context/TadobaContext";
import { toast } from "react-toastify";
import { successToaster } from "../Data/Data";
import { warningToaster } from "../Data/Data";
import { makeRequest } from "../api";
import RecordConflict from "./RecordConflict";

const TigerConflicts = () => {
  const api = useContext(TadobaContext);
  const [uploadfile, setUploadfile] = useState(null);
  const [render, setRender] = useState(1);
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(true);
  const [searchItem, setSearchItem] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [TigerData, setTigerData] = useState([]);
  const [AllTigerData, setAllTigerData] = useState([]);
  const [scroll, setScroll] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchTiger();
  }, [page, render]);

  function search(searchTerm) {
    if (searchTerm === "") {
      setSearchValue(false);
    } else {
      setSearchValue(true);
    }
    const filtered = TigerData.filter((product) => {
      return product["Tiger Name"]
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setFilteredProducts(filtered);
  }

  const searchHandler = (event) => {
    setSearchItem(event.target.value);
    search(event.target.value);
  };

  const AllTigerSetup = (
    tigers,
    existingTigerData = [],
    existingAllTigerData = []
  ) => {
    let result = [];
    let result2 = [];
    tigers.forEach((value) => {
      const dateString = value.conflict_date;
      const dateObj = new Date(dateString);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "UTC",
      };

      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        dateObj
      );

      const obj = {
        "Tiger Name": value.name ?? "NA",
        Age: value.age ?? "NA",
        Latitude: value.lat ?? "NA",
        Longitude: value.long ?? "NA",
        Range: value.range_name ?? "NA",
        Village: value.village_name ?? "NA",
        Image: value.image,
        "Conflict Date": formattedDate ?? "NA",
        Comments: value.comments ?? "NA",
      };
      const obj2 = {
        name: value.name ?? "NA",
        Age: value.age ?? "NA",
        Latitude: value.lat ?? "NA",
        Longitude: value.long ?? "NA",
        Range: value.range_name ?? "NA",
        Village: value.village_name ?? "NA",
        Image: value.image,
        "Conflict Date": formattedDate ?? "NA",
        Comments: value.comments ?? "NA",
        id: value.id,
      };

      result.push(obj);
      result2.push(obj2);
    });

    setTigerData([...existingTigerData, ...result]);
    setAllTigerData([...existingAllTigerData, ...result2]);
  };

  const recordAddHandler = async (close) => {
    close();
    setTigerData([]);
    setAllTigerData([]);
    setRender(0);
    setPage(1);
    setCurrentPage(0);
  };

  const fetchTiger = async () => {
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/identification/tiger_conflicts/?page=${page}&size=10`
      );

      console.log(result)
      const currentTigerData = TigerData;
      const currentAllTigerData = AllTigerData;
      setCount(result.data.count);
      AllTigerSetup(result.data.data, currentTigerData, currentAllTigerData);
      setCurrentPage(currentPage + 1);
      setScroll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const viewHandler = (data) => {
    console.log(data);
  };

  useEffect(()=>{
    console.log(TigerData)
  },[TigerData])

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Tiger Conflicts</span>
        </div>
        <div className="Setting_User_Attribute">
          <Popup
            trigger={
              <div className="Setting_Add_Button">
                <span>Record Conflict</span>
              </div>
            }
            modal
            nested
          >
            {(close) => (
              <RecordConflict
                render={render}
                recordAddHandler={recordAddHandler}
                setRender={setRender}
                close={close}
              />
            )}
          </Popup>
          {/* <div className="role_search">
            <input
              type="text"
              placeholder="Search by name"
              value={searchItem}
              onChange={searchHandler}
            />
            <img src={require("../Assets/Icons/search_icon.png")} alt="" />
          </div> */}
        </div>
      </div>
      <Setting_User_Table
        scroll={scroll}
        total={true}
        count={count}
        loading={loading}
        setLoading={setLoading}
        setPage={setPage}
        viewHandler={viewHandler}
        delete={true}
        data={searchValue ? filteredProducts : TigerData}
        currentPage={currentPage}
        page={page}
        type="Tiger Conflicts"
      />
      {/* <ToastContainer rtl={false} style={{ height: "10px" }} /> */}
    </div>
  );
};

export default TigerConflicts;
