import React, { useState } from "react";
import Statistics_card from "../Components/Cards/Statistics_card";
import TopSpecies_Card from "../Components/Cards/TopSpecies_Card";
import AverageUploadLineGraph from "../Components/Cards/AverageUploadLineGraph";
import Unidentified from "../Components/Cards/Unidentified";
import Toptigers from "../Components/Cards/Toptigers";
import AlertReport_Card from "../Components/Cards/AlertReport_Card";
import Upload_Timeline from "../Components/Cards/Upload_Timeline";
import { makeRequest } from "../api";
const Statistics = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(localStorage.getItem("selectedYearData") || 'all');

  const getImages = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/statistic/update_stats/`);
      setData(result.data.data);
      localStorage.removeItem("recent_images");
      localStorage.removeItem("unclassified");
      localStorage.removeItem("top_tigers");
      localStorage.removeItem("top_species");
      localStorage.removeItem("statistic_classification");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="Dashboard">
      <div className="Setting_User">
        <div className="Setting_User_Header">
          <div>
            <span>Statistics</span>
          </div>
          <div className="Setting_User_Attribute">
            {/* <div className='role_filter'>
              <img src={require('../Assets/Icons/filter.png')} alt="" />
            </div> */}
            {/* <div className="role_filter">
              <img src={require("../Assets/Icons/Calander.png")} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "24px" }}>
        <Statistics_card pageLoading={loading} setPageLoading={setLoading} selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
      </div>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <TopSpecies_Card selectedYear={selectedYear} />
        <Toptigers selectedYear={selectedYear}/>
      </div>
      <div style={{ marginTop: "24px" }}>
        <Upload_Timeline />
      </div>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <AverageUploadLineGraph />
        <Unidentified />
      </div>
      {/* <div style={{ marginTop: "24px" }}>
        <AlertReport_Card />
      </div> */}
    </div>
  );
};

export default Statistics;
