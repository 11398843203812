import React, { useEffect, useState } from "react";
import { makeRequest } from "../../api";
import { Select, ConfigProvider } from "antd";
import { RiEdit2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import { ClipLoader } from "react-spinners";
import Popup from "reactjs-popup";

const EditMultipleImages = ({
  exportedIds,
  onReset,
  setExportedImages,
  setExportedIds,
  allImages,
  listData,
  setListData,
  groupTiger,
  species_id,
  species,
}) => {
  const [speciesList, setSpeciesList] = useState([]);
  const [dropDownState, setDropDownState] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState(false);
  const [tigerList, setTigerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPopupLoading, setIsPopupLoading] = useState(false);

  const handleClick = () => {
    setDropDownState(true);
  };

  useEffect(() => {
    const fetchSpecies = async () => {
      setLoading(true);
      try {
        const result = await makeRequest("get", `api/v1/species/species/`);
        setSpeciesList(result.data.data);
      } catch (error) {
        setSpeciesList([]);
        console.error("Error fetching species:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchTiger = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;

        const names = result_data
          .map((tiger) => {
            return {
              value: tiger.id,
              label: tiger.name,
            };
          })
          .filter(Boolean);

        setTigerList(names);
      } catch (error) {
        toast.success("Error While getting Tiger data", warningToaster);
      }
    };

    if (dropDownState) {
      if (groupTiger) {
        fetchTiger();
      } else {
        fetchSpecies();
      }
    }
  }, [dropDownState]);

  const handleSpeciesChange = (value) => {
    setSelectedSpecies(value);
  };

  const onConfirm = () => {
    saveHandler();
  };

  const saveHandler = async () => {
    setLoading(true);
    setIsPopupLoading(true);
    // for when user is trying to reassign the same species
    if (Number(species_id) === selectedSpecies && !allImages) {
      setLoading(false);
      toast.success(
        `You're already viewing the selected species. Please choose a different species to proceed.`,
        {
          ...warningToaster,
          autoClose: 2000,
        }
      );
      return;
    }
    try {
      const dataToSend = exportedIds.map((imageId) => ({
        image_id: imageId,
        species_type_id: selectedSpecies,
      }));
      await makeRequest(
        "post",
        `api/v1/classification/classify_species_manually/`,
        { data: dataToSend }
      );

      setDropDownState(false);
      setExportedImages([]);
      setExportedIds([]);
      onReset();
      toast.success(`Successfully Edited Images`, {
        ...warningToaster,
        autoClose: 2000,
      });
      if (!allImages) {
        const filteredArray = listData.filter(
          (obj) => !exportedIds.includes(obj.Name)
        );
        setListData(filteredArray);
      } else if (allImages) {
        // All images, handle for filtered images
        if (
          species.length !== 0 &&
          !species.some((element) => element === selectedSpecies)
        ) {
          const filteredArray = listData.filter(
            (obj) => !exportedIds.includes(obj.Name)
          );
          setListData(filteredArray);
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.success(`Images Edit Fail`, {
        ...warningToaster,
        autoClose: 2000,
      });
    } finally {
      setIsPopupLoading(false);
    }
  };
  return (
    <div className="Multiple_Images">
      {dropDownState ? (
        <div className="Multiple_Images_Dropdown">
          <label htmlFor="">
            {groupTiger ? "Select Tiger" : "Select Species"}
          </label>
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  fontSize: 12,
                },
              },
            }}
          >
            <Select
              options={
                groupTiger
                  ? tigerList.map((tiger) => ({
                      label: tiger.label,
                      value: tiger.value,
                    }))
                  : speciesList.map((species) => ({
                      label: species.name,
                      value: species.id,
                    }))
              }
              onChange={handleSpeciesChange}
              style={{ width: 170 }}
              loading={loading}
              allowClear={true}
              placeholder="Select"
            />
          </ConfigProvider>
          <div className="Submit_Multiple_Btn">
            <button onClick={onConfirm}>Confirm</button>
          </div>
        </div>
      ) : (
        <div className="Edit_Multiple_Btn" onClick={handleClick}>
          <RiEdit2Fill />
        </div>
      )}
      <Popup
        open={isPopupLoading}
        modal
        closeOnDocumentClick={false}
        closeOnEscape={false}
        className="custom-popup"
      >
        <ClipLoader
          color={"black"}
          loading={isPopupLoading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Popup>
    </div>
  );
};

export default EditMultipleImages;
