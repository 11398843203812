// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #594F42;
}

.Forest_Name {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #3F3325;
}

.Dashboard {
    padding: 40px;
    width: 100%;
    height: 90vh;
    overflow: auto;
}

.Dashboard::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb{
    background: #D9D9D9;
    border-radius: 10px;
}

.Dashnoard_Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Dashboard_statistics {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(148, 93, 63, 0.05);
    border-radius: 8px;
    padding: 1%;
}

.dst_heading {
    display: flex;
    justify-content: space-between;
}

`, "",{"version":3,"sources":["webpack://./src/CSS/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,gDAAgD;IAChD,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".welcome {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    color: #594F42;\n}\n\n.Forest_Name {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 36px;\n    color: #3F3325;\n}\n\n.Dashboard {\n    padding: 40px;\n    width: 100%;\n    height: 90vh;\n    overflow: auto;\n}\n\n.Dashboard::-webkit-scrollbar{\n    width: 6px;\n    height: 6px;\n}\n\n::-webkit-scrollbar-thumb{\n    background: #D9D9D9;\n    border-radius: 10px;\n}\n\n.Dashnoard_Header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.Dashboard_statistics {\n    width: 100%;\n    background: #FFFFFF;\n    box-shadow: 0px 4px 16px rgba(148, 93, 63, 0.05);\n    border-radius: 8px;\n    padding: 1%;\n}\n\n.dst_heading {\n    display: flex;\n    justify-content: space-between;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
