import React from "react";
import Forgot_Password from "../Components/Login/Forgot_Password";
import Login_Details from "../Components/Login/Login_Details";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Reset_Password from "../Components/Login/Reset_Password";

const Login = ({ setLogin }) => {
  const [email, setEmail] = useState("");

  return (
    <div className="Login">
      <div
        style={{
          textAlign: "center",
          position: "relative",
          marginTop: "40px",
        }}
      >
        <span style={{ fontStyle: "italic", color: "#38514C" }}>
          "Preserve the Wild, Share the Wonder: Your Forest Memories, Safely
          Stored."
        </span>
      </div>
      <div
        className="Login_Card"
        style={{
          marginTop: "-50px",
        }}
      >
        <Routes>
          <Route
            path="/*"
            element={
              <Login_Details
                email={email}
                setEmail={setEmail}
                setLogin={setLogin}
              />
            }
          />
          <Route
            path="/forgot_password"
            element={<Forgot_Password email={email} setEmail={setEmail} />}
          />
          <Route
            path="/reset_password/:token/:uid"
            element={<Reset_Password />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Login;
