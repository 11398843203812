import React from 'react'

const SmallLoader = () => {
    return (
        <div className='small_loader_parent'>
            <div className="small_loader">
                <img src={require("../Assets/Icons/loader.png")} alt="" />
            </div>
        </div>
    )
}

export default SmallLoader