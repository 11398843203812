import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const LazyImage = ({ src, placeholderSrc, classProp, app, alt }) => {
  let image_key = localStorage.getItem("image_key");
  const [imageSrc, setImageSrc] = useState(placeholderSrc);
  const [viewFullScreen, setFullScreen] = useState(false);

  async function fetchAndConvertImageToDataUri(imageUrl) {
    try {
      let decryptUrl = await decryptImageUrl(imageUrl, image_key);

      setImageSrc(decryptUrl);
    } catch (error) {
      console.log(error);
    }
  }

  function decryptImageUrl(encryptedUrl, key) {
    key = CryptoJS.enc.Utf8.parse(key.slice(0, 32)); // Truncate or expand key to 32 bytes
    const encodedData = CryptoJS.enc.Base64.parse(encryptedUrl);
    const iv = CryptoJS.lib.WordArray.create(encodedData.words.slice(0, 4));
    const data = CryptoJS.lib.WordArray.create(encodedData.words.slice(4));

    const decryptedData = CryptoJS.AES.decrypt({ ciphertext: data }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }

  useEffect(() => {
    fetchAndConvertImageToDataUri(src);
  }, [src]);

  // console.log(imageSrc)

  return (
    <>
      {viewFullScreen ? (
        <img
          src={imageSrc}
          onClick={() => {
            setFullScreen(false);
          }}
          className="ImgFullScreen"
          alt="Loading..."
        />
      ) : (
        <img
          src={imageSrc}
          className={classProp}
          style={
            app === "tiger"
              ? {
                  width: "100%",
                  height: alt === "Popup Tiger" ? "60vh" : "auto",
                }
              : app === "tiger_view"
              ? {
                  marginLeft: "5px",
                  marginTop: "5px",
                  width: "100%",
                  height: "auto",
                }
              : app === "conflict"
              ? { width: "30%", height: "80%" }
              : {}
          }
          onClick={() => {
            setFullScreen(true);
          }}
          alt="Loading...."
        />
      )}
    </>
  );
};

export default LazyImage;
