import React from 'react'
import { useState } from 'react'
const GeolocationDeletePopup = (props) => {

  const [deleteId, setDeleteId] = useState([props.row["Camera ID"]])
  return (
    <div className='popupDeleteRole'>
            <div className='popupDeleteContent'>
                <div className="popupDeleteHeader">
                    <div>
                        <span>Delete Geolocation</span>
                        </div>
                        <div onClick={props.close}>
                            <img src={require('../../Assets/Icons/Close.png')} alt="" />
                        </div>
                </div>
                <div className="popupMainContent">
                    <span>Are you sure you want to delete the Geolocation</span>
                </div>
                <div className="popupDeleteButton">
                    <button className='CancelButton' onClick={props.close}>Cancel</button>
                    <button className='DeleteButton' onClick={()=>{
                        props.deleteHandler(deleteId)
                        props.close()
                        }}>Delete</button>
                </div>
            </div>
        </div>
  )
}

export default GeolocationDeletePopup