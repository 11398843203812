import React from "react";
import { useNavigate } from "react-router-dom";
import { SidebarData } from "../Data/Data";
import { useState } from "react";
import TadobaContext from "./Context/TadobaContext";
import { useContext } from "react";
import { url } from "../Data/Data";
import LazyImage from "./LazyImage";
import { makeRequest } from "../api";
import { ClipLoader } from "react-spinners";
const Sidebar = (props) => {
  const api = useContext(TadobaContext);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Logout = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/account/logout/`);
      localStorage.clear();
      navigate("/");
      window.location.reload();
    } catch (error) {}
  };
  const logoutHandler = () => {
    Logout();
  };

  const clickUpdate = () => {
    getImages();
  };

  const getImages = async () => {
    setLoading(true);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/statistic/update_stats/`);
      setData(result.data.data);
      localStorage.removeItem("recent_images");
      localStorage.removeItem("unclassified");
      localStorage.removeItem("top_tigers");
      localStorage.removeItem("top_species");
      localStorage.removeItem("statistic_classification");
      navigate("/dashboard");
      setTimeout(() => {
        window.location.reload();
        setLoading(false);
      }, 10000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-mask">
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              color={"green"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ marginTop: "10px", color: "green" }}>
              Updating Count.....
            </p>
          </div>
        </div>
      ) : (
        <div className={api.expanded ? "Sidebar" : "Sidebar collapsed"}>
          <ul>
            {SidebarData.map((value, key) => {
              if (window.location.pathname === "/" && value.link === "./") {
                return (
                  <li className="sideBarListItem" key={key}>
                    <div
                      className="sideBarItem"
                      onClick={() => {
                        navigate(value.link);
                      }}
                      id="active"
                    >
                      <div className="sideBarItemIcon">
                        <img src={value.colored_icon} alt="" />
                      </div>
                      <div
                        className={
                          api.expanded
                            ? "sideBarItemtitle"
                            : "sidebar_content_collapsed"
                        }
                      >
                        <span>{value.title}</span>
                      </div>
                    </div>
                  </li>
                );
              }
              return (
                <li className="sideBarListItem" key={key}>
                  <div
                    className="sideBarItem"
                    onClick={() => {
                      if (
                        value.title === "Images" ||
                        value.title === "Settings" ||
                        value.title === "Map View" ||
                        value.title === "Find Tiger"
                      ) {
                        api.setExpanded(false);
                      } else {
                        api.setExpanded(true);
                      }
                      navigate(value.link);
                    }}
                    id={
                      window.location.pathname.includes(value.link)
                        ? "active"
                        : ""
                    }
                  >
                    <div className="sideBarItemIcon">
                      {window.location.pathname.includes(value.link) ? (
                        <img src={value.colored_icon} alt="" />
                      ) : (
                        <img src={value.icon} alt="" />
                      )}
                    </div>
                    <div
                      className={
                        api.expanded
                          ? "sideBarItemtitle"
                          : "sidebar_content_collapsed"
                      }
                    >
                      <span>{value.title}</span>
                    </div>
                  </div>
                </li>
              );
            })}
            <li className="sideBarListItem">
              <div
                className="sideBarItem"
                onClick={() => {
                  navigate("/profile");
                }}
                id={
                  window.location.pathname.includes("/profile") ? "active" : ""
                }
              >
                <div className="sideBarItemIcon" style={{ height: "20px" }}>
                  {api.user.image === null || api.user.image === "" ? (
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={require("../Assets/Icons/face.png")}
                      alt=""
                    />
                  ) : (
                    <div className="profile_picture" style={{ height: "20px" }}>
                      <LazyImage
                        src={api.user.image}
                        placeholderSrc={require("../Assets/Icons/temp_img.png")}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={
                    api.expanded
                      ? "sideBarItemtitle"
                      : "sidebar_content_collapsed"
                  }
                >
                  <span>{api.user.name}</span>
                </div>
              </div>
            </li>
            <li className="sideBarListItem">
              <div className="sideBarItem" onClick={logoutHandler}>
                <div className="sideBarItemIcon" style={{ height: "20px" }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={require("../Assets/Icons/Logout.png")}
                    alt=""
                  />
                </div>
                <div
                  className={
                    api.expanded
                      ? "sideBarItemtitle"
                      : "sidebar_content_collapsed"
                  }
                >
                  <span>Logout</span>
                </div>
              </div>
            </li>
            <li className="sideBarListItem">
              <div className="sideBarItem">
                <div
                  className={
                    api.expanded
                      ? "sideBarItemtitle"
                      : "sidebar_content_collapsed"
                  }
                >
                  <button
                    className="Setting_Update_Button"
                    onClick={clickUpdate}
                  >
                    Update Count
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div
            className="Sidebar_Collapse"
            onClick={() => {
              api.setExpanded(!api.expanded);
            }}
          >
            {api.expanded ? (
              <img src={require("../Assets/Icons/State=Expanded.png")} alt="" />
            ) : (
              <img
                className="collapse_icon"
                src={require("../Assets/Icons/State=Collapsed.png")}
                alt=""
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
