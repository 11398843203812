import React from "react";
import LazyImage from "../../Components/LazyImage";

const TigerGridImage = ({ tigerData }) => {
  //   console.log(tigerData);
  return (
    <div className="tiger-image-grid">
      <div key={tigerData[0]?.id} className="tiger-image-item1">
        <LazyImage
          src={tigerData[0]?.image}
          placeholderSrc={require("../../Assets/Icons/temp_img.png")}
        />
      </div>
      <div className="tiger-image-container-item2">
        <div key={tigerData[1]?.id} className="tiger-image-item2">
          <LazyImage
            src={tigerData[1]?.image}
            placeholderSrc={require("../../Assets/Icons/temp_img.png")}
          />
        </div>
        {tigerData[2] ? (
          <div key={tigerData[2]?.id} className="tiger-image-item2 leftimg">
            <LazyImage
              src={tigerData[2]?.image}
              placeholderSrc={require("../../Assets/Icons/temp_img.png")}
            />
          </div>
        ) : (
          <div className="tiger-image-item2 rightimg">
            <img
              src={require("../../Assets/Icons/temp_img.png")}
              alt="Placeholder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TigerGridImage;
