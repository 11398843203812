import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import TadobaContext from "./Context/TadobaContext";
import Nodata from "./Nodata";
import LazyImage from "./LazyImage";
import SmallLoader from "./SmallLoader";
import { useRef } from "react";

const ImagesTable = (props) => {
  const [columnName, setColumnName] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [partialCheck, setPartialCheck] = useState(false);
  const [render, setRender] = useState(1);
  const [deleteId, setDeleteId] = useState(props.exportedImages);
  const api = useContext(TadobaContext);
  const [Checked, setChecked] = useState(props.data);
  const [showCheckBox, setShowCheckBox] = useState(true);
  const [exportedIds, setExportedIds] = useState([]);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setPartialCheck(false);
    Checked.map((value, key) => {
      if (value.checked === true) {
        setPartialCheck(true);
        setAllCheck(true);
      }
      if (value.checked === false) {
        setAllCheck(false);
      }
    });
  }, [render]);

  useEffect(() => {
    if (api.startDelete === false) {
      setExportedIds([]);
    }
  }, [api.startDelete]);

  useEffect(() => {
    setChecked(props.data);
    props.data.map((value, key) => {
      if (value.checked === true) {
        setPartialCheck(true);
      }
      if (value.checked === false) {
        setAllCheck(false);
      }
    });
  }, [props.data]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollContainerRef.current;
    if (props.data.length >= props.count) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (!props.scroll) {
        props.setPage(props.currentPage + 1);
      }
    }
  }, 0);

  const AllCheckBoxChageHandler = () => {
    const list = [];
    const newData = [...Checked];
    if (allCheck === true) {
      setAllCheck(false);
      props.setAllCheck(false);
      const tempData = newData.map((value, key) => {
        value.checked = false;
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    } else {
      const tempData = newData.map((value, key) => {
        setAllCheck(true);
        props.setAllCheck(true);
        value.checked = true;
        list.push(value["image"]);
        return value;
      });
      setChecked(tempData);
      props.setData(tempData);
    }
    setDeleteId(list);
    props.setExportedImages(list);
    setRender(render + 1);
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/find_tiger" || path === "/find_tiger/") {
      setShowCheckBox(false);
    }
  }, []);

  const checkboxChangeHandler = (row) => {
    const newData = [...Checked];
    const defaultData = newData.map((value, key) => {
      if (row["image"] === value["image"]) {
        value.checked = !value.checked;
        if (value.checked) {
          setDeleteId([...deleteId, row["image"]]);
          props.setExportedImages([...deleteId, row["image"]]);
          setExportedIds([...exportedIds, row["id"]]);
          props.setExportedIds([...exportedIds, row["id"]]);
        }
        if (value.checked === false) {
          const index = deleteId.indexOf(row["image"]);
          deleteId.splice(index, 1);
          props.setExportedImages(deleteId);
          setDeleteId(deleteId);
          exportedIds.splice(index, 1);
          setExportedIds(exportedIds);
          props.setExportedIds(exportedIds);
        }
        return value;
      } else return value;
    });
    setChecked(defaultData);
    props.setData(defaultData);
    setRender(render + 1);
  };
  useEffect(() => {
    let array = new Array();
    for (let key in props.template) {
      array.push(key);
      setColumnName(array);
    }
    console.log(array);
  }, [props.template]);

  return (
    <div className="Setting_Table">
      <div className="Setting_Table_Header">
        <span>
          Showing {props.data.length} results of {props.count}{" "}
          {props.exportedImages.length > 0 && !allCheck
            ? `(${props.exportedImages.length} Selected)`
            : allCheck
            ? `(All ${props.count} Selected)`
            : ""}
        </span>
        <div style={{ display: "flex", gap: "10px" }}>
          {partialCheck ? (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="deselect"
                onClick={() => {
                  setAllCheck(false);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                Deselect All
              </span>
              <div
                onClick={() => {
                  props.deleteHandler(deleteId);
                  setAllCheck(false);
                  setDeleteId([]);
                  setPartialCheck(false);
                  setChecked(
                    props.data.map((row, key) => {
                      const obj = { ...row };
                      obj.checked = false;
                      return obj;
                    })
                  );
                }}
              >
                <img
                  src="{require('../Assets/Icons/trash.png')}"
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {props.table !== "map" ? (
            <div className="ViewSwitcher">
              {props.grid ? (
                <>
                  <img
                    src={require("../Assets/Icons/highlighted_grid.png")}
                    onClick={() => {
                      props.setGrid(true);
                    }}
                    alt=""
                  />
                  <img
                    src={require("../Assets/Icons/list_view.png")}
                    onClick={() => {
                      props.setGrid(false);
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img
                    src={require("../Assets/Icons/grid_view.png")}
                    onClick={() => {
                      props.setGrid(true);
                    }}
                    alt=""
                  />
                  <img
                    src={require("../Assets/Icons/highlighted_list.png")}
                    onClick={() => {
                      props.setGrid(false);
                    }}
                    alt=""
                  />
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {props.data.length === 0 ? (
        <Nodata />
      ) : (
        <>
          {props.loading === true ? (
            <SmallLoader />
          ) : (
            <div
              className="Setting_Table_Layout"
              ref={scrollContainerRef}
              onScroll={handleScroll}
            >
              <table>
                <tr className="heading_row">
                  {showCheckBox &&
                    api.user.role.id !== 3 &&
                    (props.type !== "Users" || api.user.role.id !== 1) && (
                      <td className="row_check">
                        <div
                          className="checkbox"
                          onClick={() => {
                            AllCheckBoxChageHandler();
                          }}
                        >
                          {partialCheck ? (
                            <>
                              {allCheck ? (
                                <img
                                  src={require("../Assets/Icons/Checked_Box.png")}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require("../Assets/Icons/partialcheck.png")}
                                  alt=""
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={require("../Assets/Icons/checkbox.png")}
                              alt=""
                            />
                          )}
                        </div>
                      </td>
                    )}
                  {/* Check if props.type is 'nearby', if so, render each column separately */}
                  {props.type === "nearby" ? (
                    <>
                      {columnName.map((value, index) => (
                        <td key={index}>
                          <div>
                            <span>{value}</span>
                          </div>
                        </td>
                      ))}
                    </>
                  ) : (
                    columnName.map((value, index) => {
                      if (value === "Tiger Id" && props.speciesID !== 427) {
                        return <React.Fragment key={index} />;
                      }

                      return (
                        <td key={index}>
                          <div>
                            {value === "Name" ? (
                              <span>Id</span>
                            ) : (
                              <span>{value}</span>
                            )}
                          </div>
                        </td>
                      );
                    })
                  )}
                </tr>
                {props.data.map((row, key) => {
                  return (
                    <tr className="main_row" key={key}>
                      {showCheckBox &&
                        api.user.role.id !== 3 &&
                        (props.type !== "Users" || api.user.role.id !== 1) && (
                          <td className="row_check">
                            <div
                              className="checkbox"
                              onClick={() => {
                                checkboxChangeHandler(row);
                              }}
                            >
                              {Checked[key]?.checked ? (
                                <img
                                  src={require("../Assets/Icons/Checked_Box.png")}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require("../Assets/Icons/checkbox.png")}
                                  alt=""
                                />
                              )}
                            </div>
                          </td>
                        )}
                      {columnName.map((value, index) => {
                        if (value === "Name") {
                          return (
                            <td key={index}>
                              <div className="rowImgCombine">
                                <div className="row_Img">
                                  {row.image === null || row.image === "" ? (
                                    <img
                                      src={require("../Assets/Icons/person.png")}
                                      alt=""
                                    />
                                  ) : (
                                    <LazyImage
                                      src={row.image}
                                      image_key={row.image_key}
                                      placeholderSrc={require("../Assets/Icons/temp_img.png")}
                                    />
                                  )}
                                </div>
                                <div>
                                  <span>{row[value]}</span>
                                </div>
                              </div>
                            </td>
                          );
                        } else if (
                          value === "Tiger Id" &&
                          props.speciesID !== 427
                        ) {
                          return <></>;
                        } else {
                          return (
                            <td
                              className={
                                value === "Date and Time" ? "viewControls" : ""
                              }
                              key={index}
                            >
                              <div style={{ position: "relative" }}>
                                <span>{row[value]}</span>
                                {/* {value==="Date and Time"?
                                                            <div className='image_controls' style={{position:"absolute", top:"0", right:"0", visibility:"hidden"}}>
                                                                <img  src={require("../Assets/Icons/Edit.png")} alt="" />
                                                                <img  src={require("../Assets/Icons/dots.png")} alt="" />
                                                            </div>:""
                                                            } */}
                              </div>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
                {props.scroll ? (
                  <div
                    aria-colspan={10}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      left: "60%",
                      bottom: "20%",
                    }}
                  >
                    {" "}
                    <td colSpan={4}>
                      <SmallLoader />
                    </td>
                  </div>
                ) : (
                  ""
                )}
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImagesTable;
