import React, { useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import LazyImage from "../LazyImage";
import axios from "axios";
import { url } from "../../Data/Data";
import { useEffect } from "react";
import Permissions from "./Permissions";
import AccessDenied from "../AccessDenied";
import { ToastContainer } from "react-toastify";
import { makeRequest } from "../../api";
import { Select } from "antd";
const UserEdit = (props) => {
  const [warning, setWarning] = useState("This field is required");
  const [nameDisabled, setNameDisabled] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [passwordDisabled, setPasswordDisabled] = useState(false);
  const [mobileDisabled, setMobileDisabled] = useState(false);
  const api = useContext(TadobaContext);
  const user = api.users.filter(function (item) {
    return item.email === props.row.Email;
  });
  const [permission, setPermission] = useState(true);
  const [img, setImg] = useState(user[0].profile_picture);
  const [name, setName] = useState(user[0].name);
  const [email, setEmail] = useState(user[0].email);
  const [mobile, setMobile] = useState(user[0].mobile);
  // const [role, setRoles] = useState([])
  // const [role_id, setRole_id] = useState(user[0].role.id)
  const [drag, setDrag] = useState(false);
  const [image_preview, setImagePreview] = useState(user[0].profile_picture);
  const [role, setRoles] = useState([]);
  const [role_id, setRole_id] = useState(user[0].role.id);
  const [villageData, setVillageData] = useState([]);
  const [selectedVillage, setSelectedVillages] = useState([]);
  const [rangesData, setRangesData] = useState([]);
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [selectedArea, setSelectedArea] = useState(); //
  const [areaOptions, setAreaOption] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredRoleOptions, setFilteredRoleOptions] = useState([]);

  const nameChangeHandler = (event) => {
    setNameDisabled(false);
    setName(event.target.value);
  };

  // const fetchRole = async () => {
  //     let token = ""
  //     try {
  //         if (localStorage.token) {
  //             token = localStorage.token;
  //         }
  //         const result= await makeRequest("get",`api/v1/account/role_list/`)
  //         setRoles(result.data.data)

  //     }
  //     catch (error) {
  //     }
  // }
  // const api = useContext(TadobaContext);
  // const user = api.users.filter(function (item) {
  //     return item.email === props.row.Email;
  // });
  const [access, setAccess] = useState(() => {
    const filteredUsers = api.users.filter(function (item) {
      return item.email === props.row.Email;
    });
    if (filteredUsers.length > 0 && "access" in filteredUsers[0])
      return filteredUsers[0].access;
    else {
      return [];
      // setPermission(false);
    }
  });

  useEffect(() => {
    const getRangesAndVillageAndRole = async () => {
      let token = "";
      try {
        const resultVillage = await makeRequest(
          "get",
          "/api/v1/virtualwall/villages/"
        );
        const resultRanges = await makeRequest(
          "get",
          "/api/v1/map/range_list/"
        );
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/account/role_list/`);

        const tempVillageData = resultVillage.data.data;
        const tempRangeData = resultRanges.data.data;

        setVillageData(tempVillageData);
        setRangesData(tempRangeData);
        setRoles(result.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getRangesAndVillageAndRole();
  }, []);

  useEffect(() => {
    if (selectedVillage.length > 0 || selectedRanges.length > 0) {
      setCurrentPage(0);
      setData([]);
      setListData([]);
      setLoading(true);
      setPage(1);
    }
  }, [selectedVillage, selectedRanges]);

  const villageOptions = villageData.map((village) => ({
    label: village.name,
    value: village.beat.id,
  }));

  const rangesOption = rangesData.map((species) => ({
    label: species.name,
    value: species.id,
  }));

  // const roleOptions = role.map((role) => ({
  //   label: role.name,
  //   value: role.id,
  // }));
  const roleOptions = role
    .map((role) => ({
      label: role.name,
      value: role.id,
    }))
    .sort((a, b) => a.value - b.value);

  useEffect(() => {
    // Filter out the Admin role if the user's role ID is 1
    if (api.user.role.id === 1) {
      setFilteredRoleOptions(
        roleOptions.filter((role) => role.label !== "Backend admin")
      );
    } else {
      setFilteredRoleOptions(roleOptions);
    }
  }, [api.user.role.id, roleOptions]);

  console.log(role);

  const villageChangeHandler = (event) => {
    setSelectedVillages(event);
    console.log(selectedVillage);
  };

  const rangeChangeHandler = (event) => {
    setSelectedRanges(event);
    console.log(selectedRanges);
  };

  let areaChangeHandler = (event) => {
    setSelectedArea(event);
    console.log(selectedArea);
  };

  // useEffect(() => {
  //     fetchRole()
  // }, [])

  // const roleOptions = role.map((role) => ({
  //     label: role.name,
  //     value: role.id,
  //   }));

  const emailChangeHandler = (event) => {
    setEmailDisabled(false);
    setEmail(event.target.value);
  };

  const mobileChangeHandler = (event) => {
    if (event.target.value > 9999999999) {
      setMobileDisabled(true);
      setWarning("Enter a valid phone number");
      setMobile(event.target.value);
    } else {
      setMobileDisabled(false);
      setMobile(event.target.value);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    directory: true,
    onDragOver: () => {
      setDrag(true);
    },
    onDragLeave: () => {
      setDrag(false);
    },
    onDrop: (acceptedFiles) => {
      setImg(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImagePreview(acceptedFiles[0].preview);
      setDrag(false);
    },
  });

  const roleChangeHandler = (event, value) => {
    setRole_id(event);
    console.log(event);
  };

  const editHandler = () => {
    if (name === "") {
      setNameDisabled(true);
      if (email === "") {
        setEmailDisabled(true);
        if (mobile === "") {
          setMobileDisabled(true);
        }
      }
    } else if (email === "") {
      setEmailDisabled(true);
      if (mobile === "") {
        setMobileDisabled(true);
      }
    } else if (mobile === "") {
      setMobileDisabled(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("role_id", role_id);
      if (img !== null && typeof img !== "string") {
        formData.append("profile_image", img[0]);
      }
      formData.append("user_id", user[0].id);
      props.editHandler(formData, props.close);
    }
  };

  // const [access, setAccess] = useState(() => {
  //     const filteredUsers = api.users.filter(function (item) {
  //       return item.email === props.row.Email;
  //     });
  //     if(filteredUsers.length > 0 && 'access' in filteredUsers[0]) return filteredUsers[0].access
  //     else{
  //       return []
  //       // setPermission(false);
  //     }
  //   });

  // console.log(role);
  return (
    <>
      {api.user.role.id !== 2 || permission === false || access.length === 0 ? (
        <div className="PopupEditRole">
          <div className="popupEditContent">
            <div className="popupEditHeader">
              <div>
                <span>Edit User</span>
              </div>
              <div onClick={props.close}>
                <img src={require("../../Assets/Icons/Close.png")} alt="" />
              </div>
            </div>
            {props?.access ? (
              <AccessDenied />
            ) : (
              <>
                <div className="popupMainEditContent">
                  <div className="EditImage" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="ImgCircle">
                      {img === null || img === "" ? (
                        <img
                          src={require("../../Assets/Icons/person.png")}
                          alt=""
                        />
                      ) : (
                        <LazyImage
                          src={image_preview}
                          placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                        />
                      )}
                    </div>
                    <div className="ImgEdit">
                      <img
                        src={require("../../Assets/Icons/img_edit.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="EditField">
                    <label htmlFor="name">Person name</label>
                    <input
                      type="text"
                      value={name}
                      name="name"
                      onChange={nameChangeHandler}
                    />
                    {nameDisabled ? (
                      <span className="warning" style={{ color: "red" }}>
                        {warning}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="EditField">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      value={email}
                      name="email"
                      onChange={emailChangeHandler}
                    />
                    {emailDisabled ? (
                      <span className="warning" style={{ color: "red" }}>
                        {warning}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="EditField">
                    <label htmlFor="Mobile No">Mobile No</label>
                    <input
                      type="text"
                      value={mobile}
                      name="mobile"
                      className={mobileDisabled ? "input_warning" : ""}
                      onChange={mobileChangeHandler}
                    />
                    {mobileDisabled ? (
                      <span className="warning" style={{ color: "red" }}>
                        {warning}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="EditField">
                  {/* {role.map((value, key) => {
                            if(api.user.role.id===1&&value.id===2)
                            {
                                return("")
                            }
                            return (
                                <>
                                    <input type="radio" id="role" name="role" value={value.id} checked={role_id === value.id} onChange={(event) => { roleChangeHandler(event, value) }} />
                                    <span for="role">{value.name}</span>
                                </>
                            )
                        })} */}
                  <label htmlFor="Mobile No">Role</label>
                  <div
                    className="DirectionDropdown"
                    style={{ marginTop: 0.5 + "em", marginBottom: 0.7 + "em" }}
                  >
                    <Select
                      className="alerts-select-placeholder"
                      showSearch
                      mode="single"
                      style={{ width: 320, height: 40 }}
                      placeholder="Roles"
                      optionFilterProp="children"
                      // loading={
                      //     (role !== null && role.length === 0) || loading
                      // }
                      size="medium"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={roleChangeHandler} //roleChangeHandler
                      options={filteredRoleOptions} //roleOptions
                      value={role_id}
                    />
                  </div>
                  <div className="EditField">
                    {role.map((value, key) => {
                      if (
                        user[0].role.id == 1 ||
                        user[0].role.id == 2 ||
                        user[0].role.id == 3 ||
                        user[0].role.id == 5
                      ) {
                        return "";
                      } else if (
                        user[0].role.id == value.id ||
                        user[0].role.id == 0
                      )
                        return (
                          <>
                            <label htmlFor="Mobile No">Area</label>
                            <div
                              className="DirectionDropdown"
                              style={{ marginTop: 0.5 + "em" }}
                            >
                              <Select
                                className="alerts-select-placeholder"
                                showSearch
                                mode="multiple"
                                style={{ width: 320, height: 40 }}
                                placeholder="Area"
                                optionFilterProp="children"
                                size="medium"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={areaChangeHandler} //roleChangeHandler
                                options={areaOptions} //roleOptions
                                value={selectedArea}
                              />
                            </div>
                          </>
                        );
                    })}
                  </div>
                </div>
                <div className="popupDeleteButtonUsers">
                  <button
                    className="CancelButton"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="DeleteButton"
                    onClick={() => {
                      editHandler();
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Permissions
          props={props}
          role={role}
          accessHandler={props.accessHandler}
          permission={permission}
          setPermission={setPermission}
        />
      )}
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </>
  );
};

export default UserEdit;
