// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nodata {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 80%;
}

.Nodata>span {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3F3325;
}`, "",{"version":3,"sources":["webpack://./src/CSS/Nodata.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".Nodata {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    justify-content: center;\n    align-items: center;\n    justify-items: center;\n    height: 80%;\n}\n\n.Nodata>span {\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    text-align: center;\n    color: #3F3325;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
