import React, { useState } from 'react'
import TadobaContext from '../Context/TadobaContext'
import { useContext } from 'react'
const GeoLocationEdit = (props) => {

    const api = useContext(TadobaContext)
    const row=api.Geolocations.filter(function(value){
        return value.camera["camera_name"]===props.row["Camera ID"]});
    const [compartment_no, setCompartment_no] = useState(props.row["Compartment no."])
    const [beat_no, setBeat_no] = useState(props.row["Beat no."])
    const [round_no, setRound_no] = useState(props.row["Round no."])
    const [range_no, setRange_no] = useState(props.row["Range no."])
    const [station_id, setStation_id] = useState(props.row["Station ID"])
    const [camera_id, setCamera_id] = useState(props.row["Camera ID"])
    const [latitude, setLatitude] = useState(row[0].camera.latitude)
    const [logitude, setLogitude] = useState(row[0].camera.longitude)

    const eventChangeHandler = (event) => {
        if (event.target.name === "compartment_no") {
            setCompartment_no(event.target.value)
        }
        if (event.target.name === "beat_no") {
            setBeat_no(event.target.value)
        }
        if (event.target.name === "round_no") {
            setRound_no(event.target.value)
        }
        if (event.target.name === "range_no") {
            setRange_no(event.target.value)
        }
        if (event.target.name === "station_id") {
            setStation_id(event.target.value)
        }
        if (event.target.name === "camera_id") {
            setCamera_id(event.target.value)
        }
        if (event.target.name === "latitude") {
            setLatitude(event.target.value)
        }
        if (event.target.name === "logitude") {
            setLogitude(event.target.value)
        }
    }








    let id = ""
    api.Geolocations.map((value, key) => {
        if (value.camera["camera_name"] === props.row["Camera ID"]) {
            id = value.camera["camera_id"]
        }
        return 0
    }
    )

    const body = {
        "camera_id": id,
        "compartment_name": compartment_no,
        "beat_name": beat_no,
        "range_name": range_no,
        "round_name": round_no,
        "station_name": station_id,
        "camera_name": camera_id,
        "latitude": latitude,
        "longitude": logitude
    }

    return (
        <div className='PopupEditRole'>
            <div className='popupEditContent'>
                <div className="popupEditHeader">
                    <div>
                        <span>Edit Geolocation</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                <div className="GeolocationEditContent">
                <div className='EditField'>
                        <label htmlFor="Range no.">Range no.</label>
                        <input type="text" id='range_no' name='range_no' value={range_no} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Round no.">Round no.</label>
                        <input type="text" id='round_no' name='round_no' value={round_no} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Beat no.">Beat no.</label>
                        <input type="email" id='beat_no' name='beat_no' value={beat_no} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Compartment no.">Compartment no.</label>
                        <input type="text" id='compartment_no' name='compartment_no' value={compartment_no} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Station ID">Station ID</label>
                        <input type="text" id='station_id' name='station_id' value={station_id} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Camera ID">Camera ID</label>
                        <input type="text" id='camera_id' name='camera_id' value={camera_id} onChange={eventChangeHandler} />
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Lattitude">Latitude</label>
                        <input type="text" id='Latitude' name='latitude' value={latitude} onChange={eventChangeHandler}/>
                    </div>
                    <div className='EditField'>
                        <label htmlFor="Logitude">Longitude</label>
                        <input type="text" id='Logitude' name='logitude' value={logitude} onChange={eventChangeHandler}/>
                    </div>
                </div>
                <div className="popupDeleteButton">
                    <button className='CancelButton' onClick={props.close}>Cancel</button>
                    <button className='DeleteButton' onClick={() => { props.editHandler(body, props.close) }}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default GeoLocationEdit 