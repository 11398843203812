import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import UploadLoader from "./UploadLoader";
import axios from "axios";
import { MapKey, url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { GoogleMap, Marker, LoadScript, Polygon, LoadScriptNext } from "@react-google-maps/api";
import { warningToaster, successToaster } from "../../Data/Data";
import { makeRequest } from "../../api";
const UploadProperties = ({ file, setFile }) => {
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [location_type, setLocationType] = useState("");
  const [error, setError] = useState(true);
  const [number, setNumber] = useState("");
  const [range, setRange] = useState([]);
  const [camera, setCamera] = useState([]);
  const [cameraId, setCameraId] = useState("");
  const [position, setPosition] = useState(null);
  const [id, setId] = useState(null);
  const api = useContext(TadobaContext);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [totalUpload, setTotalUpload] = useState(0);
  const [completeUpload, setCompleteUpload] = useState(0);

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [defaultYear, setDefaultYear] = useState(api.defaultYear);
  const generateRandomKey = () => {
    const length = 6;
    let key = "";
    const characters = "0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const randomChar = characters.charAt(randomIndex);
      key += randomChar;
    }

    return key;
  };
  // sample commit for deployment

  const uploadAllImages = (imageFiles) => {
    const batchSize = 10;
    const batches = [];
    let images_data = [];
    let totalUploaded = 0;
    let randomKey = generateRandomKey();
    let totalUploadedSize = 0;
    let fileList = imageFiles;
    for (let i = 0; i < fileList.length; i++) {
      const url = fileList[i];
      totalUploadedSize += url.size;
    }

    api.setProgress({
      ...api.progress,
      totalImages: fileList.length,
      totalUpload: totalUploadedSize,
    });
    setTotalUpload(totalUploadedSize);
    localStorage.setItem("upload_token", randomKey);
    api.setUploadKey(randomKey);

    for (let i = 0; i < imageFiles.length; i += batchSize) {
      const batch = imageFiles.slice(i, i + batchSize);
      batches.push(batch);
    }

    const processBatch = async (count, list, batchNumber, totalImages) => {
      let shift = 0;
      if (batches.length === 0) {
        api.setImages(list);
        api.setUpload(false);
        navigate("./upload_images");
        api.setProgress({
          uploadPercentage: 0,
          totalUpload: 0,
          completeUpload: 0,
        });
        return true;
      }

      const batch = batches.shift();
      let formData = new FormData();
      formData.append("lat", latitude);
      formData.append("long", longitude);
      formData.append("upload_token", localStorage.getItem("upload_token"));
      formData.append("location_type", location_type);
      formData.append("number_type", cameraId);
      formData.append("batch_number", batchNumber);
      formData.append("total_images", totalImages);
      formData.append("camera_id", id);
      formData.append("year", defaultYear);
      for (const key of Object.keys(batch)) {
        formData.append(
          "files",
          new File(
            [batch[key], "extra data: " + batch[key].path],
            batch[key].name,
            { type: batch[key].type }
          )
        );
      }

      let token = "";
      let csrftoken = "";
      api.setUpload(true);
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        if (localStorage.csrf) {
          csrftoken = localStorage.csrf;
        }
        let loadedData;
        const result = await axios({
          url: `${url}api/v1/classification/image_upload_multiple/`,
          withCredentials: true,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            TADOBAAUTH: token,
            "X-CSRFToken": csrftoken,
          },
          data: formData,
          onUploadProgress: (progressEvent) => {
            let { loaded, total } = progressEvent;
            loadedData = Math.floor(
              (progressEvent.loaded * file.length) / progressEvent.total
            );
            shift = total;
            let percent = Math.floor(
              ((count + loaded) / totalUploadedSize) * 100
            );
            api.setProgress({
              totalUpload: totalUploadedSize,
              uploadPercentage: percent,
              completeUpload: count + loaded,
              completedImages: loadedData,
              totalImages: file.length,
            });
          },
        });
        list = [...list, ...result.data.data];
      } catch (error) {
        api.setUpload(false);
        setCamera([]);
        setNumber("");
        setNumber("");
        setLongitude("");
        setLatitude("");
        setPosition(null);
        setError(true);
        toast.success("Unable to Upload", warningToaster);
        return false;
      }
      processBatch(count + shift, list, batchNumber + 1, totalImages);
    };
    processBatch(totalUploaded, images_data, 1, imageFiles.length);
  };

  const fetchPolygon = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/map/map_borders/`);
      const convertedData = result.data.data.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude,
      }));
      setPolygonCoordinates(convertedData);
    } catch (error) {}
  };
  useEffect(() => {
    if (!localStorage.mapborders) {
      fetchPolygon();
    } else {
      const storedData = JSON.parse(localStorage.mapborders);
      setPolygonCoordinates(storedData);
    }
  }, []);

  const numberChangeHandler = (event) => {
    const item = JSON.parse(event.target.value);
    console.log(item);
    setCameraId(item.name);
    setId(item.id);
    setNumber(event.target.value);
    setLatitude(item.latitude.toFixed(6));
    setLongitude(item.longitude.toFixed(6));
    setPosition({
      lat: item.latitude,
      lng: item.longitude,
    });
    setError(false);
  };
  const locationChangeHandler = (event) => {
    setNumber("");
    setLocationType(event.target.value);
    const array = range.filter((val) => {
      return val.name === event.target.value;
    });
    fetchCameras(array[0].id);
  };

  console.log(id);

  const fetchCameras = async (id) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/latlong_list/?range_id=${id}`
      );
      setCamera(result.data.data);
    } catch (error) {}
  };

  const uploadHandler = () => {
    if (number === "") {
      return;
    }
    uploadAllImages(file);
  };

  const cancelHandler = () => {
    setFile([]);
  };

  useEffect(() => {
    const fetchRanges = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/map/range_list/`);
        setRange(result.data.data);
      } catch (error) {}
    };
    fetchRanges();
  }, []);

  const handleMarkerDragEnd = (e) => {
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return (
    <div className="Upload">
      <div className="UploadProperties">
        {api.upload ? (
          <UploadLoader
            setUpload={api.setUpload}
            percent={api.progress.uploadPercentage}
            total={api.progress.totalUpload}
            complete={api.progress.completeUpload}
            totalImages={api.progress.totalImages}
            completedImages={api.progress.completedImages}
            data={"Uploading Images..."}
            type={"general"}
          />
        ) : (
          <>
            <div className="UploadPropertiesHeading">
              <span>Assign Geolocation</span>
            </div>
            <div className="UploadFields">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="UploadImagesGroup">
                  <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                    <img
                      src={file[0].preview}
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={file[1]?.preview}
                      style={{ borderRadius: "0px 0px 0px 8px" }}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={file[2]?.preview}
                      style={{ borderRadius: "0px 0px 8px 0px" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="uploadimagdesc">
                  <span>Images</span>
                  <span style={{ color: "#9A938A" }}>({file.length})</span>
                </div>
              </div>
              <div className="UploadMapProperties">
                <div style={{ height: "100%", width: "100%" }}>
                  <LoadScriptNext googleMapsApiKey={MapKey}>
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      center={
                        position === null
                          ? { lat: 11.962482, lng: 77.15502 }
                          : position
                      }
                      // onClick={handleMarkerDragEnd}
                      zoom={9}
                    >
                      <Polygon
                        paths={polygonCoordinates}
                        options={{
                          fillColor: "skyblue",
                          fillOpacity: 0.4,
                          strokeColor: "blue",
                          strokeOpacity: 0.6,
                          strokeWeight: 2,
                        }}
                      />
                      {position === null ? (
                        ""
                      ) : (
                        <Marker
                          position={position}
                          draggable={true}
                          onDragEnd={handleMarkerDragEnd}
                        />
                      )}
                    </GoogleMap>
                  </LoadScriptNext>
                </div>
              </div>
              <div className="UploadParameters">
                <div className="UploadLocations">
                  <div className="DirectionDropdown">
                    <label htmlFor="">Latitude</label>
                    <input
                      name=""
                      value={latitude}
                      disabled={true}
                      placeholder="Latitude"
                      id=""
                    />
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="">Longitude</label>
                    <input
                      name=""
                      value={longitude}
                      disabled={true}
                      placeholder="Longitude"
                      id=""
                    />
                  </div>
                </div>
                <div className="UploadLocations">
                  <div className="DirectionDropdown">
                    <label htmlFor="">Location type</label>

                    <select onChange={locationChangeHandler} required>
                      <option disabled selected hidden value="">
                        Select or Type Location
                      </option>
                      {range.map((value, key) => {
                        return (
                          <option
                            className="role_filter_span"
                            name={value.id}
                            key={value.id}
                            value={value.name}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="DirectionDropdown">
                    <label htmlFor="">Select Camera</label>

                    <select
                      onChange={numberChangeHandler}
                      value={number}
                      required
                    >
                      <option disabled selected hidden value="">
                        Select or Type Number
                      </option>
                      {camera.map((value, key) => {
                        return (
                          <option
                            className="role_filter_span"
                            name={value.name}
                            key={value.id}
                            value={JSON.stringify(value)}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="UploadPropertiesButton">
              <div onClick={cancelHandler} className="Cancel_button">
                <span>Cancel</span>
              </div>
              <div
                onClick={uploadHandler}
                disabled={error}
                className={
                  error || number === ""
                    ? "Upload_button upload_disabled"
                    : "Upload_button"
                }
              >
                <span>Upload</span>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer rtl={false} style={{ height: "10px" }} />
    </div>
  );
};

export default UploadProperties;
