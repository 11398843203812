import React from 'react';

const CircularProgressLoader = ({ progress, size }) => {
  const radius = size / 2;
  const circumference = 2 * Math.PI * (radius-20);
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg className="circular-progress" width={size} height={size}>
      <circle
        className="circular-progress__background"
        cx={radius}
        cy={radius}
        r={radius - 20}
      />
      <circle
        className="circular-progress__progress"
        cx={radius}
        cy={radius}
        r={radius - 20}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </svg>
  );
};

export default CircularProgressLoader;
