import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { makeRequest } from "../../api";
import IndividualTigerContainer from "../../Containers/IndividualTigerImages/IndividualTigerContainer";
import NoDetail from "../NoDetail";

const TigerImages = () => {
  const { species_id } = useParams();
  const navigate = useNavigate();
  const [organizedData, setOrganizedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setLoading(true);
    const getImages = async () => {
      try {
        const result = await makeRequest(
          "get",
          `/api/v1/identification/identified_tiger_group/?page=${page}&size=20`
        );
        const temp = result.data.data;

        if (!temp || Object.keys(temp).length === 0) {
          setHasMore(false);
        } else {
          setOrganizedData((prevData) => {
            const updatedData = { ...prevData };
            Object.keys(temp).forEach((groupName) => {
              const groupKey =
                groupName === "null" ? "Unclassified" : groupName;
              updatedData[groupKey] = [
                ...(updatedData[groupKey] || []),
                ...temp[groupName].map((item) => ({ ...item, checked: false })),
              ];
            });
            return updatedData;
          });
        }
      } catch (error) {
        console.error("Failed to fetch new images:", error);
      } finally {
        setLoading(false);
      }
    };
    getImages();
  }, [page]);

  useEffect(() => {
    setPage(1);
    setOrganizedData({});
    setHasMore(true);
  }, [species_id]);

  const handleGroupClick = useCallback(
    (groupName) => {
      navigate(`${encodeURIComponent(groupName)}`);
    },
    [navigate]
  );

  return (
    <div className="Setting_User">
      <div className="Setting_User_Header">
        <div>
          <span>Images</span>
        </div>
        <div className="Setting_User_Attribute">
          <div className="role_search">
            <input type="text" placeholder="Search by name" />
            <img src={require("../../Assets/Icons/search_icon.png")} alt="" />
          </div>
          {/* <div className="role_Calander">
            <img src={require("../../Assets/Icons/Calander.png")} alt="" />
          </div> */}
        </div>
      </div>

      <IndividualTigerContainer
        organizedData={organizedData}
        lastElementRef={lastElementRef}
        handleGroupClick={handleGroupClick}
      />
      {loading && <NoDetail loading={loading} />}
    </div>
  );
};

export default TigerImages;
