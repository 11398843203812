import React from "react";

const DeleteImage = (props) => {
  console.log(props.multiple)
  return (
    <div className="popupDeleteRole">
      <div className="popupDeleteContent">
        <div className="popupDeleteHeader">
          <div>
            <span>Delete Image</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div className="popupMainContent">
          {props.multiple ? (
            <>
              <div style={{ width: "344px" }}>
                <span>
                  Are you sure you want to delete {props.count} Images
                </span>
              </div>
            </>
          ) : (
            <div style={{ width: "344px" }}>
              <span>
                Are you sure you want to delete {props.name}
                ?
              </span>
            </div>
          )}
        </div>
        <div className="popupDeleteButton">
          <button className="CancelButton" onClick={props.close}>
            Cancel
          </button>
          <button
            className="DeleteButton"
            onClick={() => {
              if (props.multiple) {
                const deletedList = props.data
                  .filter((item) => item.checked === true)
                  .map((image) => image.id);
                props.setCount([])
                props.setDeleteId([])
                props.deleteImage(deletedList, props.close);
              } else {
                props.setCount([])
                props.setDeleteId([])
                props.deleteImage([props.value.id], props.close);
              }
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteImage;
