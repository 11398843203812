import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import TadobaContext from "../Context/TadobaContext";
import LazyImage from "../LazyImage";
import { useNavigate } from "react-router-dom";
import SmallLoader from "../SmallLoader";
import Popup from "reactjs-popup";
import { makeRequest } from "../../api";
import IndividualTigerContainer from "../../Containers/IndividualTigerImages/IndividualTigerContainer";
import { warningToaster, successToaster } from "../../Data/Data";
import { toast } from "react-toastify";
import StatsBar from "../StatsBar";
import { ClipLoader } from "react-spinners";
import leftArrowIcon from "../../Assets/Icons/left_arrow.png";
import rightArrowIcon from "../../Assets/Icons/right_arrow.png";
import { Select } from "antd";
import { GrRevert } from "react-icons/gr";

const IdentifiedContainer = (props) => {
  const api = useContext(TadobaContext);
  const [uploadView, setUploadview] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [classification, setClassification] = useState(api.classification);
  const [identifiedTigers, setIdentifiedTigers] = useState([]);
  const [unIdentifiedTigers, setUnIdentifiedTigers] = useState([]);
  const [selectedTiger, setSelectedTiger] = useState("");
  const [clickedTiger, setClickedTiger] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedTigerIndex, setSelectedTigerIndex] = useState(0);
  const [tigerData, setTigerData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [identifiedData, setIdentifiedData] = useState([]);
  const [unableToIdentifyTigers, setUnableToIdentifyTigers] = useState([]);
  const [activeButton, setActiveButton] = useState(
    tigerData.length > 0 ? tigerData[0].name : null
  );
  const [activeTab, setActiveTab] = useState("identified");
  const [tigerslist, setTigersList] = useState([]);
  const [chosenTigerOptions, setChosenTigerOptions] = useState([]);
  const [allTigerData, setAllTigerData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedUnidIndex, setSelectedUnidIndex] = useState(null);

  const [tigerIds, setTigerIds] = useState(0);
  const [unIdTigerIds, setunIdTigerIds] = useState(0);

  const AllSpeciesSetup = (users) => {
    let result = [];
    users.map((value, key) => {
      const obj = {
        "Species name": value.name,
        "No. Of Images": value.no_of_images,
      };
      result.push(obj);
    });
    api.setSpecies(result);
  };

  useEffect(() => {
    const fetchSpecies = async () => {
      try {
        const result = await makeRequest("get", `api/v1/species/species/`);
        api.setAllSpecied(result.data.data);
        AllSpeciesSetup(result.data.data);
      } catch (error) {}
    };
    if (!localStorage.species) {
      fetchSpecies();
    } else {
      const storedData = JSON.parse(localStorage.species);
      api.setAllSpecied(storedData);
      AllSpeciesSetup(storedData);
    }
  }, []);

  let classified_items = [];
  for (const key in api.classification?.grouped_data) {
    classified_items.push(key);
  }

  useEffect(() => {
    const unidentifiedIds = [];

    const newClassification = api.classification?.grouped_data.Tiger.map(
      (item) => {
        const { id } = item;

        if (api.identifiedTigers?.unmatched?.includes(id)) {
          const unidentifiedTiger = {
            ...item,
            name: "Unidentified",
            checked: false,
          };
          api.setUnIdentifiedTigers((prev) => [...prev, unidentifiedTiger]);
          setUnIdentifiedTigers((prev) => [...prev, unidentifiedTiger]);

          unidentifiedIds.push(id);
          return unidentifiedTiger;
        } else if (api.identifiedTigers?.unidentified?.includes(id)) {
          const unidentifiedTiger = { ...item, name: "Unable to identify" };

          setUnableToIdentifyTigers((prev) => [...prev, unidentifiedTiger]);
          return unidentifiedTiger;
        } else {
          const identifiedName = api.identifiedTigers?.matched[id];
          const identifiedTiger = identifiedName
            ? { ...item, name: identifiedName }
            : null;
          return identifiedTiger;
        }
      }
    ).filter(Boolean);

    // const unableToIdentifyTigers = newClassification.filter(
    //   (tiger) => tiger.name === "Unable to identify"
    // );

    // setUnableToIdentifyTigers(unableToIdentifyTigers);

    setunIdTigerIds(unidentifiedIds);
    setClassification((prev) => ({
      ...prev,
      grouped_data: {
        ...prev.grouped_data,
        Tiger: newClassification,
      },
    }));
    setUploadview(true);
    const organisedData = organiseData(newClassification);
    api.setIdentifiedTigers(organisedData);

    let valuesArray = [];
    for (const key in organisedData) {
      if (organisedData.hasOwnProperty(key)) {
        valuesArray = valuesArray.concat(organisedData[key]);
      }
    }
    setIdentifiedTigers(valuesArray);
    const data = valuesArray.map((tiger) => tiger.id);
    setTigerIds(data);
  }, []);

  useEffect(() => {
    const fetchTiger = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;
        setAllTigerData(result_data);

        const names = result_data
          .map((tiger) => {
            return {
              value: tiger.id,
              label: tiger.name,
            };
          })
          .filter(Boolean);

        setTigersList(names);
      } catch (error) {
        toast.success("Error While getting Tiger data", warningToaster);
      }
    };
    fetchTiger();
  }, []);

  const organiseData = (data) => {
    return data.reduce((acc, item) => {
      const { name, id, image_key, fileName } = item;
      if (name !== "Unidentified" && name !== "Unable to identify") {
        if (!acc[name]) {
          acc[name] = [];
        }

        acc[name].push({
          id: id,
          image_key: image_key,
          species_data_id: null,
          species_name: name,
          image: fileName,
          checked: false,
        });
      }

      return acc;
    }, {});
  };

  const lastElementRef = useCallback();

  const handleGroupClick = useCallback();

  const identifyHandler = async (id, type) => {
    setLoading(false);
    let token = "";
    try {
      const data = new FormData();
      data.append("image_id", id);
      data.append("tiger_id", tigerData[selectedTigerIndex].id);
      data.append("origin", "upload");
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/identification/tiger_identify`,
        data
      );
      // setIsPopupOpen(false);
      setIdentifiedData(result.data.data);
      identifyTiger(
        result.data.data,
        unIdentifiedTigers,
        unableToIdentifyTigers,
        identifiedTigers,
        type
      );
      if (type === "identified") {
        handleNext(selectedIndex, setSelectedIndex, tigerIds);
      } else if (type === "unidentified") {
        handleNext(selectedUnidIndex, setSelectedUnidIndex, unIdTigerIds);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function identifyTiger(
    identifiedData,
    unIdentifiedTigers,
    unableToIdentifyTigers,
    identifiedTigers,
    type
  ) {
    const identifiedImageId = parseInt(identifiedData.data.image_id);
    const identifiedSpeciesName = identifiedData.data.name;

    if (type === "unidentified") {
      const index = unIdentifiedTigers.findIndex(
        (tiger) => tiger.id === identifiedImageId
      );
      if (index !== -1) {
        unIdentifiedTigers[index].species_name = identifiedSpeciesName;
        unIdentifiedTigers[index].checked = true;
      } else {
        const identifiedTiger = {
          id: identifiedData.data.image_id,
          species_name: identifiedSpeciesName,
          image: identifiedData.data.image,
          image_key: identifiedData.data.image_key,
          checked: true,
        };
        unIdentifiedTigers.push(identifiedTiger);
      }

      setUnIdentifiedTigers([...unIdentifiedTigers]);
    }
    if (type === "identified") {
      const index = identifiedTigers.findIndex(
        (tiger) => tiger.id === identifiedImageId
      );
      if (index !== -1) {
        identifiedTigers[index].species_name = identifiedSpeciesName;
        identifiedTigers[index].checked = true;
      } else {
        const identifiedTiger = {
          id: identifiedData.data.image_id,
          species_name: identifiedSpeciesName,
          image: identifiedData.data.image,
          image_key: identifiedData.data.image_key,
          checked: true,
        };
        identifiedTigers.push(identifiedTiger);
      }

      setIdentifiedTigers([...identifiedTigers]);
    }
    if (type === "unable") {
      const index = unableToIdentifyTigers.findIndex(
        (tiger) => tiger.id === identifiedImageId
      );
      if (index !== -1) {
        unableToIdentifyTigers[index].name = identifiedSpeciesName;
      } else {
        const unabletoIdentifiedTiger = {
          id: identifiedData.data.image_id,
          name: identifiedSpeciesName,
          fileName: identifiedData.data.image,
          image_key: identifiedData.data.image_key,
          checked: false,
        };
        unableToIdentifyTigers.push(unabletoIdentifiedTiger);
      }

      setUnableToIdentifyTigers([...unableToIdentifyTigers]);
    }
  }

  const fetchAdditionalImages = async (type, id) => {
    setPopupLoading(true);
    setActiveButton(false);
    if (type === "identified") {
      setSelectedIndex(id);
    } else if (type === "unidentified") {
      setSelectedUnidIndex(id);
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }

      const data = { id: id, origin: "upload" };
      const response = await makeRequest(
        "post",
        `api/v1/identification/tiger_images`,
        data
      );
      if (response.data.data.length > 0) {
        const namesArray = response.data.data.map((item) => item.name);
        setOptions(namesArray);
        if (namesArray.length > 0) {
          setIsPopupOpen(true);
        }

        setClickedTiger(id);
        setTigerData(response.data.data);
        setSelectedTigerIndex(0);
        // setIsPopupOpen(false);
        setPopupLoading(false);
      } else {
        setIsPopupOpen(false);
        setPopupLoading(false);
        toast.success("Unable to Identify Tigers", warningToaster);
      }
    } catch (error) {
      setIsPopupOpen(false);
      setPopupLoading(false);
      toast.success("Unable to Identify Tigers", warningToaster);
    }
  };

  useEffect(() => {
    if (selectedIndex && selectedIndex !== null) {
      fetchAdditionalImages("identified", selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (selectedUnidIndex && selectedUnidIndex !== null) {
      fetchAdditionalImages("unidentified", selectedUnidIndex);
    }
  }, [selectedUnidIndex]);

  useEffect(() => {
    if (tigerData.length > 0) {
      setSelectedTiger(
        tigerData[selectedTigerIndex]?.images[selectedImageIndex]?.image_key ||
          ""
      );
    }
  }, [tigerData, selectedImageIndex, selectedTigerIndex]);

  useEffect(() => {
    const selectedOption = tigerData[selectedTigerIndex]?.name;
    const flag = selectedOption === activeButton ? null : selectedOption;
    if (tigerData.length > 0) {
      setActiveButton(flag);
    }
  }, [tigerData]);

  const handleLeftArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        } else {
          return imagesCount - 1;
        }
      });
    }
  };

  const handleRightArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex < imagesCount - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }
  };

  useEffect(() => {
    setIsPopupOpen(false);
    setPopupLoading(false);
  }, [active]);

  const handleNameClick = (selectedOption) => {
    const flag = selectedOption === activeButton ? null : selectedOption;
    setActiveButton(flag);
    const selectedTigerIndex = tigerData.findIndex(
      (tiger) => tiger.name === selectedOption
    );
    if (selectedTigerIndex !== -1) {
      setSelectedTigerIndex(selectedTigerIndex);
      setSelectedImageIndex(0);
    }
  };

  const handleTabChange = (tabName) => {
    setIsPopupOpen(false);
    setActiveTab(tabName);
  };

  const handleSelectChange = (tigerId, filterOption) => {
    const selectedOption = { id: tigerId, image_id: filterOption };
    setChosenTigerOptions((prevOptions) => {
      if (!prevOptions.some((opt) => opt.id === tigerId)) {
        return [...prevOptions, selectedOption];
      }
    });
  };

  const finishIdenitfy = async () => {
    try {
      let token = "";
      for (const option of chosenTigerOptions) {
        const data = new FormData();
        data.append("image_id", option.image_id);
        data.append("tiger_id", option.id);
        data.append("origin", "upload");
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest(
          "post",
          `api/v1/identification/tiger_identify`,
          data
        );
        setIsPopupOpen(false);
        setIdentifiedData(result.data.data);
        identifyTiger(
          result.data.data,
          unIdentifiedTigers,
          unableToIdentifyTigers,
          identifiedTigers,
          "unable"
        );
      }
      setChosenTigerOptions([]);
    } catch (error) {}
  };

  useEffect(() => {
    if (!isPopupOpen) {
      setActiveButton(true);
    }
  }, [isPopupOpen]);

  const handlePrev = (index, setter, ids) => {
    const currentIndex = ids.indexOf(index);
    if (currentIndex > 0) {
      setter(ids[currentIndex - 1]);
    }
  };

  const handleNext = (index, setter, ids) => {
    const currentIndex = ids.indexOf(index);
    if (currentIndex < ids.length - 1) {
      setter(ids[currentIndex + 1]);
    }
  };

  const revertTigerIdentification = async () => {
    const selectedTiger = identifiedTigers.find(
      (item) => item.id === selectedIndex
    );

    if (selectedTiger) {
      const unIdentifiedTiger = {
        ...selectedTiger,
        fileName: selectedTiger.image, // Rename `image` to `fileName`
        name: "Unidentified", // Add new field `name`
        species: "Tiger", // Add new field `species`
      };

      delete unIdentifiedTiger.image; // Remove `image`
      delete unIdentifiedTiger.species_data_id; // Remove `species_data_id`
      delete unIdentifiedTiger.species_name; // Remove `species_name`

      const filteredItems = identifiedTigers.filter(
        (item) => item.id !== selectedIndex
      );
      setIdentifiedTigers(filteredItems);

      setUnIdentifiedTigers((prev) => [...prev, unIdentifiedTiger]);

      // Show success message and reset button state
      toast.success(`Successfully Unidentified Image`, {
        ...warningToaster,
        autoClose: 2000,
      });
      setActiveButton(false);
    }
  };

  return (
    <>
      {popupLoading ? (
        <div className="loading-mask">
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              color={"green"}
              loading={popupLoading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ marginTop: "10px", color: "green" }}>
              Loading Matching Tigers...
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="ClassificationContainer" style={{ height: "100%" }}>
            <div className="ClassificationContainerHeader">
              <div
                className={
                  activeTab === "identified"
                    ? "classification_type classification_type_active"
                    : "classification_type"
                }
                onClick={() => handleTabChange("identified")}
              >
                <img
                  src={require("../../Assets/Icons/ImagesGroup.png")}
                  alt=""
                />
                <span style={{ fontSize: "12px" }}>Identified Tigers</span>
                <span>({Object.keys(identifiedTigers).length})</span>
              </div>
              <div
                className={
                  activeTab === "unidentified"
                    ? "classification_type classification_type_active"
                    : "classification_type"
                }
                onClick={() => handleTabChange("unidentified")}
              >
                <img
                  src={require("../../Assets/Icons/ImageAlert.png")}
                  alt=""
                />
                <span style={{ fontSize: "12px" }}>Unidentified Tigers</span>
                <span>({unIdentifiedTigers.length})</span>
              </div>
              <div
                className={
                  activeTab === "unableToIdentify"
                    ? "classification_type classification_type_active"
                    : "classification_type"
                }
                onClick={() => handleTabChange("unableToIdentify")}
              >
                <img
                  src={require("../../Assets/Icons/ImageAlert.png")}
                  alt=""
                />
                <span style={{ fontSize: "12px" }}>Unable To Identify</span>
                <span>({unableToIdentifyTigers.length})</span>
              </div>
              <div>
                {activeTab === "identified" && (
                  <span style={{ fontSize: "9px" }}>
                    {
                      "* These are the matched tigers where score is > 50% you can manually verify the tiger by clicking on identify tiger."
                    }
                  </span>
                )}
                {activeTab === "unidentified" && (
                  <span style={{ fontSize: "9px" }}>
                    * Please click on Identify Tiger button to manually identify
                    as the matching score for the identification is below 50%.
                  </span>
                )}
                {activeTab === "unableToIdentify" && (
                  <span style={{ fontSize: "9px" }}>
                    * Unable to identify the below images. You can find these
                    images in 'Unidentified' folder in the tiger images and edit
                    them.
                  </span>
                )}
              </div>
            </div>
          </div>
          {uploadView && (
            <>
              {activeTab === "identified" && (
                <div className="Images_View" style={{ marginLeft: "25px" }}>
                  <div className="Images_view">
                    <div
                      className="ImageBox"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {identifiedTigers.map((tiger, index) => (
                        <div
                          key={index}
                          className="Individual_Image"
                          style={{
                            width: "20%",
                            height: "20%",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <LazyImage src={tiger.image} alt={tiger.id} />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <span>{tiger.species_name} *</span>
                            <Popup
                              open={isPopupOpen && clickedTiger === tiger.id}
                              onClose={() => setActiveButton(false)}
                              trigger={
                                <div
                                  style={{
                                    marginTop: "-5px",
                                  }}
                                >
                                  <div
                                    className="Classify_Section Classify_Header Classify_Upload"
                                    onClick={() => {
                                      fetchAdditionalImages(
                                        "identified",
                                        tiger.id
                                      );
                                    }}
                                  >
                                    <span style={{ cursor: "pointer" }}>
                                      {tiger.checked
                                        ? "Identified"
                                        : "Identify Tiger"}
                                    </span>
                                  </div>
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div
                                  className="PopupEditRole"
                                  style={{ width: "1300px" }}
                                >
                                  <div
                                    className="popupEditContentTiger"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="popupEditContentTiger_body">
                                      <div className="body">
                                        <div
                                          className="half"
                                          style={{
                                            width: "45%",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <span>Uploaded Image</span>
                                          <LazyImage
                                            src={tiger.image}
                                            alt="image 1"
                                          />
                                        </div>
                                        <div
                                          className="half"
                                          style={{
                                            width: "45%",
                                            marginRight: "30px",
                                          }}
                                        >
                                          <span>
                                            Matching Tigers (
                                            {
                                              tigerData[selectedTigerIndex]
                                                ?.name
                                            }
                                            )
                                          </span>
                                          {tigerData.length > 0 && (
                                            <LazyImage
                                              src={selectedTiger}
                                              loading="lazy"
                                              alt="Tiger image"
                                            />
                                          )}
                                          {tigerData[selectedTigerIndex]?.images
                                            .length > 0 && (
                                            <>
                                              <img
                                                src={leftArrowIcon}
                                                alt="Left Arrow"
                                                className="arrow left-arrow"
                                                onClick={handleLeftArrowClick}
                                              />
                                              <img
                                                src={rightArrowIcon}
                                                alt="Right Arrow"
                                                className="arrow right-arrow"
                                                onClick={handleRightArrowClick}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "30%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginLeft: "450px",
                                        }}
                                      >
                                        <div
                                          style={{ flex: 1, marginTop: "2vh" }}
                                        >
                                          {tigerData.map((tiger, index) => (
                                            <StatsBar
                                              key={tiger.name}
                                              score={tiger.matching_score * 100}
                                              name={
                                                <button
                                                  key={index}
                                                  className={`Setting_Select_Button ${
                                                    tiger.name === activeButton
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    handleNameClick(tiger.name)
                                                  }
                                                  style={{ minWidth: "75px" }}
                                                >
                                                  {tiger.name}
                                                </button>
                                              }
                                              isActive={
                                                tiger.name === activeButton
                                              }
                                            />
                                          ))}
                                        </div>
                                      </div>
                                      <div className="dropdownContainer_Tiger">
                                        <button
                                          className="DeleteButton"
                                          onClick={() =>
                                            identifyHandler(
                                              tiger.id,
                                              "identified"
                                            )
                                          }
                                          disabled={tiger.checked}
                                          style={{
                                            cursor: tiger.checked
                                              ? "no-drop"
                                              : "pointer",
                                          }}
                                        >
                                          {tiger.checked ? "Saved" : "Save"}
                                        </button>
                                        <div className="control-btn-container">
                                          <button
                                            disabled={
                                              tigerIds.indexOf(
                                                selectedIndex
                                              ) === 0
                                            }
                                            style={{
                                              backgroundColor:
                                                tigerIds.indexOf(
                                                  selectedIndex
                                                ) === 0
                                                  ? "#C0C0C0"
                                                  : "#EDF3F2",
                                              cursor:
                                                tigerIds.indexOf(
                                                  selectedIndex
                                                ) === 0
                                                  ? "no-drop"
                                                  : "pointer",
                                            }}
                                            className="control-btn"
                                            onClick={() =>
                                              handlePrev(
                                                selectedIndex,
                                                setSelectedIndex,
                                                tigerIds
                                              )
                                            }
                                          >
                                            Prev
                                          </button>
                                          <button
                                            disabled={
                                              tigerIds.indexOf(
                                                selectedIndex
                                              ) ===
                                              tigerIds.length - 1
                                            }
                                            style={{
                                              backgroundColor:
                                                tigerIds.indexOf(
                                                  selectedIndex
                                                ) ===
                                                tigerIds.length - 1
                                                  ? "#C0C0C0"
                                                  : "#EDF3F2",
                                              cursor:
                                                tigerIds.indexOf(
                                                  selectedIndex
                                                ) ===
                                                tigerIds.length - 1
                                                  ? "no-drop"
                                                  : "pointer",
                                            }}
                                            className="control-btn"
                                            onClick={() =>
                                              handleNext(
                                                selectedIndex,
                                                setSelectedIndex,
                                                tigerIds
                                              )
                                            }
                                          >
                                            Next
                                          </button>
                                        </div>
                                        <div
                                          onClick={revertTigerIdentification}
                                          className="revert_button"
                                          style={{ marginTop: "2vh" }}
                                        >
                                          <GrRevert />
                                          <span> Unidentify Image</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "unidentified" && (
                <div className="Images_View" style={{ marginLeft: "25px" }}>
                  <div className="Images_view">
                    <div
                      className="ImageBox"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {unIdentifiedTigers.map((tiger, index) => (
                        <div
                          key={index}
                          className="Individual_Image"
                          style={{
                            width: "20%",
                            height: "20%",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <LazyImage src={tiger.fileName} alt={tiger.id} />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {tiger.species_name && (
                              <span>{tiger.species_name} *</span>
                            )}
                            <Popup
                              open={isPopupOpen && clickedTiger === tiger.id}
                              onClose={() => setActiveButton(false)}
                              trigger={
                                <div
                                  style={{
                                    marginTop: "-5px",
                                  }}
                                >
                                  <div
                                    className="Classify_Section Classify_Header Classify_Upload"
                                    onClick={() =>
                                      fetchAdditionalImages(
                                        "unidentified",
                                        tiger.id
                                      )
                                    }
                                  >
                                    <span style={{ cursor: "pointer" }}>
                                      {tiger.checked
                                        ? "Identified"
                                        : "Identify Tiger"}
                                    </span>
                                  </div>
                                </div>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div
                                  className="PopupEditRole"
                                  style={{ width: "1300px" }}
                                >
                                  <div className="popupEditContentTiger">
                                    <div className="popupEditContentTiger_body">
                                      <div className="body">
                                        <div
                                          className="half"
                                          style={{
                                            width: "45%",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <span>Uploaded Image</span>
                                          <LazyImage
                                            src={tiger.fileName}
                                            alt="image 1"
                                          />
                                        </div>
                                        <div
                                          className="half"
                                          style={{
                                            width: "45%",
                                            marginRight: "30px",
                                          }}
                                        >
                                          <span>
                                            Matching Tigers (
                                            {
                                              tigerData[selectedTigerIndex]
                                                ?.name
                                            }
                                            )
                                          </span>
                                          {tigerData.length > 0 && (
                                            <LazyImage
                                              src={selectedTiger}
                                              loading="lazy"
                                              alt="Tiger image"
                                            />
                                          )}
                                          {tigerData[selectedTigerIndex]?.images
                                            .length > 0 && (
                                            <>
                                              <img
                                                src={leftArrowIcon}
                                                alt="Left Arrow"
                                                className="arrow left-arrow"
                                                onClick={handleLeftArrowClick}
                                              />
                                              <img
                                                src={rightArrowIcon}
                                                alt="Right Arrow"
                                                className="arrow right-arrow"
                                                onClick={handleRightArrowClick}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "30%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginLeft: "450px",
                                        }}
                                      >
                                        <div
                                          style={{ flex: 1, marginTop: "2vh" }}
                                        >
                                          {tigerData.map((tiger, index) => (
                                            <StatsBar
                                              key={tiger.name}
                                              score={tiger.matching_score * 100}
                                              name={
                                                <button
                                                  key={index}
                                                  className={`Setting_Select_Button ${
                                                    tiger.name === activeButton
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    handleNameClick(tiger.name)
                                                  }
                                                  style={{ minWidth: "75px" }}
                                                >
                                                  {tiger.name}
                                                </button>
                                              }
                                              isActive={
                                                tiger.name === activeButton
                                              }
                                            />
                                          ))}
                                        </div>
                                      </div>
                                      <div className="dropdownContainer_Tiger">
                                        <button
                                          className="DeleteButton"
                                          onClick={() =>
                                            identifyHandler(
                                              tiger.id,
                                              "unidentified"
                                            )
                                          }
                                          disabled={tiger.checked}
                                          style={{
                                            cursor: tiger.checked
                                              ? "no-drop"
                                              : "pointer",
                                          }}
                                        >
                                          {tiger.checked ? "Saved" : "Save"}
                                        </button>
                                        <div className="control-btn-container">
                                          <button
                                            disabled={
                                              unIdTigerIds.indexOf(
                                                selectedUnidIndex
                                              ) === 0
                                            }
                                            style={{
                                              backgroundColor:
                                                unIdTigerIds.indexOf(
                                                  selectedUnidIndex
                                                ) === 0
                                                  ? "#C0C0C0"
                                                  : "#EDF3F2",
                                              cursor:
                                                unIdTigerIds.indexOf(
                                                  selectedUnidIndex
                                                ) === 0
                                                  ? "no-drop"
                                                  : "pointer",
                                            }}
                                            className="control-btn"
                                            onClick={() =>
                                              handlePrev(
                                                selectedUnidIndex,
                                                setSelectedUnidIndex,
                                                unIdTigerIds
                                              )
                                            }
                                          >
                                            Prev
                                          </button>
                                          <button
                                            disabled={
                                              unIdTigerIds.indexOf(
                                                selectedUnidIndex
                                              ) ===
                                              unIdTigerIds.length - 1
                                            }
                                            style={{
                                              backgroundColor:
                                                unIdTigerIds.indexOf(
                                                  selectedUnidIndex
                                                ) ===
                                                unIdTigerIds.length - 1
                                                  ? "#C0C0C0"
                                                  : "#EDF3F2",
                                              cursor:
                                                unIdTigerIds.indexOf(
                                                  selectedUnidIndex
                                                ) ===
                                                unIdTigerIds.length - 1
                                                  ? "no-drop"
                                                  : "pointer",
                                            }}
                                            className="control-btn"
                                            onClick={() =>
                                              handleNext(
                                                selectedUnidIndex,
                                                setSelectedUnidIndex,
                                                unIdTigerIds
                                              )
                                            }
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                      ))}
                      <div ref={lastElementRef}></div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "unableToIdentify" && (
                <div className="Images_View" style={{ marginLeft: "25px" }}>
                  <div className="Images_view">
                    <div
                      className="ImageBox"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {unableToIdentifyTigers.map((tiger, index) => (
                        <div
                          key={index}
                          className="Individual_Image"
                          style={{
                            width: "20%",
                            height: "20%",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LazyImage src={tiger.fileName} alt={tiger.id} />
                          {tiger.name && tiger.name !== "Unable to identify" ? (
                            <span>{tiger.name}</span>
                          ) : (
                            <div
                              className="DirectionDropdown"
                              style={{
                                width: "100%",
                                marginLeft: "50px",
                                marginTop: "5px",
                              }}
                            >
                              <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Assign a tiger"
                                optionFilterProp="children"
                                size={"large"}
                                value={
                                  chosenTigerOptions?.some(
                                    (opt) => opt.id === tiger.id
                                  )
                                    ? tiger.id
                                    : undefined
                                }
                                onChange={(value, option) =>
                                  handleSelectChange(value, tiger.id)
                                }
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(input)
                                }
                                options={tigerslist}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {chosenTigerOptions.length !== 0 ? (
                      <div
                        className="popupDeleteButtonFilter"
                        style={{
                          position: "absolute",
                          bottom: "90px",
                          right: "40px", // Adjust as needed
                        }}
                      >
                        <button
                          style={{ width: "152px", height: "40px" }}
                          className="DeleteButton"
                          onClick={finishIdenitfy}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default IdentifiedContainer;
