import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { warningToaster } from '../../Data/Data'
import { ToastContainer } from 'react-toastify'
import { url } from '../../Data/Data'
const SpeciesAdd = (props) => {
    const [name, setName] = useState("")
    const [warning, setWarning] = useState("This field is required")
    const [nameDisabled, setNameDisabled] = useState(false)
    const nameChangeHandler = (event)=>{
        setNameDisabled(false)
        setName(event.target.value)
    }

    const setSpeciesHandler = (event)=>{
        if(name=="")
        {
            setNameDisabled(true)
        }
        else
        props.speciesAddHandler(name,props.close,setWarning,setNameDisabled)
    }
 
  return (
    <div className='PopupEditRole'>
            <div className='popupEditContent'>
                <div className="popupEditHeader">
                    <div>
                        <span>Add Species</span>
                    </div>
                    <div onClick={props.close}>
                        <img src={require('../../Assets/Icons/Close.png')} alt="" />
                    </div>
                </div>
                <div className="popupMainEditContent">
                    <div className='EditField'>
                        <label htmlFor="name">Species Name</label>
                        <input type="text" value={name} name='name' placeholder='Enter Species name' className={nameDisabled ? "input_warning" : ""} onChange={nameChangeHandler} />
                        {nameDisabled ? <span className="warning" style={{ color: "red" }} >{warning}</span> : ""}
                    </div>
                </div>
                <div className="popupDeleteButtonUsers">
                <button className='CancelButton' onClick={props.close}>Cancel</button>
                    <button className='DeleteButton' onClick={setSpeciesHandler}>Save</button>
                </div>
            </div>
            <ToastContainer rtl={false} style={{ height: "10px" }} />
        </div>
  )
  
}

export default SpeciesAdd