import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllAlerts from '../Components/Alerts/AllAlerts'
import AlertEdit from '../Components/Alerts/AlertEdit'
const Alerts = () => {
  return (
    <div className='settings'>
        <div className="settings_Child">
            <Routes>
                <Route path="/" element={<AllAlerts/>} />
                <Route path="/:alert_id" element={<AlertEdit/>} />
            </Routes>
            {/* <ComingSoon/> */}
        </div>
        </div>
  )
}

export default Alerts