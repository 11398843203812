import React from "react";
import OTPInput from "react-otp-input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
const OtpPage = (props) => {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const submitHandler = () => {};  
  
  const otpChangeHandler = (value)=>{
    props.setError("")
    props.setMessage("")
    setOtp(value)
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div className="Login_Title">
          <span>Enter OTP</span>
        </div>
        <div className="Description">
          <p>Otp is sent to the email id {props.userData.email} and mobile {props.userData.mobile}</p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        <div className="Login_Input">
          <label className="email_icon" htmlFor="login">
            Enter Otp
          </label>
          <OTPInput
            value={otp}
            onChange={otpChangeHandler}
            numInputs={4}
            containerStyle={"OtpContainer"}
            inputStyle={"Otp"}
            placeholder={"----"}
            renderInput={(prop) => <input {...prop} />}
          />
          {props.error==="Otp" ? <label id="warning">{props.message}</label> : ""}
        </div>
        <div className={props.disabled ? "Login_Button" : "Login_Button Login_button_disabled"}>
        <Spin spinning={props.loading}>
                {" "}
                <button
                disabled={props.disabled}
            onClick={() => {
              const json={
                email:props.email,
                otp: otp
              }
              props.OtpSubmitHandler(json);
            }}
          >
            Submit
          </button>
              </Spin>
          
        </div>
      </div>
    </>
  );
};

export default OtpPage;
