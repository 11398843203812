import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TadobaContext from "../Context/TadobaContext";
import OtpPage from "./OtpPage";
import { warningToaster } from "../../Data/Data";
import { toast } from "react-toastify";
import { url } from "../../Data/Data";
import { Spin } from "antd";
import { makeRequest } from "../../api";
const Login_Details = ({ email, setEmail }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("password");
  const api = useContext(TadobaContext);
  const [OtpSent, setOtpSent] = useState(false);
  const [userData, setUserData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  function ValidateEmail(input) {
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var phoneRegex = /^\d{10}$/;

    if (input.match(emailRegex) || input.match(phoneRegex)) {
      return true;
    } else {
      return false;
    }
  }
  const showPasswordHandler = (event) => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const credentialChangeHandler = (event) => {
    setError("");
    setMessage("");
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      if (email === "") {
        setError("email");
        setErrorMessage("Email Id or Mobile Number Required");
      } else if (ValidateEmail(email) === false) {
        setError("email");
        setErrorMessage("Invalid Email Id or Mobile Number");
      }
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    if (message?.includes("Enter a valid email address")) {
      setError("email");
      setErrorMessage("Invalid Email Id");
    } else if (message?.includes("user")) {
      setErrorMessage("User not avilable");
      setError("email");
    } else if (message?.includes("Invalid")) {
      setError("password");
      setErrorMessage("Invalid Password");
    } else if (
      message?.includes(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      )
    ) {
      setErrorMessage(
        "Password must contain at least 1 letter, 1 number, 1 special character, and be at least 10 characters long."
      );
      setError("password");
    } else if (
      message?.includes(
        "Account temporary is blocked on this IP address. Please contact administrator"
      )
    ) {
      setDisabled(true);
      // setErrorMessage(
      //   "Account temporary is blocked on this IP address. Please contact administrator"
      // );
      toast.warning(
        "Account temporary is blocked on this IP address. Please contact administrator",
        warningToaster
      );
    } else if (
      message?.includes("OTP is not valid") ||
      message?.includes("otp value is required")
    ) {
      setError("Otp");
    } else {
      setError("");
    }
  }, [message]);

  const forgotEventHandler = () => {
    navigate("/forgot_password");
  };

  const loginRequest = async (loginData) => {
    setLoading(true);
    try {
      const result = await makeRequest(
        "post",
        `api/v1/account/login/`,
        loginData
      );
      setUserData(result.data.data);
      setOtpSent(true);
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        setMessage(error.response.data.data[0]);
      } else setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const OtpSubmitHandler = async (value) => {
    setLoading(true);
    try {
      const result = await makeRequest(
        "post",
        `api/v1/account/verify_otp_email_sms/`,
        value
      );
      // localStorage.setItem("token", result.data.data.token);
      localStorage.setItem("csrf", result.data.data.csrf);
      localStorage.setItem("image_key", result.data.data.image_key);
      localStorage.removeItem("recent_images");
      localStorage.removeItem("unclassified");
      localStorage.removeItem("top_tigers");
      localStorage.removeItem("top_species");
      localStorage.removeItem("statistic_classification");
      localStorage.removeItem("species");
      localStorage.removeItem("mapborders");
      api.setToken(result.data.data);
      navigate("/forest");
    } catch (error) {
      setError("Otp");
      setMessage(error.response.data.data);
    } finally {
      setLoading(false);
    }
  };

  const LoginHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (password === "") {
      setErrorMessage("The field is required");
      setError("password");
    } else {
      const loginData = {
        email: email,
        password: password,
      };
      loginRequest(encryptPayload(loginData));
    }
  };

  const passwordFocusHandler = () => {
    if (email === "") {
      setErrorMessage("The field is required");
      setError("email");
    } else if (ValidateEmail(email) === false) {
      setError("email");
      setErrorMessage("Invalid Email Id or Mobile Number");
    }
  };

  const encryptAES = (plaintext) => {
    const secretKey = "RUFqlY38xUWND5Yt";
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    const combined = CryptoJS.lib.WordArray.create();
    combined.concat(iv).concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
  };

  const encryptPayload = (payload, secretKey) => {
    const encryptedPayload = {};
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        encryptedPayload[key] = encryptAES(payload[key]);
      }
    }
    return encryptedPayload;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (email === "") {
        setErrorMessage("The field is required");
        setError("email");
      } else if (password === "") {
        setErrorMessage("The field is required");
        setError("password");
      } else {
        const loginData = {
          email: email,
          password: password,
        };
        loginRequest(encryptPayload(loginData));
      }
    }
  };

  return (
    <>
      {!OtpSent ? (
        <>
          <div className="Login_Title">
            <span>Login</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "32px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "24px" }}
            >
              <div className="Login_Input">
                <label className="email_icon" htmlFor="login">
                  Email or Mobile Number
                </label>
                <input
                  type="email"
                  name="email"
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your email or Mobile number"
                  className={error === "email" ? "input_warning" : ""}
                  value={email}
                  autoFocus="autofocus"
                  onChange={credentialChangeHandler}
                  id="login"
                />
                {error === "email" ? (
                  <label id="warning">{errorMessage}</label>
                ) : (
                  ""
                )}
              </div>
              <div className="Login_Input">
                <label className="password_icon" htmlFor="password">
                  Password
                </label>
                <div className="password">
                  <input
                    type={type}
                    name="password"
                    onKeyDown={handleKeyDown}
                    onFocus={passwordFocusHandler}
                    className={error === "password" ? "input_warning" : ""}
                    value={password}
                    onChange={credentialChangeHandler}
                    placeholder="Enter your password"
                    id="password"
                  />
                  {type === "text" ? (
                    <img
                      src={require("../../Assets/Icons/eye.png")}
                      onClick={showPasswordHandler}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={showPasswordHandler}
                      src={require("../../Assets/Icons/eye-off.png")}
                      alt=""
                    />
                  )}
                </div>
                {error === "password" ? (
                  <label id="warning">{errorMessage}</label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={
                !disabled
                  ? "Login_Button"
                  : "Login_Button login_button_disabled"
              }
            >
              <Spin spinning={loading}>
                {" "}
                <button disabled={disabled} onClick={LoginHandler}>
                  Log In
                </button>
              </Spin>
            </div>
          </div>
          <div className="Login_Forgot">
            <span onClick={forgotEventHandler}>Forgot Password ?</span>
          </div>
        </>
      ) : (
        <OtpPage
          email={email}
          userData={userData}
          message={message}
          loading={loading}
          disabled={disabled}
          setMessage={setMessage}
          setError={setError}
          error={error}
          errorMessage={errorMessage}
          OtpSubmitHandler={OtpSubmitHandler}
        />
      )}
    </>
  );
};

export default Login_Details;
