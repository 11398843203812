import React, { useState } from 'react'

const Checkbox = (props) => {

    const [checked, setChecked] = useState(props.checked)
    const [value, setValue] = useState(props.value)
    const [name, setName] = useState(props.name)
    const [id, setId] = useState(props.id)
    return (
        <div className='checkbox' onClick={() => { setChecked(!checked); props.Modify({ checked, value, name, id }) }}>
            {checked ?
                <img src={require('../Assets/Icons/Checked_Box.png')} alt="" /> :
                <img src={require('../Assets/Icons/checkbox.png')} alt="" />
            }
            {/* <span>{props.type}</span> */}
            {props.type ?
                <span>{props.type}</span>
                :
                <span>{value ? value : ""}</span>
            }
        </div>
    )
}

export default Checkbox