import React from 'react'

const Nodata = () => {
  return (
    <div className='Nodata'>
        <img src={require('../Assets/Icons/No_Result.png')} alt="" />
        <span>No results found</span>
    </div>
  )
}

export default Nodata